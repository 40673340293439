import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date in ISO 8601 format */
  Date: { input: string; output: string; }
  /** A UTC date & time with time difference information */
  DateTime: { input: string; output: string; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** A Time representation with HH:MM */
  Time: { input: string; output: string; }
  _Any: { input: any; output: any; }
};

/** Add On related information */
export type AddOn = {
  __typename?: 'AddOn';
  categories: Array<AddOnCategory>;
  category?: Maybe<AddOnCategory>;
  /** Contents of the Add On */
  contents?: Maybe<AddOnContent>;
  /** Description of the Add On */
  description?: Maybe<Scalars['String']['output']>;
  /** Discounted price */
  discountedPrice?: Maybe<Scalars['Float']['output']>;
  /** Document attached to the Add On */
  document?: Maybe<Document>;
  /** Unique Identifier for the Add On */
  id: Scalars['Int']['output'];
  /** Image for the Add On in the given size */
  image?: Maybe<Image>;
  /** True if this addon is discounted */
  isDiscounted: Scalars['Boolean']['output'];
  /** True if this addon is a fruit box */
  isFruitBox: Scalars['Boolean']['output'];
  /** Last 30 days lowest price */
  last30DaysLowestPrice?: Maybe<Scalars['Float']['output']>;
  /** Name of the Add On */
  name: Scalars['String']['output'];
  /** Complete pricing information for the add on */
  pricing?: Maybe<Pricing>;
  /** Number of people this Add On serves */
  servings?: Maybe<Scalars['String']['output']>;
  /** SKU of the Add On */
  sku?: Maybe<Scalars['String']['output']>;
};


/** Add On related information */
export type AddOnImageArgs = {
  size: ImageSizeEnum;
};

/** Bundle Related content */
export type AddOnBundle = {
  __typename?: 'AddOnBundle';
  /** List of Delivery Options available for the Bundle */
  deliveryOptions?: Maybe<Array<Maybe<DeliveryOption>>>;
  /** ID of the Bundle */
  id?: Maybe<Scalars['Int']['output']>;
  /** The graphql_name of the Bundle */
  name?: Maybe<Scalars['String']['output']>;
  /** List of Recipes composing the Add On */
  recipes?: Maybe<Array<Maybe<Recipe>>>;
};


/** Bundle Related content */
export type AddOnBundleDeliveryOptionsArgs = {
  zipcode: Scalars['String']['input'];
};

/** Add On Category related information */
export type AddOnCategory = {
  __typename?: 'AddOnCategory';
  /** Unique Identifier for the category */
  id: Scalars['Int']['output'];
  /** Name of the category */
  name: Scalars['String']['output'];
};

/** The connection type for AddOn. */
export type AddOnConnection = {
  __typename?: 'AddOnConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AddOnEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AddOn>>>;
  /** Relay-based page info */
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Add On contents */
export type AddOnContent = {
  __typename?: 'AddOnContent';
  /** List of Bundles composing the Add On */
  bundles?: Maybe<Array<Maybe<AddOnBundle>>>;
};

/** An edge in a connection. */
export type AddOnEdge = {
  __typename?: 'AddOnEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AddOn>;
};

/** Add on given as a reward */
export type AddOnReward = {
  __typename?: 'AddOnReward';
  categories: Array<AddOnCategory>;
  category?: Maybe<AddOnCategory>;
  /** Contents of the Add On */
  contents?: Maybe<AddOnContent>;
  /** Description of the Add On */
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use `rewardDiscountedPrice` instead */
  discountedPrice?: Maybe<Scalars['Float']['output']>;
  /** Document attached to the Add On */
  document?: Maybe<Document>;
  /** Unique Identifier for the Add On */
  id: Scalars['Int']['output'];
  /** Image for the Add On in the given size */
  image?: Maybe<Image>;
  /** True if this addon is discounted */
  isDiscounted: Scalars['Boolean']['output'];
  /** True if this addon is a fruit box */
  isFruitBox: Scalars['Boolean']['output'];
  /** Last 30 days lowest price */
  last30DaysLowestPrice?: Maybe<Scalars['Float']['output']>;
  /** Name of the Add On */
  name: Scalars['String']['output'];
  /** Complete pricing information for the add on */
  pricing?: Maybe<Pricing>;
  rewardDiscountedPrice?: Maybe<Scalars['Float']['output']>;
  /** Number of people this Add On serves */
  servings?: Maybe<Scalars['String']['output']>;
  /** SKU of the Add On */
  sku?: Maybe<Scalars['String']['output']>;
};


/** Add on given as a reward */
export type AddOnRewardImageArgs = {
  size: ImageSizeEnum;
};

/** Address related information */
export type Address = {
  __typename?: 'Address';
  /** Address validation */
  addressValidation?: Maybe<AddressValidationEnum>;
  /** The city of the recipient */
  city?: Maybe<Scalars['String']['output']>;
  /** The company of the recipient */
  company?: Maybe<Scalars['String']['output']>;
  /** The delivery code of the recipient */
  deliveryCode?: Maybe<Scalars['String']['output']>;
  /** The first name of the recipient */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Complete first line of the address, eg: Berliner Strasse 39 */
  fullStreetAddress?: Maybe<Scalars['String']['output']>;
  /** The house number of the recipient */
  houseNumber?: Maybe<Scalars['String']['output']>;
  /** ID of the Address */
  id: Scalars['Int']['output'];
  /** The instructions of the recipient */
  instructions?: Maybe<Scalars['String']['output']>;
  /** The last name of the recipient */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The first address line of the recipient */
  line1?: Maybe<Scalars['String']['output']>;
  /** The second address line of the recipient */
  line2?: Maybe<Scalars['String']['output']>;
  /** The phone of the recipient */
  phone?: Maybe<Scalars['String']['output']>;
  /** Information about the state of the recipient */
  state?: Maybe<State>;
  /** The timezone of this address */
  timezone: Scalars['String']['output'];
  /** The type of the recipient */
  type?: Maybe<Scalars['String']['output']>;
  /** The zipcode of the recipient */
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** Properties to create An address */
export type AddressInputType = {
  /** Address validation */
  addressValidation?: InputMaybe<AddressValidationEnum>;
  /** The city of the recipient */
  city: Scalars['String']['input'];
  /** The company of the recipient */
  company?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the recipient */
  firstName: Scalars['String']['input'];
  /** The house number of the recipient */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Address. If not provided, a new address will be created */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The instructions of the recipient */
  instructions?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the recipient */
  lastName: Scalars['String']['input'];
  /** The first address line of the recipient */
  line1: Scalars['String']['input'];
  /** The second address line of the recipient */
  line2?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the recipient */
  phone: Scalars['String']['input'];
  /** ID for the state of the recipient */
  stateId?: InputMaybe<Scalars['Int']['input']>;
  /** The type of the recipient */
  type?: InputMaybe<Scalars['String']['input']>;
  /** The zipcode of the recipient */
  zipcode: Scalars['String']['input'];
};

/** List of available address validations */
export enum AddressValidationEnum {
  /** Validated by Loqate */
  LoqateValidated = 'LOQATE_VALIDATED',
  /** Manually entered and confirmed by the user but not validated by Loqate */
  ManuallyNotValidated = 'MANUALLY_NOT_VALIDATED',
  /** Manually entered and validated by Loqate */
  ManuallyValidated = 'MANUALLY_VALIDATED',
  /** other value for validation */
  Other = 'OTHER'
}

/** Adyen Key payment method details */
export type AdyenKey = {
  __typename?: 'AdyenKey';
  /** Payment token reference */
  pspReference?: Maybe<Scalars['String']['output']>;
};

/** Allergen related information */
export type Allergen = {
  __typename?: 'Allergen';
  /** Recipe's shipped ingredients the allergen is contained in. */
  containedIn: Array<Ingredient>;
  /**
   * icon
   * @deprecated This field is deprecated, because logic is incorrect
   */
  icon?: Maybe<Image>;
  /** Image of the allergen. */
  image?: Maybe<Image>;
  /** A runtime generated identifier for the allergen. */
  key: Scalars['String']['output'];
  /** Allergen name. */
  name: Scalars['String']['output'];
};


/** Allergen related information */
export type AllergenImageArgs = {
  size?: InputMaybe<ImageSizeEnum>;
};

/** AssumedIngredient related information */
export type AssumedIngredient = {
  __typename?: 'AssumedIngredient';
  /** Assumed ingredient name */
  name?: Maybe<Scalars['String']['output']>;
};

/** Bank Account payment details */
export type BankAccount = {
  __typename?: 'BankAccount';
  /** Bank Identifier Code */
  bic: Scalars['String']['output'];
  /** International Bank Account Number */
  iban: Scalars['String']['output'];
  /** The bank holder's name */
  name: Scalars['String']['output'];
};

/** List of available Brands */
export enum BrandEnum {
  /** BistroMD */
  Bm = 'BM',
  /** Dinnerly */
  Dn = 'DN',
  /** Marley Spoon */
  Ms = 'MS'
}

/** Chef related information */
export type Chef = {
  __typename?: 'Chef';
  /** Avatar image for the chef in the given size */
  avatar?: Maybe<Image>;
  /** Biography of the chef */
  bio: Scalars['String']['output'];
  /** ID of the chef */
  id: Scalars['Int']['output'];
  /** Name of the chef */
  name: Scalars['String']['output'];
  /** Quote of the chef */
  quote: Scalars['String']['output'];
};


/** Chef related information */
export type ChefAvatarArgs = {
  size: ImageSizeEnum;
};

/** Country related information */
export type Country = {
  __typename?: 'Country';
  /** ID for the country */
  id: Scalars['Int']['output'];
  /** Country iso2 code */
  iso: CountryEnum;
  /** The country name */
  name?: Maybe<Scalars['String']['output']>;
  /** List of states belonging to the country */
  states: Array<State>;
};

/** List of available Countries */
export enum CountryEnum {
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Belgium */
  Be = 'BE',
  /** Germany */
  De = 'DE',
  /** Denmark */
  Dk = 'DK',
  /** Great Britain */
  Gb = 'GB',
  /** Netherlands */
  Nl = 'NL',
  /** Sweden */
  Se = 'SE',
  /** United States of America */
  Us = 'US'
}

/** CreditCard payment details */
export type CreditCard = {
  __typename?: 'CreditCard';
  /** Type of card */
  cardType?: Maybe<Scalars['String']['output']>;
  /** Last 4 digits on the card */
  lastDigits?: Maybe<Scalars['String']['output']>;
  /** Month of expiration */
  month?: Maybe<Scalars['String']['output']>;
  /** Card holder name */
  name?: Maybe<Scalars['String']['output']>;
  /** Year of expiration */
  year?: Maybe<Scalars['String']['output']>;
};

/** Credit Reason related information */
export type CreditReason = {
  __typename?: 'CreditReason';
  /** Unique Identifier for the Credit Reason */
  id: Scalars['Int']['output'];
  /** Name of the Credit Reason */
  name: Scalars['String']['output'];
};

/** Possible values Currency */
export enum CurrencyEnum {
  /** AU Dollar */
  Aud = 'AUD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Great Britan Pound */
  Gbp = 'GBP',
  /** Swedish Krone */
  Sek = 'SEK',
  /** US Dollar */
  Usd = 'USD'
}

/** Customer related information */
export type Customer = {
  __typename?: 'Customer';
  /** Information about the account credit of the customer */
  accountCredit?: Maybe<Scalars['Float']['output']>;
  /** Active payment source */
  activePaymentSource?: Maybe<PaymentSource>;
  /** All add-ons available to the customer */
  addOns: Array<AddOn>;
  /** The default address of the customer */
  address?: Maybe<Address>;
  /** The list of addresses associated with the customer */
  addresses: Array<CustomerAddress>;
  allOrders: OrderConnection;
  /** Currently available specials for the customer */
  availableSpecials?: Maybe<Array<Special>>;
  /** Bookmarked recipes */
  bookmarkedRecipes?: Maybe<Array<UserRecipeBookmark>>;
  /** Customer brand */
  brand: BrandEnum;
  /** List of reward challenges available for the customer */
  challenges: Array<EligibleChallenge>;
  /** The country of the customer */
  country?: Maybe<Country>;
  /** Credits given to a user */
  credits: Array<UserCredit>;
  /** Currency */
  currency: CurrencyEnum;
  /** Delivery stats */
  deliveryStats?: Maybe<DeliveryStats>;
  /** The Email of the customer */
  email?: Maybe<Scalars['String']['output']>;
  /** List of features and their availability for the customer */
  features?: Maybe<Array<Feature>>;
  /** Final delivery for the customer */
  finalDelivery?: Maybe<Delivery>;
  /** The name of the customer */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Customer flags */
  flags: Array<CustomerFlagEnum>;
  /** Information about the food preferences of the customer */
  foodPreferences?: Maybe<UserFoodPreferences>;
  /** id */
  id?: Maybe<Scalars['Int']['output']>;
  /** List of incentives available for the customer */
  incentives: Array<Incentive>;
  /** Selected ingredient preferences */
  ingredientPreferences: Array<IngredientPreference>;
  /** If the customer is vegetarian */
  isVegetarian?: Maybe<Scalars['Boolean']['output']>;
  /** Information about the membership of the customer */
  membership?: Maybe<Membership>;
  /** Next delivery for the customer */
  nextDelivery?: Maybe<NextDelivery>;
  /**
   * List of all the available notification settings. Each notification setting can
   * be enabled/disabled for the current customer.
   */
  notificationSettings: Array<CustomerNotificationSetting>;
  /** List of orders of the customer */
  orders?: Maybe<Array<Order>>;
  /**
   * List of payable pending orders of the customer
   * @deprecated Please use orders(scope: PENDING) instead
   */
  pendingOrders?: Maybe<Array<Order>>;
  /** Selected recipe preferences */
  recipePreferences: Array<RecipePreference>;
  /** Information about the referral feature, such as availability and sent referrals */
  referrals?: Maybe<ReferralFeature>;
  /** Reward stats */
  rewardStats?: Maybe<RewardStats>;
  /** Taste profile steps and data for the customer */
  tasteProfileWizard?: Maybe<TasteProfileWizard>;
  tasteProfileWizardStep?: Maybe<Step>;
  /**
   * Customer makes part of the following test group
   * @deprecated Please use testGroups instead
   */
  testGroup: Scalars['String']['output'];
  /** Customer makes part of the following test groups */
  testGroups: Array<Scalars['String']['output']>;
  /** The customer's timezone */
  timezone?: Maybe<Scalars['String']['output']>;
  /** Opt-ins the customer to trusted payments */
  trusted: Scalars['Boolean']['output'];
  /**
   * Upcoming user promotion settings
   * @deprecated Use Order -> UpcomingPromotion
   */
  upcomingPromotionSettings: Array<UserPromotionSettings>;
  /** Information about the plan of the customer */
  userPlan?: Maybe<UserPlan>;
  /** Type of the user */
  userType?: Maybe<UserType>;
  /** Information about the visit of the customer */
  visit?: Maybe<Visit>;
};


/** Customer related information */
export type CustomerAddOnsArgs = {
  canDisplayDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Customer related information */
export type CustomerAllOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Customer related information */
export type CustomerIncentivesArgs = {
  claimed?: InputMaybe<Scalars['Boolean']['input']>;
  expired?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Customer related information */
export type CustomerOrdersArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<OrderScopeEnum>;
  sortBy?: InputMaybe<OrderSortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};


/** Customer related information */
export type CustomerTasteProfileWizardStepArgs = {
  identifier: TasteProfilePreferenceEnum;
};

/** Customer address association */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  /** The actual address information */
  address: Address;
  /** The customer the address is associated with */
  customer: Customer;
  /** A descriptive name for the address (e.g. 'Home Address' or 'Office') */
  description?: Maybe<Scalars['String']['output']>;
  /** The customer address identifier */
  id: Scalars['Int']['output'];
  /** True if the address is marked the default user address */
  isDefault: Scalars['Boolean']['output'];
};

/** List of possible customer flags */
export enum CustomerFlagEnum {
  /** Customer has Access Locked */
  Locked = 'LOCKED',
  /** Customer has Trusted Payments */
  Trusted = 'TRUSTED',
  /** Customer has VIP status */
  Vip = 'VIP'
}

/**
 * Determines whether a customer wants to receive automatic notifications when
 * certain events occur (e.g. an automated email when an order is skipped)
 */
export type CustomerNotificationSetting = {
  __typename?: 'CustomerNotificationSetting';
  /** Is the notification type enabled for the customer? */
  enabled: Scalars['Boolean']['output'];
  /** Unique identifier for this user setting */
  id: Scalars['Int']['output'];
  /** A (non-localized) Human readable label for the notification type. */
  label: Scalars['String']['output'];
  /** The type of the notification */
  type: NotificationTypeEnum;
};

/** Delivery related information */
export type Delivery = {
  __typename?: 'Delivery';
  /** Date of delivery */
  date?: Maybe<Scalars['Date']['output']>;
  /** The description of the delivery */
  description?: Maybe<Scalars['String']['output']>;
  /** The Id of the delivery */
  id: Scalars['Int']['output'];
  /** The intended date of the delivery */
  intendedDate?: Maybe<Scalars['Date']['output']>;
  /** Is the delivery exceptional? */
  isExceptional?: Maybe<Scalars['Boolean']['output']>;
  /** List of all delivery options to the products of this delivery */
  options?: Maybe<Array<DeliveryOption>>;
  /** The region of the delivery */
  region?: Maybe<Scalars['String']['output']>;
  /** List of shipments from the delivery */
  shipments?: Maybe<Array<Shipment>>;
  /** The current time slot of the delivery */
  timeSlot?: Maybe<TimeSlot>;
};


/** Delivery related information */
export type DeliveryOptionsArgs = {
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** Standartized delivery instruction */
export type DeliveryInstruction = {
  __typename?: 'DeliveryInstruction';
  /** Allow free text input for instruction */
  allowFreeText: Scalars['Boolean']['output'];
  /** Brand */
  brand?: Maybe<Scalars['String']['output']>;
  /** Country */
  country?: Maybe<Scalars['String']['output']>;
  /** Placeholder to be displayed for the free text input */
  freeTextPlaceholder?: Maybe<Scalars['String']['output']>;
  /** Instruction key */
  instructionKey: Scalars['String']['output'];
  /** Localized instruction description */
  localizedText: Scalars['String']['output'];
};

/** Delivery Option related information */
export type DeliveryOption = DeliveryOptionInterface & {
  __typename?: 'DeliveryOption';
  /** The day no further changes can be made to orders (excluding VIP changes) */
  cutoffDate?: Maybe<Scalars['Date']['output']>;
  /** Date of delivery */
  date?: Maybe<Scalars['Date']['output']>;
  /** List of possible Time Slots */
  slots: Array<TimeSlot>;
};

/** Delivery Option related information */
export type DeliveryOptionInterface = {
  /** The day no further changes can be made to orders (excluding VIP changes) */
  cutoffDate?: Maybe<Scalars['Date']['output']>;
  /** Date of delivery */
  date?: Maybe<Scalars['Date']['output']>;
};

/** DeliveryStats related information */
export type DeliveryStats = {
  __typename?: 'DeliveryStats';
  /** Average Gross Price Per Box */
  averageGrossPricePerBox?: Maybe<Scalars['Float']['output']>;
  /** Average Net Price */
  averageNetPricePerBox?: Maybe<Scalars['Float']['output']>;
  /**
   * Currency
   * @deprecated For performance, please use customer.currency instead
   */
  currency?: Maybe<CurrencyEnum>;
  /** Final Delivery Date */
  finalDeliveryDate?: Maybe<Scalars['Date']['output']>;
  /** First Delivery Date */
  firstDeliveryDate?: Maybe<Scalars['Date']['output']>;
  /** Last Delivery Date */
  lastDeliveryDate?: Maybe<Scalars['Date']['output']>;
  /** Next Delivery Date */
  nextDeliveryDate?: Maybe<Scalars['Date']['output']>;
  /** Number of regular orders */
  numberOfOrders?: Maybe<Scalars['Int']['output']>;
  /** Number of special orders */
  numberOfSpecialOrders?: Maybe<Scalars['Int']['output']>;
  /** Order Frequency of the customer */
  orderFrequency?: Maybe<Scalars['Float']['output']>;
  /** Total Revenue */
  totalRevenue?: Maybe<Scalars['Float']['output']>;
  /** Total Voucher Amount */
  totalVoucherAmount?: Maybe<Scalars['Float']['output']>;
};

/** Allergen related information based on diet */
export type DietaryAllergenIcon = {
  __typename?: 'DietaryAllergenIcon';
  /** icon url */
  url?: Maybe<Scalars['String']['output']>;
};

/** Direct Debit payment details */
export type DirectDebit = {
  __typename?: 'DirectDebit';
  /** Bank Identifier Code */
  bic?: Maybe<Scalars['String']['output']>;
  /** International Bank Account Number */
  iban?: Maybe<Scalars['String']['output']>;
  /** Direct Debit Name */
  name?: Maybe<Scalars['String']['output']>;
};

/** Document related information */
export type Document = {
  __typename?: 'Document';
  /** URL of the document */
  url?: Maybe<Scalars['String']['output']>;
};

/** A reward challenge a customer is progressing towards */
export type EligibleChallenge = {
  __typename?: 'EligibleChallenge';
  /** The challenge description */
  description?: Maybe<Scalars['String']['output']>;
  /** The challenge ID */
  id: Scalars['ID']['output'];
  /** The challenge's leading metric (i.e.: order_ordinal) */
  leadingMetric?: Maybe<Scalars['String']['output']>;
  /** The challenge's leading metric target value (i.e.: 5) */
  leadingMetricTargetValue?: Maybe<Scalars['String']['output']>;
  /** The challenge name */
  name: Scalars['String']['output'];
  progress: EligibleChallengeProgress;
  /** The challenge type (i.e.: referral_reward or progress_based) */
  type: Scalars['String']['output'];
};

export type EligibleChallengeProgress = {
  __typename?: 'EligibleChallengeProgress';
  /** The current leading metric value */
  currentValue: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
  /** The target leading metric value */
  targetValue: Scalars['Int']['output'];
};

/** Error type to give more information about what happened to a mutation */
export type Error = {
  __typename?: 'Error';
  /** Matching input field if error is relevant */
  attribute?: Maybe<Scalars['String']['output']>;
  /** Code of the error */
  code?: Maybe<ErrorEnum>;
  /** Localised human readable error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** List of available Error codes */
export enum ErrorEnum {
  /** Authorizing payment failed. */
  AuthorizePaymentFailed = 'AUTHORIZE_PAYMENT_FAILED',
  /** Can't change portion size in first orders */
  CantChangeFirstOrder = 'CANT_CHANGE_FIRST_ORDER',
  /** There was an unexpected error claiming the rewards */
  ClaimIncentiveFailed = 'CLAIM_INCENTIVE_FAILED',
  /** Confirm subscribing user failed */
  ConfirmSubscribeUserFailed = 'CONFIRM_SUBSCRIBE_USER_FAILED',
  /** Create first order failed. */
  CreateOrderFailed = 'CREATE_ORDER_FAILED',
  /** There was an unexpected error creating the referral */
  CreateReferralFailed = 'CREATE_REFERRAL_FAILED',
  /** Customer already exists */
  CustomerAlreadyExists = 'CUSTOMER_ALREADY_EXISTS',
  /** Customer already exists and has an active membership. */
  CustomerAlreadyExistsMembershipValid = 'CUSTOMER_ALREADY_EXISTS_MEMBERSHIP_VALID',
  /** There was an unexpected error reactivating the customer */
  CustomerReactivationFailed = 'CUSTOMER_REACTIVATION_FAILED',
  /** EventReason is not found */
  EventReasonNotFound = 'EVENT_REASON_NOT_FOUND',
  /** Order Not Editable on Mobile */
  FamilyOrderNotEditableOnMobile = 'FAMILY_ORDER_NOT_EDITABLE_ON_MOBILE',
  /** Attribute is not valid. Check Error.attribute and .message fields for more context */
  InvalidAttribute = 'INVALID_ATTRIBUTE',
  /** A provided notification setting id is invalid */
  InvalidNotificationSetting = 'INVALID_NOTIFICATION_SETTING',
  /** Too many add-ons */
  MaxAddOnsReached = 'MAX_ADD_ONS_REACHED',
  /** Too many portions */
  MaxPortionsReached = 'MAX_PORTIONS_REACHED',
  /** Too many add-on types */
  MaxUniqueAddOnsReached = 'MAX_UNIQUE_ADD_ONS_REACHED',
  /** Too few portions */
  MinPortionsReached = 'MIN_PORTIONS_REACHED',
  /** Customer does not have any referrals available. */
  NoAvailableReferrals = 'NO_AVAILABLE_REFERRALS',
  /** No payment method to activate found */
  NoPaymentMethodToActivateFound = 'NO_PAYMENT_METHOD_TO_ACTIVATE_FOUND',
  /** No Plan Match */
  NoPlanMatch = 'NO_PLAN_MATCH',
  /** Order cannot be skipped */
  OrderCannotBeSkipped = 'ORDER_CANNOT_BE_SKIPPED',
  /** Order cannot be unskipped */
  OrderCannotBeUnskipped = 'ORDER_CANNOT_BE_UNSKIPPED',
  /** Order Not Editable */
  OrderNotEditable = 'ORDER_NOT_EDITABLE',
  /** Order is not found. */
  OrderNotFound = 'ORDER_NOT_FOUND',
  /** Potential voucher abuse. */
  PotentialVoucherAbuse = 'POTENTIAL_VOUCHER_ABUSE',
  /** This person is already a member and can not be referred. */
  RefereeAlreadyAMember = 'REFEREE_ALREADY_A_MEMBER',
  /** A required notification setting is missing */
  RequiredNotificationSettingMissing = 'REQUIRED_NOTIFICATION_SETTING_MISSING',
  /** There was an unexpected error saving the notification settings */
  SaveNotificationSettingsFailed = 'SAVE_NOTIFICATION_SETTINGS_FAILED',
  /** Subscribing user failed */
  SubscribeUserFailed = 'SUBSCRIBE_USER_FAILED',
  /** Update user plan failed */
  UpdateUserPlanFailed = 'UPDATE_USER_PLAN_FAILED'
}

/** List of available EventReasons */
export enum EventReasonEnum {
  /** Admin */
  Admin = 'ADMIN',
  /** Customer service did not reply in time */
  CustomerServiceDidNotReplyInTime = 'CUSTOMER_SERVICE_DID_NOT_REPLY_IN_TIME',
  /** Delivery instructions were not followed */
  DeliveryInstructionsWereNotFollowed = 'DELIVERY_INSTRUCTIONS_WERE_NOT_FOLLOWED',
  /** Delivery problem with box */
  DeliveryProblemWithBox = 'DELIVERY_PROBLEM_WITH_BOX',
  /** Delivery slots inconvenient */
  DeliverySlotsInconvenient = 'DELIVERY_SLOTS_INCONVENIENT',
  /** Delivery times inflexible */
  DeliveryTimesInflexible = 'DELIVERY_TIMES_INFLEXIBLE',
  /** Didnt like taste */
  DidntLikeTaste = 'DIDNT_LIKE_TASTE',
  /** Didnt meet expectations */
  DidntMeetExpectations = 'DIDNT_MEET_EXPECTATIONS',
  /** Does not fit with my social plans this week */
  DoesNotFitWithMySocialPlansThisWeek = 'DOES_NOT_FIT_WITH_MY_SOCIAL_PLANS_THIS_WEEK',
  /** Dont like recipes */
  DontLikeRecipes = 'DONT_LIKE_RECIPES',
  /** Ease */
  Ease = 'EASE',
  /** Email no reason */
  EmailNoReason = 'EMAIL_NO_REASON',
  /** Ingredients were damaged */
  IngredientsWereDamaged = 'INGREDIENTS_WERE_DAMAGED',
  /** Ingredient quality not good */
  IngredientQualtityNotGood = 'INGREDIENT_QUALTITY_NOT_GOOD',
  /** Instructions unclear */
  InstructionsUnclear = 'INSTRUCTIONS_UNCLEAR',
  /** Its not good value for money */
  ItsNotGoodValueForMoney = 'ITS_NOT_GOOD_VALUE_FOR_MONEY',
  /** Its too expensive */
  ItsTooExpensive = 'ITS_TOO_EXPENSIVE',
  /** It doesnt fit in my schedule */
  ItDoesntFitInMySchedule = 'IT_DOESNT_FIT_IN_MY_SCHEDULE',
  /** It doesnt fit with my working schedule */
  ItDoesntFitWithMyWorkingSchedule = 'IT_DOESNT_FIT_WITH_MY_WORKING_SCHEDULE',
  /** It does not fit my budget */
  ItDoesNotFitMyBudget = 'IT_DOES_NOT_FIT_MY_BUDGET',
  /** It does not fit my budget this week */
  ItDoesNotFitMyBudgetThisWeek = 'IT_DOES_NOT_FIT_MY_BUDGET_THIS_WEEK',
  /** It does not fit my schedule this week */
  ItDoesNotFitMyScheduleThisWeek = 'IT_DOES_NOT_FIT_MY_SCHEDULE_THIS_WEEK',
  /** It does not fit my weekly budget */
  ItDoesNotFitMyWeeklyBudget = 'IT_DOES_NOT_FIT_MY_WEEKLY_BUDGET',
  /** It takes too much time to cook */
  ItTakesTooMuchTimeToCook = 'IT_TAKES_TOO_MUCH_TIME_TO_COOK',
  /** I am a customer of multiple meal kit services */
  IAmACustomerOfMultipleMealKitServices = 'I_AM_A_CUSTOMER_OF_MULTIPLE_MEAL_KIT_SERVICES',
  /** I am cooking but no one is eating my kids are picky */
  IAmCookingButNoOneIsEatingMyKidsArePicky = 'I_AM_COOKING_BUT_NO_ONE_IS_EATING_MY_KIDS_ARE_PICKY',
  /** I am moving out of your delivery area */
  IAmMovingOutOfYourDeliveryArea = 'I_AM_MOVING_OUT_OF_YOUR_DELIVERY_AREA',
  /** I am not happy with the attitude of the customer service employee */
  IAmNotHappyWithTheAttitudeOfTheCustomerServiceEmployee = 'I_AM_NOT_HAPPY_WITH_THE_ATTITUDE_OF_THE_CUSTOMER_SERVICE_EMPLOYEE',
  /** I am not sure if it is worth it */
  IAmNotSureIfItIsWorthIt = 'I_AM_NOT_SURE_IF_IT_IS_WORTH_IT',
  /** I am on a diet */
  IAmOnADiet = 'I_AM_ON_A_DIET',
  /** I am vegan */
  IAmVegan = 'I_AM_VEGAN',
  /** I cook with you for special occasions */
  ICookWithYouForSpecialOccasions = 'I_COOK_WITH_YOU_FOR_SPECIAL_OCCASIONS',
  /** I didnt receive my box */
  IDidntReceiveMyBox = 'I_DIDNT_RECEIVE_MY_BOX',
  /** I did not get the customer support i need */
  IDidNotGetTheCustomerSupportINeed = 'I_DID_NOT_GET_THE_CUSTOMER_SUPPORT_I_NEED',
  /** I did not get the customer support i_needed */
  IDidNotGetTheCustomerSupportINeeded = 'I_DID_NOT_GET_THE_CUSTOMER_SUPPORT_I_NEEDED',
  /** I did not have a good experience in the last weeks */
  IDidNotHaveAGoodExperienceInTheLastWeeks = 'I_DID_NOT_HAVE_A_GOOD_EXPERIENCE_IN_THE_LAST_WEEKS',
  /** I dont like the packaging */
  IDontLikeThePackaging = 'I_DONT_LIKE_THE_PACKAGING',
  /** I dont like the recipes variety */
  IDontLikeTheRecipesVariety = 'I_DONT_LIKE_THE_RECIPES_VARIETY',
  /** I dont want a subscription and would prefer single orders */
  IDontWantASubscriptionAndWouldPreferSingleOrders = 'I_DONT_WANT_A_SUBSCRIPTION_AND_WOULD_PREFER_SINGLE_ORDERS',
  /** I experienced shipping problems */
  IExperiencedShippingProblems = 'I_EXPERIENCED_SHIPPING_PROBLEMS',
  /** I had bad rotten ingredients */
  IHadBadRottenIngredients = 'I_HAD_BAD_ROTTEN_INGREDIENTS',
  /** I had damaged ingredients */
  IHadDamagedIngredients = 'I_HAD_DAMAGED_INGREDIENTS',
  /** I had issues with the ingredients */
  IHadIssuesWithTheIngredients = 'I_HAD_ISSUES_WITH_THE_INGREDIENTS',
  /** I had website app problems */
  IHadWebsiteAppProblems = 'I_HAD_WEBSITE_APP_PROBLEMS',
  /** I have allergies */
  IHaveAllergies = 'I_HAVE_ALLERGIES',
  /** I have quality delivery concerns with ms dn brand */
  IHaveQualityDeliveryConcernsWithMsDnBrand = 'I_HAVE_QUALITY_DELIVERY_CONCERNS_WITH_MS_DN_BRAND',
  /** I need gluten free options */
  INeedGlutenFreeOptions = 'I_NEED_GLUTEN_FREE_OPTIONS',
  /** I need lactose free options */
  INeedLactoseFreeOptions = 'I_NEED_LACTOSE_FREE_OPTIONS',
  /** I need more meal planning flexibility */
  INeedMoreMealPlanningFlexibility = 'I_NEED_MORE_MEAL_PLANNING_FLEXIBILITY',
  /** I prefer another meal kit to marley spoon */
  IPreferAnotherMealKitToMarleySpoon = 'I_PREFER_ANOTHER_MEAL_KIT_TO_MARLEY_SPOON',
  /** I prefer other meal kits recipes this week */
  IPreferOtherMealKitsRecipesThisWeek = 'I_PREFER_OTHER_MEAL_KITS_RECIPES_THIS_WEEK',
  /** I received a discount from another meal kit */
  IReceivedADiscountFromAnotherMealKit = 'I_RECEIVED_A_DISCOUNT_FROM_ANOTHER_MEAL_KIT',
  /** I travel often */
  ITravelOften = 'I_TRAVEL_OFTEN',
  /** I want to try another meal kit */
  IWantToTryAnotherMealKit = 'I_WANT_TO_TRY_ANOTHER_MEAL_KIT',
  /** I want to try a different meal kit */
  IWantToTryADifferentMealKit = 'I_WANT_TO_TRY_A_DIFFERENT_MEAL_KIT',
  /** I want to try my first box before i order again */
  IWantToTryMyFirstBoxBeforeIOrderAgain = 'I_WANT_TO_TRY_MY_FIRST_BOX_BEFORE_I_ORDER_AGAIN',
  /** I want to try the product before subscribing */
  IWantToTryTheProductBeforeSubscribing = 'I_WANT_TO_TRY_THE_PRODUCT_BEFORE_SUBSCRIBING',
  /** I was missing ingredients */
  IWasMissingIngredients = 'I_WAS_MISSING_INGREDIENTS',
  /** I was reactivated without my consent */
  IWasReactivatedWithoutMyConsent = 'I_WAS_REACTIVATED_WITHOUT_MY_CONSENT',
  /** I will be on vacation */
  IWillBeOnVacation = 'I_WILL_BE_ON_VACATION',
  /** I will be using a different meal kit this week */
  IWillBeUsingADifferentMealKitThisWeek = 'I_WILL_BE_USING_A_DIFFERENT_MEAL_KIT_THIS_WEEK',
  /** I will not able to use the service anymore */
  IWillNotAbleToUseTheServiceAnymore = 'I_WILL_NOT_ABLE_TO_USE_THE_SERVICE_ANYMORE',
  /** Marketing account */
  MarketingAccount = 'MARKETING_ACCOUNT',
  /** Missing ingredients */
  MissingIngredients = 'MISSING_INGREDIENTS',
  /** My delivery was damaged */
  MyDeliveryWasDamaged = 'MY_DELIVERY_WAS_DAMAGED',
  /** My delivery was delayed */
  MyDeliveryWasDelayed = 'MY_DELIVERY_WAS_DELAYED',
  /** My delivery was delayed not delivered */
  MyDeliveryWasDelayedNotDelivered = 'MY_DELIVERY_WAS_DELAYED_NOT_DELIVERED',
  /** My family situation is changing */
  MyFamilySituationIsChanging = 'MY_FAMILY_SITUATION_IS_CHANGING',
  /** My kids my family are picky */
  MyKidsMyFamilyArePicky = 'MY_KIDS_MY_FAMILY_ARE_PICKY',
  /** My order was not cancelled */
  MyOrderWasNotCancelled = 'MY_ORDER_WAS_NOT_CANCELLED',
  /** My problem was not resolved */
  MyProblemWasNotResolved = 'MY_PROBLEM_WAS_NOT_RESOLVED',
  /** Skip reason survey */
  None = 'NONE',
  /** Not kid friendly */
  NotKidFriendly = 'NOT_KID_FRIENDLY',
  /** Other */
  Other = 'OTHER',
  /** Other dietary requirement */
  OtherDietaryRequirement = 'OTHER_DIETARY_REQUIREMENT',
  /** Other personal reasons */
  OtherPersonalReasons = 'OTHER_PERSONAL_REASONS',
  /** Payment failure */
  PaymentFailure = 'PAYMENT_FAILURE',
  /** Portion size was too small */
  PortionSizeWasTooSmall = 'PORTION_SIZE_WAS_TOO_SMALL',
  /** Prep cooking time */
  PrepCookingTime = 'PREP_COOKING_TIME',
  /** Problems with box */
  ProblemsWithBox = 'PROBLEMS_WITH_BOX',
  /** Quality of ingredients */
  QualityOfIngredients = 'QUALITY_OF_INGREDIENTS',
  /** Quality problem with box */
  QualityProblemWithBox = 'QUALITY_PROBLEM_WITH_BOX',
  /** Recent problem with box */
  RecentProblemWithBox = 'RECENT_PROBLEM_WITH_BOX',
  /** Service too inflexible */
  ServiceTooInflexible = 'SERVICE_TOO_INFLEXIBLE',
  /** Serving sizes too small */
  ServingSizesTooSmall = 'SERVING_SIZES_TOO_SMALL',
  /** Skipping more than 8 weeks */
  SkippingMoreThan_8Weeks = 'SKIPPING_MORE_THAN_8_WEEKS',
  /** Subscription too inflexible */
  SubscriptionTooInflexible = 'SUBSCRIPTION_TOO_INFLEXIBLE',
  /** Takes too much time */
  TakesTooMuchTime = 'TAKES_TOO_MUCH_TIME',
  /** Taste */
  Taste = 'TASTE',
  /** Test account */
  TestAccount = 'TEST_ACCOUNT',
  /** There is too much packaging */
  ThereIsTooMuchPackaging = 'THERE_IS_TOO_MUCH_PACKAGING',
  /** The delivery times day of the week do not suit me */
  TheDeliveryTimesDayOfTheWeekDoNotSuitMe = 'THE_DELIVERY_TIMES_DAY_OF_THE_WEEK_DO_NOT_SUIT_ME',
  /** The ingredients do not fit my dietary requirements */
  TheIngredientsDoNotFitMyDietaryRequirements = 'THE_INGREDIENTS_DO_NOT_FIT_MY_DIETARY_REQUIREMENTS',
  /** The portion sizes are too small */
  ThePortionSizesAreTooSmall = 'THE_PORTION_SIZES_ARE_TOO_SMALL',
  /** The quality of ingredients was disappointing */
  TheQualityOfIngredientsWasDisappointing = 'THE_QUALITY_OF_INGREDIENTS_WAS_DISAPPOINTING',
  /** The recipes are boring */
  TheRecipesAreBoring = 'THE_RECIPES_ARE_BORING',
  /** The recipes are boring simple */
  TheRecipesAreBoringSimple = 'THE_RECIPES_ARE_BORING_SIMPLE',
  /** The recipes are repetitive */
  TheRecipesAreRepetitive = 'THE_RECIPES_ARE_REPETITIVE',
  /** The recipes are too exotic */
  TheRecipesAreTooExotic = 'THE_RECIPES_ARE_TOO_EXOTIC',
  /** The recipes dont vary enough */
  TheRecipesDontVaryEnough = 'THE_RECIPES_DONT_VARY_ENOUGH',
  /** The recipes take too long to cook */
  TheRecipesTakeTooLongToCook = 'THE_RECIPES_TAKE_TOO_LONG_TO_COOK',
  /** The recipes were not what i_thought they would be */
  TheRecipesWereNotWhatIThoughtTheyWouldBe = 'THE_RECIPES_WERE_NOT_WHAT_I_THOUGHT_THEY_WOULD_BE',
  /** This weeks recipes dont interest me */
  ThisWeeksRecipesDontInterestMe = 'THIS_WEEKS_RECIPES_DONT_INTEREST_ME',
  /** Took more time than expected */
  TookMoreTimeThanExpected = 'TOOK_MORE_TIME_THAN_EXPECTED',
  /** Too complicated to cook */
  TooComplicatedToCook = 'TOO_COMPLICATED_TO_COOK',
  /** Too expensive */
  TooExpensive = 'TOO_EXPENSIVE',
  /** Too much packaging */
  TooMuchPackaging = 'TOO_MUCH_PACKAGING',
  /** Try competitor */
  TryCompetitor = 'TRY_COMPETITOR',
  /** Try first before second */
  TryFirstBeforeSecond = 'TRY_FIRST_BEFORE_SECOND',
  /** Website app was hard to use */
  WebsiteAppWasHardToUse = 'WEBSITE_APP_WAS_HARD_TO_USE'
}

/** A feature */
export type Feature = {
  __typename?: 'Feature';
  /** If the Feature is enabled for a customer */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Identifier of the Feature */
  name?: Maybe<Scalars['String']['output']>;
};

/** Food Preference Options related information */
export type FoodPreferenceOption = {
  __typename?: 'FoodPreferenceOption';
  /** Is Enabled? */
  enabled: Scalars['Boolean']['output'];
  /** Id */
  id: Scalars['Int']['output'];
  /** Name */
  name: Scalars['String']['output'];
};

/** Fruitbox add on for UserPlan */
export type FruitBox = {
  __typename?: 'FruitBox';
  /** If the fruitbox is available for the user plan */
  available: Scalars['Boolean']['output'];
  /** If the fruitbox is enabled for that user plan */
  enabled: Scalars['Boolean']['output'];
  /** Price information */
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  /** Number of servings per fruitbox */
  servings?: Maybe<Scalars['Int']['output']>;
};

/** Price-related information for a visit */
export type FunnelPrice = {
  __typename?: 'FunnelPrice';
  /** Sum of all additional taxes of all recipes */
  additionalTaxTotal: Scalars['Float']['output'];
  /** Whether or not additional tax disclaimer should be displayed */
  showAdditionalTax: Scalars['Boolean']['output'];
  /** Total price to pay before taxes */
  subtotal: Scalars['Float']['output'];
  /** Tax rate to be applied to this order */
  taxRate: Scalars['Float']['output'];
  /** Total price to pay */
  total: Scalars['Float']['output'];
};

/** Image related information */
export type Image = {
  __typename?: 'Image';
  /** URL of the image */
  url?: Maybe<Scalars['String']['output']>;
};

/** List of available Images Sizes */
export enum ImageSizeEnum {
  /** Large size */
  Large = 'LARGE',
  /** Medium size */
  Medium = 'MEDIUM',
  /** Small size */
  Small = 'SMALL',
  /** Thumbnail size */
  Thumb = 'THUMB'
}

/** Incentive related information */
export type Incentive = {
  __typename?: 'Incentive';
  /** The challenge associated with the incentive */
  challenge: IncentiveChallenge;
  /** The time it was claimed (format based on ISO 8601) */
  claimedAt?: Maybe<Scalars['String']['output']>;
  /** The time it expires (format based on ISO 8601) */
  expiresAt: Scalars['String']['output'];
  /** The incentive UUID */
  id: Scalars['String']['output'];
  items?: Maybe<Array<IncentiveItem>>;
  /** The incentive's source ID */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** The incentive's source type (i.e.: Referral) */
  sourceType?: Maybe<Scalars['String']['output']>;
};


/** Incentive related information */
export type IncentiveItemsArgs = {
  orderNumber: Scalars['String']['input'];
};

/** A challenge a customer might get rewarded for */
export type IncentiveChallenge = {
  __typename?: 'IncentiveChallenge';
  /** The challenge description */
  description?: Maybe<Scalars['String']['output']>;
  /** The challenge ID */
  id: Scalars['ID']['output'];
  /** The challenge's leading metric (i.e.: order_ordinal) */
  leadingMetric?: Maybe<Scalars['String']['output']>;
  /** The challenge's leading metric target value (i.e.: 5) */
  leadingMetricTargetValue?: Maybe<Scalars['String']['output']>;
  /** The challenge name */
  name: Scalars['String']['output'];
  /** The challenge type (i.e.: referral_reward or progress_based) */
  type: Scalars['String']['output'];
};

/** An incentive claimed by the customer */
export type IncentiveClaim = {
  __typename?: 'IncentiveClaim';
  /** The time it was claimed */
  claimedAt?: Maybe<Scalars['DateTime']['output']>;
  /** id */
  id: Scalars['Int']['output'];
};

/** The applicable item as an incentive */
export type IncentiveItem = AddOn | AddOnReward | OrderDiscountReward;

/** Possible kinds of ingredients for a recipe */
export type Ingredient = AssumedIngredient | ShippedIngredient;

/** Ingredient Preference */
export type IngredientPreference = {
  __typename?: 'IngredientPreference';
  /** Ingredient Preference ID */
  id: Scalars['Int']['output'];
  /** Ingredient Preference Name */
  name: Scalars['String']['output'];
};

/** Invoice */
export type Invoice = {
  __typename?: 'Invoice';
  /** ID of the invoice */
  id?: Maybe<Scalars['Int']['output']>;
  /** Link to download invoice */
  link?: Maybe<Scalars['String']['output']>;
  /** Unique record string for an invoice */
  number?: Maybe<Scalars['String']['output']>;
};

/** Represents an invoice recipient payment details */
export type InvoiceRecipient = {
  __typename?: 'InvoiceRecipient';
  /** Id of the invoice */
  id: Scalars['Int']['output'];
};

/** Login related information */
export type Login = {
  __typename?: 'Login';
  /** Api token */
  apiToken: Scalars['String']['output'];
  /** Id */
  id: Scalars['Int']['output'];
};

/** Possible outcomes for login response */
export type LoginResponse = Login | QueryErrorWrapper;

/** Meal attribute related information */
export type MealAttribute = {
  __typename?: 'MealAttribute';
  /** Id of the meal attribute */
  id: Scalars['Int']['output'];
  /** Key of the meal attribute */
  key?: Maybe<Scalars['String']['output']>;
  /** Translated meal attribute name */
  name?: Maybe<Scalars['String']['output']>;
  /** Rank of visibility of the meal attribute */
  rank?: Maybe<Scalars['Int']['output']>;
  /** Visibility of the meal attribute on Findability Recipe Tile */
  visible: Scalars['Boolean']['output'];
};

/** MealCategory related information */
export type MealCategory = {
  __typename?: 'MealCategory';
  /** Text to be displayed describing the meal category. */
  displayText?: Maybe<Scalars['String']['output']>;
  /** meal category */
  key?: Maybe<MealType>;
};

/** Given meal and quantity information for an OrderRecipe */
export type MealQuantity = {
  __typename?: 'MealQuantity';
  /** Number of meals for an order_recipe */
  numberOfMeals: Scalars['Int']['output'];
  /** Total number of portions for an order_recipe */
  numberOfPortions: Scalars['Int']['output'];
};

/** List of Meal Types */
export enum MealType {
  /** Fish */
  Fish = 'FISH',
  /** Meat */
  Meat = 'MEAT',
  /** Veggie */
  Veggie = 'VEGGIE',
  /** Wine & Dessert */
  WineAndDessert = 'WINE_AND_DESSERT'
}

/** Membership related information */
export type Membership = {
  __typename?: 'Membership';
  /** Most recent cancelation date - field present only for canceled memberships */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Customer is eligible for express reactivation */
  expressReactivation?: Maybe<Scalars['Boolean']['output']>;
  /** Date when membership will be resumed. Only present if membership is paused */
  pausedUntil?: Maybe<Scalars['Date']['output']>;
  /**
   * Next possible delivery dates for a first order of membership
   * @deprecated Please use startDeliveryOptions instead for more accurate information
   */
  startDeliveryDates: Array<Scalars['Date']['output']>;
  /** Possible delivery options for starting or restarting a membership */
  startDeliveryOptions: Array<DeliveryOption>;
  /** Start date of the Membership */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Stats of the Membership */
  stats?: Maybe<MembershipStats>;
  /** Current status of the membership */
  status?: Maybe<MembershipStatusEnum>;
};

/** MembershipStats related information */
export type MembershipStats = {
  __typename?: 'MembershipStats';
  /** Last cancellation date */
  lastCancellationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Last cancellation reason */
  lastCancellationReason?: Maybe<Scalars['String']['output']>;
  /** Number of reactivations */
  numberOfReactivations?: Maybe<Scalars['Int']['output']>;
};

/** List of possible Membership Statuses */
export enum MembershipStatusEnum {
  /** Membership is active */
  Active = 'ACTIVE',
  /** Not a Member */
  None = 'NONE',
  /** Membership is paused */
  Paused = 'PAUSED',
  /** Membership is stopped */
  Stopped = 'STOPPED'
}

export type MenuContentOptionsInputType = {
  /** Filter order recipes based on favorited status */
  favoritedFilter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter order recipes based on selected categories */
  filterCategories?: InputMaybe<Array<RecipeFilterCategory>>;
  /** Filter order recipes based on selected meal types */
  mealTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter menu contents based on which recipe types are supported by the client */
  supportedRecipeTypes?: InputMaybe<Array<RecipeTypeEnum>>;
};

/** Configuration for multiple promotions to be created */
export type MultiplePromotionSetting = {
  __typename?: 'MultiplePromotionSetting';
  /** If the promotion offers free shipping */
  freeShipping: Scalars['Boolean']['output'];
  /** Which of the n next order to affect */
  orderOrdinal: Scalars['Int']['output'];
  /** Amount of the promocode (fixed or percentage) */
  value: Scalars['Float']['output'];
  /** Amount of the promocode (fixed or percentage) */
  valueType: ValueType;
};

/** List of Error types to provide more information for failures. */
export type MutationErrors = {
  __typename?: 'MutationErrors';
  /** List of errors */
  errors?: Maybe<Array<Error>>;
};

/** The mutation root of the ApiSchema. */
export type MutationRoot = {
  __typename?: 'MutationRoot';
  /** Sends a reset password email for a customer */
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  /** Subscribes a user (creates a customer, sets the user plan and creates and pays the first order) */
  subscribeUser?: Maybe<SubscribeUserResponse>;
  /** Create or update a given visit */
  upsertVisit?: Maybe<Visit>;
};


/** The mutation root of the ApiSchema. */
export type MutationRootResetPasswordArgs = {
  brand: BrandEnum;
  country: CountryEnum;
  email: Scalars['String']['input'];
};


/** The mutation root of the ApiSchema. */
export type MutationRootSubscribeUserArgs = {
  subscription: SubscriptionInputType;
};


/** The mutation root of the ApiSchema. */
export type MutationRootUpsertVisitArgs = {
  visit: VisitInputType;
};

/** Next Delivery related information */
export type NextDelivery = {
  __typename?: 'NextDelivery';
  /** Date of delivery */
  date: Scalars['Date']['output'];
  /** The current time slot of the delivery */
  timeSlot: UpcomingTimeSlot;
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  /** description of the Notification */
  description: Scalars['String']['output'];
  /** title of the Notification */
  title: Scalars['String']['output'];
  /** type of the Notification */
  type: OrderNotificationTypeEnum;
};

/** Available notification types */
export enum NotificationTypeEnum {
  /** E-mail with a reminder to rate recipes from my last box */
  DishRatingSurvey = 'DISH_RATING_SURVEY',
  /** Emails with information about what I need for my upcoming delivery */
  KitchenPreparation = 'KITCHEN_PREPARATION',
  /** Emails with new meal options for the upcoming delivery week */
  PlacedOrderReminder = 'PLACED_ORDER_REMINDER',
  /** Emails with new meal options for skipped delivery weeks */
  SkippedOrderReminder = 'SKIPPED_ORDER_REMINDER'
}

/** Nutritional information (nutritions with insufficient data are filtered out) */
export type NutritionalInformation = {
  __typename?: 'NutritionalInformation';
  /** Nutrition key */
  key: Scalars['String']['output'];
  /** Localized name of the nutrition */
  name: Scalars['String']['output'];
  /** Value per 100g value */
  per100g?: Maybe<Scalars['Float']['output']>;
  /** Value per serving */
  perPortion?: Maybe<Scalars['Float']['output']>;
  /** Total value */
  total?: Maybe<Scalars['Float']['output']>;
  /** Unit of measure */
  unit?: Maybe<Scalars['String']['output']>;
};

/** Order related information */
export type Order = {
  __typename?: 'Order';
  /** Add on categories available for this order */
  addOnCategories: Array<AddOnCategory>;
  addOns: AddOnConnection;
  /**
   * Add ons which are available for this order
   * @deprecated Please use addOns(scope: AVAILABLE) instead
   */
  availableAddOns: Array<AddOn>;
  /** Bill date of the order */
  billDate?: Maybe<Scalars['Date']['output']>;
  /** Information about the billing address */
  billingAddress?: Maybe<Address>;
  /** The brand of the order */
  brand: BrandEnum;
  /** When the order was completed */
  completedAt?: Maybe<Scalars['Date']['output']>;
  /** Information about the contents of the order */
  contents?: Maybe<OrderContents>;
  /** The country of the order */
  country: CountryEnum;
  /** The Customer the order belongs to */
  customer?: Maybe<Customer>;
  /** Number of days until order is not longer editable */
  daysLeftToEdit?: Maybe<Scalars['Int']['output']>;
  /** List of deliveries for the order */
  deliveries?: Maybe<Array<Delivery>>;
  /** Delivery date of the order */
  deliveryDate?: Maybe<Scalars['Date']['output']>;
  /** Check whether the requested features are available for this order */
  featureAvailability: Array<Scalars['Boolean']['output']>;
  /** Identifier of the order */
  id: Scalars['Int']['output'];
  /** Invoice for the order */
  invoice?: Maybe<Invoice>;
  /** Whether Market feature is enabled */
  marketAvailable: Scalars['Boolean']['output'];
  /** Data about the available menu for the order */
  menu?: Maybe<OrderMenu>;
  /** UUID of the menu recommendation at the time of order creation */
  menuRecommendationUuid?: Maybe<Scalars['String']['output']>;
  /** Notifications about the order */
  notifications: Array<Notification>;
  /** Number of the order */
  number: Scalars['String']['output'];
  /** Business Status of the Order */
  orderStatus?: Maybe<OrderStatus>;
  /** Ordinal of the order */
  ordinal?: Maybe<Scalars['Int']['output']>;
  /** The last Payment of the order */
  payment?: Maybe<Payment>;
  /** The current payment state of the order */
  paymentState?: Maybe<OrderPaymentState>;
  /** Information about the Plan that generated the order */
  plan?: Maybe<Plan>;
  /** The limits of the order */
  planLimits: PlanLimits;
  /** Complete pricing information for the add on */
  pricing?: Maybe<OrderPricing>;
  /** Production-related information */
  productionInfo?: Maybe<ProductionInfo>;
  /** Segment of the order */
  segment?: Maybe<SegmentEnum>;
  /** Information about the shipping address */
  shippingAddress?: Maybe<Address>;
  /** Internal State of the Order - do not expose this to the clients */
  state?: Maybe<OrderStateEnum>;
  /**
   * Business Status of the Order
   * @deprecated This field is deprecated, please use orderStatus instead.
   */
  status?: Maybe<OrderStatusEnum>;
  /** Promotion candidate to be used in this order */
  upcomingPromotion?: Maybe<Promotion>;
  /** Last update at */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Pricing information for the upselling banner */
  upsellingBanner?: Maybe<UpsellingBanner>;
};


/** Order related information */
export type OrderAddOnsArgs = {
  addOnCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  canDisplayDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<OrderAddOnScopeEnum>;
};


/** Order related information */
export type OrderFeatureAvailabilityArgs = {
  features: Array<Scalars['String']['input']>;
};


/** Order related information */
export type OrderMenuArgs = {
  contentOptions?: InputMaybe<MenuContentOptionsInputType>;
  favoritedFilter?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  filterCategories?: InputMaybe<Array<RecipeFilterCategory>>;
};


/** Order related information */
export type OrderUpsellingBannerArgs = {
  recipes?: InputMaybe<Array<OrderRecipeInputType>>;
};

/** Information related to the order('s) add on(s) */
export type OrderAddOn = {
  __typename?: 'OrderAddOn';
  /** Information about the add on of the order */
  addOn?: Maybe<AddOn>;
  /** Additional add-on tax per unit */
  additionalTaxTotal: Scalars['Float']['output'];
  /** Flag that indicates whether this add on is available on the order delivery date */
  availableForDelivery: Scalars['Boolean']['output'];
  /** Included add-on tax per unit */
  includedTaxTotal: Scalars['Float']['output'];
  /** Add-on price per unit */
  price: Scalars['Float']['output'];
  /** Quantity of the add on */
  quantity?: Maybe<Scalars['Int']['output']>;
};


/** Information related to the order('s) add on(s) */
export type OrderAddOnAddOnArgs = {
  canDisplayDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The list of possible order addons scopes */
export enum OrderAddOnScopeEnum {
  /** all add ons available to an order */
  Available = 'AVAILABLE',
  /** recommended addons */
  Recommended = 'RECOMMENDED'
}

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Relay-based page info */
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Information related to the contents of the order */
export type OrderContents = {
  __typename?: 'OrderContents';
  /** List of addOns of the order */
  addOns?: Maybe<Array<OrderAddOn>>;
  /** Indicates whether or not this order includes the fruit-box add-on */
  hasFruitbox: Scalars['Boolean']['output'];
  /** List of recipes of the order */
  recipes: Array<OrderRecipe>;
};


/** Information related to the contents of the order */
export type OrderContentsRecipesArgs = {
  state?: InputMaybe<RecipeState>;
};

/** An order discount given as a reward */
export type OrderDiscountReward = {
  __typename?: 'OrderDiscountReward';
  value: Scalars['Float']['output'];
  valueLimit?: Maybe<Scalars['Float']['output']>;
  valueType: ValueType;
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** List of available order frequencies */
export enum OrderFrequencyEnum {
  /** Every 4 weeks */
  FourWeeks = 'FOUR_WEEKS',
  /** Every 3 weeks */
  ThreeWeeks = 'THREE_WEEKS',
  /** Every 2 weeks */
  TwoWeeks = 'TWO_WEEKS',
  /** Weekly */
  Weekly = 'WEEKLY'
}

/** Available menu and options for order menu */
export type OrderMenu = {
  __typename?: 'OrderMenu';
  /** Count of favorite recipes in the menu */
  favoriteRecipesCount: Scalars['Int']['output'];
  /** List of Recipes filter categories */
  filterCategories?: Maybe<Array<OrderRecipeFilterCategory>>;
  /** List of available filters for the menu */
  filters?: Maybe<Array<OrderMenuFilter>>;
  /** List of interested in Recipes */
  interestedInRecipes: Array<OrderRecipe>;
  /** Information to help clients handle menu interactions */
  meta?: Maybe<OrderMenuMeta>;
  /** List of Recipes composing the Menu */
  recipes?: Maybe<Array<OrderRecipe>>;
};

/** Menu Filter object */
export type OrderMenuFilter = {
  __typename?: 'OrderMenuFilter';
  /** If the filter is selected as part of the menu query */
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  /** Unique identifier of the menu filter */
  key?: Maybe<Scalars['String']['output']>;
  /** Translated name of the menu filter. */
  name?: Maybe<Scalars['String']['output']>;
};

/** Meta data for the Order Menu */
export type OrderMenuMeta = {
  __typename?: 'OrderMenuMeta';
  /** List of quantities allowed for order's recipes */
  quantities?: Maybe<Array<OrderMenuMetaQuantity>>;
};

/** Quantity restrictions for order's recipes */
export type OrderMenuMetaQuantity = {
  __typename?: 'OrderMenuMetaQuantity';
  /** Text to be displayed describing the quantity. Ex: '2 Portions' */
  displayText?: Maybe<Scalars['String']['output']>;
  /** Quantity */
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** List of available types of notifications for an order */
export enum OrderNotificationTypeEnum {
  /** Exceptional delivery notification for an Order */
  ExceptionalDelivery = 'EXCEPTIONAL_DELIVERY',
  /** Invalid payment notification for an Order */
  PaymentMethodInvalid = 'PAYMENT_METHOD_INVALID'
}

/** List of all possible order payment states */
export enum OrderPaymentState {
  /** The order is not fully paid yet. */
  BalanceDue = 'BALANCE_DUE',
  /** The order was successfully paid but the customer           paid more than the expected amount. */
  CreditOwed = 'CREDIT_OWED',
  /** The order payment has failed. */
  Failed = 'FAILED',
  /** The order was successfully paid. */
  Paid = 'PAID',
  /** The order payment was cancelled. */
  Void = 'VOID'
}

/** Calcutation for a possible order based on a visit informations */
export type OrderPrice = {
  __typename?: 'OrderPrice';
  /** Sum of all additional taxes of all recipes */
  additionalTaxTotal?: Maybe<Scalars['Float']['output']>;
  /** Whether or not additional tax disclaimer should be displayed */
  showAdditionalTax?: Maybe<Scalars['Boolean']['output']>;
  /** Total price of a possible order without ... */
  subtotal?: Maybe<Scalars['Float']['output']>;
  /** tax rate to be applied to this order */
  taxRate?: Maybe<Scalars['Float']['output']>;
  /** Total price to pay with virtual pricing taken into consideration */
  total?: Maybe<Scalars['Float']['output']>;
};

/** Related information for Order price */
export type OrderPriceInterface = {
  /** Shipment total with virtual pricing taken into consideration */
  shipmentTotal?: Maybe<Scalars['Float']['output']>;
  /** Whether or not additional tax disclaimer should be displayed */
  showAdditionalTax?: Maybe<Scalars['Boolean']['output']>;
  /** Total price to pay with virtual pricing taken into consideration */
  total?: Maybe<Scalars['Float']['output']>;
};

/** Order Pricing related information */
export type OrderPricing = OrderPriceInterface & {
  __typename?: 'OrderPricing';
  /** Total price of all add-ons without any discount/credit */
  addOnsTotal?: Maybe<Scalars['Float']['output']>;
  /** Sum of all additional taxes of all recipes */
  additionalTax?: Maybe<Scalars['Float']['output']>;
  /** Currency of the order */
  currency?: Maybe<CurrencyEnum>;
  /** Total discount applied to order with virtual pricing taken into consideration */
  discount?: Maybe<Scalars['Float']['output']>;
  /** extra fees */
  extraFees?: Maybe<Array<PriceComponent>>;
  /** Total price of all incentives */
  incentivesTotal?: Maybe<Scalars['Float']['output']>;
  /** Sum of all included taxes of all recipes */
  includedTax?: Maybe<Scalars['Float']['output']>;
  /** Total price of the recipes without any discount/credit */
  itemTotal?: Maybe<Scalars['Float']['output']>;
  /** Amount that still needs to be paid for the order */
  outstandingBalance?: Maybe<Scalars['Float']['output']>;
  /** Payment total done so far */
  paymentTotal?: Maybe<Scalars['Float']['output']>;
  /** price breakdown */
  priceBreakdown: Array<PriceComponent>;
  /** Total price of all recipes including extra fees, without any discount/credit */
  recipesTotal?: Maybe<Scalars['Float']['output']>;
  /** Shipment total with virtual pricing taken into consideration */
  shipmentTotal?: Maybe<Scalars['Float']['output']>;
  /** Shipping cost of the order */
  shippingCost?: Maybe<Scalars['Float']['output']>;
  /** Whether or not additional tax disclaimer should be displayed */
  showAdditionalTax?: Maybe<Scalars['Boolean']['output']>;
  /** Total price to pay WITHOUT virtual pricing taken into consideration */
  subtotal?: Maybe<Scalars['Float']['output']>;
  /** Taxes of the order */
  taxes?: Maybe<Array<Maybe<Tax>>>;
  /** Total price to pay with virtual pricing taken into consideration */
  total?: Maybe<Scalars['Float']['output']>;
};


/** Order Pricing related information */
export type OrderPricingPriceBreakdownArgs = {
  usePortions?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Order Recipe related information */
export type OrderRecipe = RecipeInterface & {
  __typename?: 'OrderRecipe';
  /** List of recipe allergens */
  allergens: Array<Allergen>;
  /** List of Assumed ingredients of the Recipe */
  assumedIngredients?: Maybe<Array<AssumedIngredient>>;
  /** List of meal attributes of the Recipe */
  attributes?: Maybe<Array<MealAttribute>>;
  /** Badge for the recipe */
  badge?: Maybe<RecipeBadge>;
  /** Meal category of the recipe */
  category?: Maybe<MealCategory>;
  /** The recipe's chef */
  chef: Chef;
  /** Cooking Tip of the Recipe */
  cookingTip?: Maybe<Scalars['String']['output']>;
  /** Culinary ID */
  culinaryId: Scalars['Int']['output'];
  /** Description of a Recipe */
  description?: Maybe<Scalars['String']['output']>;
  /** Strictest type of diet this recipe fits */
  dietType?: Maybe<Scalars['String']['output']>;
  /** Allergen icons based on diet */
  dietaryAllergenIcons: Array<DietaryAllergenIcon>;
  /** Difficulty Level of a recipe */
  difficulty?: Maybe<RecipeDifficultyEnum>;
  /** Serving time */
  duration?: Maybe<RecipeDuration>;
  /** recipe fees */
  extraFees: Array<PriceComponent>;
  /** Full name of the Recipe with title and subtitle */
  fullName: Scalars['String']['output'];
  /** Heat level icon */
  heatLevelIcon?: Maybe<Image>;
  /** Unique Identifier for the Recipe */
  id?: Maybe<Scalars['Int']['output']>;
  /** Image for the Recipe in the given size */
  image?: Maybe<Image>;
  /** If the recipe is bookmarked or not */
  isBookmarked: Scalars['Boolean']['output'];
  /**
   * If the recipe is a classic or not
   * @deprecated Deprecated field
   */
  isClassic?: Maybe<Scalars['Boolean']['output']>;
  /** If the recipe is recommended or not */
  isRecommended?: Maybe<Scalars['Boolean']['output']>;
  /** List of matched add ons */
  matchedAddOns: Array<AddOn>;
  /** Meal Quantity information for an OrderRecipe */
  mealQuantity: MealQuantity;
  /** Meal Type of the Recipe */
  mealType?: Maybe<MealType>;
  /** Nutritional info for the recipe */
  nutritionalInformation: Array<NutritionalInformation>;
  /** Percentage of local ingredients */
  percentageOfLocalIngredients: Scalars['Int']['output'];
  /**
   * Quantity of the recipe
   * @deprecated Use mealQuantity.numberOfPortions instead.
   */
  quantity?: Maybe<Scalars['Int']['output']>;
  ramenMetadata: Array<RamenRecipeMetadataEntry>;
  /** Recipe Card */
  recipeCardUrl?: Maybe<Scalars['String']['output']>;
  /** Recipe serving time */
  recipeServingTime?: Maybe<Scalars['String']['output']>;
  /** Type of a recipe */
  recipeType: RecipeTypeEnum;
  /** other variants for this recipe */
  recipeVariants: Array<Recipe>;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Information about the Shipped Ingredients */
  shippedIngredients?: Maybe<Array<ShippedIngredient>>;
  /** Slug of the recipe */
  slug?: Maybe<Scalars['String']['output']>;
  /** Preperations steps of the Recipe */
  steps?: Maybe<Array<RecipeStep>>;
  /** Subtitle of the Recipe */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the Recipe */
  title?: Maybe<Scalars['String']['output']>;
  /** Detail of the Recipe */
  url?: Maybe<Scalars['String']['output']>;
  /** User preferences for the recipe :: Available only on Order -> Menu -> Recipes */
  userRecipePreferences?: Maybe<Array<Scalars['String']['output']>>;
  /** users ratings for a recipe */
  userRecipeRating?: Maybe<UserRecipeRating>;
  /** List of utensils of the Recipe */
  utensils?: Maybe<Array<Utensil>>;
  /** Recipe variant name */
  variantName?: Maybe<Scalars['String']['output']>;
  /** The image of the ingredient that is specific to this recipe variant */
  variantSpecificIngredientImage?: Maybe<Scalars['String']['output']>;
};


/** Order Recipe related information */
export type OrderRecipeExtraFeesArgs = {
  feeType?: InputMaybe<PriceComponentTypeEnum>;
};


/** Order Recipe related information */
export type OrderRecipeImageArgs = {
  size: ImageSizeEnum;
};


/** Order Recipe related information */
export type OrderRecipeMatchedAddOnsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Order Recipe related information */
export type OrderRecipeRamenMetadataArgs = {
  fields: Array<Scalars['String']['input']>;
};


/** Order Recipe related information */
export type OrderRecipeRecipeServingTimeArgs = {
  showShortenedUnits?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Order Recipe Filter Category object */
export type OrderRecipeFilterCategory = {
  __typename?: 'OrderRecipeFilterCategory';
  /** Key of the filter */
  key: Scalars['String']['output'];
  /** Localized name of the filter */
  localizedName: Scalars['String']['output'];
  /** List of filter's values */
  values: Array<RecipeCategoryFilterValue>;
};

/** Properties to create a new Order Recipe */
export type OrderRecipeInputType = {
  /** ID of the recipe */
  id: Scalars['Int']['input'];
  /** Quantity of the recipe */
  quantity: Scalars['Int']['input'];
};

/** The list of possible Order scopes. */
export enum OrderScopeEnum {
  /** The scope representing at home orders. */
  AtHome = 'AT_HOME',
  /** The scope representing the orders the customer can complain about. */
  Complainable = 'COMPLAINABLE',
  /** The scope representing completed orders in the last 6 months. */
  Complete = 'COMPLETE',
  /** The scope representing the current orders(AT_HOME + UPCOMING) */
  Current = 'CURRENT',
  /** The scope representing the orders, per user, with outstanding payments. */
  Outstanding = 'OUTSTANDING',
  /** The scope representing the past orders */
  Past = 'PAST',
  /** The scope representing all pending orders. */
  Pending = 'PENDING',
  /** The scope representing all upcoming orders. */
  Upcoming = 'UPCOMING',
  /** The scope representing all orders up to today (including today). */
  UpToToday = 'UP_TO_TODAY'
}

/** List of order sort types. */
export enum OrderSortBy {
  /** Created At */
  CreatedAt = 'CREATED_AT',
  /** Delivery Date */
  DeliveryDate = 'DELIVERY_DATE',
  /** Updated At */
  UpdatedAt = 'UPDATED_AT'
}

/** List of possible Order States */
export enum OrderStateEnum {
  /** Order is cancelled */
  Cancelled = 'CANCELLED',
  /** Order is complete */
  Complete = 'COMPLETE',
  /** Order payment is in progress */
  Payment = 'PAYMENT',
  /** Order is waiting for payment authentication */
  PaymentAuthenticationRequired = 'PAYMENT_AUTHENTICATION_REQUIRED',
  /** Order is waiting for payment authorization */
  PaymentAuthorizationRequired = 'PAYMENT_AUTHORIZATION_REQUIRED',
  /** Order is pending */
  Pending = 'PENDING',
  /** Order is placed */
  Placed = 'PLACED',
  /** Order is skipped */
  Skipped = 'SKIPPED'
}

/** Business Status of the Order */
export type OrderStatus = {
  __typename?: 'OrderStatus';
  canApplyVoucher: Scalars['Boolean']['output'];
  canChangeDeliveryTime: Scalars['Boolean']['output'];
  canEditBox: Scalars['Boolean']['output'];
  /** true if order is skipped or skippable */
  canSkip: Scalars['Boolean']['output'];
  /** true if order can be unskiped */
  canUnskip: Scalars['Boolean']['output'];
  /** Number of hours until order is not longer editable */
  hoursLeftToEdit?: Maybe<Scalars['Int']['output']>;
  /** If the box is being produced */
  inProduction?: Maybe<Scalars['Boolean']['output']>;
  /** Represents the Order Status value */
  key: OrderStatusEnum;
  /** Represents the Order Status value */
  statusV2: OrderStatusEnum;
  /** Represents the Order Status description translation */
  statusV2Description: Scalars['String']['output'];
  /** Represents the Order Status name translation */
  statusV2Name: Scalars['String']['output'];
  /** Represents the Order Status tooltip translation */
  statusV2Tooltip: Scalars['String']['output'];
  unskipFlow?: Maybe<UnskipFlow>;
};

/** Order Bussines Status: Lists the available order         business status recognized by the system. */
export enum OrderStatusEnum {
  /** At Home: The order is “hopefully“ at the clients delivery           place and up to the next 6 days. */
  AtHome = 'AT_HOME',
  /** Cancelled: The order payment failed and past billable */
  Cancelled = 'CANCELLED',
  /**
   * Editable: The order was created and the Cutoff Date/Time           has not
   * been reached. The order can still be “Skipped“ or “Cancelled“.
   */
  Editable = 'EDITABLE',
  /** Ignored: The order was never produced and remain on “non complete”           state after Cutoff Date/Time. */
  Ignore = 'IGNORE',
  /** In Progress: The order is completed and in production. */
  InProgress = 'IN_PROGRESS',
  /**
   * In Transit: The order is produced and on the way to the client.           The
   * order is delivered or about to be delivered. This states           covers the
   * delivering windows time. To the moment we don’t have           feedback from
   * carriers when the order is delivered. So we           assumed it is delivered.
   */
  InTransit = 'IN_TRANSIT',
  /** Past: The order is at client’s place and 6 days are already past. */
  Past = 'PAST',
  /** Payment: The order is ready to get billed during this date */
  Payment = 'PAYMENT',
  /** Payment Error: The order was paid but the payment failed           to be processed. */
  PaymentError = 'PAYMENT_ERROR',
  /** Pending: The order payment failed and still billable */
  Pending = 'PENDING',
  /** Skipped: The order was skipped by the user or a CC agent. */
  Skipped = 'SKIPPED',
  /** Skipped Closed: The order was skipped and the Cutoff Date/Time           passed so it can not be re-scheduled. */
  SkippedClosed = 'SKIPPED_CLOSED',
  /** Trusted: The order payment failed but order will be produced */
  Trusted = 'TRUSTED'
}

/** List of possible values for Order Type */
export enum OrderTypeEnum {
  /** Regular Order */
  Regular = 'REGULAR',
  /** Special Order */
  Special = 'SPECIAL'
}

/** Payment related information */
export type Payment = {
  __typename?: 'Payment';
  /** Amount of the payment */
  amountPaid?: Maybe<Scalars['Float']['output']>;
  /** Payment authentication URL for the payment */
  authenticationUrl?: Maybe<Scalars['String']['output']>;
  /** Payment authorization URL for the payment */
  authorizationUrl?: Maybe<Scalars['String']['output']>;
  /** Payment creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Payment failure reason */
  failureReason?: Maybe<Scalars['String']['output']>;
  /** Payment id */
  id: Scalars['Int']['output'];
  /** PaymentMethod of the payment */
  method?: Maybe<PaymentMethod>;
  /** When the payment was marked as owed */
  owedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Payment Response */
  responseMessage?: Maybe<Scalars['String']['output']>;
  /** Payment source */
  source: PaymentSource;
  /** State of the payment */
  state?: Maybe<Scalars['String']['output']>;
  /** Payment transaction token */
  transactionToken?: Maybe<Scalars['String']['output']>;
  /** Corresponding uuid in paysys */
  uuid?: Maybe<Scalars['String']['output']>;
};

/** Possible Payment source object details */
export type PaymentCredentials = AdyenKey | BankAccount | CreditCard | DirectDebit | InvoiceRecipient | PaypalCustomer | PreapprovalKey;

/** Properties for payment registration */
export type PaymentCredentialsType = {
  /** Account holder (for direct debit) */
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  /** BIC  (for direct debit) */
  bic?: InputMaybe<Scalars['String']['input']>;
  /** BIN (for credit card) */
  binDigits?: InputMaybe<Scalars['String']['input']>;
  /** Birth date (for direct debit & invoicing) */
  birthDate?: InputMaybe<Scalars['String']['input']>;
  /** Name on card (for credit card) */
  cardHolder?: InputMaybe<Scalars['String']['input']>;
  /** Provider token (for credit card & paypal) */
  clientToken?: InputMaybe<Scalars['String']['input']>;
  /** Expiration month (for credit card) */
  expiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** Expiration year (for credit card) */
  expiryYear?: InputMaybe<Scalars['String']['input']>;
  /** IBAN (for direct debit) */
  iban?: InputMaybe<Scalars['String']['input']>;
  /** Last four digits (for credit card) */
  lastFourDigits?: InputMaybe<Scalars['String']['input']>;
  /** Salutation (for direct debit & invoicing) */
  salutation?: InputMaybe<SalutationEnum>;
};

/** Method related information */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** Method id */
  id: Scalars['Int']['output'];
  /** Payment method logos */
  logos?: Maybe<Array<Scalars['String']['output']>>;
  /** Name of the method */
  name: Scalars['String']['output'];
  /** Payment method type */
  type: PaymentMethodTypeEnum;
};

/** List of available payment method types */
export enum PaymentMethodTypeEnum {
  /** Apple Pay */
  Applepay = 'APPLEPAY',
  /** Bancontact */
  Bancontact = 'BANCONTACT',
  /** Credit Card */
  CreditCard = 'CREDIT_CARD',
  /** Direct Debit */
  DirectDebit = 'DIRECT_DEBIT',
  /** Ideal */
  Ideal = 'IDEAL',
  /** Invoicing */
  Invoicing = 'INVOICING',
  /** Braintree */
  Paypal = 'PAYPAL',
  /** Paypal adapative payment */
  PaypalAdaptivePayment = 'PAYPAL_ADAPTIVE_PAYMENT',
  /** Sepa */
  Sepa = 'SEPA'
}

/** Returns the payment source */
export type PaymentSource = {
  __typename?: 'PaymentSource';
  /** If the payment method is currently active */
  active: Scalars['Boolean']['output'];
  /** Details on the payment source, card info, iban etc */
  credentials: PaymentCredentials;
  /** When the payment method was removed */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Payment method */
  method?: Maybe<PaymentMethod>;
};

/** PaypalCustomer, representing both paypal and braintree transactions */
export type PaypalCustomer = {
  __typename?: 'PaypalCustomer';
  /** Customer email */
  email?: Maybe<Scalars['String']['output']>;
};

/** Plan related information */
export type Plan = PlanInterface & {
  __typename?: 'Plan';
  /** If the plan allows for a vegetarian only box */
  allowVegetarian?: Maybe<Scalars['Boolean']['output']>;
  /** Display title for plan */
  displayTitle?: Maybe<Scalars['String']['output']>;
  /**
   * Id of Plan
   * @deprecated Replaced by planIdentifier
   */
  id?: Maybe<Scalars['Int']['output']>;
  /** If the plan should be the default selected plan */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Number of meals per week in the Plan */
  numberOfMeals?: Maybe<Scalars['Int']['output']>;
  /** Number of portions per meal in the Plan */
  numberOfPortions?: Maybe<Scalars['Int']['output']>;
  /** Plan identifier */
  planIdentifier?: Maybe<Scalars['String']['output']>;
  /** Price per portion */
  pricePerPortion?: Maybe<Scalars['Float']['output']>;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Segment display title of the plan */
  segmentDisplayTitle?: Maybe<Scalars['String']['output']>;
  /** Shipping cost for plan */
  shippingCost?: Maybe<Scalars['Float']['output']>;
  /** If shipping cost should be shown */
  shouldShowShipping: Scalars['Boolean']['output'];
  /** Total price of the plan */
  total?: Maybe<Scalars['Float']['output']>;
  /** The total price with savers discount applied */
  totalWithSavers?: Maybe<Scalars['Float']['output']>;
  /** Total price of plan including shipping cost */
  totalWithShipping?: Maybe<Scalars['Float']['output']>;
};

/** Plan related information */
export type PlanInterface = {
  /** If the plan allows for a vegetarian only box */
  allowVegetarian?: Maybe<Scalars['Boolean']['output']>;
  /** Display title for plan */
  displayTitle?: Maybe<Scalars['String']['output']>;
  /**
   * Id of Plan
   * @deprecated Replaced by planIdentifier
   */
  id?: Maybe<Scalars['Int']['output']>;
  /** If the plan should be the default selected plan */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Number of meals per week in the Plan */
  numberOfMeals?: Maybe<Scalars['Int']['output']>;
  /** Number of portions per meal in the Plan */
  numberOfPortions?: Maybe<Scalars['Int']['output']>;
  /** Plan identifier */
  planIdentifier?: Maybe<Scalars['String']['output']>;
  /** Price per portion */
  pricePerPortion?: Maybe<Scalars['Float']['output']>;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Segment display title of the plan */
  segmentDisplayTitle?: Maybe<Scalars['String']['output']>;
  /** Shipping cost for plan */
  shippingCost?: Maybe<Scalars['Float']['output']>;
  /** If shipping cost should be shown */
  shouldShowShipping: Scalars['Boolean']['output'];
  /** Total price of the plan */
  total?: Maybe<Scalars['Float']['output']>;
  /** The total price with savers discount applied */
  totalWithSavers?: Maybe<Scalars['Float']['output']>;
  /** Total price of plan including shipping cost */
  totalWithShipping?: Maybe<Scalars['Float']['output']>;
};

/** Limits of the Order */
export type PlanLimits = {
  __typename?: 'PlanLimits';
  /** Minimun Number of Meals */
  maxMeals: Scalars['Int']['output'];
  /** Minimun Number of Portions per Meal */
  maxPortions: Scalars['Int']['output'];
  /** Maximal Number of Meals */
  minMeals: Scalars['Int']['output'];
  /** Maximal Number of Portions per Meal */
  minPortions: Scalars['Int']['output'];
};

/** Represents an the pre approval key model for payments with paypal */
export type PreapprovalKey = {
  __typename?: 'PreapprovalKey';
  /** preapproval key id */
  id: Scalars['Int']['output'];
};

export type PriceComponent = {
  __typename?: 'PriceComponent';
  /** Customer facing description for this component */
  label?: Maybe<Scalars['String']['output']>;
  /** Quantity of the price component */
  quantity: Scalars['Int']['output'];
  /** Total amount for this price component */
  totalAmount: Scalars['Float']['output'];
  /** Type of the price component */
  type: PriceComponentTypeEnum;
};

/** List of available price components */
export enum PriceComponentTypeEnum {
  /** Additional shipping price */
  AdditionalShippingPrice = 'ADDITIONAL_SHIPPING_PRICE',
  /** Add ons */
  AddOns = 'ADD_ONS',
  /** Base Price */
  BasePrice = 'BASE_PRICE',
  /** Core Down Fee */
  CoreDownFee = 'CORE_DOWN_FEE',
  /** Discount */
  Discount = 'DISCOUNT',
  /** Fruitbox Fee */
  FruitboxFee = 'FRUITBOX_FEE',
  /** Incentives */
  Incentives = 'INCENTIVES',
  /** Meals */
  Meals = 'MEALS',
  /** Portions */
  Portions = 'PORTIONS',
  /** Premium Fee */
  PremiumFee = 'PREMIUM_FEE',
  /** Recipes */
  Recipes = 'RECIPES',
  /** Shipping Fee */
  ShippingFee = 'SHIPPING_FEE'
}

/** Generic pricing information for any object */
export type Pricing = {
  __typename?: 'Pricing';
  /** Currency */
  currency?: Maybe<CurrencyEnum>;
  /** Gross Value */
  gross?: Maybe<Scalars['Float']['output']>;
  /** Net Value */
  net?: Maybe<Scalars['Float']['output']>;
  /** Shipping cost */
  shipping?: Maybe<Scalars['Float']['output']>;
  /** Included Tax value */
  tax?: Maybe<Scalars['Float']['output']>;
  /** Total cost */
  total?: Maybe<Scalars['Float']['output']>;
};

/** Production-related information */
export type ProductionInfo = {
  __typename?: 'ProductionInfo';
  /** Production date of the order */
  productionDate?: Maybe<Scalars['Date']['output']>;
  /** Production date time of the order, includes TZ */
  productionDatetime?: Maybe<Scalars['DateTime']['output']>;
  /** 4-char code for the production site */
  productionSite?: Maybe<Scalars['String']['output']>;
  /** Amount of hours it takes to produce and ship a box after billing */
  transitTime?: Maybe<Scalars['Int']['output']>;
};

/** A promotion record */
export type Promotion = {
  __typename?: 'Promotion';
  /** Promotion brand */
  brand: BrandEnum;
  /** The code of the promotion */
  code?: Maybe<Scalars['String']['output']>;
  /** Promotion country */
  country: CountryEnum;
  /** The creator of the promotion, null meaning it was created by the system */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** Additional info for the promotion */
  description?: Maybe<Scalars['String']['output']>;
  /** Value of the promotion */
  discount: Scalars['Float']['output'];
  /** Date when the promotion expires */
  expirationDate?: Maybe<Scalars['Date']['output']>;
  /** If the promotion offers free shipping */
  freeShipping?: Maybe<Scalars['Boolean']['output']>;
  /** id */
  id: Scalars['Int']['output'];
  /** Is the promotion valid */
  isValid?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Message displayed on the frontend when you apply the voucher,if left blank
   * will be automatically filled depending on the promotion type
   */
  marketingCopy?: Maybe<Scalars['String']['output']>;
  /** Promotion/Campaign title */
  name?: Maybe<Scalars['String']['output']>;
  /** Config for promotions to affect the N following orders. */
  settings: Array<MultiplePromotionSetting>;
  /** Date when the promotion is valid */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** Which group to target */
  targetingGroup?: Maybe<PromotionTargetingGroup>;
  /** The type of the promotion identifying its channel */
  type?: Maybe<Scalars['String']['output']>;
  /** Date when the promotion becomes active and can be applied */
  validFrom?: Maybe<Scalars['Date']['output']>;
  /** Date until the promotion can be applied */
  validTo?: Maybe<Scalars['Date']['output']>;
  /** Promotion value */
  value: Scalars['Float']['output'];
  /** Limit of discount value to apply on percentage type discounts */
  valueLimit?: Maybe<Scalars['Float']['output']>;
  /** Type of discount, fixed or percentage */
  valueType: ValueType;
};

/** List of possible promotion platform types */
export enum PromotionPlatformType {
  /** Mobile */
  Mobile = 'MOBILE',
  /** Web */
  Web = 'WEB'
}

/** Possible outcomes for promotion response */
export type PromotionResponse = Promotion | QueryErrorWrapper;

/** List of possible targeting groups for promotions */
export enum PromotionTargetingGroup {
  /** Active Customers Only */
  ActiveCustomers = 'active_customers',
  /** New Customers Only */
  NewCustomers = 'new_customers',
  /** Reactivating Customers Only */
  ReactivatingCustomers = 'reactivating_customers'
}

/** List of available Error codes */
export enum PublicErrorCodeEnum {
  /** The email and/or password used for authentication are invalid */
  InvalidEmailPassword = 'INVALID_EMAIL_PASSWORD',
  /** Invalid query params */
  InvalidQueryParams = 'INVALID_QUERY_PARAMS',
  /** Promotion not found */
  PromotionNotFound = 'PROMOTION_NOT_FOUND',
  /** Too many requests */
  TooManyRequests = 'TOO_MANY_REQUESTS',
  /** User locked due to too many failed login attempts */
  UserAccessLocked = 'USER_ACCESS_LOCKED',
  /** User does not exist */
  UserNotFound = 'USER_NOT_FOUND',
  /** Visit not found */
  VisitNotFound = 'VISIT_NOT_FOUND'
}

/** Method related information */
export type PublicPaymentMethod = {
  __typename?: 'PublicPaymentMethod';
  /** Method id */
  id: Scalars['Int']['output'];
  /** Payment method logos */
  logos?: Maybe<Array<Scalars['String']['output']>>;
  /** Name of the method */
  name: Scalars['String']['output'];
  /** Payment method token */
  token?: Maybe<Scalars['String']['output']>;
  /** Payment method type */
  type: PaymentMethodTypeEnum;
};

/** Error that happened during a query */
export type QueryError = {
  __typename?: 'QueryError';
  /** Code of the error */
  errorCode: PublicErrorCodeEnum;
  /** Localised human readable error message */
  message: Scalars['String']['output'];
};

/** All errors that occurred during the query */
export type QueryErrorWrapper = {
  __typename?: 'QueryErrorWrapper';
  /** All errors that happened */
  errors: Array<QueryError>;
};

/** The query root of the ApiSchema. */
export type QueryRoot = {
  __typename?: 'QueryRoot';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  /** Get available recipe and ingredient preferences for the given country and brand */
  availableRecipeAndIngredientPreferences: RecipeAndIngredientPreference;
  /** Get available recipe preferences for the given country and brand */
  availableRecipePreferences: Array<RecipePreference>;
  /** Get the chargeless registration state for params given */
  chargelessRegistration: ChargelessRegistration;
  /** Returns information for a single country */
  country?: Maybe<Country>;
  /** Query to get a list of delivery instructions */
  deliveryInstructions: Array<DeliveryInstruction>;
  /** Fetches the available delivery options for a given country, brand and zipcode */
  deliveryOptions?: Maybe<Array<Maybe<VisitDeliveryOption>>>;
  /** Login */
  login: LoginResponse;
  /** Get menu information for the current and upcoming given number of weeks */
  menu?: Maybe<Array<Maybe<WeekMenu>>>;
  /** Calculate the price of an order given the visit */
  orderPrice: OrderPrice;
  /** Get the payment method information */
  paymentMethods?: Maybe<Array<Maybe<PublicPaymentMethod>>>;
  /** Get the price and sales tax info */
  planPrices: FunnelPrice;
  /** Retrieves the active plans for the given country and brand */
  plans?: Maybe<Array<Plan>>;
  /** Promotion lookup */
  promotions: PromotionResponse;
  /** Lookup of visits given an email country and brand */
  visits: Array<Visit>;
  /** Zipcode validity lookup */
  zipcodeLookup: ZipcodeLookup;
};


/** The query root of the ApiSchema. */
export type QueryRoot_entitiesArgs = {
  representations: Array<Scalars['_Any']['input']>;
};


/** The query root of the ApiSchema. */
export type QueryRootAvailableRecipeAndIngredientPreferencesArgs = {
  brand: BrandEnum;
  country: CountryEnum;
};


/** The query root of the ApiSchema. */
export type QueryRootAvailableRecipePreferencesArgs = {
  brand: BrandEnum;
  country: CountryEnum;
};


/** The query root of the ApiSchema. */
export type QueryRootChargelessRegistrationArgs = {
  brand: Scalars['String']['input'];
  country: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};


/** The query root of the ApiSchema. */
export type QueryRootCountryArgs = {
  iso?: InputMaybe<CountryEnum>;
};


/** The query root of the ApiSchema. */
export type QueryRootDeliveryInstructionsArgs = {
  brand?: InputMaybe<BrandEnum>;
  country?: InputMaybe<CountryEnum>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


/** The query root of the ApiSchema. */
export type QueryRootDeliveryOptionsArgs = {
  brand: BrandEnum;
  country: CountryEnum;
  uuid: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};


/** The query root of the ApiSchema. */
export type QueryRootLoginArgs = {
  brand: BrandEnum;
  country: CountryEnum;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  visitUuid: Scalars['String']['input'];
};


/** The query root of the ApiSchema. */
export type QueryRootMenuArgs = {
  brand: BrandEnum;
  country: CountryEnum;
  numberOfWeeks?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of the ApiSchema. */
export type QueryRootOrderPriceArgs = {
  visit: VisitInputType;
};


/** The query root of the ApiSchema. */
export type QueryRootPaymentMethodsArgs = {
  brand: BrandEnum;
  country: CountryEnum;
};


/** The query root of the ApiSchema. */
export type QueryRootPlanPricesArgs = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  addressStateId?: InputMaybe<Scalars['Int']['input']>;
  brand: BrandEnum;
  city?: InputMaybe<Scalars['String']['input']>;
  country: CountryEnum;
  email?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['Int']['input']>;
  planIdentifier?: InputMaybe<Scalars['String']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};


/** The query root of the ApiSchema. */
export type QueryRootPlansArgs = {
  brand: BrandEnum;
  country: CountryEnum;
  segments?: InputMaybe<Array<InputMaybe<SegmentEnum>>>;
};


/** The query root of the ApiSchema. */
export type QueryRootPromotionsArgs = {
  brand: BrandEnum;
  code: Scalars['String']['input'];
  country: CountryEnum;
  forOrderType?: InputMaybe<OrderTypeEnum>;
  forValue?: InputMaybe<Scalars['Float']['input']>;
  platform: PromotionPlatformType;
  visitUuid: Scalars['String']['input'];
};


/** The query root of the ApiSchema. */
export type QueryRootVisitsArgs = {
  brand: BrandEnum;
  country: CountryEnum;
  email: Scalars['String']['input'];
};


/** The query root of the ApiSchema. */
export type QueryRootZipcodeLookupArgs = {
  brand: BrandEnum;
  country: CountryEnum;
  zipcode: Scalars['String']['input'];
};

export type RamenRecipeMetadataEntry = {
  __typename?: 'RamenRecipeMetadataEntry';
  /** Field */
  field: Scalars['String']['output'];
  isList: Scalars['Boolean']['output'];
  /** value */
  value: Array<Scalars['String']['output']>;
};

/** Recipe related information */
export type Recipe = RecipeInterface & {
  __typename?: 'Recipe';
  /** List of recipe allergens */
  allergens: Array<Allergen>;
  /** List of Assumed ingredients of the Recipe */
  assumedIngredients?: Maybe<Array<AssumedIngredient>>;
  /** List of meal attributes of the Recipe */
  attributes?: Maybe<Array<MealAttribute>>;
  /** Badge for the recipe */
  badge?: Maybe<RecipeBadge>;
  /** Meal category of the recipe */
  category?: Maybe<MealCategory>;
  /** The recipe's chef */
  chef: Chef;
  /** Cooking Tip of the Recipe */
  cookingTip?: Maybe<Scalars['String']['output']>;
  /** Culinary ID */
  culinaryId: Scalars['Int']['output'];
  /** Description of a Recipe */
  description?: Maybe<Scalars['String']['output']>;
  /** Strictest type of diet this recipe fits */
  dietType?: Maybe<Scalars['String']['output']>;
  /** Allergen icons based on diet */
  dietaryAllergenIcons: Array<DietaryAllergenIcon>;
  /** Difficulty Level of a recipe */
  difficulty?: Maybe<RecipeDifficultyEnum>;
  /** Serving time */
  duration?: Maybe<RecipeDuration>;
  /** recipe fees */
  extraFees: Array<PriceComponent>;
  /** Full name of the Recipe with title and subtitle */
  fullName: Scalars['String']['output'];
  /** Heat level icon */
  heatLevelIcon?: Maybe<Image>;
  /** Unique Identifier for the Recipe */
  id?: Maybe<Scalars['Int']['output']>;
  /** Image for the Recipe in the given size */
  image?: Maybe<Image>;
  /** If the recipe is bookmarked or not */
  isBookmarked: Scalars['Boolean']['output'];
  /**
   * If the recipe is a classic or not
   * @deprecated Deprecated field
   */
  isClassic?: Maybe<Scalars['Boolean']['output']>;
  /** List of matched add ons */
  matchedAddOns: Array<AddOn>;
  /** Meal Type of the Recipe */
  mealType?: Maybe<MealType>;
  /** Nutritional info for the recipe */
  nutritionalInformation: Array<NutritionalInformation>;
  /** Percentage of local ingredients */
  percentageOfLocalIngredients: Scalars['Int']['output'];
  ramenMetadata: Array<RamenRecipeMetadataEntry>;
  /** Recipe Card */
  recipeCardUrl?: Maybe<Scalars['String']['output']>;
  /** Recipe serving time */
  recipeServingTime?: Maybe<Scalars['String']['output']>;
  /** Type of a recipe */
  recipeType: RecipeTypeEnum;
  /** other variants for this recipe */
  recipeVariants: Array<Recipe>;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Information about the Shipped Ingredients */
  shippedIngredients?: Maybe<Array<ShippedIngredient>>;
  /** Slug of the recipe */
  slug?: Maybe<Scalars['String']['output']>;
  /** Preperations steps of the Recipe */
  steps?: Maybe<Array<RecipeStep>>;
  /** Subtitle of the Recipe */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the Recipe */
  title?: Maybe<Scalars['String']['output']>;
  /** Detail of the Recipe */
  url?: Maybe<Scalars['String']['output']>;
  /** List of utensils of the Recipe */
  utensils?: Maybe<Array<Utensil>>;
  /** Recipe variant name */
  variantName?: Maybe<Scalars['String']['output']>;
  /** The image of the ingredient that is specific to this recipe variant */
  variantSpecificIngredientImage?: Maybe<Scalars['String']['output']>;
};


/** Recipe related information */
export type RecipeExtraFeesArgs = {
  feeType?: InputMaybe<PriceComponentTypeEnum>;
};


/** Recipe related information */
export type RecipeImageArgs = {
  size: ImageSizeEnum;
};


/** Recipe related information */
export type RecipeMatchedAddOnsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Recipe related information */
export type RecipeRamenMetadataArgs = {
  fields: Array<Scalars['String']['input']>;
};


/** Recipe related information */
export type RecipeRecipeServingTimeArgs = {
  showShortenedUnits?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Recipe and Ingredient Preference */
export type RecipeAndIngredientPreference = {
  __typename?: 'RecipeAndIngredientPreference';
  /** Ingredient preferences */
  ingredientPreferences: Array<IngredientPreference>;
  /** Recipe preferences */
  recipePreferences: Array<RecipePreference>;
};

/** Badge attribute related information */
export type RecipeBadge = {
  __typename?: 'RecipeBadge';
  /** Badge's background color code */
  bgColor?: Maybe<Scalars['String']['output']>;
  /** Badge's text color code */
  textColor?: Maybe<Scalars['String']['output']>;
  /** Badge's value */
  value?: Maybe<Scalars['String']['output']>;
};

/** Recipe Filter Category value object */
export type RecipeCategoryFilterValue = {
  __typename?: 'RecipeCategoryFilterValue';
  /** Key of the value */
  valueKey: Scalars['String']['output'];
  /** Localized name of the value */
  valueName: Scalars['String']['output'];
};

/** Available difficulty values for a recipe */
export enum RecipeDifficultyEnum {
  /** Easy */
  Easy = 'EASY',
  /** Hard */
  Hard = 'HARD',
  /** Medium */
  Medium = 'MEDIUM'
}

/** Serving time for a recipe */
export type RecipeDuration = {
  __typename?: 'RecipeDuration';
  /** Findability text for cooking time */
  findabilityText: Scalars['String']['output'];
  /** min serving time */
  from: Scalars['Int']['output'];
  /** max serving time */
  to: Scalars['Int']['output'];
  /** Unit of time */
  unit: Scalars['String']['output'];
};

/** Recipe Filter Category object */
export type RecipeFilterCategory = {
  /** Category key */
  key: Scalars['String']['input'];
  /** List of filter category values */
  values: Array<Scalars['String']['input']>;
};

/** Recipe related information */
export type RecipeInterface = {
  /** List of recipe allergens */
  allergens: Array<Allergen>;
  /** List of Assumed ingredients of the Recipe */
  assumedIngredients?: Maybe<Array<AssumedIngredient>>;
  /** List of meal attributes of the Recipe */
  attributes?: Maybe<Array<MealAttribute>>;
  /** Badge for the recipe */
  badge?: Maybe<RecipeBadge>;
  /** Meal category of the recipe */
  category?: Maybe<MealCategory>;
  /** The recipe's chef */
  chef: Chef;
  /** Cooking Tip of the Recipe */
  cookingTip?: Maybe<Scalars['String']['output']>;
  /** Culinary ID */
  culinaryId: Scalars['Int']['output'];
  /** Description of a Recipe */
  description?: Maybe<Scalars['String']['output']>;
  /** Strictest type of diet this recipe fits */
  dietType?: Maybe<Scalars['String']['output']>;
  /** Allergen icons based on diet */
  dietaryAllergenIcons: Array<DietaryAllergenIcon>;
  /** Difficulty Level of a recipe */
  difficulty?: Maybe<RecipeDifficultyEnum>;
  /** Serving time */
  duration?: Maybe<RecipeDuration>;
  /** recipe fees */
  extraFees: Array<PriceComponent>;
  /** Full name of the Recipe with title and subtitle */
  fullName: Scalars['String']['output'];
  /** Heat level icon */
  heatLevelIcon?: Maybe<Image>;
  /** Unique Identifier for the Recipe */
  id?: Maybe<Scalars['Int']['output']>;
  /** Image for the Recipe in the given size */
  image?: Maybe<Image>;
  /** If the recipe is bookmarked or not */
  isBookmarked: Scalars['Boolean']['output'];
  /**
   * If the recipe is a classic or not
   * @deprecated Deprecated field
   */
  isClassic?: Maybe<Scalars['Boolean']['output']>;
  /** List of matched add ons */
  matchedAddOns: Array<AddOn>;
  /** Meal Type of the Recipe */
  mealType?: Maybe<MealType>;
  /** Nutritional info for the recipe */
  nutritionalInformation: Array<NutritionalInformation>;
  /** Percentage of local ingredients */
  percentageOfLocalIngredients: Scalars['Int']['output'];
  ramenMetadata: Array<RamenRecipeMetadataEntry>;
  /** Recipe Card */
  recipeCardUrl?: Maybe<Scalars['String']['output']>;
  /** Recipe serving time */
  recipeServingTime?: Maybe<Scalars['String']['output']>;
  /** Type of a recipe */
  recipeType: RecipeTypeEnum;
  /** other variants for this recipe */
  recipeVariants: Array<Recipe>;
  /** Segment of the Plan */
  segment?: Maybe<SegmentEnum>;
  /** Information about the Shipped Ingredients */
  shippedIngredients?: Maybe<Array<ShippedIngredient>>;
  /** Slug of the recipe */
  slug?: Maybe<Scalars['String']['output']>;
  /** Preperations steps of the Recipe */
  steps?: Maybe<Array<RecipeStep>>;
  /** Subtitle of the Recipe */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of the Recipe */
  title?: Maybe<Scalars['String']['output']>;
  /** Detail of the Recipe */
  url?: Maybe<Scalars['String']['output']>;
  /** List of utensils of the Recipe */
  utensils?: Maybe<Array<Utensil>>;
  /** Recipe variant name */
  variantName?: Maybe<Scalars['String']['output']>;
  /** The image of the ingredient that is specific to this recipe variant */
  variantSpecificIngredientImage?: Maybe<Scalars['String']['output']>;
};


/** Recipe related information */
export type RecipeInterfaceExtraFeesArgs = {
  feeType?: InputMaybe<PriceComponentTypeEnum>;
};


/** Recipe related information */
export type RecipeInterfaceImageArgs = {
  size: ImageSizeEnum;
};


/** Recipe related information */
export type RecipeInterfaceMatchedAddOnsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Recipe related information */
export type RecipeInterfaceRamenMetadataArgs = {
  fields: Array<Scalars['String']['input']>;
};


/** Recipe related information */
export type RecipeInterfaceRecipeServingTimeArgs = {
  showShortenedUnits?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Recipe Preference */
export type RecipePreference = {
  __typename?: 'RecipePreference';
  /** Recipe Preference Description */
  description: Scalars['String']['output'];
  /** Recipe Preference ID */
  id: Scalars['Int']['output'];
  /** Recipe Preference Name */
  name: Scalars['String']['output'];
  /** Recipe Preference Title */
  title: Scalars['String']['output'];
};

/** Recipe rating information */
export type RecipeRatingInterface = {
  /** comment given while rating by the user */
  comment?: Maybe<Scalars['String']['output']>;
  /** event reason code given by the user */
  eventReason?: Maybe<EventReasonEnum>;
  /** rating of the recipe */
  score: Scalars['Int']['output'];
};

/** List of available RecipeState */
export enum RecipeState {
  /** Cooked Recipe */
  Cooked = 'COOKED',
  /** Uncooked Recipe */
  Uncooked = 'UNCOOKED'
}

/** Preparation step for a recipe */
export type RecipeStep = {
  __typename?: 'RecipeStep';
  /** Description of the recipe step */
  description: Scalars['String']['output'];
  /** Image of the recipe preference */
  image?: Maybe<Image>;
  /** Title of the recipe step */
  title: Scalars['String']['output'];
};


/** Preparation step for a recipe */
export type RecipeStepImageArgs = {
  size: ImageSizeEnum;
};

/** List of available recipe types */
export enum RecipeTypeEnum {
  /** Core Down */
  CoreDown = 'CORE_DOWN',
  /** Premium */
  Premium = 'PREMIUM',
  /** Standard */
  Standard = 'STANDARD'
}

/** Referral feature information for a given customer */
export type ReferralFeature = {
  __typename?: 'ReferralFeature';
  /** Number of referrals the customer can send. Returns 0 if feature cannot be used */
  availableToSend?: Maybe<Scalars['Int']['output']>;
  /** Whether the customer can use the referral feature */
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Number of boxes customer must have completed to have access to referrals */
  minimumOrdersForReferralAccess?: Maybe<Scalars['Int']['output']>;
  /** Number of orders that customer needs to have in order to use the feature */
  numberOfOrdersUntilEnabled?: Maybe<Scalars['Int']['output']>;
  /** Whether the customer can get rewards for referring others */
  referralRewardEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/** Information about pagination in a connection. */
export type RelayPageInfo = {
  __typename?: 'RelayPageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Contains a couple of stats used within the rewards program */
export type RewardStats = {
  __typename?: 'RewardStats';
  numberOfDeliveredMarketItems?: Maybe<Scalars['Int']['output']>;
  numberOfDeliveredPremiumRecipes?: Maybe<Scalars['Int']['output']>;
  numberOfDeliveredRecipes?: Maybe<Scalars['Int']['output']>;
};

/** List of available salutations */
export enum SalutationEnum {
  /** Fr. */
  Fr = 'FR',
  /** Frau */
  Frau = 'FRAU',
  /** Herr */
  Herr = 'HERR',
  /** Hr. */
  Hr = 'HR',
  /** Mr. */
  Mr = 'MR',
  /** Mrs. */
  Mrs = 'MRS'
}

/** Possible values for Segment */
export enum SegmentEnum {
  /** Add On */
  Addon = 'ADDON',
  /** Amazon */
  Amazon = 'AMAZON',
  /** Couples */
  Couples = 'COUPLES',
  /** Custom */
  Custom = 'CUSTOM',
  /** Families */
  Families = 'FAMILIES'
}

/** Information about a slot belonging to an associated object */
export type SelectedTimeSlotOption = TimeSlotInterface & {
  __typename?: 'SelectedTimeSlotOption';
  /** The surcharge or discount of the Time Slot */
  additionalShippingPrice?: Maybe<Scalars['Float']['output']>;
  /** List of possible Delivery Options */
  dates?: Maybe<Array<VisitFirstDeliveryDateOption>>;
  /** Start time of the Time Slot. 24H format */
  from?: Maybe<Scalars['Time']['output']>;
  /** Start time of the Time Slot. Complete datetime. */
  fromDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Identifier of the Time Slot */
  id: Scalars['Int']['output'];
  /** If the Time Slot is selected */
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  /** Currency of the Time Slot's surcharge or discount */
  priceCurrency: CurrencyEnum;
  /** Shipper used in Time Slot */
  shipper?: Maybe<Shipper>;
  /** End time of the Time Slot. 24H format */
  to?: Maybe<Scalars['Time']['output']>;
  /** End time of the Time Slot. Complete datetime. */
  toDateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** Shipment related information */
export type Shipment = {
  __typename?: 'Shipment';
  /** Shipment Add Ons */
  addOns?: Maybe<Array<ShipmentAddOn>>;
  /** ID of the Shipment */
  id: Scalars['Int']['output'];
  /** Number of the Shipment */
  number?: Maybe<Scalars['String']['output']>;
  /** List of Recipes from the Shipment */
  recipes?: Maybe<Array<OrderRecipe>>;
  /** Shipment tracking information */
  tracking: ShipmentTrackingInfo;
};

/** Shipment Add On related information */
export type ShipmentAddOn = {
  __typename?: 'ShipmentAddOn';
  /** Unique Identifier for the related Add On */
  id?: Maybe<Scalars['Int']['output']>;
  /** Add On name */
  name?: Maybe<Scalars['String']['output']>;
  /** AddOn quantity */
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type ShipmentTrackingInfo = {
  __typename?: 'ShipmentTrackingInfo';
  link?: Maybe<Scalars['String']['output']>;
  /** Shipper tracking number */
  number?: Maybe<Scalars['String']['output']>;
};

/** Shipped Ingredient related information */
export type ShippedIngredient = {
  __typename?: 'ShippedIngredient';
  /** Information about allergens */
  allergens: Array<Allergen>;
  /** Shipped Ingredient id */
  id: Scalars['Int']['output'];
  /** Shipped Ingredient image */
  image?: Maybe<Image>;
  /** Shipped Ingredient name */
  name?: Maybe<Scalars['String']['output']>;
  /** Shipped Ingredient name with quantity */
  nameWithQuantity?: Maybe<Scalars['String']['output']>;
  /** Production item id */
  productionItemId?: Maybe<Scalars['Int']['output']>;
  /** The quantity of production items included in this ingredient */
  productionItemQuantity?: Maybe<Scalars['Int']['output']>;
};


/** Shipped Ingredient related information */
export type ShippedIngredientImageArgs = {
  size: ImageSizeEnum;
};

/** Shipper related information */
export type Shipper = {
  __typename?: 'Shipper';
  /** Name of the Shipper */
  name?: Maybe<Scalars['String']['output']>;
};

/** List of sort directions. */
export enum SortDirection {
  /** Ascending order. */
  Asc = 'ASC',
  /** Descending order. */
  Desc = 'DESC'
}

/** Special related information */
export type Special = {
  __typename?: 'Special';
  /** Start date for the Special's availability */
  availableFrom?: Maybe<Scalars['Date']['output']>;
  /** End date for the Special's availability */
  availableTo?: Maybe<Scalars['Date']['output']>;
  /** Visual configuration properties of the Special */
  configuration?: Maybe<SpecialConfiguration>;
  /** Textual description of the delivery information */
  deliveryDescription?: Maybe<Scalars['String']['output']>;
  /** Description of the Special */
  description?: Maybe<Scalars['String']['output']>;
  /** Unique Identifier for the Special */
  id?: Maybe<Scalars['Int']['output']>;
  /** If the special is age restricted */
  isAgeRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** If the Special is currently active */
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Menu with the available items for the special */
  menu?: Maybe<SpecialMenu>;
  /** Used to group similar Specials */
  namespace?: Maybe<Scalars['String']['output']>;
  /** URL slug of the Special */
  slug?: Maybe<Scalars['String']['output']>;
  /** Title of the Special */
  title?: Maybe<Scalars['String']['output']>;
};

/** Configuration properties of a Special */
export type SpecialConfiguration = {
  __typename?: 'SpecialConfiguration';
  /** Background Color */
  backgroundColor?: Maybe<Scalars['String']['output']>;
  /** Badge image URL for Desktop usage */
  desktopBadge?: Maybe<Scalars['String']['output']>;
  /** Banner image URL for Desktop usage */
  desktopBanner?: Maybe<Scalars['String']['output']>;
  /** Header Color */
  headerColor?: Maybe<Scalars['String']['output']>;
  /** Banner image URL for Mobile usage */
  mobileBanner?: Maybe<Scalars['String']['output']>;
  /** Primary Color */
  primaryColor?: Maybe<Scalars['String']['output']>;
  /** Secondary Color */
  secondaryColor?: Maybe<Scalars['String']['output']>;
  /** Badge image URL for Tablet usage */
  tabletBadge?: Maybe<Scalars['String']['output']>;
  /** Banner image URL for Tablet usage */
  tabletBanner?: Maybe<Scalars['String']['output']>;
};

/** Menu contents for the special */
export type SpecialMenu = {
  __typename?: 'SpecialMenu';
  /** List of add ons available for the special */
  addOns?: Maybe<Array<Maybe<AddOn>>>;
};


/** Menu contents for the special */
export type SpecialMenuAddOnsArgs = {
  canDisplayDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

/** State related information */
export type State = {
  __typename?: 'State';
  /** The state abbreviation */
  abbreviation?: Maybe<Scalars['String']['output']>;
  /** ID for the State */
  id: Scalars['Int']['output'];
  /** The state name */
  name?: Maybe<Scalars['String']['output']>;
};

/** Step related information */
export type Step = {
  __typename?: 'Step';
  /** Can we skip a Step? */
  canSkip: Scalars['Boolean']['output'];
  /** The identifier of the Step */
  identifier: TasteProfilePreferenceEnum;
  /** The subtitle of the Step */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** The title of the Step */
  title?: Maybe<Scalars['String']['output']>;
};

/** SubscribeUser related information */
export type SubscribeUser = {
  __typename?: 'SubscribeUser';
  /** New customer */
  customer: Customer;
  /** Redirect Flow */
  redirectFlow: Scalars['Boolean']['output'];
  /** Redirect URL */
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

/** Union result type for the mutation SubscribeUser */
export type SubscribeUserResponse = MutationErrors | SubscribeUser;

/** Subscription related params */
export type SubscriptionInputType = {
  /** The users brand */
  brand: BrandEnum;
  /** The users country */
  country: CountryEnum;
  /** The delivery date of the first order */
  deliveryDate: Scalars['String']['input'];
  /** The users email address */
  email: Scalars['String']['input'];
  /** Fraud prevention token */
  forterToken?: InputMaybe<Scalars['String']['input']>;
  /** The users password */
  password: Scalars['String']['input'];
  /** The users password confirmation */
  passwordConfirmation: Scalars['String']['input'];
  /** Params for the payment */
  paymentCredentials?: InputMaybe<PaymentCredentialsType>;
  /** Payment method */
  paymentMethod: PaymentMethodTypeEnum;
  /** ID of the Payment method */
  paymentMethodId: Scalars['Int']['input'];
  /** A promotion code */
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  /** The users recipe preferences */
  recipePreferences?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** User Address related params */
  userAddress: UserAddressInputType;
  /** User plan related params */
  userPlan: UserPlanInputType;
  /** Information whether the user is a vegetarian */
  vegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  visitUuid?: InputMaybe<Scalars['String']['input']>;
};

/** Possible values for taste profile preference */
export enum TasteProfilePreferenceEnum {
  /** protein preferences */
  ProteinPreferences = 'PROTEIN_PREFERENCES',
  /** recipe likes */
  RecipeLikes = 'RECIPE_LIKES',
  /** taste preferences */
  TastePreferences = 'TASTE_PREFERENCES'
}

/** Taste profile wizard related information */
export type TasteProfileWizard = {
  __typename?: 'TasteProfileWizard';
  /** whether the wizard has previously been completed */
  isComplete: Scalars['Boolean']['output'];
  /** steps of the wizard */
  steps?: Maybe<Array<Step>>;
};

/** Tax related information */
export type Tax = {
  __typename?: 'Tax';
  /** Tax amount */
  amount?: Maybe<Scalars['Float']['output']>;
  /** If the tax is included or not */
  isIncluded?: Maybe<Scalars['Boolean']['output']>;
  /** Tax name */
  name?: Maybe<Scalars['String']['output']>;
  /** Tax percentage */
  percentage?: Maybe<Scalars['Float']['output']>;
};

/** Time Slot related information */
export type TimeSlot = TimeSlotInterface & {
  __typename?: 'TimeSlot';
  /** The surcharge or discount of the Time Slot */
  additionalShippingPrice?: Maybe<Scalars['Float']['output']>;
  /** Start time of the Time Slot. 24H format */
  from?: Maybe<Scalars['Time']['output']>;
  /** Start time of the Time Slot. Complete datetime. */
  fromDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Identifier of the Time Slot */
  id: Scalars['Int']['output'];
  /** Currency of the Time Slot's surcharge or discount */
  priceCurrency: CurrencyEnum;
  /** Shipper used in Time Slot */
  shipper?: Maybe<Shipper>;
  /** End time of the Time Slot. 24H format */
  to?: Maybe<Scalars['Time']['output']>;
  /** End time of the Time Slot. Complete datetime. */
  toDateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** Time Slot related information */
export type TimeSlotInterface = {
  /** The surcharge or discount of the Time Slot */
  additionalShippingPrice?: Maybe<Scalars['Float']['output']>;
  /** Start time of the Time Slot. 24H format */
  from?: Maybe<Scalars['Time']['output']>;
  /** Start time of the Time Slot. Complete datetime. */
  fromDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Identifier of the Time Slot */
  id: Scalars['Int']['output'];
  /** Currency of the Time Slot's surcharge or discount */
  priceCurrency: CurrencyEnum;
  /** Shipper used in Time Slot */
  shipper?: Maybe<Shipper>;
  /** End time of the Time Slot. 24H format */
  to?: Maybe<Scalars['Time']['output']>;
  /** End time of the Time Slot. Complete datetime. */
  toDateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** List of available unskip flow type */
export enum UnskipFlow {
  /** Delivery Slot Flow */
  DeliverySlot = 'DELIVERY_SLOT'
}

/** Upcoming Time Slot related information */
export type UpcomingTimeSlot = {
  __typename?: 'UpcomingTimeSlot';
  /** The surcharge or discount of the Time Slot */
  additionalShippingPrice?: Maybe<Scalars['Float']['output']>;
  /** Start time of the Time Slot. 24H format */
  from: Scalars['Time']['output'];
  /** Currency of the Time Slot's surcharge or discount */
  priceCurrency: CurrencyEnum;
  /** End time of the Time Slot. 24H format */
  to: Scalars['Time']['output'];
};

/** Pricing information for the upselling banner */
export type UpsellingBanner = {
  __typename?: 'UpsellingBanner';
  dynamicPrice: Scalars['Float']['output'];
  staticPrice: Scalars['Float']['output'];
};

/** Properties for the user address */
export type UserAddressInputType = {
  /** Data for the address */
  address: AddressInputType;
  /** Description of the address */
  description?: InputMaybe<Scalars['String']['input']>;
};

/** Credits given to a user */
export type UserCredit = {
  __typename?: 'UserCredit';
  /** Amount of credit that was given */
  amount: Scalars['Float']['output'];
  /** Date when the credit was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user that created the credit */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** Order number related with the credit */
  orderNumber?: Maybe<Scalars['String']['output']>;
  /** Reason for giving the credit */
  reason: CreditReason;
};

/** UserFoodPreferences related information */
export type UserFoodPreferences = {
  __typename?: 'UserFoodPreferences';
  /** Is the user vegetarian */
  isVegetarian?: Maybe<Scalars['Boolean']['output']>;
  options: Array<FoodPreferenceOption>;
};

/** UserPlan related information */
export type UserPlan = {
  __typename?: 'UserPlan';
  /**
   * Delivery day of the UserPlan
   * @deprecated Use `weekday` instead
   */
  deliveryDay?: Maybe<Scalars['Int']['output']>;
  /** Delivery options for the user plan */
  deliveryOptions?: Maybe<Array<UserPlanDeliveryOption>>;
  /** Fruitbox addon for user plan */
  fruitBox: FruitBox;
  /** If the customer wants printed recipe cards */
  hasPrintedRecipeCards: Scalars['Boolean']['output'];
  /** Order Frequency for user plan */
  orderFrequency: OrderFrequencyEnum;
  /** Information about the Plan */
  plan?: Maybe<Plan>;
  /** Available plans */
  plans: Array<Plan>;
  /** Time slot of the UserPlan */
  timeSlot?: Maybe<TimeSlot>;
  /** Delivery day of the UserPlan, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']['output']>;
};


/** UserPlan related information */
export type UserPlanDeliveryOptionsArgs = {
  zipcode?: InputMaybe<Scalars['String']['input']>;
};


/** UserPlan related information */
export type UserPlanPlansArgs = {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  segments?: InputMaybe<Array<SegmentEnum>>;
};

/** User Plan Delivery Option related information */
export type UserPlanDeliveryOption = WeekdayDeliveryOptionInterface & {
  __typename?: 'UserPlanDeliveryOption';
  /** If the week day is the default */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** If the week day is selected */
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  /** List of possible Time Slots */
  slots?: Maybe<Array<SelectedTimeSlotOption>>;
  /** Weekday of delivery, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']['output']>;
};

/** Properties for creating/updating a user plan */
export type UserPlanInputType = {
  /** If the customer wants printed recipe cards */
  hasPrintedRecipeCards?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the Plan */
  planId?: InputMaybe<Scalars['Int']['input']>;
  /** Identifier of the Plan */
  planIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** ID of the delivery slot */
  timeSlotId?: InputMaybe<Scalars['Int']['input']>;
  /** Weekday of delivery, from 1 to 7, where 1 is Monday */
  weekday?: InputMaybe<Scalars['Int']['input']>;
};

/** Information about the promotions that will be applied on the next orders */
export type UserPromotionSettings = {
  __typename?: 'UserPromotionSettings';
  /** Which of the n next order to affect */
  orderOrdinal: Scalars['Int']['output'];
  /** Next promotions */
  promotion: Promotion;
};

/** Information about the user bookmarked recipes */
export type UserRecipeBookmark = {
  __typename?: 'UserRecipeBookmark';
  /** Bookmarked Recipe */
  recipe: Recipe;
};

/** User's rating for a recipe in an order */
export type UserRecipeRating = RecipeRatingInterface & {
  __typename?: 'UserRecipeRating';
  /** comment given while rating by the user */
  comment?: Maybe<Scalars['String']['output']>;
  /** event reason code given by the user */
  eventReason?: Maybe<EventReasonEnum>;
  /** rating of the recipe */
  score: Scalars['Int']['output'];
};

/** List of User Types */
export enum UserType {
  /** churn */
  Churn = 'CHURN',
  /** rm */
  Rm = 'RM'
}

/** Utensil related information */
export type Utensil = {
  __typename?: 'Utensil';
  /** Name of the utensil */
  name?: Maybe<Scalars['String']['output']>;
};

/** List of possible discount types */
export enum ValueType {
  /** Fixed amount for discount */
  Fixed = 'fixed',
  /** Discount in percentage */
  Percentage = 'percentage'
}

/** Visit related information */
export type Visit = {
  __typename?: 'Visit';
  /** Information about address of the visit */
  address?: Maybe<Address>;
  /** Customer brand */
  brand: BrandEnum;
  /** First Order of the customer associated with the visit */
  conversionOrder?: Maybe<Order>;
  /** The country of the customer */
  country?: Maybe<CountryEnum>;
  /** Delivery day of the visit */
  deliveryDay?: Maybe<Scalars['Int']['output']>;
  /** The Delivery Option ID of the visit */
  deliveryOptionId?: Maybe<Scalars['Int']['output']>;
  /** Delivery options for the first delivery */
  deliveryOptions?: Maybe<Array<VisitDeliveryOption>>;
  /** The ID of the delivery slot */
  deliverySlotId?: Maybe<Scalars['Int']['output']>;
  /** Email of the visit */
  email?: Maybe<Scalars['String']['output']>;
  firstDeliverySlotId?: Maybe<Scalars['Int']['output']>;
  /** First name of the visit */
  firstName?: Maybe<Scalars['String']['output']>;
  /** is the user in an area we deliver to */
  inActiveArea?: Maybe<Scalars['Boolean']['output']>;
  /** is the visit referred */
  isReferred?: Maybe<Scalars['Boolean']['output']>;
  /** Is the visit vegetarian */
  isVegetarian?: Maybe<Scalars['Boolean']['output']>;
  /** Last name of the visit */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Last step of the visit */
  lastStep?: Maybe<Scalars['Int']['output']>;
  /** Creation date of the lead */
  leadSince?: Maybe<Scalars['DateTime']['output']>;
  /** The time for the newsletter opt in */
  newsletterOptIn?: Maybe<Scalars['DateTime']['output']>;
  /** Payment type of the visit */
  paymentType?: Maybe<Scalars['String']['output']>;
  /** Information about the plan of the visit */
  plan?: Maybe<Plan>;
  /** Information about price of the visit */
  price?: Maybe<FunnelPrice>;
  /** Promotion code of the visit */
  promotionCode?: Maybe<Scalars['String']['output']>;
  /** The Referrer Url of the visit */
  referrerUrl?: Maybe<Scalars['String']['output']>;
  /** The Delivery Date of the visit */
  selectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Source of the visit */
  sourceClient?: Maybe<Scalars['String']['output']>;
  /** The Start Url of the visit */
  startUrl?: Maybe<Scalars['String']['output']>;
  /** When step 1 was finished */
  step1At?: Maybe<Scalars['DateTime']['output']>;
  /** When step 2 was finished */
  step2At?: Maybe<Scalars['DateTime']['output']>;
  /** When step 3 was finished */
  step3At?: Maybe<Scalars['DateTime']['output']>;
  /** When step 4 was finished */
  step4At?: Maybe<Scalars['DateTime']['output']>;
  /** is the newsletter active */
  subscribedToNewsletter: Scalars['Boolean']['output'];
  /** Time slot of the visit */
  timeSlot?: Maybe<TimeSlot>;
  /** Visit's updated at time */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** Customer ID of the visit */
  userId?: Maybe<Scalars['Int']['output']>;
  /** UUID of the visit */
  uuid: Scalars['String']['output'];
  /** Zipcode entered in the funnel */
  zipcode?: Maybe<Scalars['String']['output']>;
};


/** Visit related information */
export type VisitDeliveryOptionsArgs = {
  zipcode: Scalars['String']['input'];
};

/** Visit Delivery Option related information */
export type VisitDeliveryOption = WeekdayDeliveryOptionInterface & {
  __typename?: 'VisitDeliveryOption';
  /**
   * List of possible Delivery Options
   * @deprecated  please stop using this and use instead slot -> dates
   */
  dates?: Maybe<Array<VisitFirstDeliveryDateOption>>;
  /** If the week day is the default */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** If the week day is selected */
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  /** List of possible Time Slots */
  slots?: Maybe<Array<SelectedTimeSlotOption>>;
  /** Weekday of delivery, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']['output']>;
};

/** Visit first delivery date option related information */
export type VisitFirstDeliveryDateOption = DeliveryOptionInterface & {
  __typename?: 'VisitFirstDeliveryDateOption';
  /** The day no further changes can be made to orders (excluding VIP changes) */
  cutoffDate?: Maybe<Scalars['Date']['output']>;
  /** Date of delivery */
  date?: Maybe<Scalars['Date']['output']>;
  /** If the date is the default */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** If the date is selected */
  isSelected?: Maybe<Scalars['Boolean']['output']>;
};

/** Properties for creating/updating a Visit */
export type VisitInputType = {
  /** Address first line */
  addressAddress1?: InputMaybe<Scalars['String']['input']>;
  /** Address second line */
  addressAddress2?: InputMaybe<Scalars['String']['input']>;
  /** Address type */
  addressAddressType?: InputMaybe<Scalars['String']['input']>;
  /** Address city */
  addressCity?: InputMaybe<Scalars['String']['input']>;
  /** Address company */
  addressCompany?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery instructions */
  addressDeliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  /** Visit first name */
  addressFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Address house number */
  addressHouseNumber?: InputMaybe<Scalars['String']['input']>;
  /** Visit last name */
  addressLastName?: InputMaybe<Scalars['String']['input']>;
  /** Address phone */
  addressPhone?: InputMaybe<Scalars['String']['input']>;
  /** Address state id */
  addressStateId?: InputMaybe<Scalars['Int']['input']>;
  /** Customer brand */
  brand: BrandEnum;
  /** The country of the customer */
  country: CountryEnum;
  /** Visit delivery slot id */
  deliverySlotId?: InputMaybe<Scalars['Int']['input']>;
  /** Customer email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Delivery slot id */
  firstDeliverySlotId?: InputMaybe<Scalars['Int']['input']>;
  /** Last referrer URL */
  lastReferrerUrl?: InputMaybe<Scalars['String']['input']>;
  /** Last start URL */
  lastStartUrl?: InputMaybe<Scalars['String']['input']>;
  /** Timestamp for the newsletter opt in */
  newsletterOptIn?: InputMaybe<Scalars['DateTime']['input']>;
  /** Payment type */
  paymentType?: InputMaybe<Scalars['String']['input']>;
  /** Visit plan id */
  planId?: InputMaybe<Scalars['Int']['input']>;
  /** Visit plan identifier */
  planIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Visit promotion code */
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  /** The Referrer URL of the visit */
  referrerUrl?: InputMaybe<Scalars['String']['input']>;
  /** Selected delivery date */
  selectedDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  /** Source client */
  sourceClient?: InputMaybe<Scalars['String']['input']>;
  /** The Start URL of the visit */
  startUrl?: InputMaybe<Scalars['String']['input']>;
  /** Timestamp for the first step */
  step1At?: InputMaybe<Scalars['DateTime']['input']>;
  /** Timestamp for the second step */
  step2At?: InputMaybe<Scalars['DateTime']['input']>;
  /** Timestamp for the third step */
  step3At?: InputMaybe<Scalars['DateTime']['input']>;
  /** Timestamp for the fourth step */
  step4At?: InputMaybe<Scalars['DateTime']['input']>;
  /** Customer ID */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** The visit uuid if existent, required if it is an update */
  uuid?: InputMaybe<Scalars['String']['input']>;
  /** Is the visit vegetarian */
  vegetarian?: InputMaybe<Scalars['Boolean']['input']>;
  /** Delivery day of the visit */
  wday?: InputMaybe<Scalars['Int']['input']>;
  /** Customer zipcode */
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** Menu available for a week */
export type WeekMenu = {
  __typename?: 'WeekMenu';
  /** List of Available Recipes */
  recipes?: Maybe<Array<Recipe>>;
  /** Start of Week */
  startOfWeek?: Maybe<Scalars['Date']['output']>;
};

/** Weekday Delivery Options related information */
export type WeekdayDeliveryOptionInterface = {
  /** If the week day is the default */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** If the week day is selected */
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  /** List of possible Time Slots */
  slots?: Maybe<Array<SelectedTimeSlotOption>>;
  /** Weekday of delivery, from 1 to 7, where 1 is Monday */
  weekday?: Maybe<Scalars['Int']['output']>;
};

/** ZipcodeLookup related information */
export type ZipcodeLookup = {
  __typename?: 'ZipcodeLookup';
  active: Scalars['Boolean']['output'];
};

export type _Entity = IncentiveClaim | Order | Promotion;

/**
 * The sdl representing the federated service capabilities. Includes federation
 * directives, removes federation types, and includes rest of full schema after
 * schema directives have been applied
 */
export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

/** Chargeless Registration eligibility */
export type ChargelessRegistration = {
  __typename?: 'chargelessRegistration';
  /** Query result */
  eligible: Scalars['Boolean']['output'];
};

export type GetDeliveryInstructionsQueryVariables = Exact<{
  country: CountryEnum;
  brand: BrandEnum;
  zipCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDeliveryInstructionsQuery = { __typename?: 'QueryRoot', deliveryInstructions: Array<{ __typename?: 'DeliveryInstruction', localizedText: string, country?: string | null, brand?: string | null, instructionKey: string, allowFreeText: boolean, freeTextPlaceholder?: string | null }> };


export const GetDeliveryInstructionsDocument = gql`
    query GetDeliveryInstructions_PublicApi($country: CountryEnum!, $brand: BrandEnum!, $zipCode: String) {
  deliveryInstructions(country: $country, brand: $brand, zipCode: $zipCode) {
    localizedText
    country
    brand
    instructionKey
    allowFreeText
    freeTextPlaceholder
  }
}
    `;
export function useGetDeliveryInstructionsQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryInstructionsQuery, GetDeliveryInstructionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryInstructionsQuery, GetDeliveryInstructionsQueryVariables>(GetDeliveryInstructionsDocument, options);
      }
export function useGetDeliveryInstructionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryInstructionsQuery, GetDeliveryInstructionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryInstructionsQuery, GetDeliveryInstructionsQueryVariables>(GetDeliveryInstructionsDocument, options);
        }
export type GetDeliveryInstructionsQueryHookResult = ReturnType<typeof useGetDeliveryInstructionsQuery>;
export type GetDeliveryInstructionsLazyQueryHookResult = ReturnType<typeof useGetDeliveryInstructionsLazyQuery>;
export type GetDeliveryInstructionsQueryResult = Apollo.QueryResult<GetDeliveryInstructionsQuery, GetDeliveryInstructionsQueryVariables>;