import React from 'react'
import { Alert, Row, Col, Divider } from 'antd'

import CustomerPlanForm from '../../Forms/CustomerPlanForm'
import CustomerFruitBoxPlanForm from '../../Forms/CustomerFruitBoxPlanForm'
import ManageCustomerMembership from '../../../containers/ManageCustomerContainer/ManageCustomerMembership'
import { BrandEnum, CountryEnum, MembershipStatusEnum } from '../../../apollo/generated/api'

export type ManageCustomerPlanProps = {
  customer: {
    id: number
    country: CountryEnum
    brand: BrandEnum
    membership: {
      startDeliveryDates: string[]
      status: MembershipStatusEnum
    }
    userPlan: {
      fruitBox: {
        available: boolean
        enabled: boolean
        pricePerUnit: number | null
        servings: number | null
      }
    }
  }
}
const ManageCustomerPlan: React.FC<ManageCustomerPlanProps> = ({ customer }) => (
  <>
    {customer.brand === BrandEnum.Bm && <Alert type="info" message="Login as user to make changes to plan." />}
    {customer.brand !== BrandEnum.Bm && (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ManageCustomerMembership customer={customer} />
          </Col>
        </Row>

        <Divider />

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <CustomerPlanForm customer={customer} />
          </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: 30 }}>
          {customer.userPlan.fruitBox.available && (
            <Col span={12}>
              <CustomerFruitBoxPlanForm customer={customer} />
            </Col>
          )}
        </Row>
      </>
    )}
  </>
)

export default ManageCustomerPlan
