import React, { FunctionComponent } from 'react'
import { Row, Col } from 'antd'

import { BrandEnum } from '../../../apollo/generated/api'
import { ManageCustomerProps } from '../props'
import ManageCustomerGeneralSettings from '../ManageCustomerGeneralSettings'
import ManageCustomerCredit from '../ManageCustomerCredit'
import ManageCustomerNotificationSettings from '../ManageCustomerNotificationSettings'
import ManageCustomerTrusted from '../ManageCustomerTrusted'

const ManageCustomerAccount: FunctionComponent<ManageCustomerProps> = ({
  customer: { id, email, trusted, brand },
}: ManageCustomerProps) => (
  <>
    <Row gutter={16}>
      <Col span={24} xl={12}>
        <ManageCustomerGeneralSettings customerId={id} customerEmail={email} />
      </Col>

      <Col span={24} xl={12}>
        <ManageCustomerCredit customerId={id} />
      </Col>
    </Row>
    {brand !== BrandEnum.Bm && (
      <Row gutter={16}>
        <Col span={24}>
          <ManageCustomerTrusted customer={{ id, trusted }} />
        </Col>
      </Row>
    )}
    <Row gutter={16}>
      <Col span={24}>
        <ManageCustomerNotificationSettings customer={{ id }} />
      </Col>
    </Row>
  </>
)

export default ManageCustomerAccount
