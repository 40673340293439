import React, { useEffect } from 'react'
import { Row, Col, Typography, message as antMessage } from 'antd'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

import { getRandomArray, getFullYear, SessionMessages, getUserSession } from '../../utils'
import { Routes } from '../../constants/RoutesConstants'
import * as Authentication from '../Authentication'
import { BEEF_USER_SESSION, COOKBOOK_USER_SESSION } from '../../constants/AuthConstants'

import * as S from './styles'
import { LOGIN_ICONS } from './constants'

const { Title, Text } = Typography
const subTitleStyle = { display: 'block', marginBottom: 30 }

const presentLoginButton = (): React.ReactNode => {
  return <Authentication.GoogleLogin />
}

export type LoginProps = RouteComponentProps<{}, StaticContext, { message?: SessionMessages }> & {}

const Login: React.FC<LoginProps> = ({ location }) => {
  const cookbookSession = getUserSession(COOKBOOK_USER_SESSION)
  const beefSession = getUserSession(BEEF_USER_SESSION)
  const isAuthenticated = cookbookSession.hasValidSession() && beefSession.hasValidSession()
  const message = location?.state?.message

  useEffect(() => {
    if (message) {
      void antMessage.success(message)
    }
  }, [message])

  if (isAuthenticated) {
    return <Redirect to={Routes.SEARCH} />
  }

  return (
    <>
      <Row>
        <Col span={12} offset={6}>
          <S.Hello>
            <img src={getRandomArray(LOGIN_ICONS)} alt="Hello Stranger!" />
            <Title level={2}>Look how beautiful you look today</Title>

            <Text style={subTitleStyle}>Login with your Marley Spoon Google account to access the admin panel.</Text>

            {presentLoginButton()}
          </S.Hello>
        </Col>
      </Row>

      <S.Footer>&copy; Marley Spoon {getFullYear()}</S.Footer>
    </>
  )
}

export default Login
