import React, { FunctionComponent, useEffect, useState } from 'react'
import { Table, Row, Col, Button, Typography } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { generatePath, Link } from 'react-router-dom'

import Colors from '../../../../constants/ColorsConstants'
import { Routes } from '../../../../constants/RoutesConstants'
import LoadComponentError from '../../../../packages/LoadComponentError'
import { Address, useGetCustomerAddressesQuery, CountryEnum, BrandEnum } from '../../../../apollo/generated/api'
import { useGetDeliveryInstructionsLazyQuery } from '../../../../apollo/generated/public_api'
import { buildCopyableAddress } from '../../../../utils/addressUtils/addressUtils'

type MappedAddress = {
  id: number
  name: JSX.Element
  address?: string | null
  zipcode?: string | null
  city?: string | null
  state?: string | null
  default: JSX.Element
  action: JSX.Element
}

type CustomerAddress = {
  id: number
  isDefault: boolean
  address: Omit<Address, 'id' | 'deliveryCode' | 'type'>
}

const { Text } = Typography
const ColumnContent: React.FC<{
  content: string
}> = ({ content }) => <Text style={{ wordBreak: 'break-word' }}>{content}</Text>
const defaultIcon = (isDefault: boolean): JSX.Element =>
  isDefault ? <CheckCircleTwoTone twoToneColor={Colors.SUCCESS} /> : <CloseCircleTwoTone twoToneColor={Colors.DANGER} />

const presentAddress = (
  customerAddress: CustomerAddress,
  customerId: number,
  instructionsText: string,
): MappedAddress => {
  const { address } = customerAddress
  const editPath = generatePath(Routes.CUSTOMER_ADDRESS_DETAIL, { id: customerId, addressId: customerAddress.id })
  const actions = (
    <>
      <Link to={editPath} data-testid={`address-${customerAddress.id}-action`}>
        <EditOutlined />
      </Link>
      <Typography.Text
        copyable={{ text: buildCopyableAddress(address, instructionsText) }}
        style={{ paddingLeft: 5 }}
      />
    </>
  )

  return {
    id: customerAddress.id,
    name: (
      <span data-testid={`address-${customerAddress.id}-name`}>
        {`${address.firstName} ${address.lastName}`.trim()}
      </span>
    ),
    address: address.fullStreetAddress,
    zipcode: address.zipcode,
    city: address.city,
    default: defaultIcon(customerAddress.isDefault),
    state: address?.state ? `${address?.state?.name} (${address?.state?.abbreviation})` : '',
    action: actions,
  }
}
const { Column } = Table
const rowGutter = 16
const tableProps = {
  pagination: {
    pageSize: 15,
  },
  bordered: true,
  'data-testid': 'customer-addresses',
  rowKey: 'id',
}

type CustomerType = {
  id: number
  brand: BrandEnum
  country: CountryEnum
}

const ListCustomerAddresses: FunctionComponent<{ customer: CustomerType }> = ({ customer: { id, country, brand } }) => {
  const { loading, error, data } = useGetCustomerAddressesQuery({ variables: { id }, fetchPolicy: 'no-cache' })
  const addressesData = data?.customer?.addresses
  const [customerAddresses, setCustomerAddresses] = useState<MappedAddress[]>([])
  const [getDeliveryInstructions, deliveryInstructionsQueryResult] = useGetDeliveryInstructionsLazyQuery()
  const deliveryInstructionsData = deliveryInstructionsQueryResult.data?.deliveryInstructions

  useEffect(() => {
    // fetch instructions based on the default address zipcode
    const defaultAddress = data?.customer?.addresses.find(address => address.isDefault)?.address

    if (defaultAddress) {
      void getDeliveryInstructions({ variables: { country, brand, zipCode: defaultAddress.zipcode } })
    }
  }, [addressesData])

  useEffect(() => {
    if (addressesData && deliveryInstructionsData) {
      const customerAddresses = addressesData.map(addr => {
        const { address } = addr
        let instructionsText = address.instructions || ''
        const selectedInstruction = deliveryInstructionsQueryResult.data?.deliveryInstructions.find(
          i => i.instructionKey === address.instructionKey,
        )

        if (selectedInstruction && !selectedInstruction.allowFreeText) {
          instructionsText = selectedInstruction.localizedText
        }

        return presentAddress(addr, id, instructionsText)
      })

      setCustomerAddresses(customerAddresses)
    }
  }, [deliveryInstructionsData])

  return (
    <>
      {error && <LoadComponentError />}

      {!error && (
        <>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Typography.Title level={4}>Address list</Typography.Title>
            </Col>

            <Col span={12} style={{ textAlign: 'right' }}>
              <Link data-testid="customer-addresses-new" to={generatePath(Routes.CUSTOMER_ADDRESS_CREATION, { id })}>
                <Button icon={<PlusCircleOutlined />}>New address</Button>
              </Link>
            </Col>
          </Row>

          <br />

          <Row gutter={rowGutter}>
            <Col span={24}>
              <Table loading={loading} dataSource={customerAddresses} {...tableProps}>
                <Column
                  title="Name"
                  dataIndex="name"
                  render={(name: string): JSX.Element => <ColumnContent content={name} />}
                />
                <Column
                  title="Address"
                  dataIndex="address"
                  render={(address: string): JSX.Element => <ColumnContent content={address} />}
                />
                <Column
                  title="City"
                  dataIndex="city"
                  render={(city: string): JSX.Element => <ColumnContent content={city} />}
                />
                <Column
                  title="State"
                  dataIndex="state"
                  render={(state: string): JSX.Element => <ColumnContent content={state} />}
                />
                <Column
                  title="Zipcode"
                  dataIndex="zipcode"
                  render={(zipcode: string): JSX.Element => <ColumnContent content={zipcode} />}
                />
                <Column title="Default" dataIndex="default" align="center" />
                <Column title="Actions" dataIndex="action" />
              </Table>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default ListCustomerAddresses
