/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FunctionComponent } from 'react'
import { Space, Table, Tag } from 'antd'
import { useQuery } from 'react-query'
import humanizeString from 'humanize-string'

import { ManageCustomerProps } from '../props'
import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { filterMaybe } from '../../../utils/typeUtils'
import { EventReason, EventType, useGetCustomerAccountHistoryQuery, UserEvent } from '../../../apollo/generated/api'
import { HistorySource } from '../../../constants/AppConstants'
import { incidentCustomerAffectedOrders } from '../../../services/IncidentService'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

const { Column } = Table
const tableProps = {
  pagination: false,
  bordered: true,
} as const
const isIncidentEvent = (event: UserEvent): boolean => event.type.name === 'Incident logged'
const formatObject = (object: Record<string, unknown>): JSX.Element => (
  <Space direction="vertical">
    {Object.keys(object).map((key: string) => (
      <div key={key}>
        {humanizeString(key)}: {`${object[key]}`}
      </div>
    ))}
  </Space>
)

/**
 * ManagerCustomerAccount component that retrieves information from the UserEvents table in the backend
 * UserEvents are created when changes are performed in the customer account (i.e membership, address, taste profile)
 * Please refer to MS::EventType model in the backend to get more information on all the possible event types.
 */
const ManageCustomerAccountHistory: FunctionComponent<ManageCustomerProps> = ({
  customer: { id, email, brand },
}: ManageCustomerProps) => {
  const { loading, error, data } = useGetCustomerAccountHistoryQuery({ variables: { id }, fetchPolicy: 'no-cache' })
  const events = data?.customer?.events.filter(filterMaybe)
  const customerTimezone = data?.customer?.timezone
  const { data: incidentData } = useQuery('incident-affected-orders', () =>
    incidentCustomerAffectedOrders({ email, brand }),
  )
  const incidentHistory: (UserEvent & { id: number })[] = []

  incidentData?.forEach((incident, index) => {
    incidentHistory.push({
      id: index,
      type: {
        name: 'Incident logged',
        displayName: 'Incident Logged',
      },
      source: 'admin',
      timestamp: incident.loggedAt,
      details: (
        <Space direction="vertical">
          <div>Incident Type: {incident.incidentType}</div>
          <div>Issue Description: {incident.issueDescription}</div>
          <div>Root Cause: {incident.rootCauseCategory}</div>
          <div>Mitigative Action: {incident.mitigativeAction}</div>
          <div>
            <a href={incident.link} target="_blank" rel="noreferrer">
              {incident.link}
            </a>
          </div>
        </Space>
      ) as unknown as string,
    })
  })
  const dataSource = events
    ?.concat(incidentHistory || [])
    .sort((event1, event2) => Date.parse(event2.timestamp) - Date.parse(event1.timestamp))

  return (
    <>
      {loading && <Loading />}
      {error && <LoadComponentError />}

      {!loading && !error && events && (
        <Table
          data-qa="customer-management-account-history-table"
          data-testid="customer-management-account-history-table"
          dataSource={dataSource}
          rowKey="id"
          {...tableProps}
        >
          <Column
            title="Date and Time"
            dataIndex="timestamp"
            key="timestamp"
            width="20%"
            render={(date: string) => <UserAwareDateWithTz datetime={date} customerTimezone={customerTimezone} />}
          />

          <Column
            title="Event"
            dataIndex="type"
            key="type"
            render={(value: EventType): string => value.displayName || ''}
          />

          <Column
            title="Reason"
            dataIndex="reason"
            render={(value: EventReason | null): string => value?.displayName || ''}
          />

          <Column
            title="Details"
            onCell={() => ({ style: { wordBreak: 'break-all' } })}
            render={(event: UserEvent) =>
              isIncidentEvent(event) ? event.details : formatObject(event.details as unknown as Record<string, unknown>)
            }
          />

          <Column
            title="Source"
            dataIndex="source"
            key="source"
            render={(value: string): JSX.Element => (
              <Tag style={{ fontSize: 12 }} color={HistorySource[value.toUpperCase() as keyof typeof HistorySource]}>
                {value.toUpperCase()}
              </Tag>
            )}
          />
        </Table>
      )}
    </>
  )
}

export default ManageCustomerAccountHistory
