import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import {
  AnonymizeCustomerDocument,
  AnonymizeCustomerMutation,
  AnonymizeCustomerMutationVariables,
} from '../../../../../apollo/generated/api'
import {
  ANONYMIZE_USER_ERROR,
  ANONYMIZE_USER_SUCCESS,
  ANONYMIZE_USER_CONTENT,
  ANONYMIZE_USER_TITLE,
} from '../../../../../constants/MessagesConstants'
import { handleMutationResult } from '../../../../../apollo'

type MutationClient = Pick<ApolloClient<NormalizedCacheObject>, 'mutate'>

const onAnonymizeConfirmation = (id: number, client: MutationClient): void => {
  void handleMutationResult(
    client.mutate<AnonymizeCustomerMutation, AnonymizeCustomerMutationVariables>({
      mutation: AnonymizeCustomerDocument,
      variables: { id },
    }),
    'anonymizeCustomer',
    {
      notifications: {
        success: {
          description: ANONYMIZE_USER_SUCCESS,
        },
        error: {
          title: ANONYMIZE_USER_ERROR,
        },
      },
    },
  )
}

const anonymizeAccount = ({ id }: { id: number }, { client }: { client: MutationClient }): void => {
  const modal = Modal.confirm({
    title: ANONYMIZE_USER_TITLE,
    content: ANONYMIZE_USER_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    okText: 'Yes, remove the customer info',
    onOk: () => {
      modal.update({ okButtonProps: { loading: true } })

      return onAnonymizeConfirmation(id, client)
    },
  })
}

export default anonymizeAccount
