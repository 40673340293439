import React, { useEffect, useState } from 'react'
import { generatePath, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, Descriptions, Divider, Tag, Typography, List, Alert, Row, Col, Tooltip } from 'antd'
import { WarningTwoTone } from '@ant-design/icons'
import humanizeString from 'humanize-string'

import {
  AffectedIngredient,
  GetComplaintQuery,
  LogisticsComplaint as ApiLogisticsComplaint,
  useGetComplaintQuery,
} from '../../../apollo/generated/beef'
import { categoryTagColor } from '../../../constants/ComplaintConstants'
import { Routes } from '../../../constants/RoutesConstants'
import LoadComponentError from '../../../packages/LoadComponentError'
import Loading from '../../../packages/Loading'
import { formatCurrency } from '../../../utils'
import {
  CurrencyEnum,
  Maybe,
  OrderRecipe,
  ShippedIngredient,
  useGetOrderComplaintDetailsLazyQuery,
  useGetOrderCurrencyLazyQuery,
  useGetOrderDeliveryDetailsLazyQuery,
  useGetOrderRecipesIngredientsLazyQuery,
  useGetOrderShipmentsLazyQuery,
} from '../../../apollo/generated/api'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import type { ManageOrderProps } from '../../../containers/ManageOrderContainer/ManageOrderContainer'

import LogisticsComplaint from './LogisticsComplaint'

type DisplayedRecipes = {
  id: Maybe<number> | undefined
  fullName: string
  ingredients: ShippedIngredient[]
  substituteIngredients: AffectedIngredient[]
}

const { Text } = Typography
const labelStyle = { fontWeight: 500 }

const ManageComplaintDetails: React.FC<{ order: ManageOrderProps }> = ({ order }) => {
  const customerTimezone = order?.customer?.timezone
  const { complaintId } = useParams<{ complaintId: string }>()
  const { loading, error, data } = useGetComplaintQuery({ variables: { id: complaintId }, fetchPolicy: 'no-cache' })
  const [getOrderDetails, { loading: orderLoading, data: orderData }] = useGetOrderComplaintDetailsLazyQuery()
  const [getCurrencyDetails, { loading: currencyLoading, data: currencyData }] = useGetOrderCurrencyLazyQuery()
  const complaint = data?.complaint
  const [getIngredients, { data: ingredientsData }] = useGetOrderRecipesIngredientsLazyQuery()
  const compensationAmount = complaint?.compensationAmount
  const compensationGranted = complaint?.compensationGranted
  const category = complaint?.category
  const currency = orderData
    ? (orderData.order?.pricing?.currency as CurrencyEnum)
    : (currencyData?.order?.pricing?.currency as CurrencyEnum)
  const amountGranted = compensationGranted === 0 ? compensationAmount : compensationGranted
  const [getShipmentDetails] = useGetOrderShipmentsLazyQuery()
  const [getDeliveryDetails] = useGetOrderDeliveryDetailsLazyQuery()
  const isLogisticsComplaint = complaint?.complaintDetails.__typename === 'LogisticsComplaint'
  const defaultLogisticsComplaintDetails: Partial<ApiLogisticsComplaint> | null = isLogisticsComplaint
    ? (complaint.complaintDetails as ApiLogisticsComplaint) || {}
    : {}
  const [logisticsComplaint, setLogisticsComplaint] = useState<Partial<ApiLogisticsComplaint>>({})

  useEffect(() => {
    if (!data || !complaint) {
      return
    }

    if (complaint.orderNumber) {
      if (complaint.affectedRecipes.length !== 0 || complaint.affectedIngredients.length !== 0) {
        void getOrderDetails({ variables: { number: complaint.orderNumber } })
      } else {
        void getCurrencyDetails({ variables: { number: complaint.orderNumber } })
      }

      if (complaint.affectedAddOns.length > 0) {
        void getIngredients({
          variables: { number: complaint.orderNumber },
        })
      }
    }

    if (isLogisticsComplaint) {
      setLogisticsComplaint(defaultLogisticsComplaintDetails)

      if (!defaultLogisticsComplaintDetails.trackingNumber) {
        void getShipmentDetails({ variables: { number: complaint.orderNumber } }).then(value => {
          const trackingNumber = value.data?.order?.deliveries?.[0]?.shipments?.[0].tracking.number

          if (trackingNumber) {
            setLogisticsComplaint({
              ...defaultLogisticsComplaintDetails,
              trackingNumber,
            })
          }
        })
      }

      if (!defaultLogisticsComplaintDetails.shippingPartner) {
        void getDeliveryDetails({ variables: { number: complaint.orderNumber } }).then(value => {
          const shippingPartner = value.data?.order?.deliveries?.[0]?.timeSlot?.shipper?.name

          if (shippingPartner) {
            setLogisticsComplaint({
              ...defaultLogisticsComplaintDetails,
              shippingPartner,
            })
          }
        })
      }
    }
  }, [data])

  const renderSubcategoryDetails = (): JSX.Element => {
    if (isLogisticsComplaint) {
      return <LogisticsComplaint complaint={logisticsComplaint} />
    }

    return <div />
  }

  const matchRecipes = (recipes: OrderRecipe[]): OrderRecipe[] => {
    const affectedRecipes = complaint?.affectedRecipes.map(r => r.externalId) || []

    return recipes.filter(r => affectedRecipes.includes((r.id || -1).toString())) || []
  }

  /**
   * Function used to match the affected ingredients on Beef with the ones on MS API per recipe.
   * If the match is not possible then it means that the ingredient was substituted.
   * For these ingredients, we create an extra DisplayedRecipe to be able to display them anyway.
   */
  const matchIngredients = (orderRecipes: OrderRecipe[]): DisplayedRecipes[] => {
    const affectedIngredients: AffectedIngredient[] = complaint?.affectedIngredients || []
    const substituteIngredients: AffectedIngredient[] = Object.assign([], affectedIngredients)
    const recipes: DisplayedRecipes[] = []

    orderRecipes.forEach(r => {
      const ingredients =
        r.shippedIngredients?.filter(ingredient => {
          const index = searchAffectedIngredient(ingredient, affectedIngredients)

          if (index >= 0) {
            const indexToRemove = substituteIngredients.findIndex(
              ing => ing.externalId === affectedIngredients[index].externalId,
            )

            substituteIngredients.splice(indexToRemove, 1)

            return true
          }

          return false
        }) || []

      if (ingredients.length > 0) {
        recipes.push({ id: r.id, fullName: r.fullName, ingredients, substituteIngredients: [] })
      }
    })

    if (substituteIngredients.length > 0) {
      recipes.push({ id: null, fullName: 'These ingredients were substituted', ingredients: [], substituteIngredients })
    }

    return recipes
  }

  /**
   * Affected ingredients are match using the externalId and name.
   * If the match is possible then it means that the ingredient was not substituted.
   */
  const searchAffectedIngredient = (
    ingredient: ShippedIngredient,
    affectedIngredients: AffectedIngredient[],
  ): number => {
    return affectedIngredients.findIndex(
      ing => ing.externalId === ingredient.id.toString() || ing.name === ingredient.name,
    )
  }

  const renderAffectedItems = (complaint: GetComplaintQuery['complaint']): JSX.Element => {
    if (!complaint) return <div />

    return (
      <>
        {complaint.affectedItems.length !== 0 && (
          <Card title="Affected items" data-testid="affected-items" style={{ marginBottom: 20 }}>
            <List
              dataSource={complaint.affectedItems.map(it => it.name)}
              renderItem={(affectedItem: string): JSX.Element => {
                return <li>→ {affectedItem}</li>
              }}
            />
          </Card>
        )}

        {complaint.fsqaCategories.length !== 0 && (
          <Card title="Food Safety Issues" data-testid="food-safety-issues" style={{ marginBottom: 20 }}>
            <List
              dataSource={complaint.fsqaCategories.map(it => it.name)}
              renderItem={(issue: string): JSX.Element => {
                return <li>→ {issue}</li>
              }}
            />
            <Divider />

            {complaint.fsqaDetails && (
              <Descriptions title="Additional Food Safety Details" layout="vertical">
                <Descriptions.Item label="Warm Protein" labelStyle={labelStyle}>
                  {complaint.fsqaDetails.warmProtein !== null && complaint.fsqaDetails.warmProtein ? (
                    <Tag color="green">Yes</Tag>
                  ) : (
                    <Tag color="red">No</Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Received within Time Delivery Window" labelStyle={labelStyle}>
                  {complaint.fsqaDetails.receivedWithinTimeWindow !== null &&
                  complaint.fsqaDetails.receivedWithinTimeWindow ? (
                    <Tag color="green">Yes</Tag>
                  ) : (
                    <Tag color="red">No</Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Box or Ice Pack Damaged" labelStyle={labelStyle}>
                  {complaint.fsqaDetails.boxIcePackDamaged !== null && complaint.fsqaDetails.boxIcePackDamaged ? (
                    <Tag color="green">Yes</Tag>
                  ) : (
                    <Tag color="red">No</Tag>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Number of Ice Packs" labelStyle={labelStyle}>
                  {complaint.fsqaDetails.numIcePacks}
                </Descriptions.Item>

                <Descriptions.Item label="Other" labelStyle={labelStyle}>
                  {complaint.fsqaDetails.other}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Card>
        )}

        {complaint.qualityIssues.length !== 0 && (
          <Card title="Quality Issues" data-testid="quality-issues" style={{ marginBottom: 20 }}>
            <List
              dataSource={complaint.qualityIssues.map(it => it.name)}
              renderItem={(qualityIssue: string): JSX.Element => {
                return <li>→ {qualityIssue}</li>
              }}
            />
          </Card>
        )}

        {complaint.affectedRecipes.length !== 0 && orderData && (
          <Card title="Affected Recipes" data-testid="affected-recipes" style={{ marginBottom: 20 }}>
            <Row gutter={16}>
              {matchRecipes(orderData.order?.menu?.recipes as OrderRecipe[]).map(({ image, fullName }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={index} span={6}>
                  <img
                    style={{ width: '100%', borderRadius: 8, marginBottom: 10 }}
                    src={image?.url || ''}
                    alt={fullName}
                  />
                  <Text strong>{fullName}</Text>
                </Col>
              ))}
            </Row>
          </Card>
        )}

        {complaint.affectedIngredients.length !== 0 && orderData && (
          <Card title="Affected Ingredients" data-testid="affected-ingredients" style={{ marginBottom: 20 }}>
            {matchIngredients(
              orderData.order?.menu?.recipes?.filter(
                r => r.shippedIngredients?.length !== 0 && r.mealQuantity.numberOfPortions > 0,
              ) as OrderRecipe[],
            ).map(({ id, fullName, ingredients, substituteIngredients }) => (
              <>
                {ingredients.length > 0 && (
                  <Card key={Number(id)} title={fullName} style={{ width: '100%', marginBottom: 15 }}>
                    <Row gutter={[16, 16]}>
                      {ingredients.map(({ image, name }, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Col key={index} span={8}>
                          <img
                            style={{ width: '100%', borderRadius: 8, marginBottom: 10 }}
                            src={image?.url || ''}
                            alt={name || ''}
                          />
                          <Text strong>{name}</Text>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                )}
                {substituteIngredients.length > 0 && (
                  <Card
                    key={Number(id)}
                    title={
                      <>
                        <Tooltip
                          placement="top"
                          title="These ingredients were substituted. We can no longer group them by recipe."
                        >
                          <WarningTwoTone twoToneColor="red" style={{ paddingRight: 5, fontSize: 'large' }} />
                        </Tooltip>
                        {fullName}
                      </>
                    }
                    style={{ width: '100%', marginBottom: 15 }}
                  >
                    <Row gutter={[16, 16]}>
                      {substituteIngredients.map(({ name }, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Col key={index} span={8}>
                          <Text strong>{name}</Text>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                )}
              </>
            ))}
          </Card>
        )}

        {complaint.affectedAddOns.length > 0 && (
          <Card title="Affected AddOns" data-testid="affected-ingredients" style={{ marginBottom: 20 }}>
            <Row gutter={[16, 16]}>
              {complaint.affectedAddOns.map(({ externalId, name }) => (
                <Col key={externalId} span={8}>
                  <img
                    style={{ width: '100%', borderRadius: 8, marginBottom: 10 }}
                    src={
                      ingredientsData?.order?.contents?.addOns?.find(addOn => `${addOn.addOn?.id}` === externalId)
                        ?.addOn?.image?.url || ''
                    }
                    alt={name || ''}
                  />
                  <Text strong>{name}</Text>
                </Col>
              ))}
            </Row>
          </Card>
        )}

        {complaint.attachments.length !== 0 && (
          <Card title="Attachments" style={{ marginBottom: 20 }}>
            <Row gutter={16}>
              {complaint.attachments.map(att => (
                <Col span={6} key={att.id}>
                  <img
                    style={{ width: '100%', borderRadius: 8, marginBottom: 10 }}
                    src={att.link || ''}
                    alt="Not available"
                  />
                </Col>
              ))}
            </Row>
          </Card>
        )}
      </>
    )
  }

  if (loading) return <Loading />
  if (error) return <LoadComponentError />
  if (!complaint) return <Alert type="error" message="Couldn't find this complaint." />

  return (
    <Card
      title="Complaint Details"
      data-testid="complaint-details"
      extra={
        <div style={{ display: 'flex', fontSize: 15, fontWeight: 400 }}>
          <Link
            data-testid="complaint-history-link"
            to={generatePath(Routes.ORDER_COMPLAINT_HISTORY, {
              number: complaint.orderNumber,
              complaintId: complaint.id,
            })}
          >
            History
          </Link>
        </div>
      }
    >
      <Descriptions
        layout="vertical"
        extra={[
          complaint.discardedAt && (
            <Tag key="deleted-tag" color="red" data-testid="delete-badge" style={{ fontWeight: 500 }}>
              DELETED
            </Tag>
          ),
        ]}
        bordered
      >
        <Descriptions.Item label="Order" labelStyle={labelStyle}>
          <a href={`/orders/${complaint.orderNumber}`} target="_blank" rel="noopener noreferrer">
            {complaint.orderNumber}
          </a>
        </Descriptions.Item>

        {complaint.description && (
          <Descriptions.Item label="Description" labelStyle={labelStyle} span={2}>
            {complaint.description}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="Category" labelStyle={labelStyle}>
          <Tag color={category ? categoryTagColor[category.name.replace(/\s/g, '')] : ''}>{category?.name}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Subcategory" labelStyle={labelStyle}>
          {complaint.subcategory.name}
        </Descriptions.Item>

        <Descriptions.Item label="Type" labelStyle={labelStyle}>
          {humanizeString(complaint.level)}
        </Descriptions.Item>

        <Descriptions.Item label="Received At" labelStyle={labelStyle}>
          <UserAwareDateWithTz datetime={complaint.receivedAt} customerTimezone={customerTimezone} />
        </Descriptions.Item>

        <Descriptions.Item label="Logged At" labelStyle={labelStyle}>
          <UserAwareDateWithTz datetime={complaint.createdAt} customerTimezone={customerTimezone} />
        </Descriptions.Item>

        <Descriptions.Item label="Logged By" labelStyle={labelStyle}>
          {complaint.createdBy}
        </Descriptions.Item>

        <Descriptions.Item label="Resolved At" labelStyle={labelStyle}>
          {complaint.resolvedAt && (
            <UserAwareDateWithTz datetime={complaint.resolvedAt} customerTimezone={customerTimezone} />
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Resolved By" labelStyle={labelStyle}>
          {complaint.resolvedBy}
        </Descriptions.Item>

        <Descriptions.Item label="Food Safety Issue" labelStyle={labelStyle}>
          {complaint.foodSafetyIssue ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
        </Descriptions.Item>

        <Descriptions.Item label="Recurring issue" labelStyle={labelStyle}>
          {complaint.recurring ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
        </Descriptions.Item>

        <Descriptions.Item label="Compensation" labelStyle={labelStyle}>
          {(compensationAmount || 0) < 0 ? (
            <div>
              <Text strong>Adjustment amount: </Text>
              <Text keyboard>{formatCurrency(compensationAmount || 0, currency)}</Text>
            </div>
          ) : (
            <>
              <div style={{ marginBottom: 10 }}>
                <Text strong>Credit amount in credit policy: </Text>
                <Text keyboard>{formatCurrency(compensationAmount || 0, currency)}</Text>
              </div>
              <div>
                <Text strong>Credit amount granted: </Text>
                <Text keyboard>{formatCurrency(amountGranted || 0, currency)}</Text>
              </div>
            </>
          )}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {renderSubcategoryDetails()}
      {orderLoading || currencyLoading ? <Loading /> : renderAffectedItems(complaint)}
    </Card>
  )
}

export default ManageComplaintDetails
