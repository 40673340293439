import React, { useEffect, useMemo, useState } from 'react'
import { Alert } from 'antd'

import { GetOrderCreditInfoQuery, useGetOrderCreditInfoLazyQuery } from '../../../apollo/generated/api'
import { formatCurrency } from '../../../utils'

export interface OrderCreditWarningProps {
  orderNumber?: string
  creditAmount?: number
}

export const OrderCreditWarning: React.FC<OrderCreditWarningProps> = props => {
  const [getCreditInfo] = useGetOrderCreditInfoLazyQuery()
  const [creditInfo, setCreditInfo] = useState<GetOrderCreditInfoQuery>()

  useEffect(() => {
    if (props.orderNumber) {
      const orderNum = props.orderNumber

      setCreditInfo(undefined)

      void getCreditInfo({ variables: { orderNumber: props.orderNumber } }).then(value => {
        if (orderNum === props.orderNumber) {
          setCreditInfo(value.data)
        }
      })
    }
  }, [props.orderNumber])

  const alertText = useMemo(() => {
    const currentCreditAmount = props.creditAmount || 0

    if (!creditInfo || !currentCreditAmount) {
      return undefined
    }

    const prevCredited = creditInfo.orderCreditInfo?.totalCredited || 0
    const orderValue = creditInfo.orderCreditInfo?.totalValue || 0
    const totalAmount = currentCreditAmount + prevCredited

    if (currentCreditAmount + prevCredited > orderValue) {
      const formattedTotalCredit = formatCurrency(totalAmount, creditInfo.orderCreditInfo?.order.pricing?.currency)
      const formattedOrderAmount = formatCurrency(orderValue, creditInfo.orderCreditInfo?.order.pricing?.currency)

      // eslint-disable-next-line max-len
      return `Alert: The Total credit and refund amount of ${formattedTotalCredit} exceeds the total order value of ${formattedOrderAmount}. Please ensure the Total Credit given is correct before proceeding.`
    }

    return ''
  }, [creditInfo, props.creditAmount])
  const waningText = useMemo(() => {
    const totalCredited = creditInfo?.orderCreditInfo?.totalCredited || 0

    if (totalCredited > 0) {
      const formatted = formatCurrency(totalCredited, creditInfo?.orderCreditInfo?.order.pricing?.currency)

      // eslint-disable-next-line max-len
      return `Attention: A credit has already been issued for this order (${formatted}). Please review the credit details before proceeding.`
    }

    return undefined
  }, [creditInfo])

  return (
    <>
      {alertText && <Alert type="error" message={alertText} style={{ marginBottom: 20 }} />}
      {waningText && <Alert type="warning" message={waningText} style={{ marginBottom: 20 }} />}
    </>
  )
}
