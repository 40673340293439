import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { handleMutationResult } from '../../../../../apollo'
import {
  COMPLETE_ORDER_CONFIRM_TITLE,
  COMPLETE_ORDER_CONFIRM_CONTENT,
  COMPLETE_ORDER_SUCCESS,
  COMPLETE_ORDER_FAILURE,
} from '../../../../../constants/MessagesConstants'
import {
  CompleteOrderDocument,
  CompleteOrderMutation,
  CompleteOrderMutationVariables,
} from '../../../../../apollo/generated/api'

type MutationClient = Pick<ApolloClient<NormalizedCacheObject>, 'mutate'>

const onCompleteConfirmation = (number: string, client: MutationClient): void => {
  void handleMutationResult(
    client.mutate<CompleteOrderMutation, CompleteOrderMutationVariables>({
      mutation: CompleteOrderDocument,
      variables: { completeOrder: { number } },
    }),
    'completeOrder',
    {
      notifications: {
        success: {
          title: COMPLETE_ORDER_CONFIRM_TITLE,
          description: COMPLETE_ORDER_SUCCESS,
        },
        error: {
          title: COMPLETE_ORDER_FAILURE,
        },
      },
    },
  )
}

const completeOrder = ({ number }: { number: string }, { client }: { client: MutationClient }): void => {
  const modal = Modal.confirm({
    title: COMPLETE_ORDER_CONFIRM_TITLE,
    content: COMPLETE_ORDER_CONFIRM_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okText: 'Yes, mark the order as complete',
    onOk: () => {
      modal.update({ okButtonProps: { loading: true } })

      return onCompleteConfirmation(number, client)
    },
  })
}

export default completeOrder
