import { Moment } from 'moment'

import { AddressSelection, AddressChangeHandler } from '../SelectOrderAddresses/SelectOrderAddresses'
import { SlotSelection, DeliveryChangeHandler } from '../OrderDeliveryCalendar'

export enum WizardStep {
  Addresses = 0,
  Delivery = 1,
  Confirmation = 2,
}

export type OrderWizardState = {
  step: WizardStep
  customerId: number
  addresses: {
    shipping?: AddressSelection
    billing?: AddressSelection
  }
  delivery: {
    date?: Moment
    timeslot?: SlotSelection
  }
  includeDatesPastCutoff: boolean
  setAddresses: AddressChangeHandler
  setDelivery: DeliveryChangeHandler
  setStep: (step: WizardStep) => void
}

export type OrderWizardStepProps = {
  state: OrderWizardState
  initial?: OrderWizardProps['initialState']
}

export type OrderWizardProps = {
  customerId: number
  includeDatesPastCutoff: boolean
  onSubmit: (state: OrderWizardState) => Promise<boolean>
  submitButtonText?: string
  initialState?: {
    delivery?: {
      date: Moment
      timeSlotId: number
    }
  }
}
