import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

export enum PaginationParams {
  AFTER = 'after',
  BEFORE = 'before',
}

interface UseRelayPaginationReturn {
  currentCursor: string
  hasPreviousPage: boolean
  handleNextPage: (cursor?: string | null) => void
  handlePreviousPage: () => void
  currentPage: number
}

function getCurrentCursor(search: string): string {
  const params = new URLSearchParams(search)
  const cursor = params.get(PaginationParams.AFTER)

  return cursor || ''
}

function useRelayPagination(): UseRelayPaginationReturn {
  const history = useHistory()
  const location = useLocation()
  const [currentCursor, setCurrentCursor] = useState<string>(getCurrentCursor(location.search))
  const [cursorStack, setCursorStack] = useState<string[]>([])
  const hasPreviousPage = cursorStack.length > 0

  useEffect(() => {
    const initialCursor = getCurrentCursor(location.search)

    if (initialCursor) {
      setCursorStack([initialCursor])
    }
  }, [])

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: currentCursor ? `?after=${currentCursor}` : '',
    })
  }, [currentCursor])

  function handleNextPage(cursor?: string | null) {
    if (cursor) {
      const updatedCursorStack = [...cursorStack]

      updatedCursorStack.push(cursor)

      setCurrentCursor(cursor)
      setCursorStack(updatedCursorStack)
    }
  }

  function handlePreviousPage() {
    const updatedCursorStack = [...cursorStack]

    updatedCursorStack.pop()

    setCurrentCursor(updatedCursorStack[updatedCursorStack.length - 1])
    setCursorStack(updatedCursorStack)
  }

  return { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage, currentPage: cursorStack.length }
}

export default useRelayPagination
