import styled from 'styled-components'

export const ToggleContainer = styled.div<{ toggle: boolean }>`
  display: flex;
  flex-direction: row;
  span {
    color: ${({ toggle }) => (toggle ? '#40a9ff' : '#d9d9d9')};
    padding-right: 5px;
  }
`
