export enum Routes {
  ROOT = '/',
  LOGIN = '/login',
  SEARCH = '/search',
  CUSTOMERS = '/customers',
  CUSTOMER_SEARCH = '/customers/search',
  CUSTOMER_EMAIL_SEARCH_RESULTS = '/customers/search/email/:email/:returnAnonymizedResults',
  CUSTOMER_ADDRESS_SEARCH_RESULTS = '/customers/search/address/:query',
  CUSTOMER_DETAIL = '/customers/:id',
  CUSTOMER_ORDERS = '/customers/:id/orders',
  CUSTOMER_PAYMENT_SOURCES = '/customers/:id/payment-sources',
  CUSTOMER_PAYMENT_SOURCE_DETAIL = '/customers/:id/payment-sources/:index',
  CUSTOMER_ACCOUNT_HISTORY = '/customers/:id/account-history',
  CUSTOMER_CREDIT_HISTORY = '/customers/:id/credit-history',
  CUSTOMER_PLAN = '/customers/:id/plan',
  CUSTOMER_RECIPE_PREFERENCES = '/customers/:id/recipe-preferences',
  CUSTOMER_ADDRESSES = '/customers/:id/addresses',
  CUSTOMER_ADDRESS_DETAIL = '/customers/:id/addresses/:addressId',
  CUSTOMER_ADDRESS_CREATION = '/customers/:id/addresses/new',
  CUSTOMER_MESSAGES = '/customers/:id/messages',
  CUSTOMER_MESSAGE = '/customers/:id/messages/:messageId',
  CUSTOMER_ORDER_CREATION = '/customers/:id/orders/new',
  CUSTOMER_REWARDS = '/customers/:id/rewards',
  CUSTOMER_REFERRALS = '/customers/:id/referrals',
  CUSTOMER_COMPLAINTS = '/customers/:id/complaints',
  CUSTOMER_INCIDENTS = '/customers/:id/incidents',
  ORDER_COMPLAINT_EDIT = '/orders/:number/complaints/:complaintId/edit',
  ORDER_COMPLAINT_DETAILS = '/orders/:number/complaints/:complaintId',
  ORDER_COMPLAINT_HISTORY = '/orders/:number/complaints/:complaintId/history',
  ORDER_SEARCH = '/orders/search',
  ORDERS = '/orders',
  ORDER_DETAIL = '/orders/:number',
  ORDER_RECIPES = '/orders/:number/recipes',
  ORDER_DELIVERY = '/orders/:number/delivery',
  ORDER_SHIPMENTS = '/orders/:number/shipments',
  ORDER_PAYMENTS = '/orders/:number/payments',
  ORDER_PAYMENT_DETAIL = '/orders/:number/payments/:id',
  ORDER_ADJUSTMENTS = '/orders/:number/adjustments',
  ORDER_COMPLAINTS = '/orders/:number/complaints',
  ORDER_HISTORY = '/orders/:number/history',
  ORDER_ADDRESS_DETAIL = '/orders/:number/addresses/:id',
  COMPLAINTS_NEW = '/orders/:number/new/complaint',
  ADMIN_USERS = '/admin-users',
  MASS_CREDIT = '/mass-credit',
  LEADS = '/leads',
  COMPLAINTS_SEARCH = '/complaints/search',
  COMPLAINTS_MASS_UPLOAD = '/complaints/mass-upload',
  SHIPMENT_TRACKING = '/orders/:number/shipments/:shipmentNumber/tracking',
  MANAGE_INGREDIENT_CLASSIFICATIONS = '/manage-ingredient-classifications',
}
