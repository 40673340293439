import React from 'react'
import { Card, Row, Col, Form, Radio } from 'antd'

import { required } from '../formConfig'
import { Scope } from '../../../../apollo/generated/beef'

const Scopes: React.FC<{ options?: Scope[] }> = ({ options }) => {
  return (
    <Card
      title="What is the complaint about?"
      headStyle={{ textAlign: 'center' }}
      style={{ marginBottom: 35, boxShadow: '0 15px 15px rgba(0, 0, 0, .05)' }}
    >
      <Row style={{ marginTop: 25 }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Form.Item name="complaintScope" rules={[required]}>
            <Radio.Group>
              {options?.map(({ id, name }) => (
                <Radio data-testid={`scope-${name}`} value={id} key={id} style={{ margin: '0 25px' }}>
                  {name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}

export default Scopes
