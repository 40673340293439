import React, { useMemo, useState } from 'react'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Alert } from 'antd'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import RecipesList from '../../RecipesList'
import { filterMaybe } from '../../../utils/typeUtils'
import {
  BrandEnum,
  CountryEnum,
  CurrencyEnum,
  GetOrderRecipesQuery,
  OrderStatusEnum,
  UpdateOrderContentsMutation,
  useGetOrderRecipesQuery,
} from '../../../apollo/generated/api'
import AddOns, { OrderAddOn } from '../../AddOns'
import { Recipe } from '../../RecipesList/RecipesList'
import { OrderUpdateSource } from '../../AddOns/types'

interface PageData {
  fruitboxAvailable: boolean
  editable: boolean
  customerId: Maybe<number>
  recipes: Recipe[]
  addOns: OrderAddOn[]
  order?: GetOrderRecipesQuery['order']
  inProduction: boolean
  country: CountryEnum
}

const ManageOrderRecipes: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const [pageData, setPageData] = useState<PageData>({
    addOns: [],
    customerId: 0,
    order: undefined,
    editable: false,
    recipes: [],
    fruitboxAvailable: false,
    inProduction: false,
    country: CountryEnum.Us,
  })
  const { data, loading, error } = useGetOrderRecipesQuery({
    variables: { number: orderNumber },
    fetchPolicy: 'no-cache',
  })

  useMemo(() => {
    const customer = data?.order?.customer

    setPageData({
      order: data?.order,
      customerId: data?.order?.customer?.id,
      fruitboxAvailable: customer?.userPlan?.fruitBox?.available || false,
      editable: data?.order?.status === OrderStatusEnum.Editable,
      recipes: (data?.order?.menu?.recipes?.filter(filterMaybe) || []) as unknown as Recipe[],
      addOns: data?.order?.contents?.addOns?.filter(filterMaybe) || [],
      inProduction: data?.order?.orderStatus?.inProduction || false,
      country: data?.order?.country || pageData.country,
    })
  }, [data])

  const onOrderUpdated = (source: OrderUpdateSource, data?: UpdateOrderContentsMutation) => {
    if (!data) {
      return
    }

    let { addOns, recipes } = pageData

    // eslint-disable-next-line default-case
    switch (source) {
      case 'add_ons':
      case 'fruitbox':
        addOns =
          (
            data.updateOrderContents as {
              contents: { addOns: OrderAddOn[] }
            }
          )?.contents?.addOns || addOns
        break
      case 'recipes':
        recipes = (data.updateOrderContents as unknown as { menu: { recipes: Recipe[] } })?.menu?.recipes || recipes
        break
    }

    setPageData({
      ...pageData,
      addOns: addOns || [],
      recipes: recipes || [],
    })
  }
  const { addOns, recipes } = pageData

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <LoadComponentError errorMessage={error.message} />
  }

  return (
    <>
      {data?.order?.brand === BrandEnum.Bm && <Alert type="info" message="Login as user to manage meals." />}
      {data?.order?.brand !== BrandEnum.Bm && (
        <>
          <RecipesList
            onOrderUpdated={onOrderUpdated}
            orderNumber={orderNumber}
            data={recipes}
            editable={pageData.editable}
            inProduction={pageData.inProduction || false}
            metaOptions={pageData?.order?.menu?.meta || {}}
            currency={pageData?.order?.pricing?.currency as CurrencyEnum}
          />
          <AddOns
            onOrderUpdated={onOrderUpdated}
            orderNumber={orderNumber}
            orderAddOns={addOns}
            customerId={pageData.customerId}
            editable={pageData.editable}
            country={pageData.country}
            fruitBoxAvailable={pageData.fruitboxAvailable}
            inProduction={pageData.inProduction}
            brand={data?.order?.brand as BrandEnum}
          />
        </>
      )}
    </>
  )
}

export default ManageOrderRecipes
