import axios from 'axios'

import { AddressInputType, CountryEnum } from '../apollo/generated/api'

import LoqateResult from './LoqateResult'

const validateUrl = 'https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws'
const suggestionsUrl = 'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws'
const retrieveUrl = 'https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws'

export type LoqateSuggestions = {
  Items: {
    Id: string
    Type: string
    Text: string
    Highlight: string
    Description: string
  }[]
}

export type LoqateAddresses = {
  Items: {
    Id: string
    DomesticId: string
    Language: string
    LanguageAlternatives: string
    Department: string
    Company: string
    SubBuilding: string
    BuildingNumber: string
    BuildingName: string
    SecondaryStreet: string
    Street: string
    Block: string
    Neighbourhood: string
    District: string
    City: string
    Line1: string
    Line2: string
    Line3: string
    Line4: string
    Line5: string
    AdminAreaName: string
    AdminAreaCode: string
    Province: string
    ProvinceName: string
    ProvinceCode: string
    PostalCode: string
    CountryName: string
    CountryIso2: string
    CountryIso3: string
    CountryIsoNumber: string
    SortingNumber1: string
    SortingNumber2: string
    Barcode: string
    POBoxNumber: string
    Label: string
    Type: string
    DataLevel: string
    Field1: string
    Field2: string
    Field3: string
    Field4: string
    Field5: string
    Field6: string
    Field7: string
    Field8: string
    Field9: string
    Field10: string
    Field11: string
    Field12: string
    Field13: string
    Field14: string
    Field15: string
    Field16: string
    Field17: string
    Field18: string
    Field19: string
    Field20: string
  }[]
}

/**
 *
 * @param address
 * @param country
 * @returns Promise<LoqateResult>
 * Returns if an address is considered valid given all the fields that we sent.
 * https://www.loqate.com/resources/support/cleanse-api/international-batch-cleanse/
 */
export const validateAddress = (address: AddressInputType, country: CountryEnum): Promise<void | LoqateResult> =>
  axios
    .post<LoqateResult, never>(validateUrl, {
      Key: window.env.locateApiKey,
      Geocode: false,
      Options: {
        Process: 'Verify',
      },
      Addresses: [
        {
          Address1: address.line1,
          Address2: address.line2,
          Address3: address.city,
          Country: country.toString(),
          PostalCode: address.zipcode,
        },
      ],
    })
    .then(({ data }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, dot-notation
      const res = new LoqateResult(data[0]['Matches'][0]['AVC'])

      return res
    })
    .catch(e => console.error(e))

/**
 * @param country ISO2 code
 * @param query your search query
 * @param container is used when you need to query an additional level on that specific address
 * @returns Promise<LoqateSuggestions>
 * More info on this api in https://www.loqate.com/resources/support/apis/Capture/Interactive/Find/1.1/
 */
export const fetchSuggestions = (
  country: CountryEnum,
  query: string,
  container?: string,
): Promise<LoqateSuggestions['Items']> => {
  return axios
    .get<LoqateSuggestions>(suggestionsUrl, {
      params: {
        Key: window.env.locateApiKey,
        Text: `${query}`,
        IsMiddleware: false,
        Origin: country.toString(),
        Countries: country.toString(),
        Container: container,
        Limit: 5,
      },
    })
    .then(({ data }) => data.Items)
}

/**
 *
 * @param id ID of the address to retrieve
 * @returns Promise<LoqateAddresses>
 * Retrieves a full address information by id. If the address id is not of the type `Address` (e.g `Street`)
 * This call will fail.
 * https://www.loqate.com/resources/support/apis/Capture/Interactive/Retrieve/1.2/
 */
export const fetchAddress = (id: string): Promise<LoqateAddresses['Items']> => {
  return axios
    .get<LoqateAddresses>(retrieveUrl, {
      params: {
        Key: window.env.locateApiKey,
        Id: id,
      },
    })
    .then(data => data.data.Items)
}
