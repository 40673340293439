import React from 'react'
import { Card, Descriptions, Typography } from 'antd'

import { CreditCard as ApiCreditCard } from '../../../apollo/generated/api'
import { capitalize } from '../../../utils/textUtils'

const { Text } = Typography

const CreditCard: React.FC<{ card: ApiCreditCard }> = ({ card }) => {
  const { name, lastDigits, cardType, month, year } = card

  return (
    <Card title="Credit Card">
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Name on card">
          <Text strong>{name}</Text>
        </Descriptions.Item>

        <Descriptions.Item label="Last 4 digits">
          <Text strong>**** **** **** {lastDigits}</Text>
        </Descriptions.Item>

        <Descriptions.Item label="Card type">
          <Text strong>{capitalize(cardType || '')}</Text>
        </Descriptions.Item>

        <Descriptions.Item label="Expiration">
          <Text strong>
            {month}/{year}
          </Text>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default CreditCard
