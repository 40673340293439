enum VerificationStatus {
  'Verified' = 'V',
  'Partially Verified' = 'P',
  'Unverified' = 'U',
  'Ambiguous' = 'A',
  'Conflict' = 'C',
  'Reverted' = 'R',
}

export default class LoqateResult {
  verification: VerificationStatus

  parsingLevel: number

  // Postcode parsing result
  postcode: string

  // Parsing score from Loqate, ranging from 0 to 100
  score: number

  constructor(code: string) {
    const [verification, parsing, postcode, score] = code.split('-')

    this.verification = verification.charAt(0) as VerificationStatus
    this.parsingLevel = Number(parsing.slice(1)) / 10
    this.postcode = postcode
    this.score = Number(score)
  }

  valid(): boolean {
    console.log(this.verification === VerificationStatus.Verified)
    console.log(this.parsingLevel < 4)

    return !(this.verification !== VerificationStatus.Verified || this.parsingLevel < 4)
  }
}
