import React from 'react'
import { Row, Col, PageHeader } from 'antd'

import SearchOrderForm from '../Forms/SearchOrderForm'
import { headerIcons } from '../../constants/IconsConstants'

const COL_SIZE = 8
const SearchOrder: React.FC = () => (
  <>
    <PageHeader
      title="Orders"
      avatar={{
        src: headerIcons.ORDERS,
        shape: 'square',
      }}
    />

    <Row justify="center" gutter={32}>
      <Col span={COL_SIZE}>
        <SearchOrderForm />
      </Col>
    </Row>
  </>
)

export default SearchOrder
