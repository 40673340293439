import { useHistory } from 'react-router'

import { PaginationParams } from './useRelayPagination'

const useSearchUrl = () => {
  const history = useHistory()
  const paramName = 'q'

  function parseSearchUrl<Type>(): Type | null {
    const query = new URLSearchParams(window.location.search).get(paramName)

    if (!query) {
      return null
    }

    return JSON.parse(window.atob(query))
  }

  function buildSearchUrl<Type>(params: Type): URL {
    const url = new URL(window.location.href)

    url.searchParams.set(paramName, window.btoa(JSON.stringify(params)))

    return url
  }

  function navigateToSearchUrl<Type>(params: Type, resetPagination = true) {
    const url = buildSearchUrl(params)

    if (resetPagination) {
      url.searchParams.delete(PaginationParams.AFTER)
      url.searchParams.delete(PaginationParams.BEFORE)
    }

    history.push(`${url.pathname}${url.search}`)
  }

  return {
    parseSearchUrl,
    buildSearchUrl,
    navigateToSearchUrl,
  }
}

export default useSearchUrl
