import React from 'react'
import { Card } from 'antd'

import { InvoiceRecipient as ApiInvoiceRecipient } from '../../../apollo/generated/api'

const InvoiceRecipient: React.FC<{ invoiceRecipient: ApiInvoiceRecipient }> = ({ invoiceRecipient }) => {
  const { id } = invoiceRecipient

  return <Card title="Invoice Payment">Invoice ID: {id}</Card>
}

export default InvoiceRecipient
