import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, notification } from 'antd'

import { client } from '../../../../../apollo'
import {
  UNSKIP_ORDER_CONFIRM_TITLE,
  UNSKIP_ORDER_CONFIRM_CONTENT,
  UNSKIP_ORDER_CONFIRM_BUTTON,
  UNSKIP_ORDER_SUCCESS,
  UNSKIP_ORDER_FAILURE,
  ERROR_TITLE,
} from '../../../../../constants/MessagesConstants'
import {
  UnskipOrderDocument,
  UnskipOrderMutation,
  UnskipOrderMutationVariables,
} from '../../../../../apollo/generated/api'

const onUnskipConfirmation = async (number: string): Promise<void> => {
  try {
    await client.mutate<UnskipOrderMutation, UnskipOrderMutationVariables>({
      mutation: UnskipOrderDocument,
      variables: { number },
    })
    notification.success({
      message: UNSKIP_ORDER_SUCCESS,
    })
  } catch (e) {
    notification.error({
      message: ERROR_TITLE,
      description: UNSKIP_ORDER_FAILURE,
    })
  }
}

const unskipOrder = ({ number }: { number: string }): void => {
  const modal = Modal.confirm({
    title: UNSKIP_ORDER_CONFIRM_TITLE,
    content: UNSKIP_ORDER_CONFIRM_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    okText: UNSKIP_ORDER_CONFIRM_BUTTON,
    onOk: () => {
      modal.update({ okButtonProps: { loading: true } })

      return onUnskipConfirmation(number)
    },
  })
}

export default unskipOrder
