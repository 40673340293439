import { COOKBOOK_USER_SESSION } from '../constants/AuthConstants'
import { getUserSession } from '../utils'

export interface AbortableRequest<T> {
  promise: Promise<T>
  abort: () => void
  then: <Q>(mapFn: (value: T) => Q) => AbortableRequest<Q>
  catch: <Q>(mapFn: (value: T) => Q) => AbortableRequest<T | Q>
}

class AbortableRequestImplm<T> implements AbortableRequest<T> {
  // eslint-disable-next-line no-useless-constructor
  constructor(public promise: Promise<T>, public abort: () => void) {}

  then<Q>(mapFn: (value: T) => Q): AbortableRequest<Q> {
    return new AbortableRequestImplm(
      this.promise.then(v => {
        return mapFn(v)
      }),
      this.abort,
    )
  }

  catch<Q>(mapFn: (value: T) => Q): AbortableRequest<T | Q> {
    return new AbortableRequestImplm(
      this.promise.catch(v => {
        return mapFn(v)
      }),
      this.abort,
    )
  }
}

function newEmptyRequest<T>(): AbortableRequest<T> {
  const p = new Promise<T>(() => {
    /* nothing */
  })

  const cancel = () => {
    /* nothing */
  }

  return new AbortableRequestImplm(p, cancel)
}

export function makeGraphqlRequest<T>(
  gqlEndpoint: string,
  query: string,
  variables: Record<string, unknown>,
  tokenKey: string = COOKBOOK_USER_SESSION,
): AbortableRequest<T> {
  const authToken = getUserSession(tokenKey).session?.token?.value
  const abortController = new AbortController()

  // eslint-disable-next-line no-prototype-builtins
  if (!window || !window.hasOwnProperty('fetch')) {
    // this happens when running the jest tests
    return newEmptyRequest()
  }

  const promise: Promise<T> = fetch(gqlEndpoint, {
    method: 'POST',
    signal: abortController.signal,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ query, variables }),
  })
    .then(response => response.json())
    .then(data => data as T)

  return new AbortableRequestImplm(promise, () => abortController.abort())
}
