import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { CameraOutlined, SwapOutlined, TagOutlined } from '@ant-design/icons'
import { Badge, Button, Card, Col, Modal, Popconfirm, Row, Space, Table, Tabs, Tag, Tooltip, Typography } from 'antd'
import humanizeString from 'humanize-string'
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors'

import logger from '../../logging'
import * as customProps from '../../constants/OverridesConstants'
import { filterMaybe } from '../../utils/typeUtils'
import {
  IN_PRODUCTION_WARNING,
  NOTIFICATION_TITLE_FAILURE,
  RECIPES_UPDATED_SUCCESS,
} from '../../constants/MessagesConstants'
import { handleMutationResult } from '../../apollo'
import {
  Image as ApiImage,
  CurrencyEnum,
  OrderMenuMeta,
  OrderRecipeInputType,
  PriceComponentTypeEnum,
  RecipeTypeEnum,
  ShippedIngredient,
  UpdateOrderContentsMutation,
  useGetOrderVirtualPriceLazyQuery,
  useUpdateOrderContentsMutation,
} from '../../apollo/generated/api'
import { OrderUpdateSource } from '../AddOns/types'
import { PriceComponent, formatCurrency } from '../../utils'
import { errorNotification } from '../../apollo/mutations'
import PriceBreakDown from '../Shared/PriceBreakDown/PriceBreakDown'

import * as S from './styles'

enum Actions {
  INCREASE,
  DECREASE,
}

type MarkAttrs = {
  tooltip?: string
  icon?: ReactNode
  color?: PresetColorType | PresetStatusColorType
  empty?: boolean
  fontSize?: number
}

export type Recipe = {
  id?: number | null
  url?: string | null
  fullName: string
  recipeCardUrl?: string | null
  quantity?: number | null
  nutritionalInformation: Array<NutritionalInformation>
  shippedIngredients?: Array<Pick<ShippedIngredient, 'id' | 'nameWithQuantity'>> | null
  image?: {
    url?: string | null
  } | null
  recipeType: RecipeTypeEnum
  extraFees: PriceComponent[]
  recipeVariants: RecipeVariant[]
}

export type RecipeVariant = Recipe & {}

type NutritionalInformation = {
  key?: string | null
  name?: string | null
  per100g?: number | null
  perPortion?: number | null
  total?: number | null
}

export type RecipesListProps = {
  orderNumber: string
  data: Recipe[]
  editable: boolean
  inProduction: boolean
  metaOptions: OrderMenuMeta
  onOrderUpdated?: (source: OrderUpdateSource, data?: UpdateOrderContentsMutation) => void
  currency: CurrencyEnum
}

const { Column } = Table
const { Text } = Typography

const isVariant = (recipe: Recipe): boolean => {
  return recipe.recipeVariants.length > 0 && recipe.recipeType === RecipeTypeEnum.Premium
}

// Returns list of recipe variants without the current recipe
const variantsOf = (recipe: Recipe): RecipeVariant[] => {
  return recipe.recipeVariants.filter(variant => variant.id !== recipe.id && !isVariant(recipe)) as RecipeVariant[]
}
const isPremiumFee = (component: PriceComponent): boolean => component.type === PriceComponentTypeEnum.PremiumFee
const varianSurcharge = (variant: RecipeVariant, portions: number): number =>
  (variant.extraFees.filter(isPremiumFee)[0]?.totalAmount || 0) * portions
const extraFeesSum = (recipe: Recipe): number =>
  recipe.extraFees.reduce((acc, fee) => acc + fee.totalAmount * (recipe.quantity || 1), 0)

const RecipesList: React.FC<RecipesListProps> = ({
  orderNumber,
  data,
  editable,
  metaOptions,
  inProduction,
  onOrderUpdated,
  currency,
}) => {
  const [updateOrderContents, { loading }] = useUpdateOrderContentsMutation()
  const [recipes, setRecipes] = useState(data)
  const [canUpdateRecipes, setCanUpdateRecipes] = useState(false)
  const [getVirtualPrice, { data: virtualPrice, loading: virtualPriceLoading, error: virtualPriceError }] =
    useGetOrderVirtualPriceLazyQuery({
      variables: {
        number: orderNumber,
        recipes: recipes
          .filter(recipe => (recipe.quantity || 0) > 0)
          .map(({ id, quantity }) => ({
            id: id as number,
            quantity: quantity as number,
          })),
      },
    })
  const filterQuantities = (): number[] =>
    (metaOptions?.quantities || []).map(({ quantity }) => quantity).filter(filterMaybe)

  const showRecipePhoto = ({ fullName, url }: { fullName: string; url?: string | null }): void => {
    const content = url ? <S.RecipePhoto src={url} alt={fullName} /> : undefined

    Modal.info({
      content,
      title: fullName,
      width: 800,
      maskClosable: true,
    })
  }

  const invalidQuantities = (): boolean => {
    return recipes.map(recipe => filterQuantities().includes(recipe.quantity || 0)).some(val => val === false)
  }

  const setQuantity = (value: number, action: Actions): number => {
    const availableQuantities = filterQuantities()
    const quantityIndex = availableQuantities.indexOf(value)
    const OPERATORS = {
      [Actions.DECREASE]: availableQuantities[quantityIndex - 1],
      [Actions.INCREASE]: availableQuantities[quantityIndex + 1],
    }

    return OPERATORS[action]
  }

  const handleQuantity = (id: number, action: Actions) => {
    const updatedRecipes = [
      ...recipes.map(recipe =>
        recipe.id === id
          ? {
              ...recipe,
              quantity: setQuantity(recipe.quantity || 0, action),
            }
          : recipe,
      ),
    ]

    setRecipes(updatedRecipes)
    setCanUpdateRecipes(true)
    void getVirtualPrice()
  }

  const handleSubmit = (): void => {
    const filteredRecipes = recipes
      .map(({ id, quantity }): OrderRecipeInputType | null => {
        if (!!id && !!quantity) {
          return { id, quantity }
        }

        return null
      })
      .filter((recipe): recipe is OrderRecipeInputType => recipe !== null)
    const mutation = updateOrderContents({
      variables: {
        number: orderNumber,
        recipes: filteredRecipes,
      },
    })

    void handleMutationResult(mutation, 'updateOrderContents', {
      notifications: {
        success: {
          title: RECIPES_UPDATED_SUCCESS,
        },
        error: {
          title: NOTIFICATION_TITLE_FAILURE,
        },
      },
    }).then(value => {
      if (onOrderUpdated) {
        onOrderUpdated('recipes', value?.data || undefined)
      }
    })
  }

  useEffect(() => {
    if (virtualPrice?.orderVirtualPrice?.error) {
      errorNotification(virtualPrice?.orderVirtualPrice?.error?.message as string)
    }

    if (virtualPriceError) {
      errorNotification(virtualPriceError.message)
    }

    if (editable) {
      void getVirtualPrice()
    }
  }, [virtualPrice, virtualPriceError])

  const updateButton = (): JSX.Element => {
    if (inProduction) {
      return (
        <Popconfirm
          title={IN_PRODUCTION_WARNING}
          placement="right"
          onConfirm={(): void => {
            handleSubmit()
            logger().warning('Recipes updated with order in production')
          }}
        >
          <Button
            data-testid="update-recipes-button"
            type="primary"
            disabled={!editable || loading || !canUpdateRecipes}
            loading={loading || virtualPriceLoading}
            size="large"
            block
          >
            Update recipes
          </Button>
        </Popconfirm>
      )
    }

    return (
      <Button
        data-testid="update-recipes-button"
        type="primary"
        disabled={!editable || loading || !canUpdateRecipes}
        loading={loading || virtualPriceLoading}
        size="large"
        onClick={handleSubmit}
        block
      >
        Update recipes
      </Button>
    )
  }

  return (
    <Card
      {...customProps.CARD}
      title="Recipes"
      extra={invalidQuantities() && <Tag color="error">Order has invalid recipe quantities</Tag>}
    >
      <Table<Recipe>
        data-qa="order-recipes-table"
        dataSource={recipes}
        rowKey="id"
        showHeader={false}
        pagination={false}
        size="small"
        expandable={{
          expandedRowRender: (recipe): JSX.Element => {
            const { shippedIngredients, nutritionalInformation } = recipe
            const hasNutritionalInfo = nutritionalInformation.length > 0
            const variants = variantsOf(recipe)

            return (
              <Tabs defaultActiveKey={hasNutritionalInfo ? 'nutritionalInformation' : 'shippedIngredients'}>
                <Tabs.TabPane tab="Nutritional Info" key="nutritionalInformation" disabled={!hasNutritionalInfo}>
                  <Table dataSource={nutritionalInformation} className="inner-table" pagination={false} rowKey="key">
                    <Column
                      title="Component"
                      render={(nutritionalInformation: NutritionalInformation): JSX.Element => (
                        <Text>{humanizeString(nutritionalInformation.key || '')}</Text>
                      )}
                    />
                    <Column title="Per 100g" dataIndex="per100g" />
                    <Column title="Per Portion" dataIndex="perPortion" />
                    <Column title="Total" dataIndex="total" />
                  </Table>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Ingredients Quantities" key="shippedIngredients" disabled={!shippedIngredients}>
                  <Table dataSource={shippedIngredients || []} pagination={false} showHeader={false} rowKey="id">
                    <Column title="Name" dataIndex="nameWithQuantity" className="capitalize" />
                  </Table>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Variants" key="recipeVariants" disabled={variants.length === 0}>
                  <Table dataSource={variants} pagination={false} className="inner-table" rowKey="id">
                    <Column title="ID" dataIndex="id" render={id => <Tag color="blue">{id}</Tag>} />
                    <Column title="Name" dataIndex="fullName" />
                    <Column
                      title="EXTRA charge"
                      render={(variant: RecipeVariant) => formatCurrency(varianSurcharge(variant, 1), currency)}
                    />
                    <Column
                      title="Surcharge (2P)"
                      render={(variant: RecipeVariant) => formatCurrency(varianSurcharge(variant, 2), currency)}
                    />
                    <Column
                      title="Surcharge (4P)"
                      render={(variant: RecipeVariant) => formatCurrency(varianSurcharge(variant, 4), currency)}
                    />
                  </Table>
                </Tabs.TabPane>
              </Tabs>
            )
          },
          rowExpandable: recipe =>
            recipe?.nutritionalInformation?.length > 0 || !!recipe?.shippedIngredients || variantsOf(recipe).length > 0,
        }}
      >
        <Column
          dataIndex="image"
          render={(image: ApiImage, { fullName }: Recipe): ReactElement => (
            <Button
              onClick={(): void => showRecipePhoto({ url: image.url, fullName })}
              title={`See photo of ${fullName}`}
              type="text"
            >
              <CameraOutlined />
            </Button>
          )}
          align="center"
        />

        <Column dataIndex="id" render={(id: string): ReactElement => <Tag color="blue">{id}</Tag>} align="center" />

        <Column render={(recipe: Recipe): ReactElement => <RecipeTypeMark recipe={recipe} />} align="right" />

        <Column
          render={(recipe: Recipe) =>
            extraFeesSum(recipe) !== 0 ? (
              <Text style={{ whiteSpace: 'nowrap' }} keyboard>
                {formatCurrency(extraFeesSum(recipe), currency)}
              </Text>
            ) : null
          }
          align="right"
        />

        <Column
          render={(recipe: Recipe): ReactElement => {
            const { recipeCardUrl, fullName } = recipe

            if (!recipeCardUrl) {
              return <span>fullName</span>
            }

            return (
              <a href={recipeCardUrl} target="_blank" rel="noopener noreferrer">
                {fullName}
              </a>
            )
          }}
        />

        <Column
          dataIndex="quantity"
          render={(quantity: number, { id }: Recipe): ReactElement => (
            <div>
              {id && (
                <Button.Group>
                  <Button
                    data-testid={`decrease-quantity-button[${id}]`}
                    onClick={() => handleQuantity(id, Actions.DECREASE)}
                    disabled={quantity === filterQuantities().shift() || !editable || virtualPriceLoading}
                  >
                    -
                  </Button>
                  <Button
                    data-testid={`recipe-quantity-value[${id}]`}
                    type={quantity > 0 ? 'primary' : undefined}
                    style={{ width: 50 }}
                  >
                    {quantity || 0}
                  </Button>
                  <Button
                    data-testid={`increase-quantity-button[${id}]`}
                    onClick={() => handleQuantity(id, Actions.INCREASE)}
                    disabled={quantity === filterQuantities().pop() || !editable || virtualPriceLoading}
                  >
                    +
                  </Button>
                </Button.Group>
              )}
            </div>
          )}
        />
      </Table>

      {editable && (
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col style={{ maxWidth: 400, width: '100%' }}>
            <PriceBreakDown
              currency={currency}
              pricing={virtualPrice?.orderVirtualPrice}
              loading={virtualPriceLoading}
            />
          </Col>
        </Row>
      )}

      <Row justify="center" style={{ marginTop: 20 }}>
        <Col span={6}>{updateButton()}</Col>
      </Row>
    </Card>
  )
}

const RecipeTypeMark: React.FC<{ recipe: Recipe }> = ({ recipe }) => {
  const variants = variantsOf(recipe)
  const attrs = useMemo<MarkAttrs>(() => {
    switch (recipe.recipeType) {
      case RecipeTypeEnum.CoreDown:
        return {
          color: 'green',
          icon: <TagOutlined />,
          tooltip: 'Core Down Recipe',
          fontSize: 18,
        }
      case RecipeTypeEnum.Premium:
        return {
          color: 'orange',
          // eslint-disable-next-line react/no-danger
          icon: <span dangerouslySetInnerHTML={{ __html: '&#10070;' }} />,
          tooltip: 'Premium Recipe',
        }
      default:
        return {
          empty: true,
        }
    }
  }, [recipe?.recipeType])

  const variantsIndicator = (): ReactNode | null => {
    if (isVariant(recipe)) {
      return (
        <Tooltip overlay="Variant Recipe">
          <SwapOutlined />
        </Tooltip>
      )
    }

    if (variants.length > 0) {
      return (
        <Tooltip overlay="This recipe has variants">
          <Badge dot color="blue">
            <SwapOutlined />
          </Badge>
        </Tooltip>
      )
    }

    return null
  }

  if (attrs.empty) {
    return <span>{variantsIndicator()}</span>
  }

  return (
    <Space>
      <Tooltip overlay={<span>{attrs.tooltip}</span>}>
        <div
          style={{
            color: attrs.color,
            fontSize: attrs.fontSize || 24,
            marginLeft: '-10px',
            cursor: 'default',
          }}
        >
          {attrs.icon}
        </div>
      </Tooltip>
      {variantsIndicator()}
    </Space>
  )
}

export default RecipesList
