import React, { useContext, useState } from 'react'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import { notification, Modal } from 'antd'
import moment from 'moment'
import { useUpdateCheck } from 'react-update-notification'
import { ClockCircleOutlined, ReloadOutlined } from '@ant-design/icons'

import { Routes } from '../../constants/RoutesConstants'
import { NOT_FOUND } from '../../constants/MessagesConstants'
import { PrivateRoute } from '../Routing'
import Login from '../Login'
import Search from '../Search'
import SearchOrder from '../SearchOrder'
import SearchCustomer from '../SearchCustomer'
import SearchComplaint from '../SearchComplaint'
import SearchCustomerEmailResults from '../SearchCustomerEmailResults'
import ManageCustomerContainer from '../../containers/ManageCustomerContainer'
import ManageOrderContainer from '../../containers/ManageOrderContainer'
import GeneralError from '../../packages/GeneralError'
import { getUserSession } from '../../utils'
import MainContainer from '../../containers/MainContainer'
import initializeLogger from '../../logging'
import ManageAdminUsersContainer from '../../containers/ManageAdminUsersContainer/ManageAdminUsersContainer'
import ManageMassCreditContainer from '../../containers/ManageMassCreditContainer'
import ManageLeadsContainer from '../../containers/ManageLeadsContainer'
import ManageMassComplaints from '../ManageMassComplaints'
import { BEEF_USER_SESSION, COOKBOOK_USER_SESSION } from '../../constants/AuthConstants'
import { ThemeContext } from '../../contexts/ThemeContextProvider'
import ManageShipmentTrackingContainer from '../../containers/ManageShipmentTrackingContainer'
import SearchCustomerAddressResults from '../SearchCustomerAddressResults'
import ManageMenuIngredientClassifications from '../ManageMenuIngredientClassifications'

const NewVersionPrompt = () => {
  // fetch /version.json?v=timestamp every 10s
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 10000,
    ignoreServerCache: true,
  })
  const [visible, setVisible] = useState(true)

  if (status === 'checking' || status === 'current') {
    return null
  }

  return (
    <Modal
      title="New Cookbook Version Available! 🚀"
      open={visible}
      centered
      maskClosable={false}
      closable={false}
      keyboard={false}
      onOk={reloadPage}
      okText="Reload the page"
      okButtonProps={{ icon: <ReloadOutlined /> }}
      onCancel={() => setVisible(false)}
      cancelText="I'll do it later"
      cancelButtonProps={{ icon: <ClockCircleOutlined /> }}
      destroyOnClose
    >
      <p>
        We&apos;re excited to bring you the latest and greatest version of <strong>Cookbook</strong>!
      </p>
      <p>To enjoy the new features and improvements, it&apos;s time to refresh the page.</p>
      <p>
        Dismiss the prompt for now, and you can refresh the app at your convenience whenever you&apos;re ready to
        explore the new version.
      </p>
    </Modal>
  )
}

const CatchAll: React.FC<RouteComponentProps & { isAuthenticated: boolean }> = ({ location, isAuthenticated }) => {
  const isHomepage = location.pathname === Routes.ROOT
  const shouldRedirect = !isAuthenticated || isHomepage

  if (shouldRedirect) {
    const redirectPath = isAuthenticated ? Routes.SEARCH : Routes.LOGIN

    return <Redirect to={redirectPath} />
  }

  return (
    <MainContainer>
      <GeneralError message={NOT_FOUND} status={404} />
    </MainContainer>
  )
}

const App: React.FC = () => {
  const cookbookSession = getUserSession(COOKBOOK_USER_SESSION)
  const beefSession = getUserSession(BEEF_USER_SESSION)
  const isAuthenticated = cookbookSession.hasValidSession() && beefSession.hasValidSession()
  const { theme } = useContext(ThemeContext)

  initializeLogger({ email: cookbookSession.session?.user.email })
  notification.config({ placement: 'bottomRight' })
  // Forcing the weeks to start on Monday instead of Sunday to match the cc-experts notion of culinary week.
  // Antd by default has en_US locale in which the weeks start of Sunday, this definition changes them to start on
  // Monday.
  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  })

  return (
    <div className={theme} data-testid="app-container">
      <NewVersionPrompt />

      <Switch>
        <Route path={Routes.LOGIN} component={Login} />

        <PrivateRoute path={Routes.SEARCH} exact component={Search} />
        <PrivateRoute path={Routes.SHIPMENT_TRACKING} exact component={ManageShipmentTrackingContainer} />

        <PrivateRoute path={Routes.ORDER_SEARCH} exact component={SearchOrder} />
        <PrivateRoute path={Routes.ORDER_DETAIL} component={ManageOrderContainer} />

        <PrivateRoute path={Routes.CUSTOMER_SEARCH} exact component={SearchCustomer} />
        <PrivateRoute path={Routes.CUSTOMER_EMAIL_SEARCH_RESULTS} component={SearchCustomerEmailResults} exact />
        <PrivateRoute path={Routes.CUSTOMER_ADDRESS_SEARCH_RESULTS} component={SearchCustomerAddressResults} exact />
        <PrivateRoute path={Routes.CUSTOMER_DETAIL} component={ManageCustomerContainer} />

        <PrivateRoute path={Routes.ADMIN_USERS} component={ManageAdminUsersContainer} roles={['super_admin']} />

        <PrivateRoute path={Routes.MASS_CREDIT} exact component={ManageMassCreditContainer} />

        <PrivateRoute path={Routes.LEADS} exact component={ManageLeadsContainer} />

        <PrivateRoute path={Routes.COMPLAINTS_SEARCH} exact component={SearchComplaint} />
        <PrivateRoute path={Routes.COMPLAINTS_MASS_UPLOAD} exact component={ManageMassComplaints} />

        <PrivateRoute
          path={Routes.MANAGE_INGREDIENT_CLASSIFICATIONS}
          exact
          component={ManageMenuIngredientClassifications}
        />

        <Route render={(props): React.ReactElement => <CatchAll {...props} isAuthenticated={isAuthenticated} />} />
      </Switch>
    </div>
  )
}

export default App
