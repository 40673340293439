import React from 'react'
import { Row, Col, Result } from 'antd'

import SearchCustomerForm from '../../../components/Forms/SearchCustomerEmailForm'

const colProps = {
  xl: { span: 6, offset: 9 },
  md: { span: 12, offset: 6 },
}
const ManageCustomerEmpty: React.FC<{ customerId: number }> = ({ customerId }) => (
  <Result
    status="404"
    title={customerId}
    subTitle="Looks like the customer you're looking for does not exist"
    extra={
      <Row>
        <Col {...colProps}>
          <SearchCustomerForm />
        </Col>
      </Row>
    }
  />
)

export default ManageCustomerEmpty
