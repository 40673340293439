import React from 'react'
import { PlayCircleOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import moment from 'moment'

import { handleMutationResult } from '../../../../apollo'
import { useResumeMembershipMutation } from '../../../../apollo/generated/api'

type ManageMembershipResumeProps = {
  customerId: number
}

const ManageMembershipResume: React.FC<ManageMembershipResumeProps> = ({ customerId }) => {
  const [form] = Form.useForm()
  const [resumeMembership, { loading }] = useResumeMembershipMutation()

  const handleResumeMembership = (): void => {
    const mutation = resumeMembership({
      variables: {
        resumeMembership: {
          id: customerId,
          date: moment().format('YYYY-MM-DD'),
        },
      },
    })

    void handleMutationResult(mutation, 'resumeMembership', {
      notifications: {
        success: {
          title: 'Membership successfully resumed',
        },
      },
    })
  }

  return (
    <Form data-testid="resume-membership-form" layout="vertical" onFinish={handleResumeMembership} form={form}>
      <Form.Item style={{ textAlign: 'left' }}>
        <Button
          data-testid="resume-membership-submit"
          type="primary"
          htmlType="submit"
          icon={<PlayCircleOutlined />}
          size="large"
          disabled={loading}
          loading={loading}
        >
          Resume membership
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ManageMembershipResume
