import React from 'react'
import { Card } from 'antd'

import { DirectDebit as ApiDirectDebit } from '../../../apollo/generated/api'

const DirectDebit: React.FC<{ directDebit: ApiDirectDebit }> = ({ directDebit }) => {
  const { name, iban, bic } = directDebit

  return (
    <Card title="Direct Debit">
      <p>Name: {name}</p>
      <p>IBAN: {iban}</p>
      <p>BIC: {bic}</p>
    </Card>
  )
}

export default DirectDebit
