import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Select, Form, Alert } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { formatDate } from '../../../../utils'
import { handleMutationResult } from '../../../../apollo'
import { useResumeMembershipMutation } from '../../../../apollo/generated/api'

const { Option } = Select

type ManageMembershipReactivateProps = {
  customerId: number
  startDeliveryDates?: ReadonlyArray<string>
}

const ManageMembershipReactivate: React.FC<ManageMembershipReactivateProps> = ({ customerId, startDeliveryDates }) => {
  const [form] = Form.useForm()
  const [reactivateMembership, { loading }] = useResumeMembershipMutation()

  const handleReactivateMembership = (values: Store): void => {
    const mutation = reactivateMembership({
      variables: {
        resumeMembership: {
          id: customerId,
          date: values?.membershipDate,
        },
      },
    })

    void handleMutationResult(mutation, 'resumeMembership', {
      notifications: {
        success: {
          title: 'Membership successfully reactivated',
        },
      },
    })
  }

  if (!startDeliveryDates) {
    return <Alert type="error" message="No dates available." />
  }

  return (
    <Form data-testid="reactivate-membership-form" layout="vertical" onFinish={handleReactivateMembership} form={form}>
      <Form.Item
        name="membershipDate"
        label="First Order CW"
        rules={[
          {
            required: true,
            message: 'You must select a date.',
          },
        ]}
        hasFeedback
      >
        <Select
          placeholder="Pick a date"
          size="large"
          style={{ width: '100%' }}
          data-testid="membership-dates-dropdown"
          optionFilterProp="children"
          showSearch
        >
          {startDeliveryDates?.map(date => (
            <Option key={date} value={date}>
              {formatDate({ date: new Date(date) })}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          data-testid="reactivate-membership-submit"
          type="primary"
          htmlType="submit"
          icon={<CheckCircleOutlined />}
          size="large"
          disabled={loading}
          loading={loading}
        >
          Reactivate membership
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ManageMembershipReactivate
