import { Alert } from 'antd'
import humanizeString from 'humanize-string'
import React from 'react'

import { ManageCustomerProps } from '../../ManageCustomer/props'

const ValidPlan: React.FC<{ userPlan: ManageCustomerProps['customer']['userPlan'] }> = ({ userPlan }) => {
  const { displayTitle, segment } = userPlan.plan

  const validWeekday = (): boolean => {
    const { weekday } = userPlan
    const deliveryOptions = userPlan.deliveryOptions?.map(opt => opt.weekday)

    return deliveryOptions?.includes(weekday) || false
  }

  const validTimeSlot = (): boolean => {
    const timeSlotId = userPlan?.timeSlot?.id
    const weekday = userPlan?.weekday
    const options = userPlan?.deliveryOptions || []
    const timeslots = options.find(opt => opt.weekday === weekday)?.slots || []

    return timeSlotId !== undefined && !!timeslots.find(slot => slot?.id === timeSlotId)
  }

  if (!validWeekday() || !validTimeSlot()) return <Alert type="error" showIcon message="User has invalid plan data" />

  return (
    <>
      {displayTitle} ({humanizeString(segment)})
    </>
  )
}

export default ValidPlan
