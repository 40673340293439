import React from 'react'

import { OrderWizardStepProps } from '../../types'
import OrderDeliveryCalendar from '../../../OrderDeliveryCalendar'

const OrderDeliveryStep: React.FC<OrderWizardStepProps> = ({
  state: { addresses, delivery, setDelivery, customerId, includeDatesPastCutoff },
  initial,
}) => {
  const selectedDate = delivery?.date ?? initial?.delivery?.date
  const selectedSlot = delivery?.timeslot?.id ?? initial?.delivery?.timeSlotId

  return (
    <div data-testid="order-delivery-step">
      <OrderDeliveryCalendar
        customerId={customerId}
        zipcode={addresses?.shipping?.zipcode}
        selectedDate={selectedDate}
        selectedSlotId={selectedSlot}
        includeDatesPastCutoff={includeDatesPastCutoff}
        onChange={setDelivery}
      />
    </div>
  )
}

export default OrderDeliveryStep
