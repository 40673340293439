import React from 'react'
import { Alert, Button, Form, Input, InputNumber, Modal, Space, Tooltip, Typography } from 'antd'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { CopyOutlined } from '@ant-design/icons'

import { handleMutationResult } from '../../../../../apollo'
import {
  GeneratePaymentLinkDocument,
  GeneratePaymentLinkMutation,
  GeneratePaymentLinkMutationVariables,
  GetOrderPaymentsDocument,
  GetOrderPaymentsQuery,
  GetOrderPaymentsQueryVariables,
} from '../../../../../apollo/generated/api'
import {
  GENERATE_PAYMENT_LINK_CONFIRM_TITLE,
  GENERATE_PAYMENT_LINK_FAILURE,
} from '../../../../../constants/MessagesConstants'
import { copyToClipboard } from '../../../../../utils/clipboard'

const required = { required: true }

const generateLink = async (number: string, amount: number, client: ApolloClient<NormalizedCacheObject>) => {
  const mutation = client.mutate<GeneratePaymentLinkMutation, GeneratePaymentLinkMutationVariables>({
    mutation: GeneratePaymentLinkDocument,
    variables: {
      amount,
      number,
    },
  })
  const { data } = await handleMutationResult(mutation, 'createPaymentLink', {
    notifications: {
      error: { title: GENERATE_PAYMENT_LINK_FAILURE },
      success: { disabled: true },
    },
  })

  // error
  if (data?.createPaymentLink?.__typename !== 'PaymentLink') {
    return false
  }

  const { url } = data.createPaymentLink

  Modal.info({
    title: 'Payment Link',
    icon: null,
    okText: 'Close',
    content: (
      <Form initialValues={{ url }}>
        <Input.Group compact>
          <Form.Item name="url" style={{ width: 'calc(100% - 32px)' }}>
            <Input readOnly />
          </Form.Item>
          <Tooltip title="Copy">
            <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(url)} />
          </Tooltip>
        </Input.Group>
      </Form>
    ),
  })

  return false
}

const generatePaymentLink = (
  { number }: { number: string },
  { client }: { client: ApolloClient<NormalizedCacheObject> },
) => {
  void client
    .query<GetOrderPaymentsQuery, GetOrderPaymentsQueryVariables>({
      query: GetOrderPaymentsDocument,
      variables: { number },
    })
    .then(({ data }) => {
      if (!data.order || !data.order.pricing || !data.order.payments) {
        return
      }

      const currency = data.order.pricing?.currency
      // last failed payment that is owed
      const owedPayment = [...data.order.payments]
        .sort((a, b) => a.id - b.id)
        .find(p => p.state === 'failed' && p.owedAt !== null)

      if (!owedPayment || !owedPayment.amountPaid) {
        return
      }

      let amount = owedPayment.amountPaid
      const modal = Modal.info({
        icon: null,
        maskClosable: true,
        okText: GENERATE_PAYMENT_LINK_CONFIRM_TITLE,
        okCancel: false,
        onOk: () => {
          modal.update({ okButtonProps: { loading: true } })

          return generateLink(number, amount, client)
        },
        content: (
          <Space direction="vertical">
            <Form initialValues={{ number, amount }}>
              <Form.Item label="Order Number" name="number">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Amount to pay" name="amount" rules={[required]}>
                <InputNumber
                  addonAfter={currency}
                  step={0.01}
                  precision={2}
                  onChange={value => {
                    amount = parseFloat(value as string)
                  }}
                />
              </Form.Item>
            </Form>
            <Alert
              showIcon
              message={
                <Typography.Text>
                  Payment link is valid for <Typography.Text strong>one attempt</Typography.Text> only.
                </Typography.Text>
              }
            />
          </Space>
        ),
      })
    })
}

export default generatePaymentLink
