import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { handleMutationResult } from '../../../../../apollo'
import {
  PAY_ORDER_CONFIRM_TITLE,
  PAY_ORDER_CONFIRM_CONTENT,
  PAY_ORDER_SUCCESS,
  PAY_ORDER_FAILURE,
} from '../../../../../constants/MessagesConstants'
import {
  GetOrderManagementDetailsDocument,
  PayOrderDocument,
  PayOrderMutation,
  PayOrderMutationVariables,
} from '../../../../../apollo/generated/api'
import { queryNames } from '../../../../../apollo/client'

type MutationClient = Pick<ApolloClient<NormalizedCacheObject>, 'mutate'>

const onPayConfirmation = (number: string, client: MutationClient): void => {
  void handleMutationResult(
    client.mutate<PayOrderMutation, PayOrderMutationVariables>({
      mutation: PayOrderDocument,
      variables: { number },
      refetchQueries: queryNames(GetOrderManagementDetailsDocument),
    }),
    'orderPay',
    {
      notifications: {
        success: {
          title: PAY_ORDER_CONFIRM_TITLE,
          description: PAY_ORDER_SUCCESS,
        },
        error: {
          title: PAY_ORDER_FAILURE,
        },
      },
    },
  )
}

const payOrder = ({ number }: { number: string }, { client }: { client: MutationClient }): void => {
  const modal = Modal.confirm({
    title: PAY_ORDER_CONFIRM_TITLE,
    content: PAY_ORDER_CONFIRM_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    okText: 'Yes, bill the order',
    onOk: () => {
      modal.update({ okButtonProps: { loading: true } })

      return onPayConfirmation(number, client)
    },
  })
}

export default payOrder
