// eslint-disable-next-line max-classes-per-file
import { print as printGqlDocument } from 'graphql'

import config from '../config'
import {
  GetMenuIngredientsParams,
  GetSteakMenuIngredientsDocument,
  GetSteakMenuIngredientsQuery,
  MenuIngredientClassificationInput,
  MutateMenuIngredientClassificationsDocument,
  MutateMenuIngredientClassificationsMutation,
} from '../apollo/generated/steak'
import { BEEF_USER_SESSION } from '../constants/AuthConstants'

import { AbortableRequest, makeGraphqlRequest } from './GraphqlService'

const GQL_ENPOINT = config.beefGraphQLEndpoint?.replace('/graphql', '/steak/graphql')

export type SuggestedComplaintCompensationInput = {
  orderNumber: string | undefined
  complaintReason: string | undefined
  addOnIds?: string[]
  ingredientIds?: string[]
}

export type SuggestedComplaintCompensation = {
  compensation?: {
    compensationAmount: number
    warnings: [SuggestedComplaintWarning]
  }
  error?: {
    code: string
    message: string
  }
}

export type SuggestedComplaintWarning = {
  reason: string
  extra: SuggestedComplaintExtra[]
}

export type SuggestedComplaintExtra = {
  key: string
  stringValue: string
}

export function fetchSuggestedCompensation(
  data: SuggestedComplaintCompensationInput,
): AbortableRequest<SuggestedComplaintCompensation> {
  const query = `
query suggestedComplaintCompensation($data:SuggestedComplaintCompensationInput!){
  suggestedComplaintCompensation(data: $data) {
    compensation {
      compensationAmount
      warnings{
        reason
        extra{
          key
          stringValue
        }
      }
    }
    error {
      code
      message
    }
  }
}
  `

  return makeGraphqlRequest<{
    data: { suggestedComplaintCompensation: SuggestedComplaintCompensation }
  }>(GQL_ENPOINT, query, { data }, BEEF_USER_SESSION).then(value => value?.data?.suggestedComplaintCompensation)
}

export interface PageData<T> {
  data: T[]
  nextPageCursor?: string | null
}

export type GetMenuIngredientsQueryIngredientType = NonNullable<
  NonNullable<GetSteakMenuIngredientsQuery['menuIngredients']['nodes']>[0]
>

export function fetchMenuIngredients(
  params: GetMenuIngredientsParams,
  after?: string,
): AbortableRequest<PageData<GetMenuIngredientsQueryIngredientType>> {
  return makeGraphqlRequest<{
    data: GetSteakMenuIngredientsQuery
  }>(GQL_ENPOINT, printGqlDocument(GetSteakMenuIngredientsDocument), { params, after }, BEEF_USER_SESSION).then(
    value => {
      const data = value?.data
      const ingredients = (data?.menuIngredients?.nodes || [] || []) as GetMenuIngredientsQueryIngredientType[]

      return {
        data: ingredients,
        nextPageCursor: data?.menuIngredients?.pageInfo?.endCursor,
      }
    },
  )
}

export class SteakApiExecutionError extends Error {}

export function saveMenuIngredientClassifications(
  data: MenuIngredientClassificationInput[],
): AbortableRequest<MutateMenuIngredientClassificationsMutation['mutateMenuIngredientClassifications']> {
  return makeGraphqlRequest<{
    errors: [{ message: string }]
    data: MutateMenuIngredientClassificationsMutation
  }>(GQL_ENPOINT, printGqlDocument(MutateMenuIngredientClassificationsDocument), { data }, BEEF_USER_SESSION).then(
    value => {
      if (value.errors?.length) {
        throw new SteakApiExecutionError(value.errors[0].message)
      }

      return value.data.mutateMenuIngredientClassifications
    },
  )
}
