import React from 'react'
import { Form, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'

import { required } from '../formConfig'
import { ComplaintFSQACategory } from '../../../../apollo/generated/beef'
import { filterOptionByText } from '../../../../utils/inputUtils'

const { Option } = Select

type Props = {
  options?: ComplaintFSQACategory[] | null
  loading: boolean
  disabled?: boolean
  setShowAdditionalFoodSafetyDetails: Function
}

const FSQACategory: React.FC<Props> = ({ options, loading, disabled, setShowAdditionalFoodSafetyDetails }) => {
  const onSelect = (optionData: DefaultOptionType) => {
    if (optionData.key === 'Temperature/Warm Box') {
      setShowAdditionalFoodSafetyDetails(true)
    }
  }

  const onDeselect = (optionData: DefaultOptionType) => {
    if (optionData.key === 'Temperature/Warm Box') {
      setShowAdditionalFoodSafetyDetails(false)
    }
  }

  return (
    <Form.Item name="fsqaCategories" label="Pick a FSQA category" rules={[required]}>
      <Select
        mode="multiple"
        data-testid="fsqa-categories-selector"
        loading={loading}
        disabled={loading || disabled}
        placeholder="Pick one or more categories"
        onSelect={(_value, optionData) => onSelect(optionData)}
        onDeselect={(_value, optionData) => onDeselect(optionData)}
        filterOption={filterOptionByText}
      >
        {options?.map(({ id, name }) => (
          <Option key={name} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FSQACategory
