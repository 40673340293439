import Category from './Category'
import Subcategory from './Subcategory'
import Scopes from './Scopes'
import Rules from './Rules'
import AffectedItem from './AffectedItem'
import Compensation from './Compensation'
import ComplaintDescription from './ComplaintDescription'
import FoodSafetyIssue from './FoodSafetyIssue'
import Ingredients from './Ingredients'
import AddOns from './AddOns'
import LogisticsDropdown from './LogisticsDropdown'
import QualityIssue from './QualityIssue'
import Recipes from './Recipes'
import ShippingPartner from './ShippingPartner'
import ReceivedAt from './ReceivedAt'
import RecurringIssue from './RecurringIssue'

const ManageComplaintForm = {
  Category,
  Subcategory,
  Scopes,
  Rules,
  AffectedItem,
  Compensation,
  ComplaintDescription,
  FoodSafetyIssue,
  Ingredients,
  AddOns,
  LogisticsDropdown,
  QualityIssue,
  Recipes,
  ShippingPartner,
  ReceivedAt,
  RecurringIssue,
}

export default ManageComplaintForm
export { required } from './formConfig'
