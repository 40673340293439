import React from 'react'
import { Card, Row, Col, Checkbox, Form, FormInstance } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { AffectedRecipe } from '../../../../apollo/generated/beef'
import { useGetOrderRecipesQuery } from '../../../../apollo/generated/api'

const Recipes: React.FC<{ orderNumber: string; form: FormInstance }> = ({ orderNumber, form }) => {
  const { loading, data: orderData } = useGetOrderRecipesQuery({ variables: { number: orderNumber } })
  const recipes = orderData?.order?.menu?.recipes?.filter(recipe => recipe?.mealQuantity?.numberOfPortions > 0)

  const onChange = (name: string, e: CheckboxChangeEvent) => {
    const currentRecipes: Pick<AffectedRecipe, 'id' | 'name'>[] = form.getFieldValue('affectedRecipes')
    const recipeId: number = e.target?.value

    if (e.target?.checked) {
      currentRecipes.push({ id: recipeId.toString(), name })
      form.setFieldsValue({ affectedRecipes: currentRecipes })
    } else {
      const recipes = currentRecipes.filter(recipe => recipe?.id !== recipeId.toString())

      form.setFieldsValue({ affectedRecipes: recipes })
    }
  }

  return (
    <Card
      title="Pick what recipes were affected"
      headStyle={{ textAlign: 'center' }}
      style={{ marginBottom: 35, boxShadow: '0 15px 15px rgba(0, 0, 0, .05)' }}
      loading={loading}
    >
      <Form.Item name="recipes" rules={[{ required: true, message: 'Pick at least one recipe.' }]}>
        <Checkbox.Group style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            {recipes?.map(({ id, fullName, image }) => (
              <Col span={8} key={Number(id)}>
                <Checkbox
                  data-testid={`recipe-${fullName}`}
                  value={id}
                  className="complaint-recipe-checkbox"
                  onChange={e => onChange(fullName, e)}
                >
                  <img style={{ width: '100%' }} src={image?.url || ''} alt={fullName} />
                  {fullName}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </Card>
  )
}

export default Recipes
