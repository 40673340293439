import { Select, Spin } from 'antd'
import React, { useState, useRef, useMemo } from 'react'

import { CountryEnum } from '../../apollo/generated/api'
import { LoqateSuggestions } from '../../services/LoqateService'
import { debounce } from '../../utils/typeUtils/typeUtils'

const { Option } = Select

/**
 * AddressAutocomplete component used in `CustomerAddressForm`
 * When you type something into the input and the debounce timeout hits we fire a request to Loqate.
 * Loqate will then retrieve the matched suggestions based on your search query.
 * If your search query is to broad (e.g 5th avenue), the return type needs to be required again to fetch
 * the available house numbers in that area.
 */
const AddressAutocomplete: React.FC<{
  fetchOptions: (country: CountryEnum, search: string, container?: string) => Promise<LoqateSuggestions['Items']>
  onOptionSelected: (id: string) => void
  debounceTimeout: number
  country: CountryEnum
}> = ({ country, fetchOptions, onOptionSelected, debounceTimeout }) => {
  const [options, setOptions] = useState<LoqateSuggestions['Items']>([])
  const [loading, setLoading] = useState(false)
  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current

      setOptions([])
      setLoading(true)

      void fetchOptions(country, value).then(newOptions => {
        if (fetchId !== fetchRef.current || value.length === 0) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setLoading(false)
      })
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const handleOptionSelected = (id: string) => {
    const option = options.find(opt => id === opt.Id)

    if (option?.Type === 'Address') {
      void onOptionSelected(id)
    } else {
      void fetchOptions(country, option?.Text || '', option?.Id).then(data => {
        setOptions(data)
      })
    }
  }

  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onSelect={handleOptionSelected}
      placeholder="Start typing the address, results with (...) require a further drilldown"
      size="large"
      style={{ width: '100%' }}
    >
      {options?.map(({ Id, Type, Text }) => (
        <Option value={Id} key={Id}>
          {Text} {Type !== 'Address' ? '(...)' : ''}
        </Option>
      ))}
    </Select>
  )
}

export default AddressAutocomplete
