import React from 'react'
import { Button, Result } from 'antd'

import logger from '../../logging'

const CURRENT_PATH = window.location.pathname

type LoadComponentErrorProps = {
  errorMessage?: string
  canRefresh?: boolean
}

const LoadComponentError: React.FC<LoadComponentErrorProps> = ({ errorMessage, canRefresh = false }) => {
  logger().error(errorMessage)

  return (
    <Result
      status="warning"
      title="Failed to load component"
      subTitle={errorMessage}
      extra={
        canRefresh && (
          <Button type="primary" onClick={(): void => window.location.assign(CURRENT_PATH)}>
            Refresh page
          </Button>
        )
      }
    />
  )
}

export default LoadComponentError
