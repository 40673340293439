import Stage from './constants/StagesConstants'

declare global {
  interface Window {
    env: AppConfig
  }
}

type AppConfig = {
  adminApiEndpoint: string
  publicGraphqlApiEndpoint: string
  graphqlApiEndpoint: string
  restApiBaseUrl: string
  breadcrumbsBaseUrl: string
  chilliBaseUrl: string
  onionApiBaseUrl: string
  stage: Stage
  googleClientId: string
  rollbarAccessToken: string
  appVersion: string
  beefRestEndpoint: string
  beefGraphQLEndpoint: string
  onionApiToken: string
  locateApiKey: string
  logisticsApiGraphqlUrl: string
  logisticsApiToken: string
}

export default window.env
