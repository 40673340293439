export const CARD = {
  headStyle: { fontWeight: 600 },
  style: {
    width: '100%',
  },
}

export const ROW = {
  gutter: 16,
  style: {
    marginBottom: 30,
  },
}
