import { Descriptions } from 'antd'
import React from 'react'

import { LogisticsComplaint as ApiLogisticsComplaint } from '../../../../apollo/generated/beef'

const labelStyle = { fontWeight: 500 }

const LogisticsComplaint: React.FC<{ complaint: Partial<ApiLogisticsComplaint> }> = ({ complaint }) => {
  return (
    <Descriptions layout="vertical" bordered>
      <Descriptions.Item label="Logistics Code" labelStyle={labelStyle}>
        {complaint.logisticsCode}
      </Descriptions.Item>

      <Descriptions.Item label="Shipping Partner" labelStyle={labelStyle}>
        {complaint.shippingPartner}
      </Descriptions.Item>

      <Descriptions.Item label="Tracking Number" labelStyle={labelStyle}>
        {complaint.trackingNumber}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default LogisticsComplaint
