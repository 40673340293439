import { Modal, ModalFuncProps } from 'antd'
import React from 'react'

import CancelOrderForm from '../../../../../components/Forms/CancelOrderForm'

const cancelOrder = ({ number, customerId }: { number: string; customerId: number }): void => {
  const modalConfig: ModalFuncProps = {
    title: 'Cancel Order',
    content: <CancelOrderForm number={number} customerId={customerId} />,
    okButtonProps: { style: { display: 'none' } },
  }

  Modal.warning(modalConfig)
}

export default cancelOrder
