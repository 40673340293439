import React from 'react'

import LoadComponentError from '../../../packages/LoadComponentError'
import { Complaint, useGetCustomerQuery } from '../../../apollo/generated/beef'
import { ManageCustomerProps } from '../props'
import { useRelayPagination } from '../../../hooks'
import SimplePagination from '../../SimplePagination'
import ManageComplaintTable from '../../ManageComplaint/ManageComplaintTable'
import StatsOverview from '../../Shared/StatsOverview/StatsOverview'

export const PAGE_SIZE = 10

const ManageCustomerComplaints: React.FC<ManageCustomerProps> = ({ customer }) => {
  const { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage } = useRelayPagination()
  const {
    id,
    deliveryStats: { numberOfOrders, currency },
  } = customer
  const { error, data } = useGetCustomerQuery({
    variables: { id: id.toString(), first: PAGE_SIZE, after: currentCursor },
    fetchPolicy: 'no-cache',
  })
  const customerData = data?.customer
  const { edges: complaints, pageInfo } = data?.customer?.complaints || { edges: [] }
  const baseComplaints = complaints ? complaints.map(c => c?.node as Complaint) : []
  const culinary = customerData?.numOfCulinaryComplaints || 0
  const logistics = customerData?.numOfLogisticsComplaints || 0
  const fulfillmentCenter = customerData?.numOfFulfillmentCenterComplaints || 0
  const procurement = customerData?.numOfProcurementComplaints || 0
  const otherComplaints = customerData?.numOfOtherComplaints || 0
  const packageAndInsulation = customerData?.numOfPackagingAndInsulationComplaints || 0
  const damagedIngredient = customerData?.numOfDamagedIngredientComplaints || 0
  const numberOfComplaints =
    culinary + logistics + fulfillmentCenter + procurement + otherComplaints + packageAndInsulation
  const complaintsRate = numberOfComplaints / numberOfOrders
  const loading = !customerData

  const onNextPage = () => {
    if (pageInfo?.hasNextPage) {
      handleNextPage(pageInfo.endCursor)
    }
  }

  if (error) return <LoadComponentError />

  return (
    <>
      <StatsOverview
        title="Complaints Overview"
        description="Ratio of complaints to total orders"
        ratio={complaintsRate}
        loading={loading}
        items={[
          { title: 'Culinary', value: culinary },
          { title: 'Fulfillment Centers', value: fulfillmentCenter },
          { title: 'Procurement', value: procurement },
          { title: 'Logistics', value: logistics },
          { title: 'Packaging & Insulation', value: packageAndInsulation },
          { title: 'Damaged Ingredient', value: damagedIngredient },
          { title: 'Other Departments', value: otherComplaints },
        ]}
      />

      <ManageComplaintTable
        complaints={baseComplaints.map(complaint => ({
          ...complaint,
          user: {
            externalId: id,
          },
        }))}
        currency={currency}
        customer={customer}
      />
      <SimplePagination
        currentCount={data?.customer?.complaints.edges?.length}
        totalCount={data?.customer?.complaints.edges?.length}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </>
  )
}

export default ManageCustomerComplaints
