import { Form, Select } from 'antd'
import React from 'react'

import { Option as OptionType } from '../../../../apollo/generated/beef'
import { required } from '../formConfig'

const { Option } = Select

const AffectedItem: React.FC<{ options: OptionType[] }> = ({ options }) => {
  return (
    <Form.Item name="affectedItem" label="Pick an affected item" rules={[required]}>
      <Select
        data-testid="affected-item-select"
        placeholder="Pick an affected item please"
        optionFilterProp="children"
        key="id"
        showSearch
      >
        {options?.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default AffectedItem
