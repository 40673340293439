import React from 'react'
import { Form, Select } from 'antd'

import { required } from '../formConfig'
import { ComplaintSubcategory } from '../../../../apollo/generated/beef'

const { Option } = Select

type Props = {
  options?: ComplaintSubcategory[] | null
  disabled: boolean
}

const Subcategory: React.FC<Props> = ({ options, disabled }) => {
  return (
    <Form.Item name="subCategoryId" label="Pick a sub category" rules={[required]}>
      <Select
        data-testid="subcategories-dropdown"
        disabled={disabled}
        placeholder="Pick any sub category"
        optionFilterProp="children"
        showSearch
      >
        {options?.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default Subcategory
