import React from 'react'
import { Row, Col, Alert, Card } from 'antd'

import { OrderWizardStepProps } from '../../types'
import { showExtraShippingPrice } from '../../../OrderDeliveryCalendar/helper'

const GutteredRow: React.FC = ({ children }) => <Row gutter={[24, 16]}>{children}</Row>
const OrderConfirmationStep: React.FC<OrderWizardStepProps> = ({ state: { addresses, delivery } }) => (
  <div>
    <GutteredRow>
      <Col span={24}>
        <Alert
          type="warning"
          message="Please make sure that the information below is correct before submitting the order:"
          style={{ marginBottom: 20 }}
        />
      </Col>
    </GutteredRow>

    <GutteredRow>
      <Col span={8}>
        <Card title="Shipping Address">
          <p>{addresses?.shipping?.name}</p>
          <p>{addresses?.shipping?.address}</p>
          <p>
            {addresses?.shipping?.zipcode} {addresses?.shipping?.city}
          </p>
        </Card>
      </Col>

      <Col span={8}>
        <Card title="Billing Address">
          <p>{addresses?.billing?.name}</p>
          <p>{addresses?.billing?.address}</p>
          <p>
            {addresses?.billing?.zipcode} {addresses?.billing?.city}
          </p>
        </Card>
      </Col>

      <Col span={8}>
        <Card title="Delivery">
          <p>{delivery.date?.format('LL')}</p>
          <p>
            {delivery.timeslot?.from} - {delivery.timeslot?.to}
            <span>
              {showExtraShippingPrice(delivery.timeslot?.additionalShippingPrice, delivery.timeslot?.priceCurrency)}
            </span>
          </p>
          <p>{delivery.timeslot?.shipper}</p>
        </Card>
      </Col>
    </GutteredRow>
  </div>
)

export default OrderConfirmationStep
