import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

import { getRandomArray } from '../../utils'

import * as S from './styles'
import loadingMessages from './loadingMessages'

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />
const Loading: React.FC<{ message?: string }> = ({ message = getRandomArray(loadingMessages) }) => (
  <S.Container>
    <Spin data-testid="loading-icon" indicator={loadingIcon} />

    <S.Message>{message}</S.Message>
  </S.Container>
)

export default Loading
