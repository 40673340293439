import React from 'react'
import { Alert, Table, Typography, Progress, Card, Row, Col, Statistic, Tabs, Descriptions, Tag, Tooltip } from 'antd'
import { CloseCircleTwoTone } from '@ant-design/icons'
import moment from 'moment'

import { ManageCustomerProps } from '../props'
import {
  BrandEnum,
  EligibleChallenge,
  EligibleChallengeProgress,
  Incentive,
  IncentiveChallenge,
  IncentivesAddOnRewardFragment,
  IncentivesOrderDiscountRewardFragment,
  OrderStatusEnum,
  useGetCustomerRewardsQuery,
  useGetIncentivesRewardsQuery,
} from '../../../apollo/generated/api'
import LoadComponentError from '../../../packages/LoadComponentError'
import Loading from '../../../packages/Loading'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

type RewardStats = {
  __typename?: 'RewardStats'
  numberOfDeliveredRecipes?: number | null
  numberOfDeliveredPremiumRecipes?: number | null
  numberOfDeliveredMarketItems?: number | null
}

const { Column } = Table
const { Text, Title } = Typography
const RewardsStats: React.FC<{ rewardStats: RewardStats }> = ({ rewardStats }) => (
  <Card title="Rewards stats" style={{ width: '100%' }}>
    <Row>
      <Col span={8}>
        <Statistic title="Number of delivered recipes" value={rewardStats?.numberOfDeliveredRecipes || 0} />
      </Col>
      <Col span={8}>
        <Statistic
          title="Number of delivered premium recipes"
          value={rewardStats?.numberOfDeliveredPremiumRecipes || 0}
        />
      </Col>
      <Col span={8}>
        <Statistic title="Number of delivered market items" value={rewardStats?.numberOfDeliveredMarketItems || 0} />
      </Col>
    </Row>
  </Card>
)
const ChallengesTable: React.FC<{ challenges: EligibleChallenge[] }> = ({ challenges }) => (
  <Table
    data-testid="customer-challenges-table"
    dataSource={challenges.slice(0, 4)}
    rowKey="id"
    bordered
    pagination={false}
  >
    <Column title="Name" dataIndex="name" render={(name: string): JSX.Element => <Text>{name}</Text>} />
    <Column
      title="Description"
      dataIndex="description"
      render={(description: string): JSX.Element => <Text>{description}</Text>}
    />
    <Column
      title="Progress"
      dataIndex="progress"
      render={(progress: EligibleChallengeProgress): JSX.Element => (
        <Progress type="line" percent={progress.percentage} size="small" />
      )}
    />
  </Table>
)

type Reward = IncentivesAddOnRewardFragment | IncentivesOrderDiscountRewardFragment
const Reward: React.FC<{ reward: Reward }> = ({ reward }) => {
  if (reward?.__typename === 'AddOnReward') {
    return (
      <Col span={8}>
        <Card title={reward.name} size="small">
          <Descriptions column={1}>
            <Descriptions.Item label="SKU">{reward.sku}</Descriptions.Item>
            <Descriptions.Item label="Discount">{reward.discount}</Descriptions.Item>
            <Descriptions.Item label="Discounted price">{reward.rewardDiscountedPrice}</Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    )
  }

  if (reward?.__typename === 'OrderDiscountReward') {
    return (
      <Col span={8}>
        <Card title="Order discount reward" size="small">
          <Descriptions column={1}>
            <Descriptions.Item label="Value">{reward.value}</Descriptions.Item>
            <Descriptions.Item label="Value type">{reward.valueType}</Descriptions.Item>
            <Descriptions.Item label="Value limit">{reward.valueLimit}</Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    )
  }

  return null
}

const Rewards: React.FC<{ incentiveId: string; orderNumber: string }> = ({ incentiveId, orderNumber }) => {
  const { data } = useGetIncentivesRewardsQuery({
    variables: { incentiveId, orderNumber },
    fetchPolicy: 'no-cache',
  })

  if (!data?.incentive?.items?.length) {
    return null
  }

  return (
    <Row gutter={16}>
      {data.incentive.items.map((reward, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Reward key={index} reward={reward as Reward} />
      ))}
    </Row>
  )
}

type Order = {
  __typename?: 'Order'
  number: string
  deliveryDate?: string | null
  orderStatus?: {
    __typename?: 'OrderStatus'
    key: OrderStatusEnum
  } | null
}

const IncentiveCard: React.FC<{ incentive: Incentive; orders?: Order[]; customerTimezone?: string | null }> = ({
  incentive,
  orders,
  customerTimezone,
}) => {
  return (
    <Card size="small" title={incentive.challenge.name} style={{ marginBottom: '20px' }}>
      <Descriptions column={1}>
        <Descriptions.Item label="Challenge description">{incentive.challenge.description}</Descriptions.Item>
        <Descriptions.Item label="Claimed at">
          {incentive.claimedAt ? (
            <UserAwareDateWithTz datetime={incentive.claimedAt} customerTimezone={customerTimezone} />
          ) : (
            <Tag color="red">Not claimed</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Expires at">
          <UserAwareDateWithTz datetime={incentive.expiresAt} customerTimezone={customerTimezone} />
        </Descriptions.Item>
      </Descriptions>
      <Tabs
        items={orders?.slice(0, 4).map(order => {
          return {
            label: (
              <>
                {order.orderStatus?.key === OrderStatusEnum.Skipped && (
                  <Tooltip placement="bottom" title="Order skipped">
                    <CloseCircleTwoTone twoToneColor="red" />
                  </Tooltip>
                )}
                {moment(order.deliveryDate).format('DD.MM')}
              </>
            ),
            key: order.number,
            children: <Rewards incentiveId={incentive.id} orderNumber={order.number} />,
          }
        })}
      />
    </Card>
  )
}
const ExpiredIncentivesTable: React.FC<{ incentives: Incentive[]; customerTimezone?: string | null }> = ({
  incentives,
  customerTimezone,
}) => (
  <Table
    data-testid="customer-expired-incentives-table"
    dataSource={incentives}
    rowKey="id"
    bordered
    pagination={false}
  >
    <Column
      title="Challenge name"
      dataIndex="challenge"
      render={(challenge: IncentiveChallenge): JSX.Element => <Text>{challenge.name}</Text>}
    />
    <Column
      title="Challenge description"
      dataIndex="challenge"
      render={(challenge: IncentiveChallenge): JSX.Element => <Text>{challenge.description}</Text>}
    />
    <Column
      title="Expired at"
      dataIndex="expiresAt"
      render={(expiresAt: string): JSX.Element => (
        <UserAwareDateWithTz datetime={expiresAt} customerTimezone={customerTimezone} />
      )}
    />
  </Table>
)

const ManageCustomerRewards: React.FC<ManageCustomerProps> = ({
  customer: { brand: customerBrand, id: customerId, timezone },
}) => {
  const { loading, data, error } = useGetCustomerRewardsQuery({
    variables: { customerId },
    fetchPolicy: 'no-cache',
  })
  const { challenges = [], incentives, expiredIncentives, orders, rewardStats } = data?.customer || {}

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <LoadComponentError errorMessage={error.message} />
  }

  return (
    <>
      {customerBrand === BrandEnum.Bm && (
        <Alert type="info" message="Rewards not currently available for this customer." />
      )}
      <RewardsStats rewardStats={rewardStats as RewardStats} />

      <Title level={4} style={{ marginTop: '30px' }}>
        Challenges
      </Title>
      <ChallengesTable challenges={challenges} />

      <Title level={4} style={{ marginTop: '30px' }}>
        Incentives
      </Title>
      {incentives?.length ? (
        incentives.map(incentive => (
          <IncentiveCard key={incentive.id} incentive={incentive} orders={orders || []} customerTimezone={timezone} />
        ))
      ) : (
        <Text type="secondary">No data</Text>
      )}

      {!!expiredIncentives?.length && (
        <>
          <Title level={4} style={{ marginTop: '30px' }}>
            Expired incentives
          </Title>
          <ExpiredIncentivesTable incentives={expiredIncentives} customerTimezone={timezone} />
        </>
      )}
    </>
  )
}

export default ManageCustomerRewards
