import React from 'react'
import { Form, DatePicker } from 'antd'

import { required } from '../formConfig'

const ReceivedAt: React.FC = () => {
  return (
    <Form.Item name="receivedAt" label="When was this complaint received?" rules={[required]}>
      <DatePicker data-testid="received-at-datepicker" style={{ width: '100%' }} />
    </Form.Item>
  )
}

export default ReceivedAt
