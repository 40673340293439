import { EditOutlined, HomeOutlined, PhoneOutlined } from '@ant-design/icons'
import { Badge, Card, Popconfirm, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, generatePath, useHistory } from 'react-router-dom'

import { Address, BrandEnum, CountryEnum, AddressValidationEnum } from '../../apollo/generated/api'
import { useGetDeliveryInstructionsLazyQuery } from '../../apollo/generated/public_api'
import { IN_PRODUCTION_WARNING } from '../../constants/MessagesConstants'
import { Routes } from '../../constants/RoutesConstants'
import { buildCopyableAddress, compareAddresses } from '../../utils/addressUtils/addressUtils'

const { Text } = Typography

const addressTypeText = (addressValidationType: AddressValidationEnum) => {
  switch (addressValidationType) {
    case AddressValidationEnum.LoqateValidated:
      return '* Address picked from address suggestions'
    case AddressValidationEnum.ManuallyValidated:
      return '* Address entered by user, validated by system on signup'
    case AddressValidationEnum.ManuallyNotValidated:
      return '* Address not found during sign up, reviewed and confirmed by user'
    default:
      return ''
  }
}

const RenderAddress: React.FC<{
  title: string
  address: Address
  defaultAddress?: Address
  orderNumber: string
  inProduction: boolean
  isEditable: boolean
}> = ({ title, address, defaultAddress, orderNumber, inProduction, isEditable }) => {
  const isDefaultAddress = compareAddresses(address, defaultAddress)
  const text = isDefaultAddress ? 'Default' : 'Non-Default'
  const color = isDefaultAddress ? 'blue' : 'orange'
  const formattedAddress = `${address.line1 || ''} ${address.line2 || ''} ${address.houseNumber || ''}`.trim()
  const iconProps = { style: { color: '#777' } }
  const addressPath = generatePath(Routes.ORDER_ADDRESS_DETAIL, { number: orderNumber, id: address.id })
  const editActionProps = { to: addressPath, style: { marginLeft: 10 } }
  const { push } = useHistory()
  const [getDeliveryInstructions, deliveryInstructionsQueryResult] = useGetDeliveryInstructionsLazyQuery()
  const match = orderNumber.match(/(DN-)?([A-Z]{2})-(.+)/)
  const brand = match?.[1]?.startsWith('DN') ? BrandEnum.Dn : BrandEnum.Ms
  const country = match?.[2].toUpperCase() as CountryEnum
  const [instructionsText, setInstructionsText] = useState<string>('')

  useEffect(() => {
    void getDeliveryInstructions({ variables: { country, brand, zipCode: address.zipcode } })
  }, [])

  useEffect(() => {
    const selectedInstruction = deliveryInstructionsQueryResult.data?.deliveryInstructions.find(
      i => i.instructionKey === address.instructionKey,
    )

    if (selectedInstruction && !selectedInstruction.allowFreeText) {
      setInstructionsText(selectedInstruction.localizedText)
    } else {
      setInstructionsText(address.instructions || '')
    }
  }, [deliveryInstructionsQueryResult.data?.deliveryInstructions])

  const instructionsLoading = deliveryInstructionsQueryResult.loading

  return (
    <Badge.Ribbon text={text} color={color}>
      <Card
        title={
          <>
            <Typography.Text copyable={{ text: buildCopyableAddress(address, instructionsText) }}>
              {title}
            </Typography.Text>
            {inProduction ? (
              <Popconfirm title={IN_PRODUCTION_WARNING} placement="right" onConfirm={() => push(addressPath)}>
                <Link {...editActionProps}>
                  <EditOutlined />
                </Link>
              </Popconfirm>
            ) : (
              isEditable && (
                <Link {...editActionProps}>
                  <EditOutlined />
                </Link>
              )
            )}
          </>
        }
        headStyle={{ paddingTop: 10 }}
      >
        <p>
          <Text strong>
            {address.firstName} {address.lastName}
          </Text>
        </p>

        <HomeOutlined {...iconProps} />
        <p>
          {formattedAddress} <br />
          {address.zipcode} — {address.city} <br />
          {address.state?.name}
        </p>
        {address.company ? <p>{address.company}</p> : null}

        {address.addressValidation ? (
          <p>
            <i>{addressTypeText(address.addressValidation)}</i>
          </p>
        ) : null}

        {address.phone ? (
          <>
            <PhoneOutlined {...iconProps} />
            <p>{address.phone}</p>
          </>
        ) : null}

        {!instructionsLoading && (
          <p data-testid="delivery_instructions" style={{ fontSize: 'small' }}>
            {instructionsText || address.instructions}
          </p>
        )}
      </Card>
    </Badge.Ribbon>
  )
}

export default RenderAddress
