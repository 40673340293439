import React from 'react'
import { Badge, Popconfirm, Space, Table, Tag, Tooltip, Typography } from 'antd'
import {
  EyeTwoTone,
  EditTwoTone,
  HistoryOutlined,
  DeleteTwoTone,
  WarningTwoTone,
  CalendarTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons'
import { generatePath, Link } from 'react-router-dom'
import humanizeString from 'humanize-string'

import {
  Complaint,
  ComplaintSubcategory,
  GetCustomerDocument,
  GetOrderComplaintsDocument,
  useDeleteComplaintMutation,
} from '../../../apollo/generated/beef'
import { categoryTagColor } from '../../../constants/ComplaintConstants'
import { formatCurrency } from '../../../utils'
import { Routes } from '../../../constants/RoutesConstants'
import { queryNames } from '../../../apollo/client'
import { handleBeefMutationResult } from '../../../apollo'
import { DELETE_COMPLAINT_ERROR, DELETE_COMPLAINT_SUCCESS } from '../../../constants/MessagesConstants'
import Colors from '../../../constants/ColorsConstants'
import { CurrencyEnum } from '../../../apollo/generated/api'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import { ManageCustomerProps } from '../../ManageCustomer/props'

const { Column } = Table
const iconStyle = { padding: 5, fontSize: 15 }
const { Text } = Typography
const ColumnContent: React.FC<{
  content: string
}> = ({ content }) => <Text style={{ wordBreak: 'break-word' }}>{content}</Text>

export type ComplaintType = Pick<Complaint, 'id' | 'compensationAmount' | 'createdAt' | 'createdBy' | 'orderNumber'>

const ManageComplaintTable: React.FC<{
  complaints?: ComplaintType[]
  currency?: CurrencyEnum
  orderNumber?: string
  customer?: ManageCustomerProps['customer']
}> = ({ complaints, currency, orderNumber, customer }) => {
  const [deleteComplaint, { loading: loadingDeleteComplaint }] = useDeleteComplaintMutation()

  const deleteComplaintAction = (complaintId: string): void => {
    const mutation = deleteComplaint({
      variables: { id: complaintId },
      refetchQueries: queryNames(GetOrderComplaintsDocument, GetCustomerDocument),
    })

    void handleBeefMutationResult(mutation, 'deleteComplaint', {
      notifications: {
        success: {
          title: DELETE_COMPLAINT_SUCCESS,
        },
        error: {
          title: DELETE_COMPLAINT_ERROR,
        },
      },
    })
  }

  return (
    <Table
      data-testid="complaints-table"
      dataSource={complaints}
      rowKey="id"
      pagination={false}
      style={{ paddingBottom: 20 }}
      bordered
      scroll={{ x: 900 }}
    >
      {!orderNumber ? (
        <Column
          title="Order Number"
          dataIndex="orderNumber"
          render={(_, { orderNumber, discardedAt }: Complaint): JSX.Element => (
            <>
              {discardedAt && (
                <Tooltip title="Deleted">
                  <Badge count={<WarningTwoTone twoToneColor="red" style={{ paddingRight: 5, marginTop: -2 }} />} />
                </Tooltip>
              )}

              <Link to={generatePath(Routes.ORDER_DETAIL, { number: orderNumber })}>{orderNumber}</Link>
            </>
          )}
        />
      ) : null}

      {!orderNumber ? (
        <Column
          title="Category"
          dataIndex="category"
          render={(_, { category, subcategory, recurring }: Complaint): JSX.Element => {
            const color = categoryTagColor[category.name.replace(/\s/g, '')]

            return (
              <>
                <Tag color={color}>{category.name}</Tag>
                <div style={{ fontSize: 13, marginTop: 5 }}>
                  <Space>
                    {subcategory.name}
                    {recurring && (
                      <Tooltip title="This is a recurring complaint">
                        <CalendarTwoTone twoToneColor={Colors.DANGER} />
                      </Tooltip>
                    )}
                  </Space>
                </div>
              </>
            )
          }}
        />
      ) : (
        <Column
          title="Complaint Type"
          dataIndex="level"
          render={(_, { level, discardedAt }: Complaint): JSX.Element => (
            <>
              {discardedAt && (
                <Tooltip title="Deleted">
                  <Badge count={<WarningTwoTone twoToneColor="red" style={{ paddingRight: 5, marginTop: -2 }} />} />
                </Tooltip>
              )}
              <Text>{humanizeString(level)}</Text>
            </>
          )}
        />
      )}

      {orderNumber ? (
        <Column
          title="Subcategory"
          dataIndex="subcategory"
          render={(value: ComplaintSubcategory, { recurring, discardedAt }: Complaint): JSX.Element => (
            <Space>
              <Text>{value.name || 'NA'}</Text>
              {recurring && !discardedAt && (
                <Tooltip title="This is a recurring complaint">
                  <CalendarTwoTone twoToneColor={Colors.DANGER} />
                </Tooltip>
              )}
            </Space>
          )}
        />
      ) : null}

      <Column
        title="Compensation"
        dataIndex="compensationAmount"
        render={(_, { compensationAmount, compensationGranted }: Complaint): JSX.Element => {
          const compensationType = compensationAmount < 0 ? 'Adjustment' : 'Credit'
          const value = compensationGranted > 0 ? compensationGranted : compensationAmount

          return (
            <>
              <div>
                <Text>{formatCurrency(value, currency)}</Text>
              </div>
              <div style={{ fontSize: 12 }}>
                <Text type="secondary">{compensationType}</Text>
              </div>
            </>
          )
        }}
      />

      <Column
        title="Logged At"
        dataIndex="createdAt"
        render={createdAt => <UserAwareDateWithTz customerTimezone={customer?.timezone} datetime={createdAt} />}
      />

      <Column
        title="Logged By"
        dataIndex="createdBy"
        render={(value): JSX.Element => <ColumnContent content={value} />}
      />

      <Column
        title="Resolved"
        dataIndex="resolvedAt"
        align="center"
        render={(value): JSX.Element =>
          value ? (
            <CheckCircleTwoTone twoToneColor={Colors.SUCCESS} />
          ) : (
            <CloseCircleTwoTone twoToneColor={Colors.DANGER} />
          )
        }
      />

      <Column
        title="Actions"
        align="center"
        width={140}
        render={(_, { id, orderNumber, discardedAt }: Complaint): JSX.Element => {
          return (
            <>
              <Link to={generatePath(Routes.ORDER_COMPLAINT_DETAILS, { number: orderNumber, complaintId: id })}>
                <Tooltip title="View">
                  <EyeTwoTone style={iconStyle} data-testid="view-complaint-icon" />
                </Tooltip>
              </Link>

              {!discardedAt && (
                <Link to={generatePath(Routes.ORDER_COMPLAINT_EDIT, { number: orderNumber, complaintId: id })}>
                  <Tooltip title="Edit">
                    <EditTwoTone style={iconStyle} data-testid="edit-complaint-icon" />
                  </Tooltip>
                </Link>
              )}

              <Link to={generatePath(Routes.ORDER_COMPLAINT_HISTORY, { number: orderNumber, complaintId: id })}>
                <Tooltip title="History">
                  <HistoryOutlined
                    style={{ ...iconStyle, color: 'rgb(24, 144, 255)' }}
                    data-testid="history-complaint-icon"
                  />
                </Tooltip>
              </Link>

              {!discardedAt && (
                <Popconfirm
                  title="Are you sure you want to delete this complaint?"
                  placement="right"
                  onConfirm={(): void => deleteComplaintAction(id)}
                >
                  {!loadingDeleteComplaint && (
                    <Tooltip title="Delete">
                      <DeleteTwoTone
                        style={iconStyle}
                        data-testid="delete-complaint-icon"
                        twoToneColor={Colors.DANGER}
                      />
                    </Tooltip>
                  )}
                </Popconfirm>
              )}
            </>
          )
        }}
      />
    </Table>
  )
}

export default ManageComplaintTable
