import { ExecutionResult } from 'graphql'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'

import * as messages from '../constants/MessagesConstants'
import Stage from '../constants/StagesConstants'
import config from '../config'
import logger from '../logging'

import { MutationErrorWrapper } from './generated/api'
import { MutationError } from './generated/beef'

type MutationResponse<MutationData, MutationName extends keyof MutationData> = {
  readonly [K in MutationName]?: ({ __typename?: string } & unknown) | null
}

type BeefMutationResponse = { __typename?: string } & unknown & { error: MutationError }

export type MutationResultNotification = {
  title?: string
  description?: string
}

export type MutationHandlerOptions = {
  notifications?: {
    success?: {
      title?: string
      description?: string
      disabled?: boolean
    }
    error?: {
      title?: string
      disabled?: boolean
    }
  }
}

export type HandlerResult<MutationData> = {
  data?: MutationData | null
  error?: ApolloError
}

export const errorNotification = (description: string, options?: MutationHandlerOptions): void => {
  if (options?.notifications?.error?.disabled === true) {
    return
  }

  notification.error({
    message: options?.notifications?.error?.title ?? messages.ERROR_TITLE,
    description,
    duration: 0,
  })
}
const handleDomainError = (error: MutationErrorWrapper, options?: MutationHandlerOptions): void =>
  errorNotification(error.errors?.map(error => error.message).join('\n') || messages.UNKNOWN_ERROR, options)

const handleSuccess = (options?: MutationHandlerOptions): void => {
  if (options?.notifications?.success?.disabled === true) {
    return
  }

  notification.success({
    message: options?.notifications?.success?.title || messages.MUTATION_SUCCESS,
    description: options?.notifications?.success?.description || messages.KEEP_GOING,
  })
}

const handleMutationException = (error: Error, options?: MutationHandlerOptions): void => {
  let { message } = error

  if ('networkError' in error || 'graphQLErrors' in error) {
    const apolloError = error as ApolloError

    logger().error(error)

    if (apolloError.networkError) {
      message = messages.NETWORK_ERROR
    } else if (apolloError.graphQLErrors) {
      message = apolloError.graphQLErrors.map(e => e.message).join('\n')
    }
  }

  if (![Stage.TEST, Stage.PRODUCTION].includes(config.stage)) {
    console.error(error)
  }

  return errorNotification(message, options)
}

export async function handleMutationResult<
  MutationData extends MutationResponse<MutationData, ResultKey>,
  ResultKey extends keyof MutationData,
>(
  mutation: Promise<ExecutionResult<MutationData>>,
  resultKey: ResultKey,
  options?: MutationHandlerOptions,
): Promise<HandlerResult<MutationData>> {
  let response

  try {
    response = await mutation
  } catch (ex) {
    handleMutationException(ex as Error, options)

    return { error: ex as ApolloError }
  }

  const result = response.data?.[resultKey]

  switch (result?.__typename) {
    case undefined:
    case null:
      throw new Error(`The mutation ${resultKey.toString()} didn't return the GraphQL type name.`)

    case 'MutationErrorWrapper':
      handleDomainError(result as unknown as MutationErrorWrapper, options)
      break

    default:
      handleSuccess(options)
      break
  }

  return response
}

export async function handleBeefMutationResult<
  MutationData extends MutationResponse<MutationData, ResultKey>,
  ResultKey extends keyof MutationData,
>(
  mutation: Promise<ExecutionResult<MutationData>>,
  resultKey: ResultKey,
  options?: MutationHandlerOptions,
): Promise<HandlerResult<MutationData>> {
  let response

  try {
    response = await mutation
  } catch (ex) {
    handleMutationException(ex as Error, options)

    return { error: ex as ApolloError }
  }

  const result = response.data?.[resultKey]
  const typename = result?.__typename
  const mutationResponse = result as unknown as BeefMutationResponse

  if (typename === null || typename === undefined) {
    throw new Error(`The mutation ${resultKey.toString()} didn't return the GraphQL type name.`)
  } else if (mutationResponse.error) {
    errorNotification(mutationResponse.error.message || messages.UNKNOWN_ERROR, options)
  } else if (options?.notifications?.success) {
    handleSuccess(options)
  }

  return response
}
