import React from 'react'
import { Row, Col, Card, Alert } from 'antd'
import moment from 'moment'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import * as messages from '../../../constants/MessagesConstants'
import { handleMutationResult } from '../../../apollo'
import { OrderWizardState, OrderWizardProps } from '../../Forms/OrderWizard/types'
import OrderWizard from '../../Forms/OrderWizard/OrderWizard'
import { formatDate } from '../../../utils'
import {
  GetOrderManagementDetailsDocument,
  UpdateOrderDeliveryMutationVariables,
  useGetOrderDeliveryDetailsQuery,
  useUpdateOrderDeliveryMutation,
} from '../../../apollo/generated/api'
import { queryNames } from '../../../apollo/client'
import RenderAddress from '../../RenderAddress'
import { IN_PRODUCTION_WARNING } from '../../../constants/MessagesConstants'
import logger from '../../../logging'
import { showExtraShippingPrice } from '../../Forms/OrderDeliveryCalendar/helper'

const ManageOrderDelivery: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const { loading, error, data } = useGetOrderDeliveryDetailsQuery({ variables: { number: orderNumber } })
  const [updateOrderDelivery] = useUpdateOrderDeliveryMutation()
  const order = data?.order
  const customerId = order?.customer?.id
  const customerAddresses = order?.customer?.addresses || []
  const inProduction = order?.orderStatus?.inProduction || false
  const customerDefaultAddress = customerAddresses.find(address => address.isDefault === true)?.address
  const currentDeliveryDateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const submitForm = async ({ addresses, delivery }: OrderWizardState): Promise<boolean> => {
    if (!order || !addresses.billing || !addresses.shipping || !delivery.date || !delivery.timeslot) {
      throw new Error('The addresses or delivery information is missing')
    }

    if (inProduction) {
      logger().warning('Order delivery updated with order in production')
    }

    const variables: UpdateOrderDeliveryMutationVariables = {
      updateDelivery: {
        id: order.id,
        addresses: {
          shippingAddressId: addresses.shipping.id,
          billingAddressId: addresses.billing.id,
        },
        delivery: {
          date: delivery.date.format('Y-MM-DD'),
          timeSlotId: delivery.timeslot.id,
        },
      },
    }
    const result = await handleMutationResult(
      updateOrderDelivery({ variables, refetchQueries: queryNames(GetOrderManagementDetailsDocument) }),
      'updateOrderDelivery',
      {
        notifications: {
          success: {
            description: messages.ORDER_DELIVERY_UPDATED,
          },
          error: {
            title: messages.ORDER_DELIVERY_UPDATE_FAILED,
          },
        },
      },
    )

    return result.data?.updateOrderDelivery?.__typename === 'Order'
  }

  if (loading) return <Loading />
  if (error || !order || !customerId) return <LoadComponentError errorMessage={error?.message} />

  const initialState: OrderWizardProps['initialState'] = {}
  const delivery = order.deliveries?.length ? order.deliveries[0] : undefined
  let alreadyDelivered = false

  if (delivery?.date && delivery.timeSlot?.id) {
    initialState.delivery = {
      date: moment(delivery.date),
      timeSlotId: delivery.timeSlot.id,
    }

    alreadyDelivered = moment(delivery.date).diff(moment(), 'days') <= 0
  }

  return (
    <>
      <Row gutter={32} style={{ marginBottom: 30 }}>
        {order.shippingAddress && (
          <Col span={12}>
            <RenderAddress
              title="Current Shipping Address"
              address={order.shippingAddress}
              orderNumber={orderNumber}
              defaultAddress={customerDefaultAddress}
              inProduction={order.orderStatus?.inProduction || false}
              isEditable={!alreadyDelivered}
            />
          </Col>
        )}

        {order.billingAddress && (
          <Col span={12}>
            <RenderAddress
              title="Current Billing Address"
              address={order.billingAddress}
              orderNumber={orderNumber}
              defaultAddress={customerDefaultAddress}
              inProduction={order.orderStatus?.inProduction || false}
              isEditable={!alreadyDelivered}
            />
          </Col>
        )}
      </Row>

      {delivery && (
        <Row style={{ marginBottom: 30 }}>
          <Col span={24}>
            <Card title="Current Delivery" headStyle={{ paddingTop: 10 }} style={{ height: '100%' }}>
              <p>{formatDate({ date: moment(delivery.date).toDate(), options: currentDeliveryDateOptions })}</p>
              <p>
                {delivery.timeSlot?.from} - {delivery.timeSlot?.to}
                <span>
                  {showExtraShippingPrice(delivery.timeSlot?.additionalShippingPrice, delivery.timeSlot?.priceCurrency)}
                </span>
              </p>
              <p>{delivery.timeSlot?.shipper?.name}</p>
            </Card>
          </Col>
        </Row>
      )}

      {alreadyDelivered && (
        <Alert
          message="Cannot change the delivery information"
          description="The order was scheduled to be delivered in the past. You cannot change its delivery anymore."
          type="warning"
          style={{ marginTop: 30 }}
          showIcon
        />
      )}

      {inProduction && (
        <Alert
          message="Order already in production"
          description={IN_PRODUCTION_WARNING}
          type="warning"
          style={{ marginTop: 30 }}
          showIcon
        />
      )}
      {!alreadyDelivered && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <OrderWizard
              data-testid="order-wizard"
              customerId={customerId}
              onSubmit={submitForm}
              initialState={initialState}
              submitButtonText="Update Delivery Information"
              includeDatesPastCutoff
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default ManageOrderDelivery
