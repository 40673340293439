import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import ThemeContextProvider from './contexts/ThemeContextProvider'
import './styles/base.scss'
import App from './components/App'
import { client } from './apollo'
import UserPreferencesContextProvider from './contexts/UserPreferencesContextProvider/UserPreferencesContextProvider'

ReactDOM.render(
  <ApolloProvider client={client}>
    <QueryClientProvider client={new QueryClient()}>
      <Router>
        <UserPreferencesContextProvider>
          <ThemeContextProvider>
            <App />
          </ThemeContextProvider>
        </UserPreferencesContextProvider>
      </Router>
    </QueryClientProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)
