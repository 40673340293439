import React, { ReactElement } from 'react'
import { Button, Modal, Table } from 'antd'
import { CameraOutlined, DeleteFilled } from '@ant-design/icons'

import { Image as ApiImage } from '../../../apollo/generated/api'
import { formatCurrency } from '../../../utils'
import { OrderAddOn, OrderAddOnQuantityHolder } from '../types'
import * as S from '../styles'

const { Column } = Table

const AddOnList: React.FC<{
  orderAddOns: OrderAddOn[]
  editable: boolean
  onDelete?: (addOn: OrderAddOn) => void
  quantityHolder: OrderAddOnQuantityHolder
}> = ({ quantityHolder, orderAddOns, editable, onDelete }) => {
  const showItemDetails = (item: OrderAddOn) => {
    const content = (
      <>
        <strong>Price:</strong>
        {formatCurrency(item.addOn?.pricing?.total || 0, item.addOn?.pricing?.currency)}
        <br />
        <strong>Description:</strong>
        {item.addOn?.description}
      </>
    )

    Modal.info({
      content,
      title: item.addOn?.name,
      width: 800,
    })
  }

  const showItemImage = (item: OrderAddOn) => {
    const url = item.addOn?.image?.url
    const content = url ? <S.AddOnPhoto src={url} alt={item.addOn?.name} /> : undefined

    Modal.info({
      content,
      title: item.addOn?.name,
      width: 800,
    })
  }

  return (
    <Table<OrderAddOn>
      data-qa="order-recipes-table"
      dataSource={orderAddOns}
      rowKey={(record: OrderAddOn) => (record.addOn?.id || 0).toString()}
      showHeader={false}
      pagination={false}
      size="middle"
    >
      <Column
        dataIndex={['addOn', 'image']}
        render={(_image: ApiImage, item: OrderAddOn): ReactElement => (
          <Button onClick={(): void => showItemImage(item)} title={`See photo of ${item.addOn?.name}`}>
            <CameraOutlined />
          </Button>
        )}
        width="8%"
      />
      <Column
        dataIndex={['addOn', 'name']}
        render={(name: string, item: OrderAddOn): ReactElement => (
          <Button type="link" onClick={(): void => showItemDetails(item)}>
            {name}
          </Button>
        )}
      />

      <Column
        render={(item: OrderAddOn): ReactElement => (
          <div>
            {item.addOn?.id && (
              <Button.Group>
                <Button
                  data-testid={`addon-decrease-quantity-button[${item.addOn?.id}]`}
                  onClick={(): void => quantityHolder.adjustQuantity(item, -1)}
                  disabled={!quantityHolder.canAdjustQuantity(item, -1)}
                >
                  -
                </Button>
                <Button
                  data-testid={`addon-quantity-value[${item.addOn?.id}]`}
                  type={quantityHolder.getQuantity(item) > 0 ? 'primary' : undefined}
                  style={{ width: 50 }}
                >
                  {quantityHolder.getQuantity(item)}
                </Button>
                <Button
                  data-testid={`addon-increase-quantity-button[${item.addOn?.id}]`}
                  onClick={(): void => quantityHolder.adjustQuantity(item, 1)}
                  disabled={!quantityHolder.canAdjustQuantity(item, 1)}
                >
                  +
                </Button>
              </Button.Group>
            )}
          </div>
        )}
        width="100px"
      />

      <Column
        width="40px"
        render={(item: OrderAddOn): ReactElement | undefined => (
          <Button
            data-testid={`addon-delete-button[${item.addOn?.id}]`}
            shape="circle"
            type="primary"
            disabled={!editable}
            danger
            icon={<DeleteFilled />}
            onClick={() => onDelete && onDelete(item)}
          />
        )}
      />
    </Table>
  )
}

export default AddOnList
