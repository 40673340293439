import { Form, Select } from 'antd'
import React from 'react'

import { CountryEnum } from '../../apollo/generated/api'
import { MappedCountries } from '../../constants/AppConstants'

const { Option } = Select

const CountryDropdown: React.FC = () => {
  return (
    <Form.Item name="country" label="Country" rules={[{ required: true, message: 'Please select a country!' }]}>
      <Select
        style={{ width: '100%' }}
        placeholder="Country"
        optionFilterProp="children"
        showSearch
        data-testid="country-dropdown"
      >
        {Object.values(CountryEnum).map(country => (
          <Option value={country} key={country}>
            {MappedCountries[country]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default CountryDropdown
