import React from 'react'
import { Card, Input, Row, Col, Button, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { FIELD_REQUIRED_ERROR } from '../../../constants/ErrorConstants'
import { COUPON_APPLIED_FAILURE, COUPON_APPLIED_SUCCESS } from '../../../constants/MessagesConstants'
import { handleMutationResult } from '../../../apollo'
import { GetOrderManagementDetailsDocument, useApplyOrderCouponMutation } from '../../../apollo/generated/api'
import { queryNames } from '../../../apollo/client'

const OrderCouponForm: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const [form] = Form.useForm()
  const [applyCoupon, { loading }] = useApplyOrderCouponMutation()

  const handleSubmit = async (values: Store): Promise<void> => {
    const mutation = applyCoupon({
      variables: {
        number: orderNumber,
        promotion: values.couponCode,
      },
      refetchQueries: queryNames(GetOrderManagementDetailsDocument),
    })
    const { data } = await handleMutationResult(mutation, 'applyCoupon', {
      notifications: {
        success: {
          title: COUPON_APPLIED_SUCCESS,
        },
        error: {
          title: COUPON_APPLIED_FAILURE,
        },
      },
    })

    if (data?.applyCoupon?.__typename === 'Order') {
      form.resetFields()
    }
  }

  return (
    <Card title="Add Coupon">
      <Form data-qa="order-coupon-form" data-testid="order-coupon-form" onFinish={handleSubmit} form={form}>
        <Row gutter={16}>
          <Col md={24}>
            <Form.Item
              name="couponCode"
              rules={[
                {
                  required: true,
                  message: FIELD_REQUIRED_ERROR,
                },
              ]}
              hasFeedback
            >
              <Input
                data-qa="coupon-value-input"
                data-testid="coupon-value-input"
                placeholder="Coupon code"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ textAlign: 'right' }}>
          <Col span={24}>
            <Button
              data-qa="order-coupon-submit"
              data-testid="order-coupon-submit"
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Add Coupon Code
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default OrderCouponForm
