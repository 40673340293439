export const LAYOUT = {
  minHeight: '100vh',
}

export const CONTENT = {
  margin: '24px 16px',
  padding: 24,
  minHeight: 280,
  height: 'auto',
  borderRadius: 4,
  boxShadow: '0 4px 0 rgba(0, 0, 0, .03)',
}

export const FOOTER: { color: string; textAlign: 'center' } = {
  color: '#bbb',
  textAlign: 'center',
}
