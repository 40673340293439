import EditCustomerAddress from './EditCustomerAddress'
import ListCustomerAddresses from './ListCustomerAddresses'
import CreateCustomerAddress from './CreateCustomerAddress'

const ManageCustomerAddresses = {
  EditAddress: EditCustomerAddress,
  ListAddresses: ListCustomerAddresses,
  CreateAddress: CreateCustomerAddress,
}

export default ManageCustomerAddresses
