import React from 'react'
import { Table } from 'antd'
import { Link, generatePath } from 'react-router-dom'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { Routes } from '../../../constants/RoutesConstants'
import { filterMaybe } from '../../../utils/typeUtils'
import { formatCurrency } from '../../../utils/priceUtils'
import { CreditReason, useGetCustomerCreditHistoryQuery } from '../../../apollo/generated/api'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import { ManageCustomerProps } from '../props'

const { Column } = Table

const ManageCustomerCreditHistory: React.FC<ManageCustomerProps> = ({ customer }) => {
  const customerId = customer.id
  const { loading, error, data } = useGetCustomerCreditHistoryQuery({
    variables: { id: customerId },
    fetchPolicy: 'no-cache',
  })
  const credits = data?.customer?.credits.filter(filterMaybe)

  if (loading) return <Loading />
  if (error) return <LoadComponentError />

  return (
    <Table
      data-qa="customer-management-account-history-table"
      data-testid="credit-history-table"
      dataSource={credits}
      rowKey="createdAt"
      pagination={false}
      bordered
    >
      <Column
        title="Date and Time"
        dataIndex="createdAt"
        key="createdAt"
        width="20%"
        render={createdAt => <UserAwareDateWithTz customerTimezone={customer.timezone} datetime={createdAt} />}
      />
      <Column
        title="Amount"
        dataIndex="amount"
        key="amount"
        render={(value): string => formatCurrency(value, data?.customer?.deliveryStats?.currency)}
      />
      <Column title="Reason" dataIndex="reason" key="reason" render={(value: CreditReason): string => value.name} />

      <Column
        title="Order"
        dataIndex="orderNumber"
        key="orderNumber"
        render={(value): JSX.Element =>
          value && <Link to={generatePath(Routes.ORDER_DETAIL, { number: value })}>{value}</Link>
        }
      />

      <Column title="User" dataIndex="createdBy" key="createdBy" />
    </Table>
  )
}

export default ManageCustomerCreditHistory
