import { Select, Space, Table, Tooltip, Typography } from 'antd'
import React from 'react'
import { MailOutlined, MobileOutlined, NotificationOutlined, WarningOutlined } from '@ant-design/icons'
import Search from 'antd/lib/input/Search'
import { generatePath, Link } from 'react-router-dom'

import {
  useGetCustomerMessagesQuery,
  UserMarketingMessage,
  UserMarketingMessageType,
} from '../../../apollo/generated/api'
import LoadComponentError from '../../../packages/LoadComponentError'
import { Routes } from '../../../constants/RoutesConstants'
import useSearchUrl from '../../../hooks/useSearchUrl'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import { ManageCustomerProps } from '../props'

import EmailOpenIcon from './EmailOpenIcon'
import EmailClickIcon from './EmailClickIcon'

const iconStyle = { fontSize: 19, color: 'inherit' }
const columnStyleNarrow = { minWidth: 120 }
const columnStyleWide = { maxWidth: 300 }

export type FilterParams = {
  types?: UserMarketingMessageType[] | null
  searchQuery?: string | null
  page?: number | null
  pageSize?: number | null
}

const EventIcon: React.FC<{ type: UserMarketingMessageType }> = ({ type }) => {
  let icon = null
  let tooltip = null

  switch (type) {
    case UserMarketingMessageType.EmailSend:
      icon = <MailOutlined />
      tooltip = 'Email Send'
      break
    case UserMarketingMessageType.EmailOpen:
      icon = <EmailOpenIcon />
      tooltip = 'Email Open'
      break
    case UserMarketingMessageType.EmailClick:
      icon = <EmailClickIcon />
      tooltip = 'Email Click'
      break
    case UserMarketingMessageType.EmailBounce:
      icon = <WarningOutlined />
      tooltip = 'Email Bounce'
      break
    case UserMarketingMessageType.InAppSend:
      icon = (
        <Space size="small">
          <MobileOutlined />
          <MailOutlined />
        </Space>
      )
      tooltip = 'In-App Send'
      break
    case UserMarketingMessageType.InAppOpen:
      icon = (
        <Space size="small">
          <MobileOutlined />
          <EmailOpenIcon />
        </Space>
      )
      tooltip = 'In-App Open'
      break
    case UserMarketingMessageType.InAppClick:
      icon = (
        <Space size="small">
          <MobileOutlined />
          <EmailClickIcon />
        </Space>
      )
      tooltip = 'In-App Click'
      break
    case UserMarketingMessageType.SmsSend:
      icon = <MobileOutlined />
      tooltip = 'SMS'
      break
    case UserMarketingMessageType.PushSend:
      icon = <NotificationOutlined />
      tooltip = 'Push'
      break
    default:
      break
  }

  return (
    <Tooltip title={tooltip}>
      <span style={iconStyle}>{icon}</span>
    </Tooltip>
  )
}
const MessagesTable: React.FC<{
  messages: UserMarketingMessage[]
  loading: boolean
  userId: number
  timezone: string
  page: number
  pageSize: number
  onPagination: (page: number, pageSize: number) => void
}> = ({ messages, loading, userId, timezone, page, pageSize, onPagination }) => (
  <div data-testid="customer-messages-table">
    <Table<UserMarketingMessage>
      dataSource={messages}
      rowKey="id"
      scroll={{ x: 700 }}
      loading={loading}
      pagination={{
        defaultPageSize: pageSize,
        current: page,
        onChange: onPagination,
      }}
    >
      <Table.Column
        title="Event"
        dataIndex="type"
        align="center"
        render={(value: UserMarketingMessageType) => <EventIcon type={value} />}
      />
      <Table.Column
        title="Sent at"
        dataIndex="sentAt"
        onCell={_ => ({ style: columnStyleNarrow })}
        render={(sentAt: string) => <UserAwareDateWithTz datetime={sentAt} customerTimezone={timezone} />}
      />
      <Table.Column
        title="Campaign ID"
        dataIndex="campaignId"
        onCell={_ => ({ style: columnStyleNarrow })}
        render={(value: string) => (
          <Typography.Text code copyable>
            {value}
          </Typography.Text>
        )}
      />
      <Table.Column
        title="Campaign name"
        dataIndex="name"
        onCell={_ => ({ style: columnStyleWide })}
        render={(value: string, record: UserMarketingMessage) => (
          <Link to={generatePath(Routes.CUSTOMER_MESSAGE, { id: userId, messageId: record.id })}>{value}</Link>
        )}
      />
      <Table.Column
        title="Subject"
        dataIndex="subject"
        onCell={_ => ({ style: columnStyleWide })}
        render={(value: string, record: UserMarketingMessage) => (
          <Link to={generatePath(Routes.CUSTOMER_MESSAGE, { id: userId, messageId: record.id })}>
            {value === '' ? 'n/a' : value}
          </Link>
        )}
      />
    </Table>
  </div>
)

const ManageCustomerMessages: React.FC<ManageCustomerProps> = ({ customer }) => {
  const { navigateToSearchUrl, parseSearchUrl } = useSearchUrl()
  const { types, searchQuery, page, pageSize } = parseSearchUrl<FilterParams>() || {}
  const { loading, data, error } = useGetCustomerMessagesQuery({
    variables: {
      id: customer.id,
      types: types || [],
      searchQuery: searchQuery || '',
    },
    fetchPolicy: 'no-cache',
  })

  const onSearch = (params: FilterParams) => {
    const { types, searchQuery, pageSize, page } = params

    void navigateToSearchUrl<FilterParams>({
      types,
      searchQuery,
      page,
      pageSize,
    })
  }

  if (error) return <LoadComponentError errorMessage={error.message} />

  return (
    <>
      <div style={{ display: 'flex', gap: 8, marginBottom: 18 }}>
        <Select
          mode="multiple"
          allowClear
          style={{ minWidth: 200 }}
          placeholder="Event type"
          defaultValue={types || []}
          onChange={(value: UserMarketingMessageType[]) => onSearch({ types: value, searchQuery })}
        >
          <Select.Option value={UserMarketingMessageType.EmailSend}>Email Send</Select.Option>
          <Select.Option value={UserMarketingMessageType.EmailOpen}>Email Open</Select.Option>
          <Select.Option value={UserMarketingMessageType.EmailClick}>Email Click</Select.Option>
          <Select.Option value={UserMarketingMessageType.EmailBounce}>Email Bounce</Select.Option>
          <Select.Option value={UserMarketingMessageType.InAppSend}>In-App Send</Select.Option>
          <Select.Option value={UserMarketingMessageType.InAppOpen}>In-App Open</Select.Option>
          <Select.Option value={UserMarketingMessageType.InAppClick}>In-App Click</Select.Option>
          <Select.Option value={UserMarketingMessageType.SmsSend}>SMS</Select.Option>
          <Select.Option value={UserMarketingMessageType.PushSend}>Push</Select.Option>
        </Select>
        <Search
          placeholder="Search text"
          allowClear
          defaultValue={searchQuery || ''}
          onSearch={(value: string) => onSearch({ types, searchQuery: value })}
        />
      </div>
      <MessagesTable
        messages={data?.customer?.marketingMessages || []}
        loading={loading}
        userId={customer.id}
        timezone={customer.timezone}
        page={page || 1}
        pageSize={pageSize || 10}
        onPagination={(page: number, pageSize: number) => onSearch({ types, searchQuery, page, pageSize })}
      />
    </>
  )
}

export default ManageCustomerMessages
