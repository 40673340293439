import React from 'react'
import { Form, FormInstance, Upload } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/lib/upload/interface'

const ComplaintAttachments: React.FC<{ form: FormInstance }> = ({ form }) => {
  const beforeUpload = (file: UploadFile): boolean => {
    form.setFieldsValue({ attachments: file })

    return false
  }

  return (
    <Form.Item name="attachments" label="Add Attachments">
      <Upload.Dragger
        name="files"
        accept="image/png,image/jpeg"
        data-testid="complaint-attachment"
        beforeUpload={beforeUpload}
        showUploadList={{ showPreviewIcon: false }}
      >
        <p className="ant-upload-drag-icon">
          <CameraOutlined style={{ fontSize: 32, margin: '20px 0' }} />
        </p>

        <p className="ant-upload-text">Click or drag image to this area to upload</p>
      </Upload.Dragger>
    </Form.Item>
  )
}

export default ComplaintAttachments
