import React, { useState, useEffect } from 'react'
import {
  CodeSandboxOutlined,
  SearchOutlined,
  UserOutlined,
  LockOutlined,
  BankOutlined,
  StarOutlined,
  HighlightOutlined,
  ReadOutlined,
} from '@ant-design/icons'
import { Menu, Layout, MenuProps } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from '../../../constants/RoutesConstants'
import { MENU } from '../../../constants/AppConstants'
import { getLocalStorage, getUserSession, persistLocalStorage } from '../../../utils'

import * as S from './styles'

const menuItems = [
  {
    path: Routes.SEARCH,
    content: (
      <>
        <SearchOutlined />
        <span>Search</span>
      </>
    ),
  },
  {
    path: Routes.ORDER_SEARCH,
    content: (
      <>
        <CodeSandboxOutlined />
        <span>Orders</span>
      </>
    ),
  },
  {
    path: Routes.CUSTOMER_SEARCH,
    content: (
      <>
        <UserOutlined />
        <span>Customers</span>
      </>
    ),
  },
  {
    path: Routes.COMPLAINTS_SEARCH,
    content: (
      <>
        <HighlightOutlined />
        <span>Complaints</span>
      </>
    ),
  },
  {
    path: Routes.MASS_CREDIT,
    content: (
      <>
        <BankOutlined />
        <span>Mass Credit</span>
      </>
    ),
  },
  {
    path: Routes.LEADS,
    content: (
      <>
        <StarOutlined />
        <span>Leads</span>
      </>
    ),
  },
  {
    path: Routes.MANAGE_INGREDIENT_CLASSIFICATIONS,
    content: (
      <>
        <ReadOutlined />
        <span>Ingredients</span>
      </>
    ),
  },
]
const superAdminItems = [
  {
    path: Routes.ADMIN_USERS,
    content: (
      <>
        <LockOutlined />
        <span>Admin</span>
      </>
    ),
  },
]

type MenuSettings = {
  collapsed: boolean
}

const MainContainerSideMenu: React.FC = () => {
  const menuSettings = getLocalStorage<MenuSettings>({ key: MENU, parse: true }) ?? { collapsed: false }
  const [menuCollapsed, setMenuCollapsed] = useState(menuSettings.collapsed)
  const { hasRole } = getUserSession()

  useEffect(() => {
    setMenuCollapsed(menuSettings.collapsed)
  }, [menuSettings])

  const onCollapse = (): void => {
    const nextState = !menuCollapsed

    persistLocalStorage(MENU, { collapsed: nextState })

    setMenuCollapsed(nextState)
  }
  const menus: MenuProps['items'] = menuItems.map(({ path, content }) => ({
    key: path,
    label: <Link to={path}>{content}</Link>,
  }))

  if (hasRole('super_admin')) {
    const adminItems = superAdminItems.map(({ path, content }) => ({
      key: path,
      label: <Link to={path}>{content}</Link>,
    }))

    menus.push(...adminItems)
  }

  return (
    <Layout.Sider data-testid="layout-sider" collapsed={menuCollapsed} onCollapse={onCollapse} theme="dark" collapsible>
      <S.Logo theme="dark">{menuCollapsed ? 'CB' : 'COOKBOOK'}</S.Logo>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[window.location.pathname]} items={menus} />
    </Layout.Sider>
  )
}

export default MainContainerSideMenu
