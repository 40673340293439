import React from 'react'
import { Form, FormInstance, Input } from 'antd'

import { useFoodSafetyContext } from '../../../../../contexts/FoodSafetyContextProvider/FoodSafetyContextProvider'

const Other: React.FC<{ form: FormInstance }> = () => {
  const { detailsDisabled } = useFoodSafetyContext()

  return (
    <Form.Item name={['fsqaDetails', 'other']} label="Other">
      <Input.TextArea disabled={detailsDisabled} data-testid="complaint-description" />
    </Form.Item>
  )
}

export default Other
