/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Select, Button, Checkbox, Card, FormInstance } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { CountryEnum } from '../../../apollo/generated/api'
import AddressAutocomplete from '../../AddressAutocomplete/AdressAutocomplete'
import { fetchAddress, fetchSuggestions, LoqateSuggestions } from '../../../services/LoqateService'
import { useGetDeliveryInstructionsLazyQuery } from '../../../apollo/generated/public_api'

import SelectDeliveryInstructions from './SelectDeliveryInstructions/SelectDeliveryInstructions'
import SelectState from './SelectState'
import { OnFinishCallback, CustomerAddressFormValues, CustomerAddressFormProps } from './types'

const rowProps = { gutter: 16 }
const required = { required: true }
const DefaultAddress: React.FC<{ enabled: boolean }> = ({ enabled }) =>
  enabled ? (
    <Form.Item name="isDefault" data-testid="is-default" valuePropName="checked">
      <Checkbox value="true" name="isDefault">
        Make this the default address of the customer?
      </Checkbox>
    </Form.Item>
  ) : (
    <div />
  )
const DeleteButton: React.FC<{ onClick?: () => void }> = ({ onClick }) =>
  onClick !== undefined ? (
    <Button data-testid="customer-address-form[delete]" type="link" danger onClick={onClick}>
      Delete Address
    </Button>
  ) : (
    <div />
  )
// eslint-disable-next-line react/no-unused-prop-types
const AddressType: React.FC<{ enabled?: boolean; initial?: string | null }> = ({ enabled }) =>
  enabled === true ? (
    <Form.Item label="Address type" name="type" rules={[required]}>
      <Select data-testid="customer-address-form[type]" size="large" placeholder="Select an address type">
        <Select.Option value="residential">Residential</Select.Option>
        <Select.Option value="commercial">Commercial</Select.Option>
      </Select>
    </Form.Item>
  ) : (
    <div />
  )
const StateSelection: React.FC<{
  enabled?: boolean
  country: CountryEnum
  initial?: number | null
  autocompleteState: string
  form: FormInstance
}> = ({ enabled, country, initial, autocompleteState, form }) =>
  enabled === true ? (
    <SelectState autocompleteState={autocompleteState} country={country} stateId={initial} form={form} />
  ) : (
    <div />
  )

// eslint-disable-next-line react/no-unused-prop-types
const DeliveryInstructions: React.FC<{ country: CountryEnum; initial?: string | null }> = ({ country }) => {
  const max = country === 'AU' ? 80 : 35

  return (
    <Form.Item label="Instructions" name="instructions" style={{ marginBottom: 5 }}>
      <Input.TextArea
        name="instructions"
        data-testid="customer-address-form[delivery-instructions]"
        maxLength={max}
        showCount
        rows={5}
      />
    </Form.Item>
  )
}

const castOnFinish =
  (callback: OnFinishCallback, _country: CountryEnum, id?: number | null) =>
  (values: Store): void => {
    const mappedValues = {
      ...values,
      ...{
        id,
        isDefault: !!values.isDefault,
        stateId: values.stateId ?? parseInt(values.stateId, 10),
      },
    } as CustomerAddressFormValues

    callback(mappedValues)
  }

const addressSuggestions = (
  country: CountryEnum,
  query: string,
  container?: string,
): Promise<LoqateSuggestions['Items']> => {
  return fetchSuggestions(country, query, container)
}

const CustomerAddressForm: React.FC<CustomerAddressFormProps> = ({
  initialValues = {},
  dynamicFields = [],
  onFinish,
  onDelete,
  loading,
  country,
  brand,
}) => {
  const [form] = Form.useForm()
  const [autocompleteState, setAutocompleteState] = useState('')
  const [getDeliveryInstructions, deliveryInstructionsQueryResult] = useGetDeliveryInstructionsLazyQuery()

  useEffect(() => {
    void getDeliveryInstructions({ variables: { country, brand, zipCode: initialValues.zipcode } })
  }, [])

  const loadAddress = (id: string): void => {
    void fetchAddress(id).then(address => {
      form.setFieldsValue({
        line1: address[0].Line1,
        line2: address[0].Line2,
        city: address[0].City,
        houseNumber: address[0].BuildingNumber,
        zipcode: address[0].PostalCode.split('-')[0],
        company: address[0].Company,
      })
      if (dynamicFields.includes('state')) {
        setAutocompleteState(address[0].ProvinceName)
      }
    })
  }
  const { loading: deliveryInstructionsLoading, data: deliveryInstructionsData } = deliveryInstructionsQueryResult
  const deliveryInstructions = deliveryInstructionsData?.deliveryInstructions || []

  return (
    <Card title={`${initialValues.id ? 'Edit' : 'Create'} address`}>
      <Form
        data-testid="customer-address-form"
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={castOnFinish(onFinish, country, initialValues.id)}
      >
        <Row {...rowProps}>
          <Col span={12}>
            <Form.Item label="First Name" name="firstName" rules={[required]}>
              <Input size="large" name="firstName" data-testid="customer-address-form[firstName]" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" name="lastName" rules={[required]}>
              <Input size="large" name="lastName" data-testid="customer-address-form[lastName]" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <AddressAutocomplete
            country={country}
            fetchOptions={addressSuggestions}
            debounceTimeout={800}
            onOptionSelected={loadAddress}
          />
        </Row>

        <Row {...rowProps}>
          <Col span={12}>
            <Form.Item label="Address line 1" name="line1" rules={[required]}>
              <Input size="large" name="line1" data-testid="customer-address-form[line1]" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Address line 2" name="line2">
              <Input size="large" name="line2" />
            </Form.Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col span={12}>
            <Form.Item label="Company" name="company">
              <Input size="large" name="company" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="House Number" name="houseNumber">
              <Input size="large" name="houseNumber" data-testid="customer-address-form[houseNumber]" />
            </Form.Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col span={12}>
            <Form.Item label="Zipcode" name="zipcode" rules={[required]}>
              <Input size="large" name="zipcode" data-testid="customer-address-form[zipcode]" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="City" name="city" rules={[required]}>
              <Input size="large" name="city" data-testid="customer-address-form[city]" />
            </Form.Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col span={12}>
            <StateSelection
              form={form}
              enabled={dynamicFields.includes('state')}
              country={country}
              initial={initialValues.stateId}
              autocompleteState={autocompleteState}
            />
          </Col>
          <Col span={12}>
            <AddressType enabled={dynamicFields.includes('addressType')} />
          </Col>
        </Row>

        <Form.Item label="Phone number" name="phone" rules={[required]}>
          <Input size="large" name="phone" data-testid="customer-address-form[phone]" />
        </Form.Item>

        {deliveryInstructions.length > 0 ? (
          <SelectDeliveryInstructions
            deliveryInstructions={deliveryInstructions}
            loading={deliveryInstructionsLoading}
            country={country}
            initialValues={initialValues}
          />
        ) : (
          <DeliveryInstructions country={country} initial={initialValues.instructions} />
        )}

        <DefaultAddress enabled={initialValues.id !== undefined} />

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              size="large"
              disabled={loading}
              data-testid="customer-address-form[submit]"
              type="primary"
              htmlType="submit"
            >
              {`${initialValues.id ? 'Save' : 'Create'} address`}
            </Button>

            <DeleteButton onClick={onDelete} />
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default CustomerAddressForm
