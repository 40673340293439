import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, notification, PageHeader, Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import {
  fetchMenuIngredients,
  GetMenuIngredientsQueryIngredientType,
  PageData,
  saveMenuIngredientClassifications,
  SteakApiExecutionError,
} from '../../services/SteakService'
import { AbortableRequest } from '../../services/GraphqlService'
import {
  GetMenuIngredientsParams,
  MenuIngredientClassification,
  MenuIngredientClassificationInput,
} from '../../apollo/generated/steak'
import { useDebounce } from '../../utils/hooks'
import * as messages from '../../constants/MessagesConstants'

import { VirtualTable, VirtualTableColumnProps } from './lib/VirtualTable'
import { PaginatedDataSource, useDataSource } from './lib/PaginatedDataSource'
import { ValueCommitter } from './lib/types'

type MenuIngredientsProps = {}

class MenuIngredientsDataSource extends PaginatedDataSource<
  GetMenuIngredientsQueryIngredientType,
  GetMenuIngredientsParams
> {
  // eslint-disable-next-line class-methods-use-this
  fetchData(
    filters: GetMenuIngredientsParams,
    cursor: string | undefined,
  ): AbortableRequest<PageData<GetMenuIngredientsQueryIngredientType>> {
    return fetchMenuIngredients(filters, cursor)
  }

  // eslint-disable-next-line class-methods-use-this
  filtersValid(filters: GetMenuIngredientsParams): boolean {
    return !!(filters?.country && filters?.brand)
  }

  // eslint-disable-next-line class-methods-use-this
  getItemId(item: GetMenuIngredientsQueryIngredientType): unknown {
    return item.id
  }
}

const COUNTRY_VALUE = ['us', 'au', 'de', 'dk', 'nl', 'at'].map(key => {
  return {
    label: key.toUpperCase(),
    value: key.toLowerCase(),
  }
})
const BRAND_VALUES = ['ms', 'dn'].map(key => {
  return {
    label: key.toUpperCase(),
    value: key.toLowerCase(),
  }
})
const YES_NO_ANY_VALUES: { label: string; value: string }[] = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Any',
    value: 'any',
  },
]
const SORTED = ['Budget', 'Minor', 'Standard', 'Moderate', 'Principle', 'Prime', 'Unclassified']

const getSortIndex = (k: string): number => {
  const idx = SORTED.indexOf(k)

  if (idx < 0) {
    return SORTED.length
  }

  return idx
}
const CLASSIFICATION_VALUES: {
  value: MenuIngredientClassification
  label: string
}[] = Object.keys(MenuIngredientClassification)
  .sort((a, b) => {
    return getSortIndex(a) - getSortIndex(b)
  })
  .map(key => {
    return {
      value: (MenuIngredientClassification as Record<string, MenuIngredientClassification>)[key],
      label: key,
    }
  })
const CLASSIFICATION_LABELS = CLASSIFICATION_VALUES.reduce((accum, current) => {
  accum.set(current.value, current.label)

  return accum
}, new Map<MenuIngredientClassification, string>())
const columns: VirtualTableColumnProps<GetMenuIngredientsQueryIngredientType>[] = [
  { title: 'Name', dataIndex: 'displayName' },
  {
    title: 'Classification',
    dataIndex: 'classification',
    editable: true,
    renderValue: (record: GetMenuIngredientsQueryIngredientType, style: React.CSSProperties) => {
      return <div style={style}>{CLASSIFICATION_LABELS.get(record.classification)}</div>
    },
    renderEditor: (
      _record: GetMenuIngredientsQueryIngredientType,
      committer: ValueCommitter<string>,
      style: React.CSSProperties,
    ) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const fieldRef = useRef<BaseSelectRef>(null)

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (fieldRef.current) {
          fieldRef.current.focus()
        }
      }, [fieldRef])

      return (
        <div style={style}>
          <Select
            value={null}
            ref={fieldRef}
            showAction={['click', 'focus']}
            style={{ width: '100%' }}
            placeholder="Select classification"
            onChange={(value1: string, _option) => {
              committer.save(value1)
            }}
            onBlur={_event => {
              committer.cancel()
            }}
            options={CLASSIFICATION_VALUES}
          />
        </div>
      )
    },
  } as VirtualTableColumnProps<GetMenuIngredientsQueryIngredientType>,
]

const ManageMenuIngredientClassifications: React.FC<MenuIngredientsProps> = () => {
  const { dataSource, hasDirtyRecords } = useDataSource(() => {
    return new MenuIngredientsDataSource()
  })
  const [selectedCounty, setSelectedCountry] = useState<string>()
  const [selectedBrand, setSelectedBrand] = useState<string>()
  const [isClassified, setIsClassified] = useState<string>()
  const [recentlyClassified, setRecentlyClassified] = useState<string>()
  const [searchTerm, setSearchTerm] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    let hasClassification: boolean | undefined

    if (isClassified === 'yes') {
      hasClassification = true
    } else if (isClassified === 'no') {
      hasClassification = false
    }

    let isRecentlyClassified: boolean | undefined

    if (recentlyClassified === 'yes') {
      isRecentlyClassified = true
    } else if (recentlyClassified === 'no') {
      isRecentlyClassified = false
    }

    let searchTerm: string | undefined
    const passedTerm = debouncedSearchTerm?.trim() || ''

    if (passedTerm !== '') {
      searchTerm = passedTerm
    }

    dataSource.setFilters({
      country: selectedCounty,
      brand: selectedBrand,
      hasClassification,
      recentlyClassified: isRecentlyClassified,
      q: searchTerm,
    })
  }, [selectedCounty, selectedBrand, isClassified, recentlyClassified, debouncedSearchTerm])

  const saveChanges = () => {
    if (isSaving) {
      return
    }
    const data: MenuIngredientClassificationInput[] = []

    // eslint-disable-next-line no-restricted-syntax
    for (const [recId, logs] of dataSource.getChangelogs()) {
      // eslint-disable-next-line no-restricted-syntax
      for (const log of logs) {
        if (log.fieldName === 'classification') {
          data.push({
            id: recId as string,
            classification: log.newValue as MenuIngredientClassification,
          })
        }
      }
    }

    if (!data.length) {
      return
    }

    setIsSaving(true)

    saveMenuIngredientClassifications(data)
      .then(value => {
        console.warn('valuevaluevalue', value)
        setIsSaving(false)
        if (value?.error) {
          notification.error({
            message: messages.UNKNOWN_ERROR,
            description: 'Error saving values, please try again',
          })
        } else {
          notification.success({
            message: messages.MUTATION_SUCCESS,
            description: 'Classifications saved',
          })

          dataSource.clearDirtyRecords()
        }
      })
      .catch(err => {
        setIsSaving(false)
        const execError = err as unknown as SteakApiExecutionError

        if (execError instanceof SteakApiExecutionError) {
          if (execError.message === 'unauthorized') {
            notification.error({
              message: 'Unauthorized',
              description: 'Missing permission to update ingredient classifications',
            })

            return
          }
        }
        notification.error({
          message: messages.UNKNOWN_ERROR,
          description: 'Error saving values, please try again',
        })
      })
  }

  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <PageHeader title="Ingredient Classifications" />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', paddingBottom: '16px', alignItems: 'center' }}>
        <span>Brand</span>
        <Select
          disabled={hasDirtyRecords && !isSaving}
          value={selectedBrand}
          placeholder="Choose"
          onChange={(value: string) => {
            setSelectedBrand(value)
          }}
          options={BRAND_VALUES}
        />

        <span>Country: </span>
        <Select
          disabled={hasDirtyRecords && !isSaving}
          value={selectedCounty}
          placeholder="Choose"
          onChange={(value: string) => {
            setSelectedCountry(value)
          }}
          options={COUNTRY_VALUE}
        />

        <span>Classified:</span>
        <Select
          disabled={hasDirtyRecords && !isSaving}
          value={isClassified}
          placeholder="Choose"
          onChange={(sel, _option) => {
            setIsClassified(sel)
          }}
          options={YES_NO_ANY_VALUES}
        />

        <span>Recently Classified:</span>
        <Select
          disabled={hasDirtyRecords && !isSaving}
          value={recentlyClassified}
          placeholder="Choose"
          onChange={(sel, _option) => {
            setRecentlyClassified(sel)
          }}
          options={YES_NO_ANY_VALUES}
        />

        <span>Name:</span>
        <Input
          disabled={hasDirtyRecords && !isSaving}
          allowClear
          onChange={ev => setSearchTerm(ev.currentTarget.value)}
          placeholder="text"
          style={{ width: 'unset' }}
        />

        <div style={{ flex: 1 }} />

        <Button type="primary" disabled={!hasDirtyRecords && !isSaving} onClick={() => saveChanges()}>
          Save
        </Button>
        <Button disabled={!hasDirtyRecords && !isSaving} onClick={() => dataSource.revertDirtyRecords()}>
          Cancel
        </Button>
      </div>

      <div style={{ height: '100%', display: 'flex', alignItems: 'stretch' }}>
        <VirtualTable columns={columns as []} dataSource={dataSource} scroll={{ y: 1 }} />
      </div>
    </div>
  )
}

export default ManageMenuIngredientClassifications
