export const excludedBillingZipcodes = [
  '6004',
  '6169',
  '6008',
  '6017',
  '6209',
  '6012',
  '6122',
  '6180',
  '6176',
  '6148',
  '6152',
  '6165',
  '6077',
  '6107',
  '6149',
  '6028',
  '6162',
  '6166',
  '6170',
  '6058',
  '6181',
  '6211',
  '6076',
  '6156',
  '6021',
  '6029',
  '6059',
  '6063',
  '6067',
  '6210',
  '6160',
  '6103',
  '6006',
  '6010',
  '6150',
  '6158',
  '6105',
  '6121',
  '6159',
  '6164',
  '6172',
  '6025',
  '6055',
  '6111',
  '6155',
  '6033',
  '6051',
  '6090',
  '6125',
  '6027',
  '6031',
  '6024',
  '6032',
  '6066',
  '6102',
  '6173',
  '6068',
  '6112',
  '6001',
  '6015',
  '6163',
  '6167',
  '6038',
  '6061',
  '6065',
  '6079',
  '6101',
  '6154',
  '6171',
  '6182',
  '6023',
  '6053',
  '6057',
  '6147',
  '6151',
  '6175',
  '6069',
  '6109',
  '6011',
  '6123',
  '6153',
  '6157',
  '6050',
  '6054',
  '6174',
  '6062',
  '6084',
  '6106',
  '6110',
  '6168',
  '6078',
  '6104',
  '6100',
  '6108',
  '2648',
  '2739',
  '3498',
  '3500',
  '3505',
  '2738',
  '3496',
  '3501',
  '0800',
  '0810',
  '0820',
  '0828',
  '0830',
  '0832',
  '0829',
  '0812',
  '5006',
  '5014',
  '5048',
  '5049',
  '5050',
  '5051',
  '5062',
  '5067',
  '5070',
  '5073',
  '5076',
  '5081',
  '5256',
  '5350',
  '5007',
  '5017',
  '5022',
  '5023',
  '5033',
  '5034',
  '5035',
  '5037',
  '5039',
  '5040',
  '5042',
  '5162',
  '5010',
  '5011',
  '5024',
  '5161',
  '5163',
  '5164',
  '5165',
  '5166',
  '5170',
  '5232',
  '5237',
  '5240',
  '5152',
  '5153',
  '5155',
  '5156',
  '5167',
  '5168',
  '5169',
  '5173',
  '5231',
  '5243',
  '5244',
  '5000',
  '5009',
  '5012',
  '5016',
  '5018',
  '5019',
  '5025',
  '5031',
  '5032',
  '5038',
  '5041',
  '5136',
  '5140',
  '5141',
  '5142',
  '5158',
  '5008',
  '5013',
  '5015',
  '5020',
  '5021',
  '5043',
  '5137',
  '5138',
  '5139',
  '5144',
  '5151',
  '5160',
  '5174',
  '5204',
  '5211',
  '5212',
  '5213',
  '5238',
  '5253',
  '7052',
  '5202',
  '5203',
  '5233',
  '5234',
  '5235',
  '5154',
  '5157',
  '5159',
  '5171',
  '5352',
  '5210',
  '5214',
  '5172',
  '5201',
  '5241',
  '5242',
  '5245',
  '5250',
  '5251',
  '5252',
  '5254',
  '5255',
  '5351',
  '5063',
  '5074',
  '5087',
  '5089',
  '7011',
  '7015',
  '5075',
  '5126',
  '5501',
  '5120',
  '5353',
  '5355',
  '5360',
  '5371',
  '5044',
  '5045',
  '5046',
  '5047',
  '5052',
  '5064',
  '5065',
  '5083',
  '5085',
  '5086',
  '5088',
  '5090',
  '5091',
  '7249',
  '7250',
  '7315',
  '5061',
  '5066',
  '5068',
  '5069',
  '5072',
  '5094',
  '5097',
  '5108',
  '5110',
  '5117',
  '5121',
  '5125',
  '5131',
  '5133',
  '5134',
  '5116',
  '5118',
  '5372',
  '7000',
  '7007',
  '7009',
  '7050',
  '5111',
  '5112',
  '5114',
  '5115',
  '7019',
  '5082',
  '5084',
  '5109',
  '5113',
  '5092',
  '5093',
  '5095',
  '5096',
  '5098',
  '5106',
  '5107',
  '5127',
  '5132',
  '5150',
  '7005',
  '7008',
  '7307',
  '7310',
  '7004',
  '7010',
  '7017',
  '7018',
  '7053',
  '7248',
]
