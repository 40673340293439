import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Select } from 'antd'
import React, { useState } from 'react'
import { Store } from 'antd/lib/form/interface'

import { MassCreditInput } from '../PreviewMassCredit/PreviewMassCredit'
import { handleMutationResult } from '../../../apollo'
import {
  CreditReason,
  CustomerCreditInputType,
  MassCreditMutationVariables,
  useMassCreditMutation,
} from '../../../apollo/generated/api'
import { filterOptionByText } from '../../../utils/inputUtils'

const { Option } = Select

const MassCreditForm: React.FC<{
  creditData: MassCreditInput[]
  creditReasons: CreditReason[]
  mutationSucceeded: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ creditData, creditReasons, mutationSucceeded }) => {
  const [massCreditMutation, { loading: massCreditLoading }] = useMassCreditMutation()
  const [canSubmit, setCanSubmit] = useState(false)

  const onFinish = ({ reasonId }: Store): void => {
    const massCredit: MassCreditMutationVariables = {
      massCredit: creditData.map(({ amount, orderNumber }): CustomerCreditInputType => {
        return {
          amount,
          orderNumber,
          reasonId,
        }
      }),
    }
    const mutation = massCreditMutation({ variables: massCredit })

    void handleMutationResult(mutation, 'massCredit', {
      notifications: {
        success: {
          title: 'Mass Credit went successfully!',
        },
      },
    })

    mutationSucceeded(true)
  }

  return (
    <Form onFinish={onFinish}>
      <Form.Item name="reasonId">
        <Select
          data-testid="mass-credit-reasons"
          disabled={creditReasons.length === 0}
          loading={creditReasons.length === 0}
          placeholder="Add a reason for mass crediting"
          onChange={() => setCanSubmit(true)}
          showSearch
          filterOption={filterOptionByText}
        >
          {creditReasons.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          htmlType="submit"
          data-testid="mass-credit-submit"
          icon={<CheckCircleOutlined />}
          size="large"
          disabled={massCreditLoading || !canSubmit}
          loading={massCreditLoading}
        >
          Run Mass Credit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default MassCreditForm
