import React from 'react'
import { Button, Result } from 'antd'
import { useHistory } from 'react-router'

const NotFoundError: React.FC = () => {
  const { goBack } = useHistory()

  return (
    <Result
      status="404"
      title="This page was not found"
      extra={
        <Button
          type="primary"
          onClick={(): void => {
            goBack()
          }}
        >
          Go back
        </Button>
      }
    />
  )
}

export default NotFoundError
