import React from 'react'
import { Descriptions, Divider, Skeleton, Space, Tooltip, Typography } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { buildOrderPrice, formatCurrency, priceBreakdownFor } from '../../../utils'
import {
  BrandEnum,
  CurrencyEnum,
  OrderPricingFragment,
  OrderVirtualPriceFragment,
  PriceComponentTypeEnum,
} from '../../../apollo/generated/api'

import { StrikedPrice } from './styles'

const { Text } = Typography
const Loader = () => (
  <div style={{ width: 80, margin: '-10px auto' }}>
    <Skeleton active paragraph={false} />
  </div>
)

const PriceItem = ({
  amount,
  testId,
  quantity,
  loading,
}: {
  amount: string
  testId: string
  quantity?: number
  loading?: boolean
}) => {
  if (loading === true) {
    return <Loader />
  }

  return (
    <Space>
      <span data-testid={testId}>{amount}</span>
      {quantity && quantity > 0 ? (
        <Tooltip title={`quantity: ${quantity}`}>
          <Text type="secondary" style={{ cursor: 'help' }}>
            <QuestionCircleOutlined />
          </Text>
        </Tooltip>
      ) : null}
    </Space>
  )
}

const DiscountedPrice: React.FC<
  React.HTMLProps<HTMLSpanElement> & {
    price: number
    discountedPrice: number
    currency?: CurrencyEnum | null
    loading?: boolean
  }
> = ({ price, discountedPrice, currency, loading, ...other }) => {
  if (loading === true) {
    return <Loader />
  }

  if (price === discountedPrice) {
    return <span {...other}>{formatCurrency(price, currency)}</span>
  }

  return (
    <span {...other}>
      <StrikedPrice>{formatCurrency(price, currency)}</StrikedPrice>
      {formatCurrency(discountedPrice, currency)}
    </span>
  )
}

export type PriceBreakdownProps = {
  pricing: OrderPricingFragment['pricing'] | OrderVirtualPriceFragment
  currency?: CurrencyEnum | null
  brand?: BrandEnum | undefined
  loading?: boolean | undefined
}

const PriceBreakDown = ({ pricing, currency, brand, loading }: PriceBreakdownProps) => {
  const price = buildOrderPrice(pricing)
  const basePrice = priceBreakdownFor(price, PriceComponentTypeEnum.Meals)?.totalAmount || 0
  const priceWithTaxBeforeDiscount = price.totalWithTax - price.discount
  const additionalShippingPrice = priceBreakdownFor(price, PriceComponentTypeEnum.AdditionalShippingPrice)?.totalAmount

  return (
    <Descriptions column={1} bordered>
      <Descriptions.Item label="Meals / Base price">
        <PriceItem
          amount={formatCurrency(basePrice, currency)}
          testId="order-meals"
          quantity={priceBreakdownFor(price, PriceComponentTypeEnum.Meals)?.quantity}
          loading={loading}
        />
      </Descriptions.Item>

      {brand !== BrandEnum.Bm && (
        <>
          <Descriptions.Item label="Premium Fee(s)">
            <PriceItem
              amount={formatCurrency(
                priceBreakdownFor(price, PriceComponentTypeEnum.PremiumFee)?.totalAmount || 0,
                currency,
              )}
              testId="order-premium-fee"
              quantity={priceBreakdownFor(price, PriceComponentTypeEnum.PremiumFee)?.quantity}
              loading={loading}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Add-ons Fee(s)">
            <PriceItem
              amount={formatCurrency(
                priceBreakdownFor(price, PriceComponentTypeEnum.AddOns)?.totalAmount || 0,
                currency,
              )}
              testId="order-add-ons-fee"
              quantity={priceBreakdownFor(price, PriceComponentTypeEnum.AddOns)?.quantity}
              loading={loading}
            />
          </Descriptions.Item>

          <Descriptions.Item label="Fruitbox Fee(s)">
            <PriceItem
              amount={formatCurrency(
                priceBreakdownFor(price, PriceComponentTypeEnum.FruitboxFee)?.totalAmount || 0,
                currency,
              )}
              testId="order-fruitbox-fee"
              quantity={priceBreakdownFor(price, PriceComponentTypeEnum.FruitboxFee)?.quantity}
              loading={loading}
            />
          </Descriptions.Item>
        </>
      )}

      <Descriptions.Item label="Shipping">
        <PriceItem
          amount={formatCurrency(
            priceBreakdownFor(price, PriceComponentTypeEnum.ShippingFee)?.totalAmount || 0,
            currency,
          )}
          testId="order-shipping"
          loading={loading}
        />
      </Descriptions.Item>

      {additionalShippingPrice && (
        <Descriptions.Item label="Additional Shipping Price">
          <PriceItem
            testId="order-additional-shipping-price"
            amount={formatCurrency(additionalShippingPrice, currency)}
            loading={loading}
          />
        </Descriptions.Item>
      )}

      <Descriptions.Item label="Discount(s)">
        <PriceItem
          amount={formatCurrency(price.discount, currency)}
          testId="order-discount"
          quantity={priceBreakdownFor(price, PriceComponentTypeEnum.Discount)?.quantity}
          loading={loading}
        />
      </Descriptions.Item>

      {brand !== BrandEnum.Bm && (
        <Descriptions.Item label="Incentive(s)">
          <PriceItem
            amount={formatCurrency(price.incentivesTotal, currency)}
            testId="order-incentives-discount"
            quantity={priceBreakdownFor(price, PriceComponentTypeEnum.Incentives)?.quantity}
            loading={loading}
          />
        </Descriptions.Item>
      )}

      <Descriptions.Item label="Taxes">
        <PriceItem testId="order-taxes" amount={formatCurrency(price.additionalTax, currency)} loading={loading} />
      </Descriptions.Item>

      <Divider />

      <Descriptions.Item label="Total">
        <DiscountedPrice
          price={priceWithTaxBeforeDiscount}
          discountedPrice={price.totalWithTax}
          currency={currency}
          data-testid="order-total"
          loading={loading}
        />
      </Descriptions.Item>
    </Descriptions>
  )
}

export default PriceBreakDown
