import React from 'react'
import { useParams, Redirect, generatePath, useHistory } from 'react-router-dom'
import { Button, notification } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { ExecutionResult } from 'graphql'

import logger from '../../../../logging'
import { Routes } from '../../../../constants/RoutesConstants'
import CustomerAddressForm, {
  CustomerAddressFormInitialValues,
  CustomerAddressFormValues,
} from '../../../Forms/CustomerAddressForm'
import Loading from '../../../../packages/Loading'
import LoadComponentError from '../../../../packages/LoadComponentError'
import { buildCustomerAddressInput } from '../../../../utils/addressUtils'
import { buildDynamicFormFields } from '../../../../utils/addressUtils/addressUtils'
import { AddressMeta } from '../../props'
import {
  CUSTOMER_ADDRESS_DELETED_FAILURE,
  CUSTOMER_ADDRESS_DELETED_SUCCESS,
  CUSTOMER_ADDRESS_UPDATED_FAILURE,
  CUSTOMER_ADDRESS_UPDATED_SUCCESS,
  ERROR_TITLE,
  INVALID_ADDRESS_WARNING,
} from '../../../../constants/MessagesConstants'
import { handleMutationResult } from '../../../../apollo'
import {
  CountryEnum,
  BrandEnum,
  DeleteCustomerAddressMutation,
  GetCustomerDocument,
  GetDetailedCustomerAddressesQuery,
  UpdateCustomerAddressMutation,
  useDeleteCustomerAddressMutation,
  useGetDetailedCustomerAddressesQuery,
  useUpdateCustomerAddressMutation,
} from '../../../../apollo/generated/api'
import { queryNames } from '../../../../apollo/client'
import { validateAddress } from '../../../../services/LoqateService'

import * as modals from './modals'

type EditCustomerAddressProps = {
  customer: {
    id: number
    country: CountryEnum
    brand: BrandEnum
    addressMeta: AddressMeta
  }
}
const findAddress = (
  id: number,
  response?: GetDetailedCustomerAddressesQuery,
): CustomerAddressFormInitialValues | null => {
  const match = response?.customer?.addresses?.find(addr => addr.id === id)

  if (match) {
    return {
      ...match.address,
      isDefault: match.isDefault,
      stateId: match.address.state?.id,
    }
  }

  return null
}
const deleteSuccessful = (response: ExecutionResult<DeleteCustomerAddressMutation>): boolean =>
  response?.data?.deleteCustomerAddress?.__typename === 'DeletedCustomerAddress'
const updateSuccessful = (response: ExecutionResult<UpdateCustomerAddressMutation>): boolean =>
  response?.data?.updateCustomerAddress?.__typename === 'CustomerAddress'

const EditCustomerAddress: React.FC<EditCustomerAddressProps> = ({ customer }) => {
  const { id: customerId, country, brand } = customer
  const addresses = useGetDetailedCustomerAddressesQuery({ variables: { customerId } })
  const [deleteAddress, deleteResult] = useDeleteCustomerAddressMutation()
  const [updateAddress, updateResult] = useUpdateCustomerAddressMutation()
  const params = useParams<{ addressId: string }>()
  const customerAddressId = parseInt(params.addressId, 10)
  const address = addresses.loading ? null : findAddress(customerAddressId, addresses.data)
  const { goBack } = useHistory()

  const onFinish = async (formValues: CustomerAddressFormValues): Promise<void> => {
    const variables = {
      customerAddress: {
        id: customerAddressId,
        ...buildCustomerAddressInput(formValues),
      },
    }

    await validateAddress(variables.customerAddress.address, country).then(async result => {
      if (result && result.valid()) {
        logger().warning('Address updated with failed loqate validation')
        notification.warning({
          message: ERROR_TITLE,
          description: INVALID_ADDRESS_WARNING,
        })
      }
      const mutation = updateAddress({ variables, refetchQueries: queryNames(GetCustomerDocument) })

      await handleMutationResult(mutation, 'updateCustomerAddress', {
        notifications: {
          success: {
            title: CUSTOMER_ADDRESS_UPDATED_SUCCESS,
          },
          error: {
            title: CUSTOMER_ADDRESS_UPDATED_FAILURE,
          },
        },
      })
    })
  }

  const onDeleteConfirmed = (): void => {
    if (!address?.id) {
      throw new Error("Can't delete the address as the ID is unknown.")
    }

    const variables = { deleteCustomerAddress: { customerId, id: address?.id } }
    const mutation = deleteAddress({ variables })

    void handleMutationResult(mutation, 'deleteCustomerAddress', {
      notifications: {
        success: {
          title: CUSTOMER_ADDRESS_DELETED_SUCCESS,
        },
        error: {
          title: CUSTOMER_ADDRESS_DELETED_FAILURE,
        },
      },
    })
  }
  const onDeleteClicked = (): void => modals.confirmDeletion(onDeleteConfirmed)

  if (deleteSuccessful(deleteResult) || updateSuccessful(updateResult)) {
    return <Redirect to={generatePath(Routes.CUSTOMER_ADDRESSES, { id: customerId })} />
  }

  if (addresses.loading) return <Loading />
  if (addresses.error) return <LoadComponentError errorMessage="Error retrieving the customer information." />
  if (!address) return <LoadComponentError errorMessage={`Address #${customerAddressId} was not found.`} />

  return (
    <>
      <Button onClick={goBack} icon={<LeftOutlined />} style={{ marginBottom: 20 }}>
        Back
      </Button>

      <CustomerAddressForm
        country={country}
        brand={brand}
        initialValues={address}
        onFinish={onFinish}
        onDelete={onDeleteClicked}
        loading={false}
        dynamicFields={buildDynamicFormFields(customer.addressMeta)}
      />
    </>
  )
}

export default EditCustomerAddress
