import React from 'react'
import { Row, Col, PageHeader } from 'antd'

import SearchCustomerEmailForm from '../Forms/SearchCustomerEmailForm'
import SearchCustomerAddressForm from '../Forms/SearchCustomerAddressForm'
import { headerIcons } from '../../constants/IconsConstants'

const SPAN = 24
const LG_SPAN = 12
const XXL_SPAN = 8
const SearchCustomer: React.FC = () => (
  <>
    <PageHeader
      title="Customers"
      avatar={{
        src: headerIcons.CUSTOMERS,
        shape: 'square',
      }}
    />
    <Row justify="center" gutter={[32, 32]}>
      <Col span={SPAN} xxl={XXL_SPAN} xl={LG_SPAN}>
        <SearchCustomerEmailForm />
      </Col>
      <Col span={SPAN} xxl={XXL_SPAN} xl={LG_SPAN}>
        <SearchCustomerAddressForm />
      </Col>
    </Row>
  </>
)

export default SearchCustomer
