import React from 'react'
import { Typography, PageHeader } from 'antd'
import { matchPath, generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'

import { headerIcons } from '../../constants/IconsConstants'
import { Routes } from '../../constants/RoutesConstants'

import ContainerHeaderActions, { ContainerHeaderAction } from './ContainerHeaderActions'
import ContainerHeaderMenu, { ContainerHeaderMenuEntry } from './ContainerHeaderMenu'

export type ContainerHeaderMenu = {
  route: {
    pattern: Routes
    prefix: boolean
  }
  icon: keyof typeof headerIcons
  title: string
  subMenu?: boolean
}
export type ContainerHeaderProps<T extends {}> = {
  title: string
  menu: ContainerHeaderMenu[]
  actions: ContainerHeaderAction<T>[]
  path: string
  object: T
  defaultIcon?: keyof typeof headerIcons
}

const currentMenuEntryIndex = (menu: ContainerHeaderMenu[], path: string): number | undefined => {
  const matchedRoutes = menu
    .map(({ route }, index) => {
      const matchedPath = matchPath(path, route.pattern)
      const exactMatch = Boolean(matchedPath?.isExact)
      const match = matchedPath && (exactMatch || route.prefix)

      return { match, exactMatch, index }
    })
    .filter(r => r.match)
  const exactMatch = matchedRoutes.find(({ exactMatch }) => exactMatch)
  const prefixMatch = matchedRoutes.find(({ match }) => match)

  return exactMatch ? exactMatch.index : prefixMatch?.index
}

const ContainerHeader: <T extends {}>(
  props: ContainerHeaderProps<T>,
) => React.ReactElement<ContainerHeaderProps<T>> = ({ title, menu, actions, path, object, defaultIcon }) => {
  const menuEntries: ContainerHeaderMenuEntry[] = []
  const history = useHistory()
  const currentIndex = currentMenuEntryIndex(menu, path)
  const iconIndex = (currentIndex && menu[currentIndex]?.icon) || defaultIcon
  const icon = iconIndex === undefined ? undefined : headerIcons[iconIndex]

  menu.map(({ route, title, icon }, index) => {
    const data = {
      title,
      icon,
      selected: index === currentIndex,
      path: generatePath(route.pattern, object),
    }

    return menuEntries.push(data)
  })

  return (
    <PageHeader
      title={<Typography.Text copyable>{title}</Typography.Text>}
      avatar={{ src: icon, shape: 'square' }}
      extra={[<ContainerHeaderActions object={object} actions={actions} key="container-header-action" />]}
      onBack={(): void => history.goBack()}
    >
      <ContainerHeaderMenu entries={menuEntries} key="container-header-menu" />
    </PageHeader>
  )
}

export default ContainerHeader
