import React from 'react'
import { Card, Alert, Row, Col } from 'antd'

import { CARD as cardProps } from '../../../constants/OverridesConstants'
import { formatMembershipStatus } from '../../../utils'
import { MembershipStatusEnum } from '../../../apollo/generated/api'

import ManageMembershipStop from './ManageMembershipStop'
import ManageMembershipPause from './ManageMembershipPause'
import ManageMembershipReactivate from './ManageMembershipReactivate'
import ManageMembershipResume from './ManageMembershipResume'

type ManageCustomerMembershipProps = {
  customer: {
    id: number
    membership: {
      startDeliveryDates: string[]
      status: MembershipStatusEnum
    }
  }
}
/**
 * Manage membership component that handles all the possible transitions of a customer membership
 * A membership can be in 4 different states:
 * - ACTIVE: The customer is receiving a box every week,
 *   the fields `paused_until` and `membership_cancelled_at` are null on the database.
 * - PAUSED: The customer is on a pause from their membership, they do not receive boxes during this period.
 *   the membership is automatically resumed after the date set on `pause_until`. A customer can transiton back to
 *   the ACTIVE state using the resumeMembership mutation which will clear the `pause_until` field. We will ship a box
 *   as soon as possible
 * - STOPPED: The customer stopped their membership, they also do not receive boxes. This means the customer terminated
 *   their relationship with us. This can be reverted using the `resumeMembership` mutation that requires a date for
 *   when the customer is coming back.
 * - NONE: This state should only occur if the customer had some issues during signup or some other unforeseen issue
 *   with their account
 */
const ManageCustomerMembership: React.FC<ManageCustomerMembershipProps> = ({ customer }) => {
  const {
    id: customerId,
    membership: { status, startDeliveryDates },
  } = customer
  const component = {
    NONE: <Alert type="info" message="Customer don't have any membership yet." />,
    ACTIVE: (
      <Row gutter={16}>
        <Col span={12}>
          <Card {...cardProps} title="Pause Membership">
            <ManageMembershipPause customerId={customerId} />
          </Card>
        </Col>
        <Col span={12}>
          <Card {...cardProps} title="Cancel Membership">
            <ManageMembershipStop customerId={customerId} />
          </Card>
        </Col>
      </Row>
    ),
    STOPPED: <ManageMembershipReactivate customerId={customerId} startDeliveryDates={startDeliveryDates} />,
    PAUSED: (
      <Row gutter={16}>
        <Col span={12}>
          <Card {...cardProps} title="Resume Membership">
            <ManageMembershipResume customerId={customerId} />
          </Card>
        </Col>
        <Col span={12}>
          <Card {...cardProps} title="Cancel Membership">
            <ManageMembershipStop customerId={customerId} />
          </Card>
        </Col>
      </Row>
    ),
  }

  return (
    <Card
      {...cardProps}
      title="Customer Membership"
      extra={formatMembershipStatus(status)}
      data-testid="membership-card"
    >
      {component[status]}
    </Card>
  )
}

export default ManageCustomerMembership
