import React from 'react'
import { PauseCircleOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import moment, { Moment } from 'moment'

import { handleMutationResult } from '../../../../apollo'
import { usePauseMembershipMutation } from '../../../../apollo/generated/api'

const ManageMembershipPause: React.FC<{ customerId: number }> = ({ customerId }) => {
  const [form] = Form.useForm()
  const [pauseMembership, { loading, error }] = usePauseMembershipMutation()

  const disabledDate = (current: Moment): boolean => {
    return current && current < moment().endOf('day')
  }
  const formRules = [
    {
      required: true,
      message: 'You must select a date.',
    },
  ]

  const handleSubmit = (values: Store): void => {
    const mutation = pauseMembership({
      variables: {
        pauseMembership: {
          id: customerId,
          pauseUntil: moment(values?.pauseUntil).format('YYYY-MM-DD'),
        },
      },
    })

    void handleMutationResult(mutation, 'pauseMembership', {
      notifications: {
        success: {
          title: 'Membership successfully paused',
        },
      },
    })
  }

  if (error) console.log(error)

  return (
    <Form data-testid="pause-membership-form" layout="vertical" onFinish={handleSubmit} form={form}>
      <Form.Item name="pauseUntil" label="Pause membership until" rules={formRules} hasFeedback>
        <DatePicker
          data-testid="pause-membership-datepicker"
          format="YYYY-MM-DD"
          disabledDate={disabledDate}
          size="large"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          data-testid="pause-membership-submit"
          htmlType="submit"
          icon={<PauseCircleOutlined />}
          size="large"
          disabled={loading}
          loading={loading}
        >
          Pause membership
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ManageMembershipPause
