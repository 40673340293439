import React from 'react'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from '../../../constants/RoutesConstants'

const SearchCustomerEmailResultsEmpty: React.FC<{ searchParam: string }> = ({ searchParam }) => (
  <Result
    status="404"
    title={`We couldn't find any customers by "${searchParam}"`}
    subTitle="Go ahead and try with a different search"
    extra={
      <Link to={Routes.CUSTOMER_SEARCH}>
        <Button type="primary">Search another customer</Button>
      </Link>
    }
  />
)

export default SearchCustomerEmailResultsEmpty
