import React, { useEffect, useState } from 'react'
import { Typography, Button, Form, Input, Table, Card, Row, Col, Popconfirm, notification } from 'antd'
import { Store } from 'antd/lib/form/interface'

import * as messages from '../../constants/MessagesConstants'
import CountryDropdown from '../CountryDropdown'
import BrandDropdown from '../BrandDropdown'
import {
  BrandEnum,
  CountryEnum,
  GetVisitsDocument,
  useDeleteLeadMutation,
  useGetVisitsLazyQuery,
  Visit,
} from '../../apollo/generated/api'
import { queryNames } from '../../apollo/client'

const { Text } = Typography

const ManageLeads: React.FC<{}> = () => {
  const [form] = Form.useForm()
  const [getVisits, { loading, data }] = useGetVisitsLazyQuery()
  const [deleteLead, { loading: loadingMutation }] = useDeleteLeadMutation()
  const [leads, setLeads] = useState<Visit[]>()

  const handleSubmit = ({ email, brand, country }: Store) => {
    void getVisits({ variables: { email, brand, country } })
  }

  useEffect(() => {
    if (data && data?.visits) {
      // remove duplicates
      setLeads(
        data.visits.filter(
          (visit, index, arr) => arr.findIndex(otherVisit => otherVisit.email === visit.email) === index,
        ),
      )
    }
  }, [data])

  const promptDelete = (email: string, brand: BrandEnum, country: CountryEnum): void => {
    void deleteLead({ variables: { email, brand, country }, refetchQueries: queryNames(GetVisitsDocument) }).then(
      data => {
        if (data.data?.deleteLead?.__typename === 'MutationErrorWrapper') {
          const { errors } = data.data.deleteLead

          notification.error({
            message: messages.ERROR_TITLE,
            description: errors?.map(error => error.message).join('\n'),
            duration: 0,
          })
        } else {
          notification.success({
            message: messages.MUTATION_SUCCESS,
            description: 'Lead delete successfully, Iterable info will be removed in the next minutes!',
          })
        }
      },
    )
  }

  return (
    <>
      <Card style={{ marginBottom: 25 }}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Row gutter={16} align="bottom">
            <Col span={6}>
              <CountryDropdown />
            </Col>

            <Col span={6}>
              <BrandDropdown />
            </Col>

            <Col span={6}>
              <Form.Item
                name="email"
                label={
                  <>
                    Email{' '}
                    <Text type="secondary" style={{ fontSize: 12, marginLeft: 5 }}>
                      (must match exactly)
                    </Text>
                  </>
                }
                rules={[{ required: true, message: 'Please type in lead email!' }]}
              >
                <Input data-testid="email-input" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit" data-testid="lead-search-submit" block>
                  Search Leads
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Table
        data-testid="search-leads-results-table"
        dataSource={leads}
        loading={loading}
        pagination={false}
        columns={[
          {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
          },
          {
            title: 'Action',
            render: ({ email, brand, country }: Visit): JSX.Element => {
              return (
                <div>
                  {email && brand && country && (
                    <span>
                      <Popconfirm
                        title="Delete this lead?"
                        placement="bottom"
                        okButtonProps={{ htmlType: 'submit', autoFocus: true }}
                        cancelText="Cancel"
                        data-testid="popover-lead-submit-button"
                        onConfirm={() => {
                          promptDelete(email, brand, country)
                        }}
                        okText="Ok"
                      >
                        <Button data-testid="delete-lead-button" disabled={loadingMutation || loading}>
                          Delete Lead
                        </Button>
                      </Popconfirm>
                    </span>
                  )}
                </div>
              )
            },
          },
        ]}
        rowKey="email"
      />
    </>
  )
}

export default ManageLeads
