import { Form, FormInstance, Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React from 'react'

import { useFoodSafetyContext } from '../../../../../contexts/FoodSafetyContextProvider/FoodSafetyContextProvider'

const NACheckbox: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { detailsDisabled, setDetailsDisabled } = useFoodSafetyContext()

  const onChange = (event: CheckboxChangeEvent) => {
    setDetailsDisabled(event.target.checked)
    if (event.target.checked) {
      form.setFieldsValue({
        fsqaDetails: {
          warmProtein: undefined,
          boxIcePackDamaged: undefined,
          receivedWithinTimeWindow: undefined,
          numIcePacks: undefined,
          other: undefined,
        },
      })
    }
  }

  const requiredCheckbox = (): boolean => {
    if (form.getFieldValue('fsqaDetails') === undefined) return true
    const { warmProtein, boxIcePackDamaged, receivedWithinTimeWindow, numIcePacks, other } =
      form.getFieldValue('fsqaDetails') || {}

    if (
      warmProtein === undefined &&
      boxIcePackDamaged === undefined &&
      receivedWithinTimeWindow === undefined &&
      numIcePacks === undefined &&
      other === undefined
    ) {
      return true
    }

    return false
  }

  return (
    <Form.Item rules={[{ required: requiredCheckbox() }]}>
      <Checkbox onChange={e => onChange(e)} value={detailsDisabled} data-testid="na-checkbox">
        N/A
      </Checkbox>
    </Form.Item>
  )
}

export default NACheckbox
