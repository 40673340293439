import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, notification } from 'antd'

import { client } from '../../../../../apollo'
import {
  SKIP_ORDER_CONFIRM_TITLE,
  SKIP_ORDER_CONFIRM_CONTENT,
  SKIP_ORDER_SUCCESS,
  SKIP_ORDER_FAILURE,
  ERROR_TITLE,
} from '../../../../../constants/MessagesConstants'
import { SkipOrderDocument, SkipOrderMutation, SkipOrderMutationVariables } from '../../../../../apollo/generated/api'

const onSkipConfirmation = async (number: string): Promise<void> => {
  try {
    await client.mutate<SkipOrderMutation, SkipOrderMutationVariables>({
      mutation: SkipOrderDocument,
      variables: { number },
    })
    notification.success({
      message: SKIP_ORDER_SUCCESS,
    })
  } catch (e) {
    notification.error({
      message: ERROR_TITLE,
      description: SKIP_ORDER_FAILURE,
    })
  }
}

const skipOrder = ({ number }: { number: string }): void => {
  const modal = Modal.confirm({
    title: SKIP_ORDER_CONFIRM_TITLE,
    content: SKIP_ORDER_CONFIRM_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    okText: 'Yes, skip the order',
    onOk: () => {
      modal.update({ okButtonProps: { loading: true } })

      return onSkipConfirmation(number)
    },
  })
}

export default skipOrder
