import { BrandEnum, CountryEnum } from '../apollo/generated/api'

export const PREFERENCES = 'appPreferences'
export const DARK_THEME = 'dark'
export const LIGHT_THEME = 'light'
export const DEFAULT_MENU_MODE = 'inline'
export const MENU = 'menu'
export const THEME = 'theme'
export const USER_PREFERENCES = 'user-preferences'
export const REDIRECT_PATH = 'REDIRECT_PATH'

export const MappedBrands = {
  [BrandEnum.Dn]: 'Dinnerly',
  [BrandEnum.Ms]: 'Marley Spoon',
  [BrandEnum.Bm]: 'BistroMD',
}

export const MappedCountries = {
  [CountryEnum.At]: 'Austria',
  [CountryEnum.Au]: 'Australia',
  [CountryEnum.Be]: 'Belgium',
  [CountryEnum.De]: 'Germany',
  [CountryEnum.Dk]: 'Denmark',
  [CountryEnum.Gb]: 'Great Britain',
  [CountryEnum.Nl]: 'Netherlands',
  [CountryEnum.Se]: 'Sweden',
  [CountryEnum.Us]: 'United States of America',
}

export enum HistorySource {
  USER = 'green',
  AUTO = 'blue',
  ADMIN = 'gold',
}

export enum ProductionSites {
  Ca1 = 'CA1',
  Mel1 = 'MEL1',
  Nwg = 'NWG',
  Nyc1 = 'NYC1',
  Per1 = 'PER1',
  Syd1 = 'SYD1',
  Tex1 = 'TEX1',
}
