import {
  CurrencyEnum,
  OrderPricingFragment,
  OrderVirtualPriceFragment,
  PriceComponentTypeEnum,
} from '../../apollo/generated/api'

const mappedLocale: { [currency in CurrencyEnum]: string } = {
  AUD: 'en-AU',
  DKK: 'da-DK',
  EUR: 'en-EU',
  GBP: 'en-GB',
  SEK: 'sv-SE',
  USD: 'en-US',
}

export const formatCurrency = (value: number, currency?: CurrencyEnum | null): string => {
  const sanitizedCurrency = currency ?? 'EUR'
  const formatter = new Intl.NumberFormat(mappedLocale[sanitizedCurrency], {
    style: 'currency',
    currency: sanitizedCurrency,
    minimumFractionDigits: 2,
  })

  return formatter.format(value)
}

const mappedSymbol: { [currency in CurrencyEnum]: string } = {
  AUD: '$A',
  DKK: 'kr',
  EUR: '€',
  GBP: '£',
  SEK: 'kr',
  USD: '$',
}

export const currencySymbol = (currency: CurrencyEnum): string => mappedSymbol[currency]

export type PriceComponent = {
  type: PriceComponentTypeEnum
  quantity: number
  totalAmount: number
}

export type ConsolidatedOrderPrice = {
  // The shipping price
  shippingTotal: number

  // The total discount value
  discount: number

  // Additional tax value applied to the order
  additionalTax: number

  // True if there's additional tax information
  showAdditionalTax: boolean

  // Incentives total applied to the order when customer claimed any reward
  incentivesTotal: number

  // Final order price, after all discounts were applied
  total: number

  // Total price with tax (could be the same as total or total + additionalTax)
  totalWithTax: number

  premiumFee: number

  priceBreakdown: PriceComponent[]
}

function getTotalFeeAmount(
  fees: PriceComponent[] | null | undefined,
  feeType: PriceComponentTypeEnum,
  defaultValue = 0,
): number {
  // eslint-disable-next-line no-restricted-syntax
  for (const fee of fees || []) {
    if (fee.type === feeType) {
      return fee.totalAmount
    }
  }

  return defaultValue
}

export const buildOrderPrice = (
  pricing?: OrderPricingFragment['pricing'] | OrderVirtualPriceFragment,
): ConsolidatedOrderPrice => {
  const discount = pricing?.discount || 0
  const shippingCost = pricing?.shippingCost || 0
  const additionalTax = pricing?.additionalTax || 0
  const incentivesTotal = pricing?.incentivesTotal || 0
  const total = pricing?.total || 0

  return {
    discount,
    shippingTotal: shippingCost,
    total,
    showAdditionalTax: pricing?.showAdditionalTax || false,
    additionalTax,
    premiumFee: getTotalFeeAmount(pricing?.extraFees, PriceComponentTypeEnum.PremiumFee),
    totalWithTax: total + additionalTax,
    incentivesTotal,
    priceBreakdown: pricing?.priceBreakdown || [],
  }
}

export const priceBreakdownFor = (price: ConsolidatedOrderPrice, type: PriceComponentTypeEnum) => {
  return price.priceBreakdown?.find(component => component.type === type)
}
