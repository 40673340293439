import React, { useState } from 'react'
import { Redirect, generatePath, useHistory } from 'react-router-dom'
import { Row, Col, Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import * as messages from '../../../constants/MessagesConstants'
import { Routes } from '../../../constants/RoutesConstants'
import { handleMutationResult } from '../../../apollo'
import { OrderWizardState } from '../../Forms/OrderWizard/types'
import OrderWizard from '../../Forms/OrderWizard/OrderWizard'
import { GetCustomerOrdersDocument, useCreateOrderMutation } from '../../../apollo/generated/api'
import { queryNames } from '../../../apollo/client'

const CreateOrder: React.FC<{ customer: { id: number } }> = ({ customer }) => {
  const { goBack } = useHistory()
  const customerId = customer.id
  const [redirect, setRedirect] = useState<string>()
  const [createOrder] = useCreateOrderMutation()

  const submitWizard = async ({ delivery, addresses }: OrderWizardState): Promise<boolean> => {
    if (!delivery.timeslot?.id) {
      throw new Error('Cannot submit the wizard without a timeslot selection')
    }

    const mutation = createOrder({
      variables: {
        createOrder: {
          customerId,
          shippingAddressId: addresses.shipping?.id,
          billingAddressId: addresses.billing?.id,
          delivery: {
            date: delivery.date?.format('Y-MM-DD') || '',
            timeSlotId: delivery.timeslot?.id,
          },
        },
      },
      refetchQueries: queryNames(GetCustomerOrdersDocument),
    })
    const { data } = await handleMutationResult(mutation, 'createOrder', {
      notifications: {
        success: {
          title: messages.CREATE_ORDER_SUCCESS,
        },
      },
    })

    if (data?.createOrder?.__typename === 'Order') {
      setRedirect(generatePath(Routes.ORDER_DETAIL, { number: data.createOrder.number }))
    }

    return false
  }

  if (redirect) return <Redirect to={redirect} />

  return (
    <>
      <Row gutter={[0, 40]} style={{ marginBottom: 25 }}>
        <Col span={24}>
          <Button onClick={goBack} icon={<LeftOutlined />}>
            Back
          </Button>
        </Col>
      </Row>

      <OrderWizard
        customerId={customer.id}
        onSubmit={submitWizard}
        submitButtonText="Create Order"
        includeDatesPastCutoff
      />
    </>
  )
}

export default CreateOrder
