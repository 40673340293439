import React, { useRef } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { NavLink } from 'react-router-dom'
import { CSSProperties } from 'styled-components'
import { EllipsisOutlined } from '@ant-design/icons'

import useSize from '../../../hooks/useSize'

export type ContainerHeaderMenuEntry = {
  title: string
  selected: boolean
  path: string
}

const ButtonGroup = Button.Group
const averageBtnWidth = 100
const buttonStyle: CSSProperties = {
  textTransform: 'uppercase',
  fontSize: 11,
  letterSpacing: -0.3,
  fontWeight: 600,
  width: '100%',
  maxWidth: 200,
}

const ContainerHeaderMenu: React.FC<{
  entries: ContainerHeaderMenuEntry[]
}> = ({ entries }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const containerSize = useSize(containerRef)
  let entriesToRender: ContainerHeaderMenuEntry[] = entries
  let subEntriesToRender: ContainerHeaderMenuEntry[] | undefined = []
  const calculatedWidth = averageBtnWidth * entriesToRender.length
  const containerWidth = containerSize?.width || 0

  // not enough space to render full menu, use sub menu to hide the rest by calculating how many entries to hide
  if (containerSize && calculatedWidth > containerWidth) {
    const noOfEntriesToHide = Math.floor((calculatedWidth - containerWidth) / averageBtnWidth) + 1

    entriesToRender = entries.slice(0, entries.length - noOfEntriesToHide)
    subEntriesToRender = entries.slice(entriesToRender.length)
  }

  if (!entries.length) return <div />

  const selectedSubMenu = subEntriesToRender?.filter(({ selected }) => selected)[0]
  const subMenu: MenuProps = {
    selectedKeys: [selectedSubMenu?.path ?? ''],
    items: subEntriesToRender?.map(({ title, path }) => ({
      key: path,
      label: <NavLink to={path}>{title}</NavLink>,
    })),
  }

  return (
    <div ref={containerRef}>
      <ButtonGroup
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        {entriesToRender.map(({ title, selected, path }) => (
          <Button key={path} style={buttonStyle} type={selected ? ('primary' as const) : undefined}>
            <NavLink to={path}>{title}</NavLink>
          </Button>
        ))}
        {subEntriesToRender.length > 0 ? (
          <Dropdown data-testid="container-header-sub-menu" menu={subMenu} placement="bottomRight">
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        ) : null}
      </ButtonGroup>
    </div>
  )
}

export default ContainerHeaderMenu
