import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Checkbox, FormInstance, Alert } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Search from 'antd/lib/input/Search'

import { useGetOrderRecipesIngredientsQuery } from '../../../../apollo/generated/api'
import { AffectedIngredient } from '../../../../apollo/generated/beef'

const Ingredients: React.FC<{ orderNumber: string; form: FormInstance }> = ({ orderNumber, form }) => {
  const [affectedRecipes, setAffectedRecipes] = useState<number[]>([])
  const { data: orderData, loading } = useGetOrderRecipesIngredientsQuery({ variables: { number: orderNumber } })
  const recipes = orderData?.order?.menu?.recipes?.filter(recipe => recipe?.mealQuantity?.numberOfPortions > 0)
  const [displayedRecipes, setDisplayedRecipes] = useState(recipes)
  const [affectedIngredientIds, setAffectedIngredientIds] = useState<number[]>([])

  const calculateRecipes = (): void => {
    // Always reset the state when a change is made
    setAffectedRecipes([])

    const ingredients: Pick<AffectedIngredient, 'id'>[] = form.getFieldValue('affectedIngredients')
    const mappedIngredients = ingredients?.map(({ id }) => id)
    const ar = recipes?.map(({ id: recipeId, shippedIngredients }) => {
      return shippedIngredients?.map(({ id }) => {
        if (mappedIngredients.includes(id.toString())) {
          return Number(recipeId)
        }

        return false
      })
    })

    setAffectedRecipes([...new Set(ar?.flat().filter(Boolean))] as number[])
  }

  const getAffectedIngredientIds = () => {
    const affectedIngredients = (form.getFieldValue('affectedIngredients') || []) as AffectedIngredient[]

    return affectedIngredients.map(ingredient => Number(ingredient.id))
  }
  const updateAffectedIngredientIds = () => setAffectedIngredientIds(getAffectedIngredientIds())

  useEffect(() => {
    if ((form.getFieldValue('affectedIngredients') as AffectedIngredient[])?.length) {
      if (!affectedRecipes.length) {
        calculateRecipes()
      }
      if (!affectedIngredientIds.length) {
        updateAffectedIngredientIds()
      }
    }

    if (recipes && !displayedRecipes) {
      setDisplayedRecipes(recipes)
    }
  }, [orderData])

  const onChange = (name: string, e: CheckboxChangeEvent) => {
    const currentIngredients: Pick<AffectedIngredient, 'id' | 'name'>[] = Array.from(
      form.getFieldValue('affectedIngredients') || 0,
    )
    const ingredientId: number = e.target?.value

    if (e.target?.checked) {
      if (!currentIngredients.find(i => i.id === ingredientId.toString())) {
        currentIngredients.push({ id: ingredientId.toString(), name })
      }
      form.setFieldsValue({ affectedIngredients: currentIngredients })
    } else {
      const ingredients = currentIngredients.filter(ingredient => ingredient?.id !== ingredientId.toString())

      form.setFieldsValue({ affectedIngredients: ingredients })
    }

    updateAffectedIngredientIds()
  }

  const filterRecipes = (value: string) => {
    return recipes
      ?.map(recipe => ({
        ...recipe,
        shippedIngredients: recipe.shippedIngredients?.filter(ingredient =>
          ingredient.name?.toLowerCase().includes(value.toLowerCase()),
        ),
      }))
      .filter(recipe => recipe?.shippedIngredients?.length)
  }

  const onSearch = (value: string): void => {
    const filteredRecipes = value === '' ? recipes : filterRecipes(value)

    setDisplayedRecipes(filteredRecipes)
  }

  const onSearchInputChange = ({ target }: { target: HTMLInputElement }) => {
    if (target.value.length === 1) return

    onSearch(target.value)
  }

  return (
    <Card
      title="Pick the ingredients that were affected"
      headStyle={{ textAlign: 'center' }}
      style={{ marginBottom: 35, boxShadow: '0 15px 15px rgba(0, 0, 0, .05)' }}
      loading={loading}
    >
      <Search
        data-testid="complaint-ingredient-search"
        placeholder="Search for ingredients"
        allowClear
        size="middle"
        onSearch={onSearch}
        onChange={onSearchInputChange}
      />

      <Form.Item name="affectedIngredients" rules={[{ required: true, message: 'Pick at least one ingredient.' }]}>
        {displayedRecipes?.map(({ id, fullName, shippedIngredients }) => (
          <Card
            key={Number(id)}
            title={fullName}
            style={{
              marginBottom: 30,
              ...(affectedRecipes.includes(Number(id)) ? { border: '1px #1a90ff solid' } : {}),
            }}
          >
            <Checkbox.Group style={{ width: '100%' }} onChange={calculateRecipes} value={affectedIngredientIds}>
              <Row gutter={[16, 16]}>
                {shippedIngredients?.map(({ id, name, image }) => (
                  <Col span={8} key={id}>
                    <Checkbox
                      data-testid={`ingredient-${name}`}
                      value={id}
                      className="complaint-ingredient-checkbox"
                      onChange={e => onChange(name || '', e)}
                    >
                      <img style={{ width: '100%' }} src={image?.url || ''} alt={name || ''} />
                      <div style={{ marginTop: 5 }}>{name}</div>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Card>
        ))}
      </Form.Item>
      {affectedRecipes ? (
        <Alert message={`${affectedRecipes.length} recipes are being affected.`} type="info" showIcon />
      ) : null}
    </Card>
  )
}

export default Ingredients
