import React, { useState } from 'react'
import { Alert, Form, FormInstance, Radio } from 'antd'

import { useFoodSafetyContext } from '../../../../../contexts/FoodSafetyContextProvider/FoodSafetyContextProvider'

/**
 * This is a checkbox component that needs to be checked as `Yes` for a valid FoodSafety complaint
 */
const ReceivedWithinTimeWindow: React.FC<{ form: FormInstance }> = () => {
  const { detailsDisabled } = useFoodSafetyContext()
  const [hideAlert, setHideAlert] = useState<boolean>()

  return (
    <>
      <Form.Item
        name={['fsqaDetails', 'receivedWithinTimeWindow']}
        label="Received within delivery time window?"
        rules={[{ required: !detailsDisabled, message: 'This field is required' }]}
      >
        <Radio.Group disabled={detailsDisabled}>
          <Radio
            data-testid="within-time-window-yes"
            value
            style={{ marginRight: 25 }}
            onClick={() => setHideAlert(true)}
          >
            Yes
          </Radio>

          <Radio
            data-testid="within-time-window-no"
            value={false}
            onClick={() => {
              setHideAlert(false)
            }}
            style={{ marginRight: 25 }}
          >
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
      {hideAlert === false && (
        <Alert
          description={`This order was not received within the time delivery window,
          so it doesn’t meet the criteria for Temperature/Warm Box issues. Please revise your input.`}
          message="Food Safety Issue"
          type="error"
          closable={false}
          style={{ marginTop: 30 }}
        />
      )}
    </>
  )
}

export default ReceivedWithinTimeWindow
