import { RcFile, UploadProps } from 'antd/lib/upload'
import csv from 'csvtojson'
import camelcaseKeys from 'camelcase-keys'

export const columns = [
  {
    title: 'Order No.',
    dataIndex: 'orderNo',
    key: 'orderNo',
  },
  {
    title: 'Date Created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Subcategory',
    dataIndex: 'subcategory',
    key: 'subcategory',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Credit Amount',
    dataIndex: 'creditAmount',
    key: 'creditAmount',
  },
  {
    title: 'Credit Reason',
    dataIndex: 'creditReason',
    key: 'creditReason',
  },
]

const options = {
  trim: true,
  ignoreEmpty: true,
  delimiter: 'auto',
}
const allowedHeaders = {
  orderNo: 'Order No.',
  dateCreated: 'Date Created',
  category: 'Category',
  subcategory: 'Subcategory',
  description: 'Description',
  creditAmount: 'Credit Amount',
  creditReason: 'Credit Reason',
}

interface Props {
  setRejected: Function
  setData: Function
}

export const draggerProps = ({ setRejected, setData }: Props): UploadProps => ({
  accept: `.csv`,
  name: `files`,
  multiple: false,
  beforeUpload: (file: RcFile): boolean => {
    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) return

      void csv(options)
        .on('header', (headers: string[]) => {
          const rejected: Array<{ wrongHeader: string; correctHeader: string }> = []

          headers.forEach((header, index) => {
            if (header.toString() === '') return

            if (!Object.values(allowedHeaders).includes(header.toString())) {
              rejected.push({
                wrongHeader: header,
                correctHeader: Object.values(allowedHeaders)[index],
              })
            }
          })

          setRejected(rejected)
        })
        .fromString(e.target.result as string)
        .then(data => setData(camelcaseKeys(data)))
    }

    reader.readAsText(file)

    return false
  },
})
