import jwtDecode from 'jwt-decode'
import axios from 'axios'

import config from '../../config'
import {
  COOKBOOK_USER_SESSION,
  BEEF_USER_SESSION,
  REQUEST_TIMEOUT,
  GOOGLE_PROVIDER_TOKEN,
} from '../../constants/AuthConstants'
import { getLocalStorage, setLocalStorage } from '../localStorageUtils'
import logger from '../../logging'
import { filterMaybe } from '../typeUtils'

export enum SessionMessages {
  InvalidUserSession = "You aren't logged in or your session has expired.",
  UserLoggedOut = "You've logged out successfuly.",
  LoginFailed = "There was an error during your user's authentication.",
}

const userRoles = ['cc-agent', 'super_admin'] as const

export type UserRole = (typeof userRoles)[number]

export enum SessionType {
  BEEF = 'beef',
}

export type SessionInformation = {
  token: {
    value: string
    exp: number
  }
  roles: UserRole[]
  user: {
    email: string
    givenName: string
    picture: string
  }
}

export type UserSession = {
  hasValidSession: () => boolean
  session: SessionInformation | null
  hasRole(role: UserRole): boolean
}

export const getUserSession = (tokenKey = COOKBOOK_USER_SESSION): UserSession => {
  const session = getLocalStorage<SessionInformation>({ key: tokenKey, parse: true })
  const now = Math.floor(new Date().getTime() / 1000)
  const hasValidSession = (): boolean => !!session && session?.token.exp > now

  const hasRole = (role: UserRole): boolean => {
    return !!session?.roles?.includes(role)
  }

  return { hasValidSession, session, hasRole }
}

type ApiTokenClaims = {
  exp: number
  scopes: string[]
}

const createUserSession = (apiToken: string, user: SessionInformation['user'], type?: SessionType): UserSession => {
  const token = jwtDecode<ApiTokenClaims>(apiToken)
  const isBeef = type === SessionType.BEEF
  const roles = !isBeef
    ? token.scopes
        .map((scope: string): UserRole | undefined => {
          return userRoles.find(role => role === scope)
        })
        .filter(filterMaybe) || []
    : []
  const userSession = {
    token: {
      value: apiToken,
      exp: token.exp,
    },
    user,
    roles,
  }
  const key = isBeef ? BEEF_USER_SESSION : COOKBOOK_USER_SESSION

  setLocalStorage<SessionInformation>({
    key,
    value: userSession,
    stringify: true,
  })

  return getUserSession(key)
}

export const destroyUserSession = (): void => {
  const destroyKeys = [COOKBOOK_USER_SESSION, GOOGLE_PROVIDER_TOKEN, BEEF_USER_SESSION]

  destroyKeys.map(key => window.localStorage.removeItem(key))
}

export const authenticate = async (googleToken: string, type?: SessionType): Promise<UserSession> => {
  const isBeef = type === SessionType.BEEF
  const apiEndpoint = isBeef ? `${config.beefRestEndpoint}/login` : `${config.restApiBaseUrl}/employee_token`
  // eslint-disable-next-line camelcase
  const decoded = jwtDecode<{ email: string; given_name: string; picture: string; exp: number }>(googleToken)
  const user = {
    email: decoded.email,
    givenName: decoded.given_name,
    picture: decoded.picture,
  }
  const body = {
    email: user.email,
    provider_token: googleToken,
    ...(!isBeef
      ? {
          scopes: ['admin'],
        }
      : null),
  }
  const apiResponse = await axios.post<{ token: string }>(apiEndpoint, body, { timeout: REQUEST_TIMEOUT })
  const apiToken = apiResponse.data.token
  const userSession = createUserSession(apiToken, user, type)

  setLocalStorage({ key: GOOGLE_PROVIDER_TOKEN, value: googleToken })

  logger().setDefaultContext({ email: decoded.email })

  return userSession
}

export const getTokenKey = (uri: string): string => {
  if (uri === config.adminApiEndpoint) {
    return COOKBOOK_USER_SESSION
  }

  return BEEF_USER_SESSION
}

export const buildTokenValue = (tokenKey: string, token: string): string => {
  if (tokenKey === COOKBOOK_USER_SESSION) {
    return `Bearer ${token}`
  }

  return token
}
