import React from 'react'
import { Form, Select } from 'antd'

import { required } from '../formConfig'
import { ComplaintCategory } from '../../../../apollo/generated/beef'

const { Option } = Select

type Props = {
  options?: ComplaintCategory[] | null
  loading: boolean
  disabled?: boolean
}

const Category: React.FC<Props> = ({ options, loading, disabled }) => {
  return (
    <Form.Item name="categoryId" label="Pick a category" rules={[required]}>
      <Select
        data-testid="categories-dropdown"
        loading={loading}
        disabled={loading || disabled}
        placeholder="Pick any category"
        optionFilterProp="children"
        showSearch
      >
        {options?.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default Category
