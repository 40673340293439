import React from 'react'
import { Card, Descriptions, Typography } from 'antd'

import { BankAccount as ApiBankAccount } from '../../../apollo/generated/api'

const { Text } = Typography

const BankAccount: React.FC<{ bankAccount: ApiBankAccount }> = ({ bankAccount }) => {
  const { name, iban, bic } = bankAccount

  return (
    <Card title="Bank Account">
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Name">
          <Text strong>{name}</Text>
        </Descriptions.Item>

        <Descriptions.Item label="IBAN">
          <Text strong>{iban}</Text>
        </Descriptions.Item>

        <Descriptions.Item label="BIC">
          <Text strong>{bic}</Text>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default BankAccount
