import React from 'react'
import { Route, Switch } from 'react-router'
import { PageHeader } from 'antd'

import { Routes } from '../../constants/RoutesConstants'
import { headerIcons } from '../../constants/IconsConstants'
import ManageLeads from '../../components/ManageLeads'

const ManageLeadsContainer: React.FC = () => {
  return (
    <>
      <PageHeader title="Manage Leads" avatar={{ src: headerIcons.CUSTOMERS_LIST, shape: 'square' }} />
      <Switch>
        <Route exact path={Routes.LEADS} component={ManageLeads} />
      </Switch>
    </>
  )
}

export default ManageLeadsContainer
