import React from 'react'
import { Row, Col, Result } from 'antd'

import SearchOrderForm from '../../../components/Forms/SearchOrderForm'

const colProps = {
  xl: { span: 6, offset: 9 },
  md: { span: 12, offset: 6 },
}
const ManageOrderEmpty: React.FC<{ orderNumber: string }> = ({ orderNumber }) => (
  <Result
    status="404"
    title={orderNumber}
    subTitle="Looks like the order you're looking for does not exist"
    extra={
      <Row>
        <Col {...colProps}>
          <SearchOrderForm />
        </Col>
      </Row>
    }
  />
)

export default ManageOrderEmpty
