import React from 'react'
import { Row, Col, Card, Input, Button, Form, Popconfirm, Divider } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { CARD as cardProps, ROW as rowProps } from '../../../constants/OverridesConstants'
import { EMAIL_REQUIRED_ERROR, PASSWORD_REQUIRED_ERROR } from '../../../constants/ErrorConstants'
import {
  CUSTOMER_EMAIL_SAVED_SUCCESS,
  CUSTOMER_EMAIL_SAVED_ERROR,
  RESET_CUSTOMER_PASSWORD_SUCCESS,
  RESET_CUSTOMER_PASSWORD_ERROR,
  CUSTOMER_PASSWORD_CHANGED_SUCCESS,
  CUSTOMER_PASSWORD_CHANGED_ERROR,
} from '../../../constants/MessagesConstants'
import { handleMutationResult } from '../../../apollo'
import {
  useChangeCustomerPasswordMutation,
  useResetCustomerPasswordMutation,
  useUpdateCustomerEmailMutation,
} from '../../../apollo/generated/api'

const FORM_LAYOUT = 'vertical'

const ManageCustomerGeneralSettings: React.FC<ManageCustomerGeneralSettingsProps> = ({ customerEmail, customerId }) => {
  const [updateCustomerEmail, { loading: loadingCustomerEmail }] = useUpdateCustomerEmailMutation()
  const [resetCustomerPassword, { loading: loadingCustomerPasswordReset }] = useResetCustomerPasswordMutation()
  const [changeCustomerPassword, { loading: loadingPasswordChange }] = useChangeCustomerPasswordMutation()
  const emailChangeTextFields = [
    {
      field: 'email',
      label: 'Email Address',
      errorMessage: EMAIL_REQUIRED_ERROR,
      placeholder: 'Customer Email',
      required: true,
    },
  ]
  const passwordChangeTextFields = [
    {
      field: 'password',
      label: 'New password',
      errorMessage: PASSWORD_REQUIRED_ERROR,
      placeholder: 'Customer Password',
      required: true,
    },
  ]

  const handleEmailUpdate = async (values: Store): Promise<void> => {
    const mutation = updateCustomerEmail({
      variables: { id: customerId, email: values.email },
    })

    await handleMutationResult(mutation, 'updateCustomerEmail', {
      notifications: {
        success: {
          title: CUSTOMER_EMAIL_SAVED_SUCCESS,
        },
        error: {
          title: CUSTOMER_EMAIL_SAVED_ERROR,
        },
      },
    })
  }

  const resendCustomerPassword = async (): Promise<void> => {
    const mutation = resetCustomerPassword({
      variables: { id: customerId },
    })

    await handleMutationResult(mutation, 'resetCustomerPassword', {
      notifications: {
        success: {
          title: RESET_CUSTOMER_PASSWORD_SUCCESS,
        },
        error: {
          title: RESET_CUSTOMER_PASSWORD_ERROR,
        },
      },
    })
  }

  const handlePasswordUpdate = async (values: Store): Promise<void> => {
    const mutation = changeCustomerPassword({
      variables: {
        userId: `${customerId}`,
        password: values.password,
      },
    })

    await handleMutationResult(mutation, 'changeCustomerPassword', {
      notifications: {
        success: {
          title: CUSTOMER_PASSWORD_CHANGED_SUCCESS,
        },
        error: {
          title: CUSTOMER_PASSWORD_CHANGED_ERROR,
        },
      },
    })
  }

  return (
    <Card
      {...cardProps}
      title="General Settings"
      data-testid="customer-general-settings-card"
      style={{ height: '100%' }}
    >
      <Form
        data-qa="customer-email-form"
        name="updateCustomerEmail"
        layout={FORM_LAYOUT}
        initialValues={{ email: customerEmail }}
        onFinish={handleEmailUpdate}
      >
        <Row {...rowProps} style={{ margin: 0 }}>
          {emailChangeTextFields.map(({ field, label, errorMessage, placeholder, required }) => (
            <Col span={24} key={field}>
              <Form.Item name={field} label={label} rules={[{ required, message: errorMessage }]} hasFeedback>
                <Input
                  data-qa={`customer-details-${field}-input`}
                  placeholder={placeholder}
                  disabled={loadingCustomerEmail}
                  size="large"
                />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Row {...rowProps} style={{ margin: 0 }}>
          <Col span={24}>
            <Button
              data-testid="update-email-button"
              type="primary"
              htmlType="submit"
              disabled={loadingCustomerEmail}
              size="large"
              block
            >
              Update email address
            </Button>
          </Col>
        </Row>
      </Form>

      <Form
        data-qa="customer-password-form"
        name="updateCustomerPassword"
        layout={FORM_LAYOUT}
        onFinish={handlePasswordUpdate}
      >
        <Row {...rowProps} style={{ margin: 0 }}>
          {passwordChangeTextFields.map(({ field, label, errorMessage, placeholder, required }) => (
            <Col span={24} key={field}>
              <Divider>Change password</Divider>

              <Form.Item name={field} label={label} rules={[{ required, message: errorMessage }]} hasFeedback>
                <Input
                  data-qa={`customer-details-${field}-input`}
                  placeholder={placeholder}
                  disabled={loadingPasswordChange}
                  size="large"
                />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Row {...rowProps} style={{ margin: 0 }}>
          <Col span={24}>
            <Button
              data-testid="update-password-button"
              type="primary"
              htmlType="submit"
              disabled={loadingPasswordChange}
              size="large"
              block
            >
              Change password
            </Button>
          </Col>
        </Row>
      </Form>

      <Row {...rowProps} style={{ margin: 0 }}>
        <Col span={24}>
          <Divider>Reset password</Divider>

          <Popconfirm
            data-testid="reset-password-confirm"
            title={`Are you sure you want to resend a password reset link to ${customerEmail}?`}
            placement="bottom"
            onConfirm={resendCustomerPassword}
            cancelText="No"
            okText="Yes"
          >
            <Button data-testid="reset-password-button" size="large" disabled={loadingCustomerPasswordReset} block>
              Send reset password link
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Card>
  )
}

type ManageCustomerGeneralSettingsProps = {
  customerEmail: string
  customerId: number
}

export default ManageCustomerGeneralSettings
