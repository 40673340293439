import React from 'react'
import { Table, Typography, List, Alert, Divider, Space } from 'antd'
import { generatePath, Link } from 'react-router-dom'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { Routes } from '../../../constants/RoutesConstants'
import { filterMaybe } from '../../../utils/typeUtils'
import { Delivery, Shipment, useGetOrderShipmentsQuery } from '../../../apollo/generated/api'
import TimeWithTimeZone, { DateFormats } from '../../Shared/TimeWithTimezone/TimeWithTimezone'
import { type ManageOrderProps } from '../../../containers/ManageOrderContainer/ManageOrderContainer'

const { Column } = Table
const { Text } = Typography

type ShippedItem = {
  name: string | null | undefined
}

const TableHeader = ({ delivery, customerTimezone }: { delivery: Delivery; customerTimezone?: string | null }) => {
  const { date, timeSlot, region } = delivery

  return (
    <Space>
      <span>
        <TimeWithTimeZone datetime={date as string} format={DateFormats.DateText} timezone={customerTimezone} /> (
        {timeSlot?.from} – {timeSlot?.to})
      </span>
      <Divider type="vertical" />
      <span>Region: {region || 'n/a'}</span>
    </Space>
  )
}

const ShippedRecipes = ({ shipment }: { shipment: Shipment }) => {
  const mappedAddOns: ShippedItem[] = (shipment.addOns || []).map(({ name }): ShippedItem => {
    return {
      name,
    }
  })
  const mappedRecipes = (shipment.recipes || []).map((recipe): ShippedItem => {
    return {
      name: recipe.title,
    }
  })

  return (
    <List
      size="small"
      dataSource={mappedAddOns.concat(mappedRecipes)}
      renderItem={({ name }) => <List.Item>{name}</List.Item>}
    />
  )
}

const DeliveryTable: React.FC<{ delivery: Delivery; orderNumber: string; customerTimezone?: string | null }> = ({
  delivery,
  orderNumber,
  customerTimezone,
}) => {
  const { shipments } = delivery

  return (
    <Table
      data-testid="order-management-shipments-table"
      dataSource={shipments || []}
      rowKey="number"
      bordered
      pagination={false}
      title={() => <TableHeader delivery={delivery} customerTimezone={customerTimezone} />}
    >
      <Column
        title="Shipment Number"
        render={(_, { number }: Shipment) => (
          <Text code copyable>
            {number}
          </Text>
        )}
      />

      <Column title="Recipes" render={(_, shipment: Shipment) => <ShippedRecipes shipment={shipment} />} />

      <Column
        title="Tracking"
        render={(_, { tracking, number }: Shipment) => {
          return tracking.link ? (
            <>
              <a href={tracking.link} target="_blank" rel="noopener noreferrer">
                Parcellab Link
              </a>

              <Divider />

              {number && (
                <Link to={generatePath(Routes.SHIPMENT_TRACKING, { number: orderNumber, shipmentNumber: number })}>
                  RADAR Link
                </Link>
              )}
            </>
          ) : (
            <div />
          )
        }}
      />
    </Table>
  )
}

const ManageOrderShipments: React.FC<{ orderNumber: string; order: ManageOrderProps }> = ({ orderNumber, order }) => {
  const queryVars = { variables: { number: orderNumber } }
  const { loading, error, data } = useGetOrderShipmentsQuery({ ...queryVars, fetchPolicy: 'no-cache' })
  const deliveries = data?.order?.deliveries?.filter(filterMaybe) || []

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />

  if (!deliveries.length) {
    return <Alert type="info" message="No Shipments" description="The order has no shipment associated with it." />
  }

  return (
    <>
      {deliveries.map(delivery => (
        <DeliveryTable
          key={delivery.id}
          delivery={delivery as Delivery}
          orderNumber={orderNumber}
          customerTimezone={order?.customer?.timezone}
        />
      ))}
    </>
  )
}

export default ManageOrderShipments
