import { InMemoryCache, ApolloClient, NormalizedCacheObject, HttpLink, DocumentNode, ApolloLink } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import config from '../config'
import logger from '../logging'
import { getUserSession, getTokenKey, buildTokenValue } from '../utils'

import apiIntrospection from './generated/introspection'
import beefIntrospection from './generated/beef/introspection'

const customFetch = (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  const tokenKey = getTokenKey(uri.toString())
  const token = getUserSession(tokenKey).session?.token.value || ''
  const authorization = buildTokenValue(tokenKey, token)
  const headers = new Headers({
    ...options?.headers,
    Authorization: authorization,
  })
  const config = options ?? {}

  config.headers = headers

  return fetch(uri, config)
}

const customLogisticsApiFetch = (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  const authorization = `Bearer ${config.logisticsApiToken}`
  const headers = new Headers({
    ...options?.headers,
    Authorization: authorization,
  })
  const fetchConfig = options ?? {}

  fetchConfig.headers = headers

  return fetch(uri, fetchConfig)
}
const cache = new InMemoryCache({
  possibleTypes: {
    ...apiIntrospection.possibleTypes,
    ...beefIntrospection.possibleTypes,
  },
  typePolicies: {
    Customer: {
      fields: {
        userPlan: { merge: false },
        deliveryStats: { merge: false },
      },
    },
    Order: {
      fields: {
        adjustments: { merge: false },
        pricing: {
          // eslint-disable-next-line default-param-last
          merge: (previous: object = {}, incoming: object): object => ({ ...previous, ...incoming }),
        },
      },
    },
  },
})
const apiLink = new HttpLink({
  uri: config.adminApiEndpoint,
  fetch: customFetch,
})
const publicApiLink = new HttpLink({
  uri: config.publicGraphqlApiEndpoint,
  fetch: customFetch,
})
const beefLink = new HttpLink({
  uri: config.beefGraphQLEndpoint,
  fetch: customFetch,
})
const logisticsApiLink = new HttpLink({
  uri: config.logisticsApiGraphqlUrl,
  fetch: customLogisticsApiFetch,
})
const steakLink = new HttpLink({
  uri: config.beefGraphQLEndpoint?.replace('/graphql', '/steak/graphql'),
  fetch: customFetch,
})

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: ApolloLink.split(
    operation => {
      const [, serviceName] = operation.operationName.split('_')

      return serviceName === 'Beef'
    },
    beefLink,
    ApolloLink.split(
      operation => {
        const [, serviceName] = operation.operationName.split('_')

        return serviceName === 'Logistics'
      },
      logisticsApiLink,
      ApolloLink.split(
        operation => {
          const isSteak = operation.operationName.includes('Steak')

          return isSteak
        },
        steakLink,
        ApolloLink.split(
          operation => {
            const isPublicApi = operation.operationName.includes('PublicApi')

            return isPublicApi
          },
          publicApiLink,
          apiLink,
        ),
      ),
    ),
  ),
})

export const queryNames = (...documents: DocumentNode[]): string[] => {
  const names = documents.map(doc => getOperationName(doc))

  if (names.length !== documents.length) {
    logger().error(`There was an error infering the query names: ${names.join(',')}`)
  }

  return names.filter((name): name is string => name != null)
}
