import React from 'react'
import { Form, Radio } from 'antd'

const RecurringIssue: React.FC<{ recurring: boolean }> = ({ recurring }) => {
  const options = [
    { label: 'Yes', 'data-testid': 'recurring-issue-yes', value: true, style: { marginRight: 25 } },
    { label: 'No', 'data-testid': 'recurring-issue-no', value: false, style: { marginRight: 25 } },
  ]

  return (
    <Form.Item
      initialValue={recurring}
      name="recurringIssue"
      label="Is this complaint a recurring issue?"
      data-testid="recurring-issue-component"
    >
      <Radio.Group options={options} disabled />
    </Form.Item>
  )
}

export default RecurringIssue
