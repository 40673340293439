import React from 'react'
import { DatePicker, Select, Space, Table, Tag, Tooltip } from 'antd'
import { useQuery } from 'react-query'
import { Link, generatePath } from 'react-router-dom'
import moment from 'moment'

import LoadComponentError from '../../../packages/LoadComponentError'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import { ManageCustomerProps } from '../props'
import { Incident, IncidentStatuses, incidentCustomerAffectedOrders } from '../../../services/IncidentService'
import useSearchUrl from '../../../hooks/useSearchUrl'
import { Routes } from '../../../constants/RoutesConstants'
import { useGetCustomerOrdersTotalCountQuery } from '../../../apollo/generated/api'
import StatsOverview from '../../Shared/StatsOverview/StatsOverview'

export type FilterParams = {
  status?: IncidentStatuses
  rootCauseCategory?: string | null
  page?: number | null
  pageSize?: number | null
  calendarWeekFrom?: number | null
  calendarWeekTo?: number | null
}

const { Column } = Table
const statusToColor: Record<string, string> = {
  [IncidentStatuses.LOGGED]: 'blue',
  [IncidentStatuses.CLOSED]: 'green',
}

const shortenLink = (link: string) => {
  return link.match(/(?<root>^.+\.com)(?<port>:\d+)*\/(?<shortened>.+)/)?.groups?.shortened ?? link
}

const incidentsCountByCategory = (incidents: Incident[] | undefined | null, category: string) => {
  if (!incidents) {
    return 0
  }

  return incidents.filter(order => order.rootCauseCategory === category).length
}
const dateFromCalendarWeek = (cw: number | undefined | null) => (cw ? moment().day('Monday').week(cw) : null)

const ManageCustomerIncidents: React.FC<ManageCustomerProps> = ({ customer }) => {
  const { email, brand, timezone, id: customerId } = customer
  const { navigateToSearchUrl, parseSearchUrl } = useSearchUrl()
  const { status, rootCauseCategory, calendarWeekFrom, calendarWeekTo, page, pageSize } =
    parseSearchUrl<FilterParams>() || {}
  const cacheKeyAllIncidents = `user-all-incidents-${[email, brand].join('-')}`
  const cacheKeyFilteredFiltered = `user-incidents-${[
    email,
    brand,
    status,
    rootCauseCategory,
    calendarWeekFrom,
    calendarWeekTo,
  ].join('-')}`
  const {
    data: incidentsData,
    isLoading: isIncidentsLoading,
    error: incidentsError,
  } = useQuery(cacheKeyFilteredFiltered, () =>
    incidentCustomerAffectedOrders({
      email,
      brand,
      status,
      rootCauseCategory,
      calendarWeekFrom,
      calendarWeekTo,
    }),
  )
  const {
    data: allIncidentsData,
    isLoading: isAllIncidentsLoading,
    error: allIncidentsError,
  } = useQuery(cacheKeyAllIncidents, () => incidentCustomerAffectedOrders({ email, brand }))
  const {
    data: ordersData,
    loading: isOrdersLoading,
    error: ordersError,
  } = useGetCustomerOrdersTotalCountQuery({ variables: { id: customerId } })
  const availableCatagories = Array.from(new Set(allIncidentsData?.map(incident => incident.rootCauseCategory) ?? []))
  const isStatsLoading = isOrdersLoading || isAllIncidentsLoading || isIncidentsLoading
  const numberOfOrders = ordersData?.customer?.allOrders.totalCount ?? 0
  const incidentsRate = numberOfOrders === 0 ? 0 : (incidentsData?.length ?? 0) / numberOfOrders

  const onSearch = (params: FilterParams) => {
    const { status, rootCauseCategory, calendarWeekFrom, calendarWeekTo, page, pageSize } = params

    void navigateToSearchUrl<FilterParams>({
      status,
      rootCauseCategory,
      calendarWeekFrom,
      calendarWeekTo,
      page,
      pageSize,
    })
  }

  if (incidentsError || ordersError || allIncidentsError) {
    return <LoadComponentError />
  }

  return (
    <>
      <StatsOverview
        title="Incidents Overview"
        description="Ratio of incidents to total orders"
        ratio={incidentsRate}
        loading={isStatsLoading}
        items={availableCatagories.map(category => ({
          title: category,
          value: incidentsCountByCategory(incidentsData, category),
        }))}
      />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <div key={cacheKeyFilteredFiltered} style={{ display: 'flex', gap: 8 }}>
          <Select
            allowClear
            placeholder="Incident status"
            style={{ minWidth: 150 }}
            defaultValue={status}
            onChange={(value: IncidentStatuses) =>
              onSearch({ status: value, rootCauseCategory, calendarWeekFrom, calendarWeekTo })
            }
          >
            <Select.Option value={IncidentStatuses.LOGGED}>Logged</Select.Option>
            <Select.Option value={IncidentStatuses.CLOSED}>Closed</Select.Option>
          </Select>
          <Tooltip title="Affected Delivery Calendar Weeks">
            <DatePicker.RangePicker
              picker="week"
              onChange={values => {
                if (!values) {
                  onSearch({ status, rootCauseCategory })
                } else {
                  onSearch({
                    status,
                    rootCauseCategory,
                    calendarWeekFrom: values[0]?.week(),
                    calendarWeekTo: values[1]?.week(),
                  })
                }
              }}
              value={[dateFromCalendarWeek(calendarWeekFrom), dateFromCalendarWeek(calendarWeekTo)]}
            />
          </Tooltip>
          <Select
            allowClear
            showSearch
            placeholder="Root Cause Category"
            style={{ width: '100%', maxWidth: 330 }}
            defaultValue={rootCauseCategory}
            onChange={(value: string) =>
              onSearch({ status, rootCauseCategory: value, calendarWeekFrom, calendarWeekTo })
            }
          >
            {availableCatagories.map(category => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Table
          data-testid="customer-incidents-table"
          dataSource={incidentsData ?? []}
          rowKey="uuid"
          bordered
          loading={isIncidentsLoading}
          scroll={{ x: 700 }}
          pagination={{
            defaultPageSize: pageSize || 10,
            current: page || 1,
            onChange: (page: number, pageSize: number) =>
              onSearch({ status, rootCauseCategory, calendarWeekFrom, calendarWeekTo, page, pageSize }),
          }}
        >
          <Column
            title="Status"
            dataIndex="status"
            render={(status: IncidentStatuses) => <Tag color={statusToColor[status]}>{status}</Tag>}
          />
          <Column
            title="Logged At"
            dataIndex="loggedAt"
            width={120}
            render={(createdAt: string) => <UserAwareDateWithTz customerTimezone={timezone} datetime={createdAt} />}
          />
          <Column
            title="Order & Delivery CW"
            onCell={() => ({ style: { whiteSpace: 'nowrap' } })}
            render={({ orderNumber, deliveryCalendarWeek }: Incident) => (
              <Space direction="vertical">
                <Link to={generatePath(Routes.ORDER_DETAIL, { number: orderNumber })}>{orderNumber}</Link>
                {deliveryCalendarWeek ? `CW ${deliveryCalendarWeek}` : null}
              </Space>
            )}
          />
          <Column
            title="Issue description"
            dataIndex="issueDescription"
            onCell={() => ({ style: { wordBreak: 'break-all' } })}
          />
          <Column title="Root cause category" dataIndex="rootCauseCategory" />
          <Column title="Mitigative action" dataIndex="mitigativeAction" />
          <Column
            title="Link"
            dataIndex="link"
            render={link => (
              <a href={link} target="_blank" rel="noopener noreferrer">
                {shortenLink(link)}
              </a>
            )}
          />
        </Table>
      </Space>
    </>
  )
}

export default ManageCustomerIncidents
