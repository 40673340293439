import React, { useState, useEffect, useRef } from 'react'
import moment, { Moment } from 'moment'
import { Calendar, Tag, Divider, Alert, Card } from 'antd'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { useGetCustomerDeliveryOptionsQuery } from '../../../apollo/generated/api'

import { mapDeliveryOptions, dateCellRenderer, showExtraShippingPrice } from './helper'
import { SlotSelection, OrderDeliveryCalendarProps } from './types'

const { CheckableTag } = Tag

const DeliverySlots: React.FC<{
  slots: SlotSelection[]
  onChange: (slot?: SlotSelection) => void
  checkedId?: number
}> = ({ slots, onChange, checkedId }) => {
  if (!slots.length) {
    return <Alert message="There are no available slots for this date." type="warning" />
  }

  return (
    <>
      {slots.map(slot => (
        <CheckableTag
          data-testid={`delivery-slot-${slot.id}`}
          style={{ padding: '10px 30px', border: '1px solid #1890ff' }}
          onChange={(checked): void => (checked ? onChange(slot) : undefined)}
          checked={checkedId === slot.id}
          key={slot.id}
        >
          <span>
            {slot.from} - {slot.to}
            &nbsp;({slot.shipper}
            {showExtraShippingPrice(slot.additionalShippingPrice, slot.priceCurrency)})
          </span>
        </CheckableTag>
      ))}
    </>
  )
}

const OrderDeliveryCalendar: React.FC<OrderDeliveryCalendarProps> = ({
  zipcode,
  customerId,
  selectedDate,
  selectedSlotId,
  onChange,
  includeDatesPastCutoff = false,
  suburbId,
}) => {
  const { loading, error, data } = useGetCustomerDeliveryOptionsQuery({
    variables: { zipcode, customerId, includeDatesPastCutoff, suburbId },
  })
  const deliveryOptions = mapDeliveryOptions(data?.customer?.deliveryOptions)
  const flattenedSlots = ([] as SlotSelection[]).concat(...Object.values(deliveryOptions))
  const [deliveryDay, setDeliveryDay] = useState<Moment>(moment(selectedDate))
  const [timeslot, setTimeslot] = useState<SlotSelection | undefined>()
  const availableSlots = deliveryDay ? deliveryOptions[deliveryDay.format('YYYY-MM-DD')] || [] : []

  const changeDeliveryDay = (date: Moment): void => {
    setDeliveryDay(date)
    setTimeslot(undefined)
  }
  const initiaSlotId = useRef<number>()
  const initialSlot = flattenedSlots.find(slot => slot?.id === selectedSlotId)

  useEffect(() => {
    if (initiaSlotId.current !== initialSlot?.id) {
      initiaSlotId.current = initialSlot?.id
      setTimeslot(initialSlot)
    }
  }, [initialSlot])

  useEffect(() => onChange({ date: deliveryDay, timeslot }), [deliveryDay, timeslot, onChange])

  if (loading) return <Loading message="Loading the available delivery dates..." />
  if (error) return <LoadComponentError errorMessage={error.message} />

  return (
    <>
      <div data-testid="delivery-calendar">
        <h3>1. Select the delivery date</h3>

        <Card>
          <Calendar
            defaultValue={selectedDate}
            dateFullCellRender={dateCellRenderer(deliveryOptions)}
            validRange={[moment(), moment().add(2, 'month')]}
            onChange={changeDeliveryDay}
          />
        </Card>
      </div>

      <Divider />

      <div data-testid="delivery-slots">
        <h3 style={{ marginBottom: 20 }}>2. Select the delivery time</h3>

        <DeliverySlots slots={availableSlots} onChange={(slot): void => setTimeslot(slot)} checkedId={timeslot?.id} />
      </div>
    </>
  )
}

export default OrderDeliveryCalendar
