
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ComplaintDetails": [
      "CulinaryComplaint",
      "DamagedIngredientComplaint",
      "FulfillmentCenterComplaint",
      "LogisticsComplaint",
      "OtherComplaint",
      "PackagingAndInsulationComplaint",
      "ProcurementComplaint"
    ],
    "Error": [
      "MutationError"
    ],
    "Option": [
      "AffectedItem",
      "FSQACategoryOption",
      "LogisticsCode",
      "QualityIssue"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ComplaintDetails": [
      "CulinaryComplaint",
      "DamagedIngredientComplaint",
      "FulfillmentCenterComplaint",
      "LogisticsComplaint",
      "OtherComplaint",
      "PackagingAndInsulationComplaint",
      "ProcurementComplaint"
    ],
    "Error": [
      "MutationError"
    ],
    "Option": [
      "AffectedItem",
      "FSQACategoryOption",
      "LogisticsCode",
      "QualityIssue"
    ]
  }
};
      export default result;
    