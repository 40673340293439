import React from 'react'
import { Alert, Card, Descriptions, Table, Typography } from 'antd'
import moment from 'moment'

import Loading from '../../packages/Loading'
import LoadComponentError from '../../packages/LoadComponentError'
import { useGetOrderDeliveryDetailsQuery } from '../../apollo/generated/api'
import {
  DeliveryTracking,
  ShipmentTrackingActivity,
  useGetShipmentTrackingQuery,
} from '../../apollo/generated/logistics'
import type { TrackingParams } from '../../containers/ManageShipmentTrackingContainer'
import TimeWithTimeZone, { DateFormats } from '../Shared/TimeWithTimezone/TimeWithTimezone'
import UserAwareDateWithTz from '../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

const { Column } = Table
const { Text, Link } = Typography
const defaultText = 'N/A'
const unmappedStatus = 'unmapped'
const unmappedStatusDetails = 'no_internal_mapping_for_shipper_status'

const ManageShipmentTracking: React.FC<TrackingParams> = ({ shipmentNumber, number: orderNumber }) => {
  const queryVars = { variables: { shipmentNumber } }
  const { loading, error, data } = useGetShipmentTrackingQuery({ ...queryVars, fetchPolicy: 'no-cache' })
  const { data: OrderData } = useGetOrderDeliveryDetailsQuery({ variables: { number: orderNumber } })
  const deliveryTrackings = data?.deliveryTracking || []
  const order = OrderData?.order
  const delivery = order?.deliveries?.length ? order.deliveries[0] : null

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />

  if (!deliveryTrackings.length) {
    return <Alert type="info" message="No tracking data" description="The shipment has no tracking information." />
  }

  const shipmentLocalTime = (timestamp: string, offset: string) => {
    const momentTimestamp = moment(timestamp)

    return offset ? momentTimestamp.utcOffset(offset) : momentTimestamp
  }

  const relativeTime = (timestamp: string, offset: string) => {
    const localTime = shipmentLocalTime(timestamp, offset)

    return localTime.fromNow()
  }

  const activityAddress = (data: ShipmentTrackingActivity) => {
    return [data.address, data.zipcode, data.city, data.state, data.country].filter(Boolean).join(', ')
  }

  const gmailLink = (email: string) => {
    return `https://mail.google.com/mail/?view=cm&source=mailto&to=${email}`
  }

  const renderShipmentTrackingTable = (shipmentTracking: DeliveryTracking, index: number): JSX.Element => {
    const { shipmentTrackingActivities } = shipmentTracking
    const deliveredActivity = shipmentTrackingActivities?.find((activity): activity is ShipmentTrackingActivity => {
      return activity?.internalStatus === 'delivered'
    })

    return (
      <div data-testid="shipment-tracking-page" key={`shipment-tracking-page-${index}`}>
        <Card title="Shipment Details" data-testid="shipment-tracking-details-card" headStyle={{ textAlign: 'center' }}>
          <Descriptions column={2} bordered>
            {order?.shippingAddress && (
              <>
                <Descriptions.Item label="Customer name">
                  <Text>
                    {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                  </Text>
                </Descriptions.Item>

                <Descriptions.Item label="Customer address">
                  <Text>
                    {order.shippingAddress.fullStreetAddress}, {order.shippingAddress.zipcode},{' '}
                    {order.shippingAddress.city}
                  </Text>
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="Shipment number">
              <Text code copyable>
                {shipmentTracking.shipmentNumber}
              </Text>
            </Descriptions.Item>

            <Descriptions.Item label="Tracking number">
              <Text code copyable>
                {shipmentTracking.trackingNumber}
              </Text>
            </Descriptions.Item>

            <Descriptions.Item label="Customer tracking link">
              {shipmentTracking.trackingLink ? (
                <Link href={shipmentTracking.trackingLink} target="_blank">
                  {shipmentTracking.trackingLink}
                </Link>
              ) : (
                <Text>{defaultText}</Text>
              )}
            </Descriptions.Item>

            {delivery && (
              <>
                <Descriptions.Item label="Delivery date">
                  <TimeWithTimeZone
                    datetime={delivery.date as string}
                    timezone={order?.shippingAddress?.timezone}
                    format={DateFormats.DateText}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Time slot">
                  <Text>
                    {delivery.timeSlot?.from} - {delivery.timeSlot?.to}
                  </Text>
                </Descriptions.Item>
              </>
            )}

            <Descriptions.Item label="Placed location">
              <Text>{deliveredActivity?.boxPlacedLocation || defaultText}</Text>
            </Descriptions.Item>

            <Descriptions.Item label="Received by">
              <Text>{deliveredActivity?.receivedBy || defaultText}</Text>
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <Card title="Shipper Details" data-testid="shipment-tracking-shipper-card" headStyle={{ textAlign: 'center' }}>
          <Descriptions column={2} bordered>
            <Descriptions.Item label="Name">
              <Text>{shipmentTracking.shipperName}</Text>
            </Descriptions.Item>

            <Descriptions.Item label="Phone">
              <Text>{shipmentTracking.shipperPhone || defaultText}</Text>
            </Descriptions.Item>

            <Descriptions.Item label="Email">
              {shipmentTracking.shipperEmail ? (
                <Link href={gmailLink(shipmentTracking.shipperEmail)} target="_blank">
                  {shipmentTracking.shipperEmail}
                </Link>
              ) : (
                <Text>{defaultText}</Text>
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Slack">
              <Text>{shipmentTracking.shipperSlack || defaultText}</Text>
            </Descriptions.Item>

            <Descriptions.Item label="Details">
              <Text style={{ whiteSpace: 'pre-line' }}>{shipmentTracking.shipperInformation || defaultText}</Text>
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <Card title="Track & Trace" id="shipment-tracking-status-card" headStyle={{ textAlign: 'center' }}>
          <Table
            data-testid="shipment-tracking-activities-table"
            dataSource={(shipmentTrackingActivities || []) as ShipmentTrackingActivity[]}
            rowKey="timestamp"
            bordered
            pagination={false}
          >
            <Column
              title="Date and Time"
              render={(_, { timestamp, timezoneOffset }: ShipmentTrackingActivity) => (
                <>
                  <UserAwareDateWithTz
                    datetime={timestamp as string}
                    customerTimezone={order?.shippingAddress?.timezone}
                  />
                  <small>{relativeTime(timestamp as string, timezoneOffset as string)}</small>
                </>
              )}
            />

            <Column
              title="Shipper status"
              render={(_, { shipperStatus }: ShipmentTrackingActivity): JSX.Element => (
                <Text>{shipperStatus || defaultText}</Text>
              )}
            />

            <Column
              title="Internal status"
              render={(_, { internalStatus }: ShipmentTrackingActivity): JSX.Element => (
                <Text>{internalStatus && internalStatus !== unmappedStatus ? internalStatus : defaultText}</Text>
              )}
            />

            <Column
              title="Status details"
              render={(_, { internalStatusDetails }: ShipmentTrackingActivity): JSX.Element => (
                <Text>
                  {internalStatusDetails && internalStatusDetails !== unmappedStatusDetails
                    ? internalStatusDetails
                    : defaultText}
                </Text>
              )}
            />

            <Column
              title="Location"
              render={(_, activity: ShipmentTrackingActivity): JSX.Element => (
                <Text>{activityAddress(activity) || defaultText}</Text>
              )}
            />
          </Table>
        </Card>
      </div>
    )
  }

  return (
    <>
      {deliveryTrackings.map((tracking, index) => {
        return renderShipmentTrackingTable(tracking as DeliveryTracking, index)
      })}
    </>
  )
}

export default ManageShipmentTracking
