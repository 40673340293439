import styled from 'styled-components'
import { Avatar as AntdAvatar } from 'antd'

export const HEADER = {
  padding: '0 20px',
}

export const Avatar = styled(AntdAvatar)`
  border: 2px #1990ff solid;
  cursor: pointer;

  img {
    border-radius: 250px;
    padding: 2px;
  }
`

export const User = styled.div`
  text-align: right;

  span {
    display: block;
    margin-right: 20px;

    &:first-child {
      margin-top: 16px;
    }

    &:last-child {
      color: #bbb;
      font-size: 12px;
      margin-top: 5px;
    }
  }

  line-height: 1em;
`
