/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { Alert, Button, Card, Col, Form, Row, Select } from 'antd'
import { Store } from 'antd/lib/form/interface'
import moment from 'moment'
import { UploadFile } from 'antd/lib/upload/interface'

import {
  AffectedAddOn,
  AffectedIngredient,
  AffectedRecipe,
  ComplaintCategory,
  ComplaintLevel,
  ComplaintSubcategory,
  Scope,
  useGetComplaintCategoriesQuery,
  useGetComplaintQuery,
  useUpdateComplaintMutation,
} from '../../../apollo/generated/beef'
import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import ManageComplaintForm, { required } from '../ManageComplaintForm'
import { handleBeefMutationResult } from '../../../apollo'
import { UPDATE_COMPLAINT_FAILURE, UPDATE_COMPLAINT_SUCCESS } from '../../../constants/MessagesConstants'
import { Routes } from '../../../constants/RoutesConstants'
import { useGetOrderManagementDetailsLazyQuery } from '../../../apollo/generated/api'
import { ScopeNames } from '../scopes'
import { COOKBOOK_USER_SESSION } from '../../../constants/AuthConstants'
import { getUserSession } from '../../../utils'

const { Option } = Select
const LogisticsComplaintType = 'LogisticsComplaint'

const ManageComplaintEdit: React.FC = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const { complaintId } = useParams<{ complaintId: string }>()
  const [scope, setScope] = useState<ScopeNames | null>()
  const { loading, error, data } = useGetComplaintQuery({ variables: { id: complaintId }, fetchPolicy: 'no-cache' })
  const [getOrder, { data: orderData }] = useGetOrderManagementDetailsLazyQuery()
  const { loading: loadingCategories, error: errorCategories, data: categoriesData } = useGetComplaintCategoriesQuery()
  const [updateComplaint, { loading: updatingComplaint }] = useUpdateComplaintMutation()
  const complaint = data?.complaint
  const categories = categoriesData?.complaintCategories
  const [subcategory, setSubcategory] = useState<ComplaintSubcategory | undefined>()
  const [category, setCategory] = useState<ComplaintCategory | undefined>()

  useEffect(() => {
    if (!complaint) return

    let scopeName: ScopeNames | null = null

    switch (complaint.level) {
      case ComplaintLevel.Recipe:
        scopeName = ScopeNames.Recipes
        break
      case ComplaintLevel.Ingredient:
        scopeName = ScopeNames.Ingredients
        break
      case ComplaintLevel.WholeDelivery:
        scopeName = ScopeNames.WholeDelivery
        break
      case ComplaintLevel.Fruitbox:
        scopeName = ScopeNames.Fruitbox
        break
      case ComplaintLevel.AddOn:
        scopeName = ScopeNames.AddOn
        break
      default:
        break
    }

    if (complaint.orderNumber) {
      void getOrder({ variables: { number: complaint.orderNumber } })
    }

    const selectedCategory = categories?.find(({ id }) => id === complaint.category.id) as ComplaintCategory | undefined
    const selectedSubcategory = selectedCategory?.subcategories.find(({ id }) => id === complaint.subcategory.id)

    setScope(scopeName)
    setCategory(selectedCategory)
    setSubcategory(selectedSubcategory)
  }, [complaint, categories])

  if (loading) return <Loading message="Hang tight ..." />
  if (error && errorCategories) return <LoadComponentError />

  const resolved = !!complaint?.resolvedAt

  if (!complaint) return <Alert type="error" message="Couldn't find this complaint." />
  if (!category) return <Alert type="error" message="Couldn't set category." />
  if (!subcategory) return <Alert type="error" message="Couldn't set sub-category." />

  const getScope = (scopeName: string): Scope | undefined => {
    return subcategory.scopes.find(({ name }) => name.toLowerCase() === scopeName.toLowerCase())
  }

  const pickScope = () => {
    switch (complaint.level) {
      case ComplaintLevel.Recipe:
        return getScope(ScopeNames.Recipes)?.id
      case ComplaintLevel.Ingredient:
        return getScope(ScopeNames.Ingredients)?.id
      case ComplaintLevel.WholeDelivery:
        return getScope(ScopeNames.WholeDelivery)?.id
      case ComplaintLevel.Fruitbox:
        return getScope(ScopeNames.Fruitbox)?.id
      case ComplaintLevel.AddOn:
        return getScope(ScopeNames.AddOn)?.id
      default:
        return 0
    }
  }
  const affectedRecipes: Pick<AffectedRecipe, 'id' | 'name'>[] = complaint.affectedRecipes.map(
    ({ externalId, name }) => ({ id: externalId, name }),
  )
  const recipes = complaint.affectedRecipes.map(({ externalId }) => Number(externalId))
  const affectedIngredients: Pick<AffectedIngredient, 'id' | 'name'>[] = complaint.affectedIngredients.map(
    ({ externalId, name }) => ({ id: externalId, name }),
  )
  const ingredients = complaint.affectedIngredients.map(({ externalId }) => Number(externalId))
  const affectedAddOns: string[] = complaint.affectedAddOns.map(({ externalId, name }) => `${externalId}|${name}`)
  const initialValues = {
    ...complaint,
    categoryId: complaint.category.id,
    subCategoryId: subcategory.id,
    complaintScope: pickScope(),
    recipes,
    affectedRecipes,
    ingredients,
    affectedIngredients,
    affectedAddOns,
    recurring: complaint.recurring,
    compensationValue: complaint.compensationAmount,
    grantedValue: complaint.compensationGranted,
    receivedAt: moment(complaint.receivedAt, 'YYYY-MM-DD'),
    affectedItem: complaint.affectedItems[0]?.id,
    fsqaCategories: complaint.fsqaCategories.map(({ id }) => id),
    qualityIssue: complaint.qualityIssues.map(({ id }) => id),
    reasonId: complaint.externalCreditReasonId,
    attachments: null,
    existingAttachments: complaint.attachments,
    removedAttachments: [],
    ...(complaint.complaintDetails.__typename === LogisticsComplaintType
      ? {
          trackingNumber: complaint.complaintDetails.trackingNumber,
          logisticsCode: complaint.complaintDetails.logisticsCode,
        }
      : null),
  }

  const readFile = (file: UploadFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      if (file.originFileObj) {
        reader.readAsDataURL(file.originFileObj)
        reader.onloadend = () => {
          const base64data = reader.result

          if (base64data) {
            resolve(base64data.toString())
          }
          reader.onerror = reject
        }
      } else {
        resolve('')
      }
    })
  }

  const handleAttachments = async ({ fileList }: { fileList: UploadFile[] }): Promise<string[]> => {
    return Promise.all(fileList.map(file => readFile(file)))
  }

  const handleSubmit = async (values: Store): Promise<void> => {
    const wholeDelivery = getScope(ScopeNames.WholeDelivery)?.id === values.complaintScope
    const fruitbox = getScope(ScopeNames.Fruitbox)?.id === values.complaintScope
    const addOn = getScope(ScopeNames.AddOn)?.id === values.complaintScope
    const affectedRecipes = form.getFieldValue('affectedRecipes')
    const affectedIngredients: AffectedIngredient[] = form.getFieldValue('affectedIngredients')
    const affectedAddOns: AffectedAddOn[] = form.getFieldValue('affectedAddOns').map((value: string) => {
      const [addOnId, addOnName] = value.split('|')

      return {
        id: Number(addOnId),
        name: addOnName,
      }
    })
    const removedAttachments = form.getFieldValue('removedAttachments') || []
    const {
      affectedItem,
      fsqaCategories,
      grantedValue,
      compensationValue,
      qualityIssue,
      recipes,
      logisticsCode,
      shippingPartner,
      trackingNumber,
      receivedAt,
      attachments,
      reasonId,
      fsqaDetails,
    } = values
    const compensationAmount = Number(compensationValue || '0')
    const compensationGranted = Number(grantedValue || '0')
    const { warmProtein, boxIcePackDamaged, receivedWithinTimeWindow, numIcePacks, other } = fsqaDetails || {}
    const suggestedCompensationAmount = form.getFieldValue('suggestedCompensationAmount')

    if (receivedWithinTimeWindow === false) {
      await Promise.reject()
    }

    const variables = {
      id: complaint.id,
      complaint: {
        createAdjustmentOrCredit: (compensationAmount || compensationGranted) !== 0,
        authToken: getUserSession(COOKBOOK_USER_SESSION).session?.token.value,
        categoryId: values.subCategoryId,
        description: values.description,
        foodSafetyIssue: values.foodSafetyIssue,
        attachments: attachments ? await handleAttachments(attachments) : [],
        orderNumber: complaint.orderNumber,
        userId: `${orderData?.order?.customer?.id}`,
        deleteAttachments: removedAttachments,
        recurring: values.recurring,
        wholeDelivery,
        fruitbox,
        addOn,
        receivedAt: moment(receivedAt).format('YYYY-MM-DD'),
        externalCreditReasonId: reasonId,
        compensationAmount,
        compensationGranted,
        suggestedCompensationAmount,
        details: {
          ...(affectedItem ? { affectedItem: [affectedItem] } : null),
          ...(fsqaCategories ? { fsqaCategories } : null),
          ...(affectedIngredients.length ? { affectedIngredients } : null),
          ...(affectedAddOns.length ? { affectedAddOns } : null),
          ...(logisticsCode ? { logisticsCode } : null),
          ...(recipes ? { affectedRecipes } : null),
          ...(shippingPartner ? { shippingPartner } : null),
          ...(trackingNumber ? { trackingNumber } : null),
          ...(qualityIssue ? { qualityIssue } : null),
          fsqaComplaintDetails: {
            warmProtein: warmProtein ?? null,
            boxIcePackDamaged: boxIcePackDamaged ?? null,
            receivedWithinTimeWindow: receivedWithinTimeWindow ?? null,
            numIcePacks: numIcePacks ?? null,
            other: other ?? null,
          },
        },
      },
    }
    const mutation = updateComplaint({ variables })
    const { data } = await handleBeefMutationResult(mutation, 'updateComplaint', {
      notifications: {
        success: {
          title: UPDATE_COMPLAINT_SUCCESS,
        },
        error: {
          title: UPDATE_COMPLAINT_FAILURE,
        },
      },
    })

    if (data?.updateComplaint?.complaint?.__typename === 'Complaint') {
      push(generatePath(Routes.ORDER_COMPLAINTS, { number: complaint.orderNumber }))
    }
  }

  const handleChange = (values: Store) => {
    const keys = Object.keys(values)
    const isComplaintScope = keys.includes('complaintScope')
    const isSubCategory = keys.includes('subCategoryId')

    if (isComplaintScope) {
      const scope = subcategory.scopes.find(({ id }) => id === values.complaintScope)

      setScope(scope?.name as ScopeNames)
    }

    if (isSubCategory) {
      const subcategory = category.subcategories.find(({ id }) => id === values.subCategoryId)

      // If only 1 scope available, pre-select it
      if (subcategory?.scopes.length === 1) {
        const [scope] = subcategory.scopes

        form.setFieldsValue({ complaintScope: scope.id })
        setScope(scope.name as ScopeNames)
      }

      setSubcategory(subcategory as ComplaintSubcategory)
    }
  }

  // Remove fruitbox scope for countries that do not have it enabled
  const filterScopes = (scopes: Scope[]): Scope[] => {
    const fruitboxDisabled = orderData?.order?.customer?.userPlan?.fruitBox.available || false

    return fruitboxDisabled ? scopes : scopes.filter(scope => scope.name !== 'Fruitbox')
  }
  const editableSubcategory = !resolved

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onValuesChange={handleChange}
    >
      <Card
        title={`You're editing a complaint for order ${complaint.orderNumber}`}
        loading={loadingCategories}
        headStyle={{ textAlign: 'center' }}
        style={{ marginBottom: 35, boxShadow: '0 15px 15px rgba(0, 0, 0, .05)' }}
      >
        <Row gutter={32} justify="center">
          <Col span={8}>
            <ManageComplaintForm.ReceivedAt />
          </Col>

          <Col span={8}>
            <ManageComplaintForm.Category
              options={categories as ComplaintCategory[]}
              loading={loadingCategories}
              disabled
            />
          </Col>

          <Col span={8}>
            <Form.Item name="subCategoryId" label="Pick a sub-category" rules={[required]}>
              <Select
                data-testid="subcategories-dropdown"
                placeholder="Pick any sub-category"
                disabled={!editableSubcategory}
              >
                {category.subcategories.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <ManageComplaintForm.Scopes options={filterScopes(subcategory.scopes)} />

      {scope === ScopeNames.Recipes && <ManageComplaintForm.Recipes orderNumber={complaint.orderNumber} form={form} />}
      {scope === ScopeNames.Ingredients && (
        <ManageComplaintForm.Ingredients orderNumber={complaint.orderNumber} form={form} />
      )}
      {scope === ScopeNames.AddOn && <ManageComplaintForm.AddOns orderNumber={complaint.orderNumber} />}

      <ManageComplaintForm.Rules
        rules={subcategory.rules}
        form={form}
        orderNumber={complaint.orderNumber || ''}
        category={category as ComplaintCategory}
        subcategory={subcategory}
        recurring={complaint.recurring}
        scope={scope}
      />

      <Row gutter={16} justify="center" style={{ marginTop: 50 }}>
        <Col xl={12} xxl={6}>
          <Button
            data-testid="submit-update-complaint"
            htmlType="submit"
            type="primary"
            disabled={updatingComplaint}
            loading={updatingComplaint}
            size="large"
            block
          >
            {resolved ? 'Update complaint' : 'Resolve Complaint'}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default ManageComplaintEdit
