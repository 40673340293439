import React from 'react'
import { Table, PageHeader, Typography, Tag } from 'antd'
import { Link, generatePath, RouteComponentProps } from 'react-router-dom'

import { headerIcons } from '../../constants/IconsConstants'
import { Routes } from '../../constants/RoutesConstants'
import Loading from '../../packages/Loading'
import GeneralError from '../../packages/GeneralError'
import { filterMaybe } from '../../utils/typeUtils'
import CountryFlag from '../../packages/CountryFlag'
import BrandIcon from '../../packages/BrandIcon'
import {
  BrandEnum,
  Country,
  Membership,
  MembershipStatusEnum,
  useSearchCustomerQuery,
} from '../../apollo/generated/api'
import MembershipLabel from '../MembershipLabel'
import UserAwareDateWithTz from '../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

import SearchCustomerEmailResultsEmpty from './SearchCustomerEmailResultsEmpty'

const { Text } = Typography
const anonymisedEmailPattern = '.com.invalid'
const columns = [
  {
    title: 'Brand',
    render: ({ brand }: { brand: BrandEnum }): JSX.Element => <BrandIcon brand={brand} />,
    width: 70,
  },
  {
    title: 'Country',
    render: ({ country }: { country: Country }): JSX.Element => <CountryFlag country={country.iso} />,
  },
  {
    title: 'Email address',
    key: 'email',
    dataIndex: 'email',
    render: (value: string): JSX.Element => (
      <div>
        {value.search(anonymisedEmailPattern) > 0 ? (
          <Tag color="red" data-testid="anonymised-icon">
            Anonymised Account
          </Tag>
        ) : (
          <Text copyable>{value}</Text>
        )}
      </div>
    ),
  },
  {
    title: 'Membership Status',
    key: 'status',
    render: ({ membership, timezone }: { membership: Membership; timezone?: string | null }): JSX.Element => (
      <MembershipLabel
        status={membership.status || MembershipStatusEnum.None}
        pausedUntil={membership.pausedUntil}
        customerTimezone={timezone}
      />
    ),
  },
  {
    title: 'Member Since',
    render: ({ membership, timezone }: { membership: Membership; timezone?: string }): JSX.Element =>
      membership.startedAt ? (
        <UserAwareDateWithTz datetime={membership.startedAt} customerTimezone={timezone} />
      ) : (
        <span>-</span>
      ),
  },
  {
    title: 'Action',
    key: 'action',
    render: ({ id }: { id: number }): JSX.Element => (
      <span>
        <Link to={generatePath(Routes.CUSTOMER_DETAIL, { id })}>Manage customer</Link>
      </span>
    ),
  },
]

const SearchCustomerEmailResults: React.FC<
  RouteComponentProps<{ email: string; returnAnonymizedResults?: string }>
> = ({
  match: {
    params: { email, returnAnonymizedResults },
  },
  history: { goBack },
}) => {
  const queryVars = {
    variables: { emailStartsWith: email, returnAnonymizedResults: returnAnonymizedResults?.toLowerCase() === 'true' },
  }
  const { loading, error, data } = useSearchCustomerQuery({ ...queryVars })
  const results = (data?.customerSearch || []).filter(filterMaybe)

  if (loading) return <Loading />
  if (error) return <GeneralError message={error.message} />
  if (!results.length) return <SearchCustomerEmailResultsEmpty searchParam={email} />

  return (
    <>
      <PageHeader
        title={`Search results for: "${email}"`}
        avatar={{
          src: headerIcons.CUSTOMERS_LIST,
          shape: 'square',
        }}
        onBack={(): void => goBack()}
      />

      <Table data-testid="search-customers-email-results-table" dataSource={results} columns={columns} rowKey="id" />
    </>
  )
}

export default SearchCustomerEmailResults
