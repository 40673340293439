import styled from 'styled-components'

export const Logo = styled.div`
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: rgba(255, 255, 255, 0.9);
  margin: 20px 20px 10px;
  font-size: 17px;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 7px 0 5px;
  border-radius: 6px;
  ${<T extends { theme?: string }>({ theme }: T): string => (theme === 'light' ? 'color: #00152a;' : '')}
`
