import React, { useState } from 'react'
import { Form, Row, Col, Card, Button, notification, Alert } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { ButtonSize } from 'antd/lib/button'
import { useHistory, generatePath, Link } from 'react-router-dom'
import moment from 'moment'
import { UploadFile } from 'antd/lib/upload/interface'

import {
  useGetComplaintCategoriesQuery,
  ComplaintSubcategory,
  useCreateComplaintMutation,
  useGetRecurringSubcategoriesQuery,
  CountryEnum,
  BrandEnum,
  ComplaintCategory,
  Scope,
  AffectedIngredient,
  AffectedAddOn,
  CreateComplaintInputType,
  useGetOrderComplaintsQuery,
} from '../../../apollo/generated/beef'
import { useGetOrderManagementDetailsQuery } from '../../../apollo/generated/api'
import { handleBeefMutationResult } from '../../../apollo'
import { CREATE_COMPLAINT_SUCCESS, CREATE_COMPLAINT_FAILURE, KEEP_GOING } from '../../../constants/MessagesConstants'
import { Routes } from '../../../constants/RoutesConstants'
import ManageComplaintForm from '../ManageComplaintForm'
import { ScopeNames } from '../scopes'
import { COOKBOOK_USER_SESSION } from '../../../constants/AuthConstants'
import { getUserSession } from '../../../utils'

const ManageComplaintCreate: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const { push } = useHistory()
  const { data: categoriesData, loading: loadingCategories } = useGetComplaintCategoriesQuery()
  const { data: orderData } = useGetOrderManagementDetailsQuery({ variables: { number: orderNumber } })
  const { data: recurringSubcategoriesData } = useGetRecurringSubcategoriesQuery({
    variables: { userId: orderData?.order?.customer?.id?.toString() as string },
    fetchPolicy: 'no-cache',
  })
  const { data: complaintData } = useGetOrderComplaintsQuery({
    variables: { orderNumber },
    fetchPolicy: 'no-cache',
  })
  const [category, setCategory] = useState<ComplaintCategory>()
  const [subcategory, setSubcategory] = useState<ComplaintSubcategory>()
  const [scope, setScope] = useState<ScopeNames>()
  const [redirect, setRedirect] = useState(true)
  const [showCategories, setShowCategories] = useState(true)
  const [recurring, setRecurring] = useState(false)
  const [form] = Form.useForm()
  const [createComplaint, { loading: creatingComplaint }] = useCreateComplaintMutation()
  const submitButtonProps = {
    disabled: creatingComplaint,
    loading: creatingComplaint,
    size: 'large' as ButtonSize,
    block: true,
  }

  const resetForm = (values?: Store): void => {
    form.resetFields()
    form.setFieldsValue(values)
    setSubcategory(undefined)
    setScope(undefined)
  }

  const handleAllSubmits = (redirect: boolean) => {
    setRedirect(redirect)

    form.submit()
  }

  const readFile = (file: UploadFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      if (file.originFileObj) {
        reader.readAsDataURL(file.originFileObj)
        reader.onloadend = () => {
          const base64data = reader.result

          if (base64data) {
            resolve(base64data.toString())
          }
          reader.onerror = reject
        }
      } else {
        resolve('')
      }
    })
  }

  const handleAttachments = async ({ fileList }: { fileList: UploadFile[] }): Promise<string[]> => {
    return Promise.all(fileList.map(file => readFile(file)))
  }

  const handleSubmit = async (values: Store): Promise<void> => {
    const wholeDelivery = scope === ScopeNames.WholeDelivery
    const fruitbox = scope === ScopeNames.Fruitbox
    const addOn = scope === ScopeNames.AddOn
    const productionDate = orderData?.order?.productionInfo?.productionDate
    const affectedRecipes = form.getFieldValue('affectedRecipes')
    const affectedIngredients: AffectedIngredient[] = form.getFieldValue('affectedIngredients')
    const affectedAddOns: AffectedAddOn[] = form.getFieldValue('affectedAddOns').map((value: string) => {
      const [addOnId, addOnName] = value.split('|')

      return {
        id: Number(addOnId),
        name: addOnName,
      }
    })
    const suggestedCompensationAmount = form.getFieldValue('suggestedCompensationAmount')
    const {
      affectedItem,
      fsqaCategories,
      grantedValue,
      compensationValue,
      qualityIssue,
      recipes,
      logisticsCode,
      shippingPartner,
      trackingNumber,
      receivedAt,
      recurringIssue,
      attachments,
      reasonId,
      fsqaDetails,
    } = values
    const compensationAmount = Number(compensationValue || '0')
    const compensationGranted = Number(grantedValue || '0')
    const { warmProtein, boxIcePackDamaged, receivedWithinTimeWindow, numIcePacks, other } = fsqaDetails || {}

    if (receivedWithinTimeWindow === false) {
      try {
        await Promise.reject()
      } catch {
        notification.error({ message: 'Check your inputs' })
      }
    }

    const variables: { complaint: CreateComplaintInputType } = {
      complaint: {
        createAdjustmentOrCredit: (compensationAmount || compensationGranted) !== 0,
        authToken: getUserSession(COOKBOOK_USER_SESSION).session?.token.value,
        brand: orderData?.order?.brand as BrandEnum,
        categoryId: values.subCategoryId,
        country: orderData?.order?.country as CountryEnum,
        plannedDeliveryDate: orderData?.order?.deliveryDate,
        description: values.description,
        foodSafetyIssue: values.foodSafetyIssue,
        attachments: attachments ? await handleAttachments(attachments) : [],
        orderNumber,
        userId: `${orderData?.order?.customer?.id}`,
        userEmail: `${orderData?.order?.customer?.email || 'missing@email.com'}`,
        wholeDelivery,
        fruitbox,
        addOn,
        receivedAt: moment(receivedAt).format('YYYY-MM-DD'),
        ...(recurringIssue ? { recurring: recurringIssue } : null),
        productionSite: `${orderData?.order?.productionInfo?.productionSite}`,
        planType: `${orderData?.order?.plan?.displayTitle}`,
        ...(productionDate ? { productionDate } : null),
        zipcode: `${orderData?.order?.shippingAddress?.zipcode}`,
        externalCreditReasonId: reasonId,
        compensationAmount,
        compensationGranted,
        suggestedCompensationAmount,
        details: {
          ...(affectedItem ? { affectedItem: [affectedItem] } : null),
          ...(fsqaCategories ? { fsqaCategories } : null),
          ...(affectedIngredients.length ? { affectedIngredients } : null),
          ...(affectedAddOns.length ? { affectedAddOns } : null),
          ...(logisticsCode ? { logisticsCode } : null),
          ...(recipes ? { affectedRecipes } : null),
          ...(shippingPartner ? { shippingPartner } : null),
          ...(trackingNumber ? { trackingNumber } : null),
          ...(qualityIssue ? { qualityIssue } : null),
          fsqaComplaintDetails: {
            warmProtein: warmProtein ?? null,
            boxIcePackDamaged: boxIcePackDamaged ?? null,
            receivedWithinTimeWindow: receivedWithinTimeWindow ?? null,
            numIcePacks: numIcePacks ?? null,
            other: other ?? null,
          },
        },
      },
    }
    const mutation = createComplaint({ variables })
    const { data } = await handleBeefMutationResult(mutation, 'createComplaint', {
      notifications: {
        error: {
          title: CREATE_COMPLAINT_FAILURE,
        },
      },
    })

    if (data?.createComplaint?.complaint?.__typename === 'Complaint') {
      notification.success({
        message: CREATE_COMPLAINT_SUCCESS,
        description: KEEP_GOING,
      })

      resetForm()

      if (redirect) {
        push(generatePath(Routes.ORDER_COMPLAINTS, { number: orderNumber }))
      }
    }
  }

  const handleChange = (values: Store) => {
    const keys = Object.keys(values)
    const isCategory = keys.includes('categoryId')
    const isSubCategory = keys.includes('subCategoryId')
    const isComplaintScope = keys.includes('complaintScope')
    const isReceivedAt = keys.includes('receivedAt')

    if (isCategory) {
      resetForm({ categoryId: values.categoryId, receivedAt: form.getFieldValue('receivedAt'), recurringIssue: false })

      const category = categoriesData?.complaintCategories?.find(({ id }) => id === values.categoryId)

      setCategory(category as ComplaintCategory | undefined)
    }

    if (isSubCategory) {
      const subcategory = category?.subcategories.find(({ id }) => id === values.subCategoryId)

      // If only 1 scope available, pre-select it
      if (subcategory?.scopes.length === 1) {
        const [scope] = subcategory.scopes

        form.setFieldsValue({ complaintScope: scope.id })
        setScope(scope.name as ScopeNames)
      }

      // If selected subcategory is part of the previously logged
      // subcategories, it is a recurring issue.
      const isRecurring =
        recurringSubcategoriesData?.recurringSubcategories?.edges?.some(
          recurringSubcategory => recurringSubcategory?.node?.id === subcategory?.id,
        ) || false

      setRecurring(isRecurring)
      form.setFieldsValue({ recurringIssue: isRecurring })
      setSubcategory(subcategory)
    }

    if (isComplaintScope) {
      const scope = subcategory?.scopes.find(({ id }) => id === values.complaintScope)

      setScope(scope?.name as ScopeNames | undefined)
    }

    if (isReceivedAt) {
      resetForm({ receivedAt: values.receivedAt })
      setShowCategories(values.receivedAt !== null)
    }
  }

  // Remove fruitbox scope for countries that do not have it enabled
  const filterScopes = (scopes: Scope[]): Scope[] => {
    const fruitboxDisabled = orderData?.order?.customer?.userPlan?.fruitBox.available || false

    return fruitboxDisabled ? scopes : scopes.filter(scope => scope.name !== 'Fruitbox')
  }
  const unresolvedComplaints = (complaintData?.orderComplaints.edges || [])
    .map(edge => (edge?.node?.resolvedAt === null ? edge.node : null))
    .filter(complaint => complaint !== null)

  return (
    <Form
      form={form}
      initialValues={{
        foodSafetyIssue: false,
        affectedRecipes: [],
        affectedIngredients: [],
        affectedAddOns: [],
      }}
      onFinish={handleSubmit}
      layout="vertical"
      onValuesChange={handleChange}
    >
      {unresolvedComplaints.length > 0 && (
        <Alert
          message="Warning"
          description={
            <>
              This order has an unresolved complaint. Please check the list of{' '}
              <Link to={generatePath(Routes.ORDER_COMPLAINTS, { number: orderNumber })}>logged complaints</Link> before
              proceeding.
            </>
          }
          type="warning"
          showIcon
          style={{ marginBottom: 35 }}
        />
      )}
      <Card
        title="Let us get started"
        loading={loadingCategories}
        headStyle={{ textAlign: 'center' }}
        style={{ marginBottom: 35, boxShadow: '0 15px 15px rgba(0, 0, 0, .05)' }}
      >
        <Row gutter={32} justify="center">
          <Col span={8}>
            <ManageComplaintForm.ReceivedAt />
          </Col>

          {showCategories ? (
            <>
              <Col span={8}>
                <ManageComplaintForm.Category
                  options={categoriesData?.complaintCategories as ComplaintCategory[]}
                  loading={loadingCategories}
                />
              </Col>

              <Col span={8}>
                <ManageComplaintForm.Subcategory options={category?.subcategories} disabled={!category} />
              </Col>
            </>
          ) : null}
        </Row>
      </Card>

      {subcategory?.scopes.length && <ManageComplaintForm.Scopes options={filterScopes(subcategory.scopes)} />}

      {scope === ScopeNames.Recipes && <ManageComplaintForm.Recipes orderNumber={orderNumber} form={form} />}
      {scope === ScopeNames.Ingredients && <ManageComplaintForm.Ingredients orderNumber={orderNumber} form={form} />}
      {scope === ScopeNames.AddOn ? <ManageComplaintForm.AddOns orderNumber={orderNumber} /> : null}

      {subcategory?.rules.length && (
        <ManageComplaintForm.Rules
          orderNumber={orderNumber}
          category={category}
          subcategory={subcategory}
          form={form}
          rules={subcategory.rules}
          recurring={recurring}
          scope={scope}
        />
      )}

      <Row gutter={16} justify="center" style={{ marginTop: 50 }}>
        <Col xl={12} xxl={6}>
          <Button {...submitButtonProps} data-testid="submit-and-fill-another" onClick={() => handleAllSubmits(false)}>
            Submit and fill another one
          </Button>
        </Col>

        <Col xl={12} xxl={6}>
          <Button {...submitButtonProps} data-testid="submit" type="primary" onClick={() => handleAllSubmits(true)}>
            Submit complaint
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default ManageComplaintCreate
