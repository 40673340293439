import React, { createContext, useState, useEffect } from 'react'

import { THEME, LIGHT_THEME, DARK_THEME } from '../../constants/AppConstants'

export type ThemeContextState = {
  theme: string
  switchTheme: () => void
}

const getSystemTheme = () =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? DARK_THEME : LIGHT_THEME
const contextDefaultValues: ThemeContextState = {
  theme: localStorage.getItem(THEME) || getSystemTheme(),
  switchTheme: () => {
    return null
  },
}

export const ThemeContext = createContext<ThemeContextState>(contextDefaultValues)

const ThemeContextProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState(contextDefaultValues.theme)
  const getNextTheme = () => (theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME)

  useEffect(() => {
    document.querySelector('html')?.classList.remove(getNextTheme())
    document.querySelector('html')?.classList.add(theme)
  }, [theme])

  const switchTheme = () => {
    setTheme(() => getNextTheme())
    localStorage.setItem(THEME, getNextTheme())
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <ThemeContext.Provider value={{ theme, switchTheme }}>{children}</ThemeContext.Provider>
}

export default ThemeContextProvider
