import React from 'react'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'

import { getUserSession, destroyUserSession, SessionMessages } from '../../../utils'
import { Routes } from '../../../constants/RoutesConstants'
import { REDIRECT_PATH } from '../../../constants/AppConstants'
import MainContainer from '../../../containers/MainContainer'
import { UserRole } from '../../../utils/authUtils/authUtils'
import NotFoundError from '../../../packages/UnauthorizedComponentError'
import { BEEF_USER_SESSION, COOKBOOK_USER_SESSION } from '../../../constants/AuthConstants'

const PrivateRoute: React.FC<RouteProps & { roles?: UserRole[] }> = props => {
  const cookbookSession = getUserSession(COOKBOOK_USER_SESSION)
  const beefSession = getUserSession(BEEF_USER_SESSION)
  const isAuthenticated = cookbookSession.hasValidSession() && beefSession.hasValidSession()
  const { roles } = props
  const { pathname } = useLocation()

  if (!isAuthenticated) {
    destroyUserSession()

    localStorage.setItem(REDIRECT_PATH, pathname)

    return (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          state: { error: SessionMessages.InvalidUserSession },
        }}
      />
    )
  }

  if (!!roles && !roles.every((role: UserRole): boolean => cookbookSession.hasRole(role))) {
    return <NotFoundError />
  }

  return (
    <MainContainer>
      <Route {...props}>{props.children}</Route>
    </MainContainer>
  )
}

export default PrivateRoute
