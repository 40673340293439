import React, { FunctionComponent } from 'react'
import { RouteComponentProps, generatePath } from 'react-router'
import { Card, Input, Button, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { HomeOutlined } from '@ant-design/icons'

import { Routes } from '../../../constants/RoutesConstants'

const cardProps = {
  headStyle: { fontWeight: 600 },
}

const SearchCustomerAddressForm: FunctionComponent<Pick<RouteComponentProps, 'history'>> = ({ history }) => {
  const handleSubmit = ({ customerAddress }: Store): void => {
    history.push(generatePath(Routes.CUSTOMER_ADDRESS_SEARCH_RESULTS, { query: customerAddress }))
  }

  return (
    <Card title="Search a customer by address" {...cardProps}>
      <Form name="searchCustomerByAddress" data-qa="search-customer-address-form" onFinish={handleSubmit}>
        <Form.Item name="customerAddress" hasFeedback rules={[{ required: true, message: 'Address is required' }]}>
          <Input
            data-qa="customer-address-input"
            placeholder="Full or partial address"
            prefix={<HomeOutlined />}
            size="large"
          />
        </Form.Item>
        <Button data-qa="search-customer-address-submit" type="primary" htmlType="submit" size="large" block>
          Search address
        </Button>
      </Form>
    </Card>
  )
}

export default SearchCustomerAddressForm
