/* eslint-disable max-len */
import React from 'react'

const EmailOpenIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25862 7.75854C2.08722 7.75854 1.94828 7.89379 1.94828 8.06064V16.7881C1.94828 16.9549 2.08722 17.0902 2.25862 17.0902H16.7414C16.9128 17.0902 17.0517 16.9549 17.0517 16.7881V8.06064C17.0517 7.89379 16.9128 7.75854 16.7414 7.75854H14.5575V6.34872H16.7414C17.7126 6.34872 18.5 7.11518 18.5 8.06064V16.7881C18.5 17.7335 17.7126 18.5 16.7414 18.5H2.25862C1.28736 18.5 0.5 17.7335 0.5 16.7881V8.06064C0.5 7.11518 1.28736 6.34872 2.25862 6.34872H4.44253V7.75854H2.25862Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25879 4.4801C3.25879 3.53464 4.04615 2.76819 5.01741 2.76819H13.9829C14.9542 2.76819 15.7415 3.53464 15.7415 4.4801V7.94869C15.7415 8.338 15.4173 8.6536 15.0174 8.6536C14.6175 8.6536 14.2933 8.338 14.2933 7.94869V4.4801C14.2933 4.31326 14.1543 4.178 13.9829 4.178H5.01741C4.84601 4.178 4.70706 4.31326 4.70706 4.4801V7.94869C4.70706 8.338 4.38286 8.6536 3.98293 8.6536C3.583 8.6536 3.25879 8.338 3.25879 7.94869V4.4801Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93652 8.50572C6.93652 8.11641 7.26073 7.80081 7.66066 7.80081H11.3388C11.7388 7.80081 12.063 8.11641 12.063 8.50572C12.063 8.89503 11.7388 9.21062 11.3388 9.21062H7.66066C7.26073 9.21062 6.93652 8.89503 6.93652 8.50572Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01709 6.15852C6.01709 5.76921 6.3413 5.45361 6.74123 5.45361H12.2585C12.6584 5.45361 12.9826 5.76921 12.9826 6.15852C12.9826 6.54783 12.6584 6.86343 12.2585 6.86343H6.74123C6.3413 6.86343 6.01709 6.54783 6.01709 6.15852Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.56479 6.63073C1.80475 6.31928 2.25864 6.25616 2.57858 6.48975L9.31422 11.4073C9.42456 11.4879 9.57629 11.4879 9.68663 11.4073L16.4223 6.48975C16.7422 6.25616 17.1961 6.31928 17.4361 6.63073C17.676 6.94218 17.6112 7.38401 17.2912 7.6176L10.5556 12.5352C9.93031 12.9917 9.07054 12.9917 8.44525 12.5352L1.70962 7.6176C1.38968 7.38401 1.32483 6.94218 1.56479 6.63073Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.229 3.97164C5.94621 3.69635 5.94621 3.25003 6.229 2.97475L8.25613 1.00145C8.94292 0.332909 10.0564 0.332911 10.7432 1.00145L12.7703 2.97475C13.0531 3.25003 13.0531 3.69635 12.7703 3.97164C12.4875 4.24692 12.029 4.24692 11.7462 3.97164L9.71911 1.99834C9.59791 1.88036 9.40141 1.88036 9.28022 1.99834L7.25309 3.97164C6.97029 4.24692 6.51179 4.24692 6.229 3.97164Z"
    />
  </svg>
)

export default EmailOpenIcon
