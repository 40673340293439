import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import * as messages from '../../../../constants/MessagesConstants'

export const confirmDeletion = (onOk: () => void): void => {
  Modal.confirm({
    title: messages.CUSTOMER_ADDRESS_CONFIRM_DELETION_TITLE,
    content: messages.CUSTOMER_ADDRESS_CONFIRM_DELETION_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    okText: 'Yes, delete the address',
    onOk,
  })
}
