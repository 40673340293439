import { Button, Card, Checkbox, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'

import { CARD as cardProps } from '../../../constants/OverridesConstants'
import {
  CustomerNotificationSettingInputType,
  useGetCustomerNotificationSettingsQuery,
  useUpdateCustomerNotificationSettingsMutation,
} from '../../../apollo/generated/api'
import LoadComponentError from '../../../packages/LoadComponentError'
import Loading from '../../../packages/Loading'
import { handleMutationResult } from '../../../apollo'
import { filterMaybe } from '../../../utils/typeUtils'
import {
  SAVE_NOTIFICATION_SETTINGS_FAILURE,
  SAVE_NOTIFICATION_SETTINGS_SUCCESS,
} from '../../../constants/MessagesConstants'

const ManageCustomerNotificationSettings: React.FC<{ customer: { id: number } }> = ({ customer: { id } }) => {
  const [options, setOptions] = useState<CustomerNotificationSettingInputType[]>([])
  const [updateNotificationSettings] = useUpdateCustomerNotificationSettingsMutation()
  const { loading, data, error } = useGetCustomerNotificationSettingsQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  })
  const settings = data?.customer?.notificationSettings || []

  const handleSubmit = async (): Promise<void> => {
    const variables = { id, notifications: options }
    const mutation = updateNotificationSettings({ variables })

    await handleMutationResult(mutation, 'updateCustomerNotificationSettings', {
      notifications: {
        success: {
          title: SAVE_NOTIFICATION_SETTINGS_SUCCESS,
        },
        error: {
          title: SAVE_NOTIFICATION_SETTINGS_FAILURE,
        },
      },
    })
  }

  useEffect(() => {
    if (data) {
      setOptions(
        settings.filter(filterMaybe).map(setting => {
          return { id: setting.id, enabled: setting.enabled }
        }),
      )
    }
  }, [data, settings])

  const togglePreference = (id: number): void => {
    setOptions(options?.map(option => (option.id === id ? { ...option, enabled: !option.enabled } : option)))
  }

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />

  return (
    <Card
      {...cardProps}
      title="Notification Settings"
      style={{ marginTop: 16 }}
      data-testid="notification-settings-form"
    >
      {settings?.map(({ id, label, enabled }) => (
        <Row key={id}>
          <Col style={{ lineHeight: 2 }} data-testid="notification-settings-item">
            <Checkbox
              defaultChecked={enabled}
              onChange={(): void => {
                togglePreference(id)
              }}
              data-testid={`settings-checkbox-${id}`}
            >
              {label}
            </Checkbox>
          </Col>
        </Row>
      ))}
      <Row style={{ marginTop: 16 }}>
        <Col style={{ textAlign: 'left' }}>
          <Button
            type="primary"
            htmlType="submit"
            data-testid="notification-settings-form[submit]"
            icon={<CheckCircleOutlined />}
            size="large"
            loading={loading}
            onClick={handleSubmit}
          >
            Update Notification Settings
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ManageCustomerNotificationSettings
