import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  padding: 20% 0 0;
`

export const Message = styled.h3`
  margin-top: 25px;
  font-weight: 500;
  color: #1890ff;
`
