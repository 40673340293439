import MarleySpoonIcon from '../assets/brands/icons/ms.png'
import DinnerlyIcon from '../assets/brands/icons/dn.png'
import BistroMdIcon from '../assets/brands/icons/bm.png'
import { BrandEnum } from '../apollo/generated/api'

export const brandsIcons: { [brand in BrandEnum]: string } = {
  [BrandEnum.Ms]: MarleySpoonIcon,
  [BrandEnum.Dn]: DinnerlyIcon,
  [BrandEnum.Bm]: BistroMdIcon,
}
