import React from 'react'
import { useParams } from 'react-router'

import LoadComponentError from '../../../../packages/LoadComponentError'
import { useGetOrderPaymentDetailsQuery } from '../../../../apollo/generated/api'
import Loading from '../../../../packages/Loading'
import PaymentSource from '../../../PaymentSources'

const ManageOrderPaymentDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useGetOrderPaymentDetailsQuery({ variables: { id: parseInt(id, 10) } })
  const { source, transactionToken } = data?.payment || {}

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />
  if (!source) return <LoadComponentError errorMessage="Couldn't load this payment's details" />

  return <PaymentSource source={source} transactionToken={transactionToken || ''} />
}

export default ManageOrderPaymentDetails
