import React from 'react'
import { Card } from 'antd'

const PaypalCustomer: React.FC<{ transactionToken: string; email: string }> = ({ transactionToken, email }) => {
  return (
    <Card title="Paypal Customer">
      Email: {email} - Refund token: {transactionToken}
    </Card>
  )
}

export default PaypalCustomer
