import { Form, Button, Card, Checkbox, Col, Row } from 'antd'
import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Store } from 'antd/lib/form/interface'

import { CARD as cardProps } from '../../../constants/OverridesConstants'
import { useUpdateCustomerTrustMutation } from '../../../apollo/generated/api'
import { handleMutationResult } from '../../../apollo'

interface FormStore extends Store {
  updateCustomerTrust: {
    trusted: boolean
  }
}
const ManageCustomerTrusted: React.FC<{ customer: { id: number; trusted: boolean } }> = ({
  customer: { id, trusted },
}) => {
  const [updateCustomerTrust, { loading }] = useUpdateCustomerTrustMutation()

  const handleSubmit = (values: FormStore) => {
    const mutation = updateCustomerTrust({
      variables: {
        customerTrust: {
          id,
          trusted: values.updateCustomerTrust.trusted,
        },
      },
    })

    void handleMutationResult(mutation, 'updateCustomerTrust')
  }

  return (
    <Card {...cardProps} title="Trusted Payments" style={{ marginTop: 16 }} data-testid="trusted-customer-form">
      <Form layout="vertical" onFinish={values => handleSubmit(values)}>
        <Row style={{ marginTop: 16 }}>
          <Col>
            <Form.Item
              name={['updateCustomerTrust', 'trusted']}
              initialValue={trusted}
              valuePropName="checked"
              data-testid="trusted-customer-form[checkbox]"
            >
              <Checkbox data-testid="trust-checkbox">
                If the customer is trusted, send the box regardless of payment failure
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }}>
          <Col style={{ textAlign: 'left' }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="trusted-customer-form[submit]"
                icon={<CheckCircleOutlined />}
                size="large"
                loading={loading}
              >
                Update Trusted Payments Settings
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default ManageCustomerTrusted
