import React, { FunctionComponent, useState } from 'react'
import { RouteComponentProps, generatePath } from 'react-router'
import { MailOutlined } from '@ant-design/icons'
import { Card, Input, Button, Form, Switch, Alert } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { Routes } from '../../../constants/RoutesConstants'
import { EMAIL_REQUIRED_ERROR } from '../../../constants/ErrorConstants'

import { ToggleContainer } from './styles'

const cardProps = {
  headStyle: { fontWeight: 600 },
}

const SearchCustomerEmailForm: FunctionComponent<Pick<RouteComponentProps, 'history'>> = ({ history }) => {
  const [anonymizedSearch, setAnonymizedSearch] = useState(false)

  const handleSubmit = ({ customerEmail }: Store): void => {
    const email = (customerEmail as string).trim()

    history.push(
      generatePath(Routes.CUSTOMER_EMAIL_SEARCH_RESULTS, { email, returnAnonymizedResults: anonymizedSearch }),
    )
  }
  const onChangeAnonymizedSearch = (toggle: boolean) => setAnonymizedSearch(toggle)
  const AnonymizedSearchToggle = () => (
    <ToggleContainer toggle={anonymizedSearch}>
      <span>Include anonymized</span>
      <Switch defaultChecked={anonymizedSearch} onChange={onChangeAnonymizedSearch} />
    </ToggleContainer>
  )

  return (
    <Card title="Search a customer by email" extra={AnonymizedSearchToggle()} {...cardProps}>
      <Form name="searchCustomerByEmail" data-qa="search-customer-email-form" onFinish={handleSubmit}>
        <Form.Item
          name="customerEmail"
          rules={[
            {
              required: true,
              message: EMAIL_REQUIRED_ERROR,
            },
          ]}
          hasFeedback
        >
          <Input
            data-qa="customer-email-input"
            placeholder="Customer email address"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            size="large"
          />
        </Form.Item>
        {anonymizedSearch && (
          <Form.Item>
            <Alert message="Please enter full customer email address" type="warning" showIcon />
          </Form.Item>
        )}
        <Button data-qa="search-customer-email-submit" type="primary" htmlType="submit" size="large" block>
          Search customer
        </Button>
      </Form>
    </Card>
  )
}

export default SearchCustomerEmailForm
