import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
};

export type AddOn = ComplainableItem & {
  __typename?: 'AddOn';
  /** An globally unique identifier for this object */
  complainableId: Scalars['ID']['output'];
  complainableType: ComplainableType;
  /** date complainable was ordered */
  deliveryDate?: Maybe<Scalars['String']['output']>;
  /** An id that can be used to identify identical items in distinct groups (eg identical ingredients in different recipes) */
  groupItemId?: Maybe<Scalars['ID']['output']>;
  /** image url */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** name of this object */
  name: Scalars['String']['output'];
  /** how many items of this type were included in the order */
  orderedQuantity: Scalars['Int']['output'];
};

export type AdditionalDetailsInputConfig = {
  __typename?: 'AdditionalDetailsInputConfig';
  /** indicates weather adding additional details is enabled */
  enabled: Scalars['Boolean']['output'];
  /** indicates weather adding additional details is required */
  required: Scalars['Boolean']['output'];
};

/** Autogenerated return type of Authenticate */
export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  /** Auth Token */
  authToken?: Maybe<Scalars['String']['output']>;
  /** mutation error */
  error?: Maybe<GqlError>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']['output']>;
};

export type CcTicketOutcome = {
  __typename?: 'CcTicketOutcome';
  /** Compensation credit rejection reason */
  compensationCreditRejectionReason?: Maybe<Scalars['String']['output']>;
  /** copy code */
  copyCode?: Maybe<Scalars['String']['output']>;
  /** details */
  details: ComplaintOutcomeDetails;
  /** outcome id */
  outcomeId: Scalars['ID']['output'];
  /** Satisfaction survey url */
  satisfactionSurveyUrl?: Maybe<Scalars['String']['output']>;
};

export type CompensationCreditOutcome = {
  __typename?: 'CompensationCreditOutcome';
  /** credit amount */
  compensationAmount: Scalars['Float']['output'];
  /** copy code */
  copyCode?: Maybe<Scalars['String']['output']>;
  /** details */
  details: ComplaintOutcomeDetails;
  /** credit amount */
  formattedCompensationAmount: Scalars['String']['output'];
  /** outcome id */
  outcomeId: Scalars['ID']['output'];
  /** Satisfaction survey url */
  satisfactionSurveyUrl?: Maybe<Scalars['String']['output']>;
};

/** A collection of items the customer can complaint about */
export type ComplainableGroup = {
  __typename?: 'ComplainableGroup';
  complainableItems: Array<ComplainableItem>;
  /** What kind of complainable items does this group have */
  complainableType: ComplainableType;
  /** An unique id for this group */
  id: Scalars['ID']['output'];
  /** Image for this group */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Subtitle of this group */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Title of this group */
  title: Scalars['String']['output'];
};

/** Something that the customer can complaint about */
export type ComplainableItem = {
  /** An globally unique identifier for this object */
  complainableId: Scalars['ID']['output'];
  complainableType: ComplainableType;
  /** date complainable was ordered */
  deliveryDate?: Maybe<Scalars['String']['output']>;
  /** An id that can be used to identify identical items in distinct groups (eg identical ingredients in different recipes) */
  groupItemId?: Maybe<Scalars['ID']['output']>;
  /** image url */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** name of this object */
  name: Scalars['String']['output'];
  /** how many items of this type were included in the order */
  orderedQuantity: Scalars['Int']['output'];
};

export type ComplainableItemInput = {
  /**
   * Other details about what was wrong with this item.
   * Mandatory if the selected complaint option has required additional details
   */
  additionalDetails?: InputMaybe<Scalars['String']['input']>;
  /** id of complainable */
  complainableId: Scalars['ID']['input'];
  /** quantity */
  complainableQuantity: Scalars['Int']['input'];
  /** complaint option id */
  complaintOptionId: Scalars['ID']['input'];
  /** user-facing complaint option labels */
  complaintOptionLabels: Array<Scalars['String']['input']>;
  /** User-facing labels for all selection_list_values */
  selectionListLabels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** User selections for the SelectionList ComplaintOptionInputConfig */
  selectionListValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ComplainableOrderData = {
  __typename?: 'ComplainableOrderData';
  complainableGroups: Array<ComplainableGroup>;
  complaintOptions: Array<ComplaintOption>;
  globalTrackingParams: Array<NamedValue>;
  order: Order;
  uploadPolicy: UploadPolicy;
};


export type ComplainableOrderDataComplainableGroupsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** List of possible object types that the customer can complain about */
export enum ComplainableType {
  /** Add on */
  Addon = 'ADDON',
  /** Ingredient */
  Ingredient = 'INGREDIENT',
  /** Order */
  Order = 'ORDER',
  /** Recipe */
  Recipe = 'RECIPE'
}

export type ComplaintCompensationData = {
  __typename?: 'ComplaintCompensationData';
  /** compensation amount */
  compensationAmount?: Maybe<Scalars['Float']['output']>;
  /** warnings */
  warnings?: Maybe<Array<ComplaintCompensationWarning>>;
};

export type ComplaintCompensationWarning = {
  __typename?: 'ComplaintCompensationWarning';
  /** extra details about this warning */
  extra?: Maybe<Array<NamedValue>>;
  /** reason of warning */
  reason: Scalars['String']['output'];
};

export type ComplaintOption = {
  __typename?: 'ComplaintOption';
  /** The type of complaint this field can be used for */
  complaintType: ComplaintType;
  /** Id */
  id: Scalars['ID']['output'];
  /** user input configuration */
  inputConfig: UserComplaintOptionInputConfig;
  /** Key that should be used for getting the string transaction of this option */
  key: Scalars['String']['output'];
  /** Id */
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type ComplaintOutcomeDetails = {
  __typename?: 'ComplaintOutcomeDetails';
  /** credit amount */
  compensationAmount?: Maybe<Scalars['Float']['output']>;
  /** delivery info */
  deliveryInfo: OrderDeliveryInfo;
  /** credit amount */
  formattedCompensationAmount?: Maybe<Scalars['String']['output']>;
  /** outcome id */
  outcomeId: Scalars['ID']['output'];
  /** true if complaint is reoccurring */
  reoccurring: Scalars['Boolean']['output'];
  /** Satisfaction survey url */
  satisfactionSurveyUrl?: Maybe<Scalars['String']['output']>;
  /** Self resolved */
  selfResolved: Scalars['Boolean']['output'];
};

export type ComplaintOutcomeResponse = CcTicketOutcome | CompensationCreditOutcome | GqlError;

/** List of possible complaint types */
export enum ComplaintType {
  /** Order contents */
  OrderContents = 'ORDER_CONTENTS',
  /** Order delivery */
  OrderDelivery = 'ORDER_DELIVERY',
  /** Other */
  OrderOther = 'ORDER_OTHER'
}

/** Autogenerated return type of EscalateOutcome */
export type EscalateOutcomePayload = {
  __typename?: 'EscalateOutcomePayload';
  /** copy code */
  copyCode?: Maybe<Scalars['String']['output']>;
  /** mutation error */
  error?: Maybe<GqlError>;
};

/** Autogenerated return type of GenerateSignedRedirectUrl */
export type GenerateSignedRedirectUrlPayload = {
  __typename?: 'GenerateSignedRedirectUrlPayload';
  /** mutation error */
  error?: Maybe<GqlError>;
  /** Signed url */
  url?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of GenerateSteakAuthToken */
export type GenerateSteakAuthTokenPayload = {
  __typename?: 'GenerateSteakAuthTokenPayload';
  /** mutation error */
  error?: Maybe<GqlError>;
  /** Token */
  token?: Maybe<Scalars['String']['output']>;
};

export type GetComplainableOrderDataResponse = ComplainableOrderData | GqlError;

export type GetMenuIngredientsParams = {
  /** Brand */
  brand: Scalars['String']['input'];
  /** Country */
  country: Scalars['String']['input'];
  /** return classified/unclassified only */
  hasClassification?: InputMaybe<Scalars['Boolean']['input']>;
  /** search by text */
  q?: InputMaybe<Scalars['String']['input']>;
  /** recently classified */
  recentlyClassified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetSuggestedComplaintCompensationResponse = {
  __typename?: 'GetSuggestedComplaintCompensationResponse';
  compensation?: Maybe<ComplaintCompensationData>;
  error?: Maybe<GqlError>;
};

export type GetUserComplainableOrdersDataResponse = {
  __typename?: 'GetUserComplainableOrdersDataResponse';
  complainableOrders: Array<ComplainableOrderData>;
  error?: Maybe<GqlError>;
  success: Scalars['Boolean']['output'];
};

export type GetUserInfoResponse = {
  __typename?: 'GetUserInfoResponse';
  creditRevenueTier: Scalars['Int']['output'];
  error?: Maybe<GqlError>;
};

export type GqlError = {
  __typename?: 'GqlError';
  /** error code */
  code: Scalars['String']['output'];
  /** error message */
  message: Scalars['String']['output'];
};

export type Ingredient = ComplainableItem & {
  __typename?: 'Ingredient';
  /** An globally unique identifier for this object */
  complainableId: Scalars['ID']['output'];
  complainableType: ComplainableType;
  /** date complainable was ordered */
  deliveryDate?: Maybe<Scalars['String']['output']>;
  /** An id that can be used to identify identical items in distinct groups (eg identical ingredients in different recipes) */
  groupItemId?: Maybe<Scalars['ID']['output']>;
  /** image url */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** name of this object */
  name: Scalars['String']['output'];
  /** how many items of this type were included in the order */
  orderedQuantity: Scalars['Int']['output'];
};

export type MenuIngredient = {
  __typename?: 'MenuIngredient';
  /** brand */
  brand: Scalars['String']['output'];
  /** classification */
  classification: MenuIngredientClassification;
  /** country */
  country: Scalars['String']['output'];
  /** Display name */
  displayName: Scalars['String']['output'];
  /** Id */
  id: Scalars['ID']['output'];
};

export enum MenuIngredientClassification {
  Budget = 'BUDGET',
  CcReview = 'CC_REVIEW',
  Minor = 'MINOR',
  Moderate = 'MODERATE',
  Prime = 'PRIME',
  Principle = 'PRINCIPLE',
  Standard = 'STANDARD',
  Unclassified = 'UNCLASSIFIED'
}

export type MenuIngredientClassificationInput = {
  /** classification */
  classification: MenuIngredientClassification;
  /** id */
  id: Scalars['ID']['input'];
};

/** The connection type for MenuIngredient. */
export type MenuIngredientConnection = {
  __typename?: 'MenuIngredientConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MenuIngredientEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MenuIngredient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MenuIngredientEdge = {
  __typename?: 'MenuIngredientEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MenuIngredient>;
};

/** Autogenerated return type of MutateMenuIngredientsClassifications */
export type MutateMenuIngredientsClassificationsPayload = {
  __typename?: 'MutateMenuIngredientsClassificationsPayload';
  /** mutation error */
  error?: Maybe<GqlError>;
  /** ingredients */
  menuIngredients?: Maybe<Array<MenuIngredient>>;
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  authenticate?: Maybe<AuthenticatePayload>;
  escalateOutcome?: Maybe<EscalateOutcomePayload>;
  generateSignedUrl?: Maybe<GenerateSignedRedirectUrlPayload>;
  generateSteakAuthToken?: Maybe<GenerateSteakAuthTokenPayload>;
  mutateMenuIngredientClassifications?: Maybe<MutateMenuIngredientsClassificationsPayload>;
  submitComplaint?: Maybe<ComplaintOutcomeResponse>;
};


export type MutationRootAuthenticateArgs = {
  accessKey: Scalars['String']['input'];
};


export type MutationRootEscalateOutcomeArgs = {
  additionalDetails: Scalars['String']['input'];
  orderNumber: Scalars['String']['input'];
  outcomeId: Scalars['ID']['input'];
};


export type MutationRootGenerateSignedUrlArgs = {
  orderNumber: Scalars['String']['input'];
};


export type MutationRootGenerateSteakAuthTokenArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationRootMutateMenuIngredientClassificationsArgs = {
  data: Array<MenuIngredientClassificationInput>;
};


export type MutationRootSubmitComplaintArgs = {
  complaint: SubmitComplaintMutationInput;
};

export type NamedValue = {
  __typename?: 'NamedValue';
  /** Boolean value */
  booleanValue?: Maybe<Scalars['Boolean']['output']>;
  /** Float value */
  floatValue?: Maybe<Scalars['Float']['output']>;
  /** Int value */
  intValue?: Maybe<Scalars['Int']['output']>;
  /** Key */
  key: Scalars['String']['output'];
  /** String value */
  stringValue?: Maybe<Scalars['String']['output']>;
};

export type Order = ComplainableItem & {
  __typename?: 'Order';
  /** An globally unique identifier for this object */
  complainableId: Scalars['ID']['output'];
  complainableType: ComplainableType;
  /** date complainable was ordered */
  deliveryDate?: Maybe<Scalars['String']['output']>;
  /** An id that can be used to identify identical items in distinct groups (eg identical ingredients in different recipes) */
  groupItemId?: Maybe<Scalars['ID']['output']>;
  /** image url */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** name of this object */
  name: Scalars['String']['output'];
  /** how many items of this type were included in the order */
  orderedQuantity: Scalars['Int']['output'];
};

export type OrderDeliveryInfo = {
  __typename?: 'OrderDeliveryInfo';
  /** logged window */
  complaintLoggedWindow: TimeWindow;
  /** delivery date (customer timezone) */
  deliveryDate?: Maybe<Scalars['String']['output']>;
  /** The status of the delivery */
  deliveryStatus: Scalars['String']['output'];
  /** the time of day when the delivery took place (customer timezone) */
  deliveryTime?: Maybe<Scalars['String']['output']>;
  /** delivery window */
  deliveryWindow: TimeWindow;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PhotoUploadInputConfig = {
  __typename?: 'PhotoUploadInputConfig';
  /** indicates weather photo upload is enabled */
  enabled: Scalars['Boolean']['output'];
};

/** The query root of the steak api schema. */
export type QueryRoot = {
  __typename?: 'QueryRoot';
  complainableOrderData?: Maybe<GetComplainableOrderDataResponse>;
  menuIngredients: MenuIngredientConnection;
  suggestedComplaintCompensation: GetSuggestedComplaintCompensationResponse;
  userComplainableOrders: GetUserComplainableOrdersDataResponse;
  userInfo: GetUserInfoResponse;
};


/** The query root of the steak api schema. */
export type QueryRootComplainableOrderDataArgs = {
  orderNumber: Scalars['String']['input'];
};


/** The query root of the steak api schema. */
export type QueryRootMenuIngredientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  params: GetMenuIngredientsParams;
};


/** The query root of the steak api schema. */
export type QueryRootSuggestedComplaintCompensationArgs = {
  data: SuggestedComplaintCompensationInput;
};


/** The query root of the steak api schema. */
export type QueryRootUserComplainableOrdersArgs = {
  orderNumber?: InputMaybe<Scalars['String']['input']>;
};


/** The query root of the steak api schema. */
export type QueryRootUserInfoArgs = {
  userId: Scalars['Int']['input'];
};

export type S3AttachmentInput = {
  /** s3 bucket */
  bucket: Scalars['String']['input'];
  /** s3 key */
  key: Scalars['String']['input'];
};

export type SelectionListInputConfig = {
  __typename?: 'SelectionListInputConfig';
  /** indicates if this field is enabled */
  enabled: Scalars['Boolean']['output'];
  /** single or multiple selection mode */
  multiple: Scalars['Boolean']['output'];
  /** indicates if this field is required */
  required: Scalars['Boolean']['output'];
  /** list of values the user can select from */
  values: Array<SelectionListOption>;
};

export type SelectionListOption = {
  __typename?: 'SelectionListOption';
  /** additional details configuration */
  additionalDetails: AdditionalDetailsInputConfig;
  /** indicates if this field is enabled */
  key: Scalars['String']['output'];
};

export type SubmitComplaintMutationInput = {
  /** Additional details */
  additionalDetails?: InputMaybe<Scalars['String']['input']>;
  /** ID of Zendesk ticket with photo upload */
  attachmentZendeskTicketId?: InputMaybe<Scalars['String']['input']>;
  /** Attachments */
  attachments?: InputMaybe<Array<S3AttachmentInput>>;
  /** Complaint Type */
  complaintType?: InputMaybe<ComplaintType>;
  /** Complaint item details */
  items: Array<ComplainableItemInput>;
  /** Order number */
  orderNumber: Scalars['String']['input'];
  /** Source of complaint submission */
  source?: InputMaybe<Scalars['String']['input']>;
};

export type SuggestedComplaintCompensationInput = {
  /** add on ids */
  addOnIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Complaint reason ID - beef subcategory ID */
  complaintReason: Scalars['ID']['input'];
  /** ingredient ids */
  ingredientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Order number */
  orderNumber: Scalars['String']['input'];
};

export enum TimeWindow {
  /** Within Delivery Window */
  OnTime = 'ON_TIME',
  /** overdue <= 24 hours */
  Overdue_24 = 'OVERDUE_24',
  /** overdue  24-48 hours */
  Overdue_48 = 'OVERDUE_48',
  /** overdue  48+ hours */
  OverdueOver_48 = 'OVERDUE_OVER_48',
  /** not in the delivery window, but later during the same delivery day */
  OverdueSameDay = 'OVERDUE_SAME_DAY',
  /** undefined */
  Undefined = 'UNDEFINED'
}

export type UploadPolicy = {
  __typename?: 'UploadPolicy';
  /** the url which the files need to be uploaded at */
  uploadConstraints: Array<Array<Scalars['Any']['output']>>;
  /** list of values which need to be appended to the upload request */
  uploadFields: Array<NamedValue>;
  /** the url which the files need to be uploaded at */
  uploadUrl: Scalars['String']['output'];
};

export type UserComplaintOptionInputConfig = {
  __typename?: 'UserComplaintOptionInputConfig';
  /** additional details configuration */
  additionalDetails: AdditionalDetailsInputConfig;
  /** photo upload configuration */
  photoUpload: PhotoUploadInputConfig;
  /** selection list configuration */
  selectionList: SelectionListInputConfig;
};

export type GetSteakMenuIngredientsQueryVariables = Exact<{
  params: GetMenuIngredientsParams;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSteakMenuIngredientsQuery = { __typename?: 'QueryRoot', menuIngredients: { __typename?: 'MenuIngredientConnection', nodes?: Array<{ __typename?: 'MenuIngredient', id: string, displayName: string, classification: MenuIngredientClassification } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type GetSteakUserInfoQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetSteakUserInfoQuery = { __typename?: 'QueryRoot', userInfo: { __typename?: 'GetUserInfoResponse', creditRevenueTier: number, error?: { __typename?: 'GqlError', code: string, message: string } | null } };

export type MutateMenuIngredientClassificationsMutationVariables = Exact<{
  data: Array<MenuIngredientClassificationInput> | MenuIngredientClassificationInput;
}>;


export type MutateMenuIngredientClassificationsMutation = { __typename?: 'MutationRoot', mutateMenuIngredientClassifications?: { __typename?: 'MutateMenuIngredientsClassificationsPayload', error?: { __typename?: 'GqlError', code: string } | null, menuIngredients?: Array<{ __typename?: 'MenuIngredient', id: string, classification: MenuIngredientClassification }> | null } | null };

export const namedOperations = {
  Query: {
    GetSteakMenuIngredients_Cookbook: 'GetSteakMenuIngredients_Cookbook',
    GetSteakUserInfo_Cookbook: 'GetSteakUserInfo_Cookbook'
  },
  Mutation: {
    MutateMenuIngredientClassifications_Cookbook: 'MutateMenuIngredientClassifications_Cookbook'
  }
}

export const GetSteakMenuIngredientsDocument = gql`
    query GetSteakMenuIngredients_Cookbook($params: GetMenuIngredientsParams!, $after: String) {
  menuIngredients(params: $params, after: $after) {
    nodes {
      id
      displayName
      classification
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export function useGetSteakMenuIngredientsQuery(baseOptions: Apollo.QueryHookOptions<GetSteakMenuIngredientsQuery, GetSteakMenuIngredientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSteakMenuIngredientsQuery, GetSteakMenuIngredientsQueryVariables>(GetSteakMenuIngredientsDocument, options);
      }
export function useGetSteakMenuIngredientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSteakMenuIngredientsQuery, GetSteakMenuIngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSteakMenuIngredientsQuery, GetSteakMenuIngredientsQueryVariables>(GetSteakMenuIngredientsDocument, options);
        }
export type GetSteakMenuIngredientsQueryHookResult = ReturnType<typeof useGetSteakMenuIngredientsQuery>;
export type GetSteakMenuIngredientsLazyQueryHookResult = ReturnType<typeof useGetSteakMenuIngredientsLazyQuery>;
export type GetSteakMenuIngredientsQueryResult = Apollo.QueryResult<GetSteakMenuIngredientsQuery, GetSteakMenuIngredientsQueryVariables>;
export const GetSteakUserInfoDocument = gql`
    query GetSteakUserInfo_Cookbook($id: Int!) {
  userInfo(userId: $id) {
    creditRevenueTier
    error {
      code
      message
    }
  }
}
    `;
export function useGetSteakUserInfoQuery(baseOptions: Apollo.QueryHookOptions<GetSteakUserInfoQuery, GetSteakUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSteakUserInfoQuery, GetSteakUserInfoQueryVariables>(GetSteakUserInfoDocument, options);
      }
export function useGetSteakUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSteakUserInfoQuery, GetSteakUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSteakUserInfoQuery, GetSteakUserInfoQueryVariables>(GetSteakUserInfoDocument, options);
        }
export type GetSteakUserInfoQueryHookResult = ReturnType<typeof useGetSteakUserInfoQuery>;
export type GetSteakUserInfoLazyQueryHookResult = ReturnType<typeof useGetSteakUserInfoLazyQuery>;
export type GetSteakUserInfoQueryResult = Apollo.QueryResult<GetSteakUserInfoQuery, GetSteakUserInfoQueryVariables>;
export const MutateMenuIngredientClassificationsDocument = gql`
    mutation MutateMenuIngredientClassifications_Cookbook($data: [MenuIngredientClassificationInput!]!) {
  mutateMenuIngredientClassifications(data: $data) {
    error {
      code
    }
    menuIngredients {
      id
      classification
    }
  }
}
    `;
export type MutateMenuIngredientClassificationsMutationFn = Apollo.MutationFunction<MutateMenuIngredientClassificationsMutation, MutateMenuIngredientClassificationsMutationVariables>;
export function useMutateMenuIngredientClassificationsMutation(baseOptions?: Apollo.MutationHookOptions<MutateMenuIngredientClassificationsMutation, MutateMenuIngredientClassificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMenuIngredientClassificationsMutation, MutateMenuIngredientClassificationsMutationVariables>(MutateMenuIngredientClassificationsDocument, options);
      }
export type MutateMenuIngredientClassificationsMutationHookResult = ReturnType<typeof useMutateMenuIngredientClassificationsMutation>;
export type MutateMenuIngredientClassificationsMutationResult = Apollo.MutationResult<MutateMenuIngredientClassificationsMutation>;
export type MutateMenuIngredientClassificationsMutationOptions = Apollo.BaseMutationOptions<MutateMenuIngredientClassificationsMutation, MutateMenuIngredientClassificationsMutationVariables>;