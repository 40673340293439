import { Row, Col, Card, Table, Alert, Upload, Form, Button, Typography, PageHeader } from 'antd'
import React, { useState, useEffect } from 'react'
import { StoreValue } from 'antd/lib/form/interface'
import { DeleteTwoTone, InboxOutlined } from '@ant-design/icons'

import Colors from '../../constants/ColorsConstants'
import { headerIcons } from '../../constants/IconsConstants'
import { useCreateMassComplaintsMutation } from '../../apollo/generated/beef'
import { handleMutationResult } from '../../apollo'
import { COOKBOOK_USER_SESSION } from '../../constants/AuthConstants'
import { getUserSession } from '../../utils'

import { draggerProps, columns } from './config'

const { Text } = Typography
const { Dragger } = Upload

const normFile = (e: StoreValue) => {
  if (Array.isArray(e)) return e

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return e.fileList
}

interface ComplaintLine {
  orderNo: string
  dateCreated: string
  category: string
  subcategory: string
  description: string
  creditAmount: number
  creditReason: string
}

const LINES_LIMIT = 1000

const ManageMassComplaints: React.FC = () => {
  const [form] = Form.useForm()
  const [rejected, setRejected] = useState<Array<{ wrongHeader: string; correctHeader: string }>>()
  const [data, setData] = useState<ComplaintLine[]>()
  const [limitReached, setLimitReached] = useState<boolean | undefined>(false)
  const [createMassComplaintsMutation, { loading }] = useCreateMassComplaintsMutation()
  const canSubmit = () => data?.length && !rejected?.length && !loading && data.length <= LINES_LIMIT

  const handleSubmit = async (): Promise<void> => {
    const createComplaintBatch =
      data?.map(complaint => ({
        categoryName: complaint.category,
        receivedAt: complaint.dateCreated,
        orderNumber: complaint.orderNo,
        subcategoryName: complaint.subcategory,
        description: complaint.description,
        compensationAmount: Number(complaint.creditAmount),
        creditReason: complaint.creditReason,
      })) || []
    const mutation = createMassComplaintsMutation({
      variables: {
        createComplaintBatch,
        authToken: getUserSession(COOKBOOK_USER_SESSION).session?.token.value ?? '',
      },
    })
    const { data: result } = await handleMutationResult(mutation, 'createComplaintBatch', {
      notifications: {
        success: {
          title: 'Mass complaints successfully processing!',
        },
      },
    })

    if (result?.createComplaintBatch?.__typename === 'CreateComplaintBatchPayload') {
      form.resetFields()
      setData([])
    }
  }

  useEffect(() => {
    setLimitReached(data && data.length > LINES_LIMIT)
  }, [data])

  return (
    <>
      <PageHeader title="Mass Complaints" avatar={{ src: headerIcons.COMPLAIN_ICON, shape: 'square' }} />

      <Row>
        <Col span={24}>
          <Card title="Provide a .csv file with a list of complaints">
            {rejected?.length ? (
              <Alert
                type="error"
                message="We've found some problems with the headers"
                description={
                  <ul style={{ paddingLeft: 20 }}>
                    {rejected.map(({ wrongHeader, correctHeader }) => (
                      <li key={correctHeader}>
                        <Text strong>{wrongHeader}</Text> should be{' '}
                        <Text strong copyable>
                          {correctHeader}
                        </Text>
                      </li>
                    ))}
                  </ul>
                }
                style={{ margin: '20px 0' }}
                showIcon
              />
            ) : null}

            {limitReached ? (
              <Alert
                type="error"
                message="Oops!"
                description={`You can only upload a max of ${LINES_LIMIT} rows.`}
                style={{ margin: '20px 0' }}
                showIcon
              />
            ) : null}

            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                <Dragger
                  {...draggerProps({ setRejected, setData })}
                  onRemove={() => {
                    setData([])
                    setRejected([])
                  }}
                  data-testid="complaints-csv-upload"
                  disabled={loading}
                  showUploadList={{
                    showRemoveIcon: true,
                    removeIcon: <DeleteTwoTone twoToneColor={Colors.DANGER} />,
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-hint" style={{ padding: '0 20px' }}>
                    Drag your .csv file here, we&apos;ll validate the data as best as we can before creating any data.
                  </p>
                </Dragger>
              </Form.Item>

              <div style={{ marginTop: 10 }}>
                Not sure how the .csv file should look like?{' '}
                <a href={`${process.env.PUBLIC_URL}/templates/complaints.csv`} download="complaints.csv">
                  Download template
                </a>
                .
              </div>

              {data?.length && !rejected?.length ? (
                <Card title="Preview your data before uploading it" style={{ marginTop: 25 }}>
                  <Table
                    rowKey="orderNo"
                    style={{ marginTop: 25 }}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    size="small"
                    scroll={{ y: 400 }}
                    bordered
                  />
                </Card>
              ) : null}

              <Button
                data-testid="submit-complaints"
                htmlType="submit"
                type="primary"
                style={{ marginTop: 30 }}
                disabled={!canSubmit()}
                size="large"
                loading={loading}
                block
              >
                Upload complaints
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ManageMassComplaints
