import React from 'react'
import { RouteComponentProps, generatePath } from 'react-router'
import { CodeSandboxOutlined } from '@ant-design/icons'
import { Card, Input, Button, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { Routes } from '../../../constants/RoutesConstants'
import { ORDER_ID_REQUIRED_ERROR } from '../../../constants/ErrorConstants'

const cardProps = {
  headStyle: { fontWeight: 600 },
}
const inputName = 'orderId'

const SearchOrderForm: React.FC<Pick<RouteComponentProps, 'history'>> = ({ history }) => {
  const handleSubmit = ({ orderId }: Store): void => {
    const number = (orderId as string).trim()

    history.push(generatePath(Routes.ORDER_DETAIL, { number }))
  }

  return (
    <Card title="Search an order" {...cardProps}>
      <Form name="searchOrderForm" data-qa="search-order-form" onFinish={handleSubmit}>
        <Form.Item
          name={inputName}
          rules={[
            {
              required: true,
              message: ORDER_ID_REQUIRED_ERROR,
            },
          ]}
          hasFeedback
        >
          <Input
            data-qa="order-id-input"
            placeholder="Order number"
            prefix={<CodeSandboxOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            size="large"
          />
        </Form.Item>

        <Button data-qa="search-order-submit" type="primary" htmlType="submit" size="large" block>
          Search order
        </Button>
      </Form>
    </Card>
  )
}

export default SearchOrderForm
