import React from 'react'
import { Table, Button, Alert, Popconfirm } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import Colors from '../../../constants/ColorsConstants'
import { handleMutationResult } from '../../../apollo'
import { VOID_PAYMENT_SOURCES_SUCCESS, VOID_PAYMENT_SOURCES_FAILURE } from '../../../constants/MessagesConstants'
import { filterMaybe } from '../../../utils/typeUtils'
import {
  GetCustomerPaymentSourcesDocument,
  PaymentSource,
  useGetCustomerPaymentSourcesQuery,
  useVoidCustomerActivePaymentSourceMutation,
} from '../../../apollo/generated/api'
import { queryNames } from '../../../apollo/client'
import { Routes } from '../../../constants/RoutesConstants'
import { ManageCustomerProps } from '../props'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

const { Column } = Table

const ManageCustomerPaymentSources: React.FC<ManageCustomerProps> = ({ customer }) => {
  const queryVars = { variables: { id: customer.id } }
  const { loading, error, data } = useGetCustomerPaymentSourcesQuery({ ...queryVars, fetchPolicy: 'no-cache' })
  const [voidCustomerActivePaymentSource, { loading: loadingMutation }] = useVoidCustomerActivePaymentSourceMutation()
  const paymentSources = data?.customer?.paymentSources.filter(filterMaybe)

  const removePaymentInfo = async (): Promise<void> => {
    const mutation = voidCustomerActivePaymentSource({
      variables: { id: customer.id },
      refetchQueries: queryNames(GetCustomerPaymentSourcesDocument),
    })

    await handleMutationResult(mutation, 'voidCustomerActivePaymentSource', {
      notifications: {
        success: {
          title: VOID_PAYMENT_SOURCES_SUCCESS,
        },
        error: {
          title: VOID_PAYMENT_SOURCES_FAILURE,
        },
      },
    })
  }

  const voidPaymentSourceButton = (): JSX.Element => {
    return (
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Popconfirm
          data-testid="void-payment-info-confirm"
          title="Are you sure you want to remove the active payment source info from the customer?"
          placement="bottom"
          onConfirm={removePaymentInfo}
          cancelText="No"
          okText="Yes"
        >
          <Button danger disabled={loadingMutation} data-testid="void-payment-sources-btn">
            Void active payment source
          </Button>
        </Popconfirm>
      </div>
    )
  }

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />
  if (paymentSources?.length === 0) {
    return (
      <Alert
        showIcon
        type="warning"
        message="No payment sources"
        description="This customer doesn't have any payment source configured"
      />
    )
  }

  return (
    <>
      <Table
        data-testid="customer-management-payment-sources-table"
        dataSource={paymentSources}
        rowKey={({ method }): string => method?.name || ''}
        bordered
        pagination={false}
      >
        <Column
          title="Payment Source"
          dataIndex="method.name"
          render={(_, { method }: PaymentSource): string => method?.name || ''}
        />

        <Column
          title="Active"
          dataIndex="activePaymentSource.active"
          render={(_, { active }: PaymentSource): React.ReactNode =>
            active ? (
              <CheckCircleTwoTone twoToneColor={Colors.SUCCESS} />
            ) : (
              <CloseCircleTwoTone twoToneColor={Colors.DANGER} />
            )
          }
        />

        <Column
          title="Deleted at"
          dataIndex="deletedAt"
          render={(deletedAt: string | null) =>
            deletedAt ? <UserAwareDateWithTz datetime={deletedAt} customerTimezone={customer.timezone} /> : '-'
          }
        />

        <Column
          title="Actions"
          render={(_text, _record, index): React.ReactElement => (
            <Link to={generatePath(Routes.CUSTOMER_PAYMENT_SOURCE_DETAIL, { id: customer.id, index })}>View</Link>
          )}
        />
      </Table>

      {voidPaymentSourceButton()}
    </>
  )
}

export default ManageCustomerPaymentSources
