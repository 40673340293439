export enum FlagType {
  COMPLAINTS = 'complaints',
}

export const FeatureFlags = {
  test: {
    [FlagType.COMPLAINTS]: true,
  },
  development: {
    [FlagType.COMPLAINTS]: true,
  },
  staging: {
    [FlagType.COMPLAINTS]: true,
  },
  production: {
    [FlagType.COMPLAINTS]: true,
  },
}
