import React from 'react'
import { Form, FormInstance, InputNumber } from 'antd'

import { useFoodSafetyContext } from '../../../../../contexts/FoodSafetyContextProvider/FoodSafetyContextProvider'

const NumberOfIcePacks: React.FC<{ form: FormInstance }> = () => {
  const { detailsDisabled } = useFoodSafetyContext()

  return (
    <Form.Item name={['fsqaDetails', 'numIcePacks']} label="Number of Ice Packs">
      <InputNumber disabled={detailsDisabled} style={{ width: '80%' }} />
    </Form.Item>
  )
}

export default NumberOfIcePacks
