import React, { useMemo, useState } from 'react'
import { Card, Button, Row, Col, Alert } from 'antd'

import { ItemWithQuantityInputType, useUpdateOrderContentsMutation } from '../../../apollo/generated/api'
import { handleMutationResult } from '../../../apollo'
import { ADDONS_UPDATED_SUCCESS, NOTIFICATION_TITLE_FAILURE } from '../../../constants/MessagesConstants'
import { AddOn, AddOnsComponentConfig, OrderAddOn, OrderAddOnQuantityHolder } from '../types'
import FruitBoxAddOn from '../FruitBoxAddOn/FruitBoxAddOn'
import AddOnList from '../AddOnList/AddOnList'
import AddOnSearchAutocomplete from '../AddOnSearchAutocomplete/AddOnSearchAutocomplete'

const AddOns: React.FC<AddOnsComponentConfig> = ({
  orderNumber,
  brand,
  orderAddOns: initialOrderAddOns,
  customerId,
  fruitBoxAvailable,
  editable,
  inProduction,
  country,
  onOrderUpdated,
}) => {
  const [orderAddOns, setOrderOrderAddOns] = useState(initialOrderAddOns.filter(value => !value.addOn?.isFruitBox))
  const [fruitboxAddons, setFruitboxAddons] = useState(initialOrderAddOns.filter(value => value.addOn?.isFruitBox))
  const [itemQuantities, setItemQuantities] = useState(new Map<number | undefined, number>())

  useMemo(() => {
    // we want to reset everything whenever orderAddOns changes
    setOrderOrderAddOns(initialOrderAddOns.filter(value => !value.addOn?.isFruitBox))
    setFruitboxAddons(initialOrderAddOns.filter(value => value.addOn?.isFruitBox))
    setItemQuantities(new Map())
  }, [initialOrderAddOns])

  const [updateOrderContents, updateRes] = useUpdateOrderContentsMutation()
  const qHolder: OrderAddOnQuantityHolder = useMemo(() => {
    return {
      getQuantity(orderItem: OrderAddOn): number {
        const k = orderItem.addOn?.id

        if (itemQuantities.has(k)) {
          return itemQuantities.get(k) || 0
        }

        return orderItem.quantity || 0
      },

      setQuantity(orderItem: OrderAddOn, count: number): boolean {
        if (count >= 0 && count !== qHolder.getQuantity(orderItem)) {
          const m = new Map(itemQuantities)

          m.set(orderItem.addOn?.id, count)
          setItemQuantities(m)

          return true
        }

        return false
      },

      canAdjustQuantity(orderItem: OrderAddOn, delta: number): boolean {
        if (!editable) {
          return false
        }
        if (qHolder.getQuantity(orderItem) + delta < 0) {
          return false
        }

        return true
      },

      adjustQuantity(orderItem: OrderAddOn, delta: number) {
        qHolder.setQuantity(orderItem, qHolder.getQuantity(orderItem) + delta)
      },

      getWatchedExpression(): unknown[] {
        return [itemQuantities]
      },
    }
  }, [itemQuantities])

  const insertOrderAddOn = (addOn: AddOn | undefined) => {
    if (!addOn) {
      return
    }
    const exists = !!orderAddOns.find(value => value.addOn?.id === addOn.id)

    if (exists) {
      return
    }

    const o: OrderAddOn = {
      addOn,
      quantity: 0,
    }

    setOrderOrderAddOns([o, ...orderAddOns])
    qHolder.setQuantity(o, 1)
  }

  const deleteOrderAddOn = (orderAddOn: OrderAddOn) => {
    setOrderOrderAddOns(orderAddOns.filter(value => value.addOn?.id !== orderAddOn.addOn?.id))
    qHolder.setQuantity(orderAddOn, 0)
  }

  const handleSubmit = () => {
    const values: ItemWithQuantityInputType[] = []

    // eslint-disable-next-line no-restricted-syntax
    for (const orderAddOn of orderAddOns) {
      if (orderAddOn.addOn?.isFruitBox) {
        // eslint-disable-next-line no-continue
        continue
      }
      const addonId = orderAddOn.addOn?.id || NaN

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(addonId)) {
        // eslint-disable-next-line no-continue
        continue
      }
      values.push({
        id: addonId,
        quantity: qHolder.getQuantity(orderAddOn),
      })
    }

    const mutation = updateOrderContents({
      variables: {
        number: orderNumber,
        addOns: values,
      },
    })

    void handleMutationResult(mutation, 'updateOrderContents', {
      notifications: {
        success: {
          title: ADDONS_UPDATED_SUCCESS,
        },
        error: {
          title: NOTIFICATION_TITLE_FAILURE,
        },
      },
    }).then(value => {
      if (value.data && onOrderUpdated) {
        onOrderUpdated('add_ons', value.data)
      }
    })
  }
  const hasChanges = itemQuantities.size !== 0

  return (
    <Card title="AddOns" style={{ marginTop: 30 }}>
      {inProduction && <Alert message="This order is in production, any changes to AddOns cannot be performed" />}
      {country && (
        <AddOnSearchAutocomplete
          orderNumber={orderNumber}
          brand={brand}
          country={country}
          editable={editable}
          onItemSelected={insertOrderAddOn}
        />
      )}
      <AddOnList quantityHolder={qHolder} orderAddOns={orderAddOns} editable={editable} onDelete={deleteOrderAddOn} />
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col span={6}>
          <Button
            data-testid="addon-submit-button"
            type="primary"
            disabled={!editable || updateRes.loading || !hasChanges}
            loading={updateRes.loading}
            size="large"
            onClick={() => handleSubmit()}
            block
          >
            Update Add-ons
          </Button>
        </Col>
      </Row>
      <FruitBoxAddOn
        brand={brand}
        onOrderUpdated={onOrderUpdated}
        orderNumber={orderNumber}
        orderAddOns={fruitboxAddons}
        customerId={customerId}
        editable={editable}
        fruitBoxAvailable={fruitBoxAvailable}
        inProduction={inProduction}
      />
    </Card>
  )
}

export default AddOns
