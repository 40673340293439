import React, { useState } from 'react'
import { Alert, Col, Form, FormInstance, Radio } from 'antd'

import { required } from '../formConfig'
import { FSQACategoryOption, Option, useGetComplaintFSQACategoriesQuery } from '../../../../apollo/generated/beef'
import FSQACategory from '../FSQACategory'
import WarmProtein from '../FSQADetails/WarmProtein'
import BoxOrIcePackDamaged from '../FSQADetails/BoxOrIcePackDamaged'
import NumberOfIcePacks from '../FSQADetails/NumberOfIcePacks'
import Other from '../FSQADetails/Other'
import ReceivedWithinTimeWindow from '../FSQADetails/ReceivedWithinTimeWindow'
import NACheckbox from '../FSQADetails/NACheckbox'
import { FoodSafetyContext } from '../../../../contexts/FoodSafetyContextProvider/FoodSafetyContextProvider'

interface Component {
  [name: string]: React.FC<{
    form: FormInstance
  }>
}

const RuleComponent: Component = {
  'Warm Protein': WarmProtein,
  'Received Within Time Delivery Window': ReceivedWithinTimeWindow,
  'Box/Ice Pack Damaged': BoxOrIcePackDamaged,
  'Number of Ice Packs': NumberOfIcePacks,
  // eslint-disable-next-line prettier/prettier, object-shorthand
  Other: Other,
}

const FoodSafetyIssue: React.FC<{ form: FormInstance; options: Option[] }> = ({ form, options }) => {
  const { data: categoriesData, loading: loadingCategories } = useGetComplaintFSQACategoriesQuery()
  const [isFoodSafetyIssue, setIsFoodSafetyIssue] = useState(!!form.getFieldValue('foodSafetyIssue'))
  const [showAdditionalFoodSafetyDetails, setShowAdditionalFoodSafetyDetails] = useState(
    !!form.getFieldValue('fsqaDetails'),
  )
  const [foodSafetyDetailChanged, setFoodSafetyDetailChanged] = useState(false)

  const onChange = () => {
    setIsFoodSafetyIssue(!isFoodSafetyIssue)
    form.setFieldsValue({ foodSafetyIssue: !isFoodSafetyIssue })
  }

  return (
    <>
      <Form.Item name="foodSafetyIssue" label="Is this complaint a food safety issue?" rules={[required]}>
        <Radio.Group defaultValue={false} onChange={() => onChange()}>
          <Radio data-testid="food-safety-issue-yes" value style={{ marginRight: 25 }}>
            Yes
          </Radio>

          <Radio data-testid="food-safety-issue-no" value={false} style={{ marginRight: 25 }}>
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
      {isFoodSafetyIssue && (
        <>
          <FSQACategory
            options={categoriesData?.fsqaCategories}
            loading={loadingCategories}
            setShowAdditionalFoodSafetyDetails={setShowAdditionalFoodSafetyDetails}
          />

          <Alert
            description="Please be aware that this complaint will be flagged for food safety issues."
            message="Food Safety Issue"
            type="warning"
            closable={false}
            style={{ marginTop: 30 }}
          />
        </>
      )}
      <FoodSafetyContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{ detailsDisabled: foodSafetyDetailChanged, setDetailsDisabled: setFoodSafetyDetailChanged }}
      >
        {isFoodSafetyIssue &&
          showAdditionalFoodSafetyDetails &&
          (options as FSQACategoryOption[]).map(({ id, name }) => {
            const Component = RuleComponent[name]

            if (!Component) return <div key={id} />

            return (
              <Col key={id}>
                <Component form={form} />
              </Col>
            )
          })}

        {isFoodSafetyIssue && showAdditionalFoodSafetyDetails && <NACheckbox form={form} />}
      </FoodSafetyContext.Provider>
    </>
  )
}

export default FoodSafetyIssue
