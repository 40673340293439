import React from 'react'
import { Card } from 'antd'

import { PreapprovalKey as ApiPreapprovalKey } from '../../../apollo/generated/api'

const PreapprovalKey: React.FC<{ preapprovalKey: ApiPreapprovalKey }> = ({ preapprovalKey }) => {
  const { id } = preapprovalKey

  return <Card title="Preapproval Key">Key id: {id}</Card>
}

export default PreapprovalKey
