import React, { useState, useEffect } from 'react'
import { Table, Divider, Typography } from 'antd'

import { filterMaybe } from '../../../utils/typeUtils'
import LoadComponentError from '../../../packages/LoadComponentError'
import { useGetCustomerAddressesQuery } from '../../../apollo/generated/api'

const { Column } = Table
const { Text } = Typography
const ColumnContent: React.FC<{
  content: string
}> = ({ content }) => <Text style={{ wordBreak: 'break-word' }}>{content}</Text>

const AddressTable: React.FC<{
  loading: boolean
  addresses: AddressSelection[]
  testId: string
  onChange: (selected: AddressSelection) => void
  initialAddressId?: number
}> = ({ loading, addresses, testId, onChange: onChangeCallback, initialAddressId }) => {
  const [selectedKeys, setSelectedKeys] = useState<number[]>([])

  useEffect(() => setSelectedKeys(initialAddressId ? [initialAddressId] : []), [initialAddressId])

  const onChange = (_keys: React.ReactText[], rows: AddressSelection[]): void => {
    if (rows.length !== 1) {
      throw new Error('Invalid state, exactly one address needs to be selected!')
    }

    setSelectedKeys(rows.map((addr): number => addr.id))
    onChangeCallback(rows[0])
  }

  return (
    <Table<AddressSelection>
      loading={loading}
      dataSource={addresses}
      pagination={false}
      rowKey="id"
      data-testid={testId}
      rowSelection={{ type: 'radio', onChange, selectedRowKeys: selectedKeys }}
    >
      <Column<AddressSelection>
        title="Name"
        dataIndex="name"
        render={(name: string): JSX.Element => <ColumnContent content={name} />}
      />
      <Column<AddressSelection>
        title="Address"
        dataIndex="address"
        render={(address: string): JSX.Element => <ColumnContent content={address} />}
      />
      <Column<AddressSelection>
        title="City"
        dataIndex="city"
        render={(city: string): JSX.Element => <ColumnContent content={city} />}
      />
      <Column<AddressSelection>
        title="State"
        dataIndex="state"
        render={(state: string): JSX.Element => <ColumnContent content={state} />}
      />
      <Column<AddressSelection>
        title="Company"
        dataIndex="company"
        render={(company: string): JSX.Element => <ColumnContent content={company} />}
      />
      <Column<AddressSelection>
        title="Zip Code"
        dataIndex="zipcode"
        render={(zipcode: string): JSX.Element => <ColumnContent content={zipcode} />}
      />
      <Column<AddressSelection>
        title="Phone"
        dataIndex="phone"
        render={(phone: string): JSX.Element => <ColumnContent content={phone} />}
      />
    </Table>
  )
}
const findAddress = (addresses: AddressSelection[], id?: number): AddressSelection | undefined =>
  addresses.find(addr => addr.id === id)

const SelectOrderAddresses: React.FC<SelectOrderAddressesProps> = ({
  customerId,
  onChange,
  initialShippingId,
  initialBillingId,
}) => {
  const { loading, data, error } = useGetCustomerAddressesQuery({
    variables: { id: customerId },
    fetchPolicy: 'no-cache',
  })
  const [addresses, setAddresses] = useState<AddressSelection[]>([])
  const [billingAddressId, setBillingAddressId] = useState<number | undefined>(initialBillingId)
  const [shippingAddressId, setShippingAddressId] = useState<number | undefined>(initialShippingId)
  const customerAddresses = data?.customer?.addresses

  useEffect(() => {
    if (customerAddresses === undefined) {
      return
    }

    const mappedAddresses = customerAddresses.filter(filterMaybe).map(
      (addr): AddressSelection => ({
        id: addr.address.id,
        default: addr.isDefault,
        name: `${addr.address.firstName} ${addr.address.lastName}`,
        address: addr.address.fullStreetAddress || '',
        zipcode: addr.address.zipcode || '',
        city: addr.address.city || '',
        phone: addr.address.phone || '',
        state: addr.address?.state ? `${addr.address?.state?.name} (${addr.address?.state?.abbreviation})` : '',
        company: addr.address.company || '',
      }),
    )
    const defaultAddress = mappedAddresses.find((addr): boolean => addr.default)

    if (!shippingAddressId) {
      setShippingAddressId(defaultAddress?.id)
    }

    if (!billingAddressId) {
      setBillingAddressId(defaultAddress?.id)
    }

    if (mappedAddresses.length) {
      setAddresses(mappedAddresses)
    }
  }, [customerAddresses, shippingAddressId, billingAddressId])

  useEffect(() => {
    onChange({
      shipping: findAddress(addresses, shippingAddressId),
      billing: findAddress(addresses, billingAddressId),
    })
  }, [shippingAddressId, billingAddressId, onChange, addresses])

  if (error) return <LoadComponentError errorMessage={error.message} />

  return (
    <div data-testid="select-order-addresses">
      <h3>Shipping Address</h3>

      <AddressTable
        loading={loading}
        addresses={addresses}
        initialAddressId={shippingAddressId}
        testId="order-addresses-shipping"
        onChange={(shipping: AddressSelection): void => setShippingAddressId(shipping.id)}
      />

      <Divider />

      <h3>Billing Address</h3>

      <AddressTable
        loading={loading}
        addresses={addresses}
        initialAddressId={billingAddressId}
        testId="order-addresses-billing"
        onChange={(billing: AddressSelection): void => setBillingAddressId(billing.id)}
      />
    </div>
  )
}

export type AddressChangeHandler = ({
  shipping,
  billing,
}: {
  shipping?: AddressSelection
  billing?: AddressSelection
}) => void

export type AddressSelection = {
  id: number
  default: boolean
  name: string
  address: string
  zipcode: string
  city: string
  phone: string
  state: string
  company: string
}

type SelectOrderAddressesProps = {
  customerId: number
  onChange: AddressChangeHandler
  initialShippingId?: number
  initialBillingId?: number
}

export default SelectOrderAddresses
