import React from 'react'
import { Tag } from 'antd'

import { MembershipStatusEnum } from '../../apollo/generated/api'

const statusColor = {
  NONE: 'blue',
  ACTIVE: 'green',
  STOPPED: 'red',
  PAUSED: 'gold',
}

export const formatMembershipStatus = (status: MembershipStatusEnum): React.ReactElement => (
  <Tag color={statusColor[status]}>{status}</Tag>
)
