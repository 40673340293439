import { Alert } from 'antd'
import moment from 'moment'
import React from 'react'

import { DeliveryOption, GetOrderManagementDetailsQuery } from '../../../apollo/generated/api'

const ValidOrderDelivery: React.FC<{ order: GetOrderManagementDetailsQuery['order'] }> = ({ order }) => {
  const getDeliveryDates = (): (string | null | undefined)[] => {
    return order?.deliveries?.flatMap(del => (del?.options as DeliveryOption[])?.map(opt => opt?.date)) || []
  }

  const getOrderDeliverySlots = (): number[] => {
    const deliveryDate = order?.deliveryDate
    const delivery = order?.deliveries?.length ? order.deliveries[0] : null

    return (
      (delivery?.options as DeliveryOption[])?.find(opt => opt.date === deliveryDate)?.slots.map(slot => slot.id) || []
    )
  }

  const validDeliveryDate = (): boolean => {
    const deliveryDate = order?.deliveryDate

    return getDeliveryDates()?.includes(deliveryDate)
  }

  const validDeliverySlot = (): boolean => {
    const delivery = order?.deliveries?.length ? order.deliveries[0] : null
    const timeSlotId = delivery?.timeSlot?.id || -1
    const deliverySlots = getOrderDeliverySlots()

    return timeSlotId !== undefined && !!deliverySlots?.find(id => id === timeSlotId)
  }

  const dateAfterToday = (): boolean => {
    const deliveryDate = order?.deliveryDate

    return deliveryDate !== undefined && moment(deliveryDate) >= moment()
  }

  const alreadyBilled = (): boolean => {
    const billDate = order?.billDate

    return billDate !== undefined && moment() >= moment(billDate)
  }

  if (alreadyBilled()) return <div />

  if (dateAfterToday() && (!validDeliveryDate() || !validDeliverySlot())) {
    return <Alert type="error" showIcon message="Order has invalid delivery data" />
  }

  return <div />
}

export default ValidOrderDelivery
