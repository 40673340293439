import ManageOrderAdjustments from './ManageOrderAdjustments'
import ManageOrderRecipes from './ManageOrderRecipes'
import ManageOrderDelivery from './ManageOrderDelivery'
import ManageOrderPayments from './ManageOrderPayments'
import ManageOrderShipments from './ManageOrderShipments'
import ManageOrderHistory from './ManageOrderHistory'
import ManageOrderPaymentDetails from './ManageOrderPayments/ManageOrderPaymentDetails'
import ManageOrderComplaints from './ManageOrderComplaints'
import ManageOrderAddress from './ManageOrderAddress'

const ManageOrder = {
  Adjustments: ManageOrderAdjustments,
  Delivery: ManageOrderDelivery,
  Recipes: ManageOrderRecipes,
  Payments: ManageOrderPayments,
  PaymentDetails: ManageOrderPaymentDetails,
  Shipments: ManageOrderShipments,
  History: ManageOrderHistory,
  Complaints: ManageOrderComplaints,
  AdressDetails: ManageOrderAddress,
}

export default ManageOrder
