import { LeftOutlined as LeftArrow, RightOutlined as RightArrow } from '@ant-design/icons'
import { Button, Space, Typography } from 'antd'
import React from 'react'

import { SimplePaginationContainer } from './styles'

type Props = {
  currentCount?: number
  totalCount?: number
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  onNextPage?: () => void
  onPreviousPage?: () => void
}

const SimplePagination = (props: Props): JSX.Element => {
  return (
    <SimplePaginationContainer role="navigation">
      <Space>
        {!!props.totalCount && (
          <Typography.Text type="secondary">
            {props.currentCount} of {props.totalCount}
          </Typography.Text>
        )}

        <Button
          aria-label="Previous"
          onClick={props.onPreviousPage}
          disabled={!props.hasPreviousPage}
          icon={<LeftArrow />}
        />

        <Button aria-label="Next" onClick={props.onNextPage} disabled={!props.hasNextPage} icon={<RightArrow />} />
      </Space>
    </SimplePaginationContainer>
  )
}

SimplePagination.defaultProps = {
  currentCount: 0,
  totalCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  onNextPage: undefined,
  onPreviousPage: undefined,
}

export default SimplePagination
