import React from 'react'
import { Card, Descriptions, Typography } from 'antd'

import { AdyenKey as ApiAdyenKey } from '../../../apollo/generated/api'

const { Text } = Typography

const AdyenKey: React.FC<{ adyenKey: ApiAdyenKey; methodName: string }> = ({ adyenKey, methodName }) => {
  const { pspReference } = adyenKey

  return (
    <Card title="Adyen">
      <Descriptions column={1} bordered>
        <Descriptions.Item label={`${methodName} Payment`}>
          <Text strong>{pspReference}</Text>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default AdyenKey
