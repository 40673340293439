import { Alert, Button, Form, FormInstance, FormItemProps, InputNumber, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useWatch } from 'antd/lib/form/Form'

import { CurrencyEnum, useGetOrderCurrencyQuery } from '../../../../apollo/generated/api'
import { buildOrderPrice, capitalize, currencySymbol, formatCurrency } from '../../../../utils'
import SelectCreditReasonInput from '../../../ManageCustomer/ManageCustomerCredit/SelectCreditReasonInput'
import { AffectedIngredient, ComplaintSubcategory } from '../../../../apollo/generated/beef'
import Loading from '../../../../packages/Loading'
import {
  fetchSuggestedCompensation,
  SuggestedComplaintCompensation,
  SuggestedComplaintCompensationInput,
  SuggestedComplaintWarning,
} from '../../../../services/SteakService'
import { ScopeNames } from '../../scopes'
import { OrderCreditWarning } from '../../../Shared/OrderCreditWarning/OrderCreditWarning'

enum CompensationTypes {
  CREDIT = 'credit',
  ADJUSTMENT = 'adjustment',
}

const { Option } = Select

const Compensation: React.FC<{
  orderNumber: string
  subcategory: ComplaintSubcategory | undefined
  form: FormInstance
}> = ({ orderNumber, subcategory, form }) => {
  const [compensationValue, setCompensationValue] = useState<number>(form.getFieldValue('compensationValue') ?? 0)
  const [grantedValue, setGrantedValue] = useState<number>(form.getFieldValue('grantedValue') ?? 0)
  const [compensationType, setCompensationType] = useState(
    compensationValue < 0 ? CompensationTypes.ADJUSTMENT : CompensationTypes.CREDIT,
  )
  const maxAllowedCredit = 999
  const [blockForm, setBlockForm] = useState(false)
  const { data, loading } = useGetOrderCurrencyQuery({ variables: { number: orderNumber } })
  const currency = data?.order?.pricing?.currency as CurrencyEnum
  const parseValueByCompensationType = (value: number) =>
    compensationType === CompensationTypes.ADJUSTMENT ? -Math.abs(value) : Math.abs(value)

  const handleCompensationValueChange = (value: number | null) => {
    setCompensationValue(parseValueByCompensationType(value as number))
    form.setFieldsValue({ compensationValue: parseValueByCompensationType(value as number) })
  }
  const affectedIngredients = useWatch('affectedIngredients', { form, preserve: true })
  const affectedAddOns = useWatch('affectedAddOns', { form, preserve: true })
  const complaintScope = useWatch('complaintScope', { form, preserve: true })
  const [suggestedCompensation, setSuggestedCompensation] = useState<SuggestedComplaintCompensation | null>(null)
  const selectedScopeName = subcategory?.scopes?.find(x => x.id === complaintScope)?.name

  const applySuggestedCompensation = (compensation: SuggestedComplaintCompensation | null) => {
    setSuggestedCompensation(compensation)
    form.setFieldsValue({ suggestedCompensationAmount: compensation?.compensation?.compensationAmount })
  }

  useEffect(() => {
    applySuggestedCompensation(null)
    if (blockForm) {
      return
    }

    const reqData: SuggestedComplaintCompensationInput = {
      complaintReason: subcategory?.id,
      orderNumber,
    }

    switch (selectedScopeName) {
      // allow only ingredient, add-on and delivery complaints
      case ScopeNames.Ingredients:
        reqData.ingredientIds = (affectedIngredients as AffectedIngredient[])?.map(value => value.id)
        if (!reqData.ingredientIds?.length) {
          return
        }
        break
      case ScopeNames.AddOn:
        reqData.addOnIds = extractAddonIds(affectedAddOns)
        if (!reqData.addOnIds?.length) {
          return
        }
        break
      case ScopeNames.WholeDelivery:
        break
      default:
        return
    }

    const req = fetchSuggestedCompensation(reqData)

    req.promise
      .then(value => {
        applySuggestedCompensation(value)
      })
      .catch(reason => {
        console.error(reason)
      })

    // eslint-disable-next-line consistent-return
    return () => {
      req.abort()
    }
  }, [subcategory?.id, affectedIngredients, affectedAddOns, selectedScopeName, blockForm])

  const handleCompensationTypeChange = (value: string) => {
    setCompensationType(value as CompensationTypes)
  }
  const selectAfter = (
    <Select
      data-testid="compensation-type-dropdown"
      value={compensationType}
      onChange={handleCompensationTypeChange}
      disabled={blockForm}
      style={{ width: '20%' }}
    >
      {Object.values(CompensationTypes).map(compensation => (
        <Option key={compensation} value={compensation} data-testid={compensation}>
          {capitalize(compensation)}
        </Option>
      ))}
    </Select>
  )
  const price = buildOrderPrice({ ...data?.order?.pricing, ...{ priceBreakdown: [] } })
  const isValidAdjustment = compensationValue ? Math.abs(compensationValue) <= price.totalWithTax : true
  const isValidCredit = compensationValue < maxAllowedCredit
  const isValidGrantedValue = grantedValue < maxAllowedCredit
  const formItemProps: Partial<FormItemProps> = {
    style: { display: 'flex', width: '100%' },
    label:
      compensationType === CompensationTypes.ADJUSTMENT ? (
        <span>
          What&apos;s the amount of the adjustment? (<b>Ex.: -35</b>)
        </span>
      ) : (
        <span>
          Please fill in the credit amount according to the credit policy (<b>Ex.: 35</b>)
        </span>
      ),
    tooltip:
      compensationType === CompensationTypes.ADJUSTMENT
        ? { title: 'Write a negative value', icon: <InfoCircleOutlined /> }
        : null,
    ...(compensationType === CompensationTypes.ADJUSTMENT && {
      validateStatus: !isValidAdjustment && !blockForm ? 'error' : '',
      help: !isValidAdjustment && !blockForm ? 'The adjustment amount must be not higher than the order total' : '',
    }),
    ...(compensationType === CompensationTypes.CREDIT && {
      validateStatus: !isValidCredit && !blockForm ? 'error' : '',
      help:
        !isValidCredit && !blockForm
          ? `You can't give value more than ${currencySymbol(currency)}${maxAllowedCredit}`
          : '',
    }),
  }

  const compensationIssued = (): string => {
    if (grantedValue > 0) {
      return formatCurrency(grantedValue, currency)
    }

    return formatCurrency(compensationValue, currency)
  }

  useEffect(() => {
    if (form.getFieldValue('resolvedAt')) {
      setBlockForm(true)
    }
  }, [])

  useEffect(() => {
    handleCompensationValueChange(compensationValue)
  }, [compensationType])

  const handleGrantedValueChange = (value: number | null) => {
    setGrantedValue(value as number)
    form.setFieldsValue({ grantedValue: value })
  }

  const defaultCreditReason = () => {
    if (form.getFieldValue('reasonId')) {
      const defaultValue = data?.order?.customer?.creditReasons.find(
        reason => reason.id === form.getFieldValue('reasonId'),
      )

      if (defaultValue) {
        return defaultValue.name
      }
    }

    if (data?.order?.customer?.creditReasons && subcategory) {
      const defaultValue = data?.order?.customer?.creditReasons.find(reason => reason.name === subcategory.name)

      if (defaultValue) {
        form.setFieldsValue({ reasonId: defaultValue.id })

        return defaultValue.name
      }
    }

    return undefined
  }

  if (loading) return <Loading />

  const hasWarnings = !!suggestedCompensation?.compensation?.warnings?.length

  return (
    <>
      {suggestedCompensation?.compensation && (
        <div
          style={{
            border: '1px solid #ffe58f',
            color: 'rgba(0,0,0,0.7)',
            padding: '8px',
            margin: '16px 0',
            background: '#ffe58f',
          }}
        >
          {hasWarnings && (
            <Alert
              showIcon
              message={getCompensationWarningMessage(suggestedCompensation?.compensation?.warnings)}
              type="warning"
            />
          )}

          {(suggestedCompensation?.compensation?.compensationAmount || 0) > 0 && (
            <>
              <p style={{ marginTop: '8px' }}>
                <span>
                  The <b>suggested compensation</b> according to the credit policy is
                  <b>
                    &nbsp;{currencySymbol(currency)}
                    {suggestedCompensation?.compensation?.compensationAmount}
                  </b>
                </span>
                <br />
              </p>
              {!blockForm && (
                <Button
                  type="primary"
                  onClick={() =>
                    handleCompensationValueChange(suggestedCompensation?.compensation?.compensationAmount || 0)
                  }
                >
                  Apply
                </Button>
              )}
            </>
          )}
        </div>
      )}
      <Form.Item
        name="compensationValue"
        {...formItemProps}
        data-testid="compensation"
        initialValue={compensationValue}
        rules={[
          {
            // eslint-disable-next-line @typescript-eslint/require-await
            validator: async () => {
              if (!blockForm) {
                if (compensationType === CompensationTypes.CREDIT && !isValidCredit) {
                  throw new Error(`You can't give value more than ${currencySymbol(currency)}${maxAllowedCredit}`)
                }
                if (compensationType === CompensationTypes.ADJUSTMENT && !isValidAdjustment) {
                  throw new Error('The adjustment amount must be not higher than the order total')
                }
              }
            },
          },
        ]}
      >
        <OrderCreditWarning orderNumber={orderNumber} creditAmount={compensationValue} />
        <InputNumber
          value={compensationValue}
          formatter={value => `${currencySymbol(currency)} ${value}`}
          parser={value => {
            const parsedValue = (value || '').replace(currency, '').replace(/[^0-9.-]/g, '')

            return parseValueByCompensationType(Number(parsedValue))
          }}
          disabled={blockForm}
          style={{ width: '80%' }}
          data-testid={compensationType === CompensationTypes.ADJUSTMENT ? 'adjustment-amount' : 'credit-amount'}
          onChange={handleCompensationValueChange}
        />
        {selectAfter}
      </Form.Item>
      {compensationType === CompensationTypes.CREDIT ? (
        <Form.Item
          label={
            <span>
              Please fill in the <b>total value</b> of the granted credit, if different than above (<b>Ex.: 35</b>)
            </span>
          }
          name="grantedValue"
          initialValue={grantedValue}
          rules={[
            {
              // eslint-disable-next-line @typescript-eslint/require-await
              validator: async () => {
                if (compensationType === CompensationTypes.CREDIT && !isValidGrantedValue && !blockForm) {
                  throw new Error(`You can't give value more than ${currencySymbol(currency)}${maxAllowedCredit}`)
                }
              },
            },
          ]}
        >
          <InputNumber
            formatter={value => `${currencySymbol(currency)} ${value}`}
            parser={value => {
              const parsedValue = (value || '').replace(currency, '').replace(/[^0-9.-]/g, '')

              return parseValueByCompensationType(Number(parsedValue))
            }}
            style={{ width: '100%' }}
            disabled={blockForm}
            onChange={handleGrantedValueChange}
            data-testid="granted-amount"
          />
        </Form.Item>
      ) : null}
      <SelectCreditReasonInput
        loading={loading}
        creditReasons={data?.order?.customer?.creditReasons || []}
        defaultValue={defaultCreditReason()}
        size="middle"
        disabled={blockForm}
        required={compensationValue !== 0 || grantedValue !== 0}
      />
      {compensationType === CompensationTypes.CREDIT && !blockForm && (compensationValue > 0 || grantedValue > 0) ? (
        <Alert
          message={`The credit that will be applied to this customer's account is ${compensationIssued()}.`}
          type="info"
          showIcon
        />
      ) : null}
      {blockForm ? (
        <Alert
          message="Compensation can't be edited"
          description={
            compensationValue < 0
              ? `An adjustment of ${compensationIssued()} has already been applied.`
              : `A credit of ${compensationIssued()} has already been applied.`
          }
          type="warning"
          showIcon
        />
      ) : null}
    </>
  )
}

export default Compensation

const getCompensationWarningMessage = (warnings: SuggestedComplaintWarning[]): string => {
  if (!warnings?.length) {
    return ''
  }

  const primaryWarning = warnings[0]

  if (!primaryWarning?.reason) {
    return ''
  }

  switch (primaryWarning.reason) {
    case 'max_global_exceeded':
      return 'The number of credits allowed for this region in total has been exceeded'
    case 'max_credit_percent_exceeded':
    case 'max_ingredient_credit_percent_exceeded':
    case 'max_add_on_credit_percent_exceeded':
      // eslint-disable-next-line max-len
      return 'The customer has claimed credits for too many items which is exceeding the recommended % of credits to grant in the complaint self-service'
    case 'incomplete_order':
      return 'The order has not been completed yet and therefore cannot be credited'
    case 'feature_unavailable':
      return 'Self-service disabled'
    case 'no_matching_config':
      return 'Delivery issue not mapped in the self-service. Please refer the Credit Policy.'
    case 'invalid_frequency':
      // eslint-disable-next-line max-len
      return `Customer has exceeded complaint rate for this month and thus is ineligible for more self-service credits for this month`
    case 'non_compensable_complaint_options':
      return 'The complaint reason chose is not one which we currently proactively grant credit for'
    case 'no_complaint_items':
      return 'Form invalid. Please check the fields'
    case 'non_compensable_classification':
      return 'Ingredient not mapped in the self-service. Please refer the Credit Policy'
    case 'not_automated':
      return 'Delivery issue not mapped in the self-service. Please refer the Credit Policy.'
    case 'prev_compensated_complaint':
      return 'Customer has complained multiple times for the same delivery complaint'
    default:
      return primaryWarning.reason.replaceAll('_', ' ')
  }
}

function extractAddonIds(affectedAddOns: string[]): string[] {
  if (!affectedAddOns?.length) {
    return []
  }

  return affectedAddOns.reduce((accum, str) => {
    if (str?.length) {
      const chunks = str?.split('|')

      if (chunks?.length > 0) {
        const num = Number(chunks[0])

        if (!Number.isNaN(num)) {
          accum.push(`${num}`)
        }
      }
    }

    return accum
  }, [] as string[])
}
