import React from 'react'
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DollarCircleOutlined,
  SafetyCertificateOutlined,
  MessageOutlined,
  LinkOutlined,
  ContainerOutlined,
} from '@ant-design/icons'

import { Routes } from '../../../constants/RoutesConstants'
import ContainerHeader, { ContainerHeaderMenu, ContainerHeaderAction } from '../../ContainerHeader'
import {
  CountryEnum,
  OrderAvailableAction,
  OrderStateEnum,
  Maybe,
  OrderAction,
  Invoice,
} from '../../../apollo/generated/api'

import { skipOrder, unskipOrder, cancelOrder, completeOrder, qualityControlOrder, logComplaint } from './actions'
import payOrder from './actions/payOrder/payOrder'
import generatePaymentLink from './actions/generatePaymentLink'
import generateInvoice from './actions/generateInvoice'
import regenerateInvoice from './actions/regenerateInvoice'

type ManageOrderHeaderProps = {
  order: {
    number: string
    state?: Maybe<OrderStateEnum> | undefined
    availableActions: ReadonlyArray<OrderAvailableAction>
    customerId: number
    country: CountryEnum
  }
  currentPath: string
}
const qualityControlDisabledCountries = [CountryEnum.Au, CountryEnum.Us]

const ManageOrderHeader: React.FC<ManageOrderHeaderProps> = ({ order, currentPath }) => {
  const headerConfiguration: {
    menu: ContainerHeaderMenu[]
    actions: ContainerHeaderAction<{
      number: string
      customerId: number
      availableActions: ReadonlyArray<OrderAvailableAction>
      state?: Maybe<OrderStateEnum> | undefined
      invoice?: Invoice | undefined
    }>[]
  } = {
    menu: [
      {
        route: {
          pattern: Routes.ORDER_DELIVERY,
          prefix: false,
        },
        icon: 'ORDER_DELIVERY',
        title: 'Delivery',
      },
      {
        route: {
          pattern: Routes.ORDER_RECIPES,
          prefix: true,
        },
        icon: 'ORDER_RECIPES',
        title: 'Recipes',
      },
      {
        route: {
          pattern: Routes.ORDER_SHIPMENTS,
          prefix: true,
        },
        icon: 'ORDER_SHIPMENTS',
        title: 'Shipments',
      },
      {
        route: {
          pattern: Routes.ORDER_PAYMENTS,
          prefix: true,
        },
        icon: 'ORDER_PAYMENTS',
        title: 'Payments',
      },
      {
        route: {
          pattern: Routes.ORDER_ADJUSTMENTS,
          prefix: true,
        },
        icon: 'ORDER_ADJUSTMENTS',
        title: 'Adjustments',
      },
      {
        route: {
          pattern: Routes.ORDER_COMPLAINTS,
          prefix: true,
        },
        icon: 'COMPLAIN_ICON',
        title: 'Complaints',
      },
      {
        route: {
          pattern: Routes.ORDER_HISTORY,
          prefix: true,
        },
        icon: 'ORDER_HISTORY',
        title: 'History',
      },
    ],
    actions: [
      {
        title: 'Skip Order',
        key: 'action-skip-order',
        icon: <MinusCircleOutlined />,
        onClick: skipOrder,
        isVisible: ({ availableActions }): boolean =>
          availableActions.some(({ action }): boolean => action === OrderAction.SkipOrder),
      },
      {
        title: 'Unskip Order',
        key: 'action-unskip-order',
        icon: <PlusCircleOutlined />,
        onClick: unskipOrder,
        isVisible: ({ availableActions }): boolean =>
          availableActions.some(({ action }): boolean => action === OrderAction.UnskipOrder),
      },
      {
        title: 'Cancel Order',
        key: 'action-cancel-order',
        icon: <CloseCircleOutlined />,
        onClick: cancelOrder,
        isVisible: ({ availableActions }): boolean =>
          availableActions.some(({ action }): boolean => action === OrderAction.CancelOrder),
      },
      {
        title: 'Complete Order',
        key: 'action-complete-order',
        icon: <CheckCircleOutlined />,
        onClick: completeOrder,
        isVisible: ({ availableActions }): boolean =>
          availableActions.some(({ action }): boolean => action === OrderAction.CompleteOrder),
      },
      {
        title: 'Pay Order',
        key: 'action-pay-order',
        icon: <DollarCircleOutlined />,
        onClick: payOrder,
        isVisible: (): boolean => true,
      },
      {
        title: 'Flag for Quality Control',
        key: 'action-qc-order',
        icon: <SafetyCertificateOutlined />,
        onClick: qualityControlOrder,
        isVisible: ({ availableActions }): boolean =>
          availableActions.some(({ action }): boolean => action === OrderAction.QualityControlOrder) &&
          !qualityControlDisabledCountries.includes(order.country),
      },
      {
        title: 'Log Complaint',
        key: 'action-log-complaint-order',
        icon: <MessageOutlined />,
        onClick: logComplaint,
        isVisible: (): boolean => true,
      },
      {
        title: 'Generate Payment Link',
        key: 'action-generate-payment-link-order',
        icon: <LinkOutlined />,
        onClick: generatePaymentLink,
        isVisible: ({ availableActions }): boolean =>
          availableActions.some(({ action }): boolean => action === OrderAction.GeneratePaymentLink),
      },
      {
        title: 'Generate Invoice',
        key: 'action-generate-invoice-order',
        icon: <ContainerOutlined />,
        onClick: generateInvoice,
        isVisible: ({ availableActions, invoice }): boolean =>
          availableActions.some(({ action }): boolean => action === OrderAction.GenerateInvoice && !invoice),
      },
      {
        title: 'Regenerate Invoice',
        key: 'action-regenerate-invoice-order',
        icon: <ContainerOutlined />,
        onClick: regenerateInvoice,
        isVisible: ({ invoice }): boolean => !!invoice?.link,
      },
      {
        title: 'Download Invoice',
        key: 'action-download-invoice-order',
        icon: <ContainerOutlined />,
        onClick: ({ invoice }) => invoice?.link && window.open(invoice.link, '_blank', 'noopener,noreferrer'),
        isVisible: ({ invoice }): boolean => !!invoice?.link,
      },
    ],
  }

  return (
    <ContainerHeader
      {...headerConfiguration}
      title={order.number}
      path={currentPath}
      object={order}
      defaultIcon="ORDER_DELIVERY"
    />
  )
}

export default ManageOrderHeader
