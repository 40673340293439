import { USER_PREFERENCES } from '../constants/AppConstants'
import { getLocalStorage, persistLocalStorage } from '../utils'

export type UserPreferencesType = {
  primaryTz: string
  secondaryTz: string
  // theme: string
}

const useUserPreferences = () => {
  const preferences: UserPreferencesType | null = getLocalStorage<UserPreferencesType | null>({
    key: USER_PREFERENCES,
    parse: true,
  })

  const savePreferences = (data: UserPreferencesType): void => {
    persistLocalStorage<UserPreferencesType>(USER_PREFERENCES, data)
  }
  const hasPreferences = preferences !== null

  return {
    preferences,
    savePreferences,
    hasPreferences,
  }
}

export default useUserPreferences
