import React from 'react'
import { Form, InputNumber } from 'antd'
import { Rule } from 'antd/lib/form'

import { currencySymbol } from '../../../../utils/priceUtils'
import { CurrencyEnum } from '../../../../apollo/generated/api'

const AmountInput: React.FC<AmountInputProps> = ({ loading, currency, accountCredit }) => {
  const maxAllowed = 999
  const prefix = currency ? currencySymbol(currency) : null
  const min = (accountCredit || 0) * -1
  const rules: Rule[] = [
    { required: true, message: 'You must choose a value.' },
    {
      min,
      type: 'number',
      transform: (value): number => (value ? parseFloat(value) : 0),
      message: `You can't remove more credit than the customer's current available credit.`,
    },
    {
      max: maxAllowed,
      type: 'number',
      transform: (value): number => (value ? parseFloat(value) : 0),
      message: `You can't give more credit than ${prefix}${maxAllowed}`,
    },
  ]

  return (
    <Form.Item name="amount" label={`Amount (${prefix})`} rules={rules} hasFeedback>
      <InputNumber
        data-testid="credit-amount"
        name="amount"
        type="number"
        placeholder="E.g.: -10"
        size="large"
        precision={2}
        step={0.01}
        disabled={loading}
        style={{ width: '100%' }}
      />
    </Form.Item>
  )
}

type AmountInputProps = {
  loading: boolean
  currency?: CurrencyEnum
  accountCredit?: number
}

export default AmountInput
