import BoxesIcon from '../assets/header/icons/Boxes.svg'
import BoxIcon from '../assets/header/icons/Box.svg'
import UsersIcon from '../assets/header/icons/Users.svg'
import ChefIcon from '../assets/header/icons/Chef.svg'
import SearchIcon from '../assets/header/icons/Search.png'
import OrderDeliveryIcon from '../assets/header/icons/OrderDelivery.png'
import BookHistoryIcon from '../assets/header/icons/BookHistory.svg'
import CardPaymentIcon from '../assets/header/icons/CardPayment.png'
import SetupIcon from '../assets/header/icons/Setup.svg'
import CustomerSupportIcon from '../assets/header/icons/CustomerSupport.png'
import PaperIcon from '../assets/header/icons/Paper.svg'
import HouseIcon from '../assets/header/icons/House.svg'
import FoodIcon from '../assets/header/icons/Food.svg'
import SettingsIcon from '../assets/header/icons/Settings.svg'
import FruitBoxIcon from '../assets/addons/icons/FruitBox.svg'
import CashBackIcon from '../assets/header/icons/CashBack.svg'
import ComplainIcon from '../assets/header/icons/Complain.svg'
import MailboxIcon from '../assets/header/icons/Mailbox.svg'

export const headerIcons = {
  ORDERS: BoxesIcon,
  CUSTOMERS: UsersIcon,
  SEARCH: ChefIcon,
  ORDER_DELIVERY: BoxIcon,
  CUSTOMERS_LIST: SearchIcon,
  ORDER_SHIPMENTS: OrderDeliveryIcon,
  ORDER_RECIPES: BookHistoryIcon,
  ORDER_PAYMENTS: CardPaymentIcon,
  ORDER_ADJUSTMENTS: SetupIcon,
  ORDER_HISTORY: BookHistoryIcon,
  CUSTOMER_DETAILS: CustomerSupportIcon,
  CUSTOMER_ORDERS: BoxIcon,
  CUSTOMER_PAYMENTS: CardPaymentIcon,
  CUSTOMER_ACCOUNT_HISTORY: BookHistoryIcon,
  CUSTOMER_CREDIT_HISTORY: CardPaymentIcon,
  CUSTOMER_PLAN: PaperIcon,
  CUSTOMER_RECIPE_PREFERENCES: FoodIcon,
  CUSTOMER_ADDRESSES: HouseIcon,
  CUSTOMER_MESSAGES: MailboxIcon,
  CUSTOMER_REWARDS: CashBackIcon,
  ADMIN_USERS: SettingsIcon,
  MASS_CREDIT: CashBackIcon,
  COMPLAIN_ICON: ComplainIcon,
  SHIPMENT_TRACKING: OrderDeliveryIcon,
}

export const addOnsIcons = {
  FRUITBOX: FruitBoxIcon,
}
