// eslint-disable camelcase
import Rollbar, { LogArgument } from 'rollbar'

import config from './config'

let logger: Logger
const filterUndefined = (value?: LogArgument): value is LogArgument => value !== undefined
const rollbarWrapper = (rollbar: Rollbar): Logger => ({
  setDefaultContext: (context): void => {
    rollbar.configure({ payload: { person: { id: context.email || '' } } })
  },
  debug: (message, context): void => {
    rollbar.debug(...[message, context].filter(filterUndefined))
  },
  info: (message, context): void => {
    rollbar.info(...[message, context].filter(filterUndefined))
  },
  error: (error, context): void => {
    rollbar.error(...[error, context].filter(filterUndefined))
  },
  warning: (message, context): void => {
    rollbar.warning(...[message, context].filter(filterUndefined))
  },
})

type LoggerContext = { email?: string }

export interface Logger {
  setDefaultContext: (context: LoggerContext) => void
  debug: (message?: string, context?: object) => void
  info: (message?: string, context?: object) => void
  error: (error?: string | Error, context?: object) => void
  warning: (error?: string | Error, context?: object) => void
}

export const initializeLogger = (context?: LoggerContext): Logger => {
  if (logger === undefined) {
    logger = rollbarWrapper(
      new Rollbar({
        accessToken: config.rollbarAccessToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        captureIp: 'anonymize',
        payload: {
          environment: config.stage,
          person: {
            id: context?.email || '',
          },
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: config.appVersion,
              guess_uncaught_frames: true,
            },
          },
        },
      }),
    )
  }

  return logger
}

export const useLogger = initializeLogger

export default useLogger
// eslint-enable camelcase
