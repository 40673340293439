import React, { useContext } from 'react'
import { Divider, Form, Modal, Select, Space, Typography } from 'antd'
import { ClockCircleOutlined, GlobalOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { tz } from 'moment-timezone'
import { DefaultOptionType } from 'antd/lib/select'
import { useForm } from 'antd/lib/form/Form'

import { UserPreferencesType } from '../../hooks/useUserPreferences'
import { UserPreferencesContext } from '../../contexts/UserPreferencesContextProvider/UserPreferencesContextProvider'

export interface UserPreferencesProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

export enum CustomTimezones {
  CUSTOMER = 'customer-timezone',
  FC = 'fc-timezone',
}

const { Text } = Typography
const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const basicTimezones = tz.names()
const tzOptions: DefaultOptionType[] = basicTimezones.map(tz => ({
  label: (
    <Space>
      <GlobalOutlined />
      {tz.replaceAll('_', ' ')}
    </Space>
  ),
  value: tz,
}))
const customOptions: DefaultOptionType[] = [
  {
    label: (
      <Space>
        <UserOutlined />
        Customer Timezone
      </Space>
    ),
    value: CustomTimezones.CUSTOMER,
  },
  // {
  //   label: (
  //     <Space>
  //       <ShopOutlined />
  //       FC Timezone
  //     </Space>
  //   ),
  //   value: CustomTimezones.FC,
  // },
]

tzOptions.unshift(...customOptions)

const UserPreferences: React.FC<UserPreferencesProps> = ({ visible, setVisible }) => {
  const [form] = useForm<UserPreferencesType>()
  const { preferences, savePreferences } = useContext(UserPreferencesContext)
  const initialValues: UserPreferencesType = {
    primaryTz: preferences?.primaryTz ?? (CustomTimezones.CUSTOMER as string),
    secondaryTz: preferences?.secondaryTz ?? browserTimezone,
  }

  const onSave = (values: UserPreferencesType) => {
    savePreferences(values)
    setVisible(false)
  }

  return (
    <Modal
      title={
        <Space>
          <SettingOutlined />
          <span>User Preferences</span>
        </Space>
      }
      open={visible}
      centered
      maskClosable
      closable
      keyboard={false}
      okText="Save"
      onOk={() => form.submit()}
      cancelText="Cancel"
      onCancel={() => setVisible(false)}
      destroyOnClose
    >
      <p>Here you can configure your user preferences.</p>
      <Divider orientation="left">
        <Space>
          <ClockCircleOutlined />
          <span>Timezones</span>
        </Space>
      </Divider>
      <p>
        <Text type="secondary">
          The primary timezone will be used to display the time and date in the application by default.
        </Text>
      </p>
      <Form form={form} initialValues={initialValues} onFinish={onSave}>
        <Form.Item label="Primary timezone" name="primaryTz">
          <Select options={tzOptions} showSearch />
        </Form.Item>
        <p>
          <Text type="secondary">The secondary timezone will be visible when hovering over the date.</Text>
        </p>
        <Form.Item label="Secondary timezone" name="secondaryTz">
          <Select options={tzOptions} showSearch />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UserPreferences
