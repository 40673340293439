import React from 'react'
import { Layout } from 'antd'

import { getFullYear } from '../../utils'

import MainContainerSideMenu from './MainContainerSideMenu'
import MainContainerHeader from './MainContainerHeader'
import * as styles from './styles'

const MainContainer: React.FC = ({ children }) => (
  <Layout style={styles.LAYOUT}>
    <MainContainerSideMenu />

    <Layout>
      <MainContainerHeader />
      <Layout.Content style={styles.CONTENT}>{children}</Layout.Content>
      <Layout.Footer style={styles.FOOTER}>&copy; {getFullYear()} Marley Spoon</Layout.Footer>
    </Layout>
  </Layout>
)

export default MainContainer
