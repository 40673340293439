import React from 'react'
import { Button, Dropdown, Grid, MenuProps, Space } from 'antd'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { DownOutlined } from '@ant-design/icons'

import { client } from '../../../apollo'

const { useBreakpoint } = Grid

type OnClickFunc<T> = (
  object: T,
  { history, client }: { history: History; client: ApolloClient<NormalizedCacheObject> },
) => void

export type ContainerHeaderAction<T extends object> = {
  title: string
  key: string
  icon?: React.ReactElement
  onClick?: OnClickFunc<T>
  isVisible?: (object: T) => boolean
}

type ContainerHeaderActionsProps<T extends object> = {
  actions: ContainerHeaderAction<T>[]
  object: T
}

const ContainerHeaderActions = <T extends object>(
  props: ContainerHeaderActionsProps<T>,
): React.ReactElement<ContainerHeaderActionsProps<T>> => {
  const { xl } = useBreakpoint()
  const history = useHistory()
  const actions = props.actions.filter(action => !action.isVisible || action.isVisible(props.object))
  const actionsMap = actions.map(({ key, title, icon, onClick }) => {
    return {
      key,
      onClick: (): void => (onClick ? onClick(props.object, { history, client }) : undefined),
      text: (
        <Space direction="horizontal">
          <span>{icon}</span>
          <span>{title}</span>
        </Space>
      ),
    }
  })
  const mobileMenu: MenuProps = {
    items: actionsMap.map(({ key, onClick, text }) => ({
      key,
      label: text,
      onClick,
    })),
  }
  const mobileActions = (
    <Dropdown data-testid="container-header-actions" menu={mobileMenu} placement="bottomRight">
      <Button>
        Actions <DownOutlined />
      </Button>
    </Dropdown>
  )
  const desktopActions = (
    <Space data-testid="container-header-actions">
      {actionsMap.map(({ key, onClick, text }) => {
        return (
          <Button style={{ fontSize: '95%' }} key={key} onClick={onClick}>
            {text}
          </Button>
        )
      })}
    </Space>
  )

  if (!actions.length) return <div />

  return xl ? desktopActions : mobileActions
}

export default ContainerHeaderActions
