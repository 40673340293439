import React from 'react'
import { Moment } from 'moment'

import { filterMaybe } from '../../../utils/typeUtils'
import { formatCurrency } from '../../../utils'
import { CurrencyEnum } from '../../../apollo/generated/api'

import OrderDeliveryDay from './OrderDeliveryDay'
import { SlotSelection, ApiDeliveryOptions } from './types'

export const mapDeliveryOptions = (
  options: ApiDeliveryOptions | null | undefined,
): { [key: string]: SlotSelection[] } =>
  // Typescript is not accepting to have the key as a string | undefined | null
  // We blame typescript for not being able to infer that the date can never be undefined/null and the api for
  // setting the date as nullable.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (options || []).filter(filterMaybe).reduce((days, { date, slots }) => {
    const validSlots = (slots || []).filter(filterMaybe).map(slot => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      from: slot.from?.toString(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      to: slot.to?.toString(),
      id: slot.id,
      shipper: slot.shipper?.name?.toString() || undefined,
      additionalShippingPrice: slot?.additionalShippingPrice,
      priceCurrency: slot?.priceCurrency,
    }))

    if (date) {
      return { ...days, [date]: validSlots }
    }

    return days
  }, {})

export const dateCellRenderer = (deliveryDays: { [key: string]: SlotSelection[] }) =>
  // eslint-disable-next-line react/display-name, func-names
  function (date: Moment): React.ReactNode {
    return (
      <OrderDeliveryDay
        date={date}
        slots={deliveryDays[date.format('YYYY-MM-DD')]}
        showExtraShippingPrice={showExtraShippingPrice}
      />
    )
  }

export const showExtraShippingPrice = (
  additionalShippingPrice: number | null | undefined,
  currency?: CurrencyEnum | null,
) => {
  if (additionalShippingPrice) {
    return `, ${formatCurrency(additionalShippingPrice || 0.0, currency)}`
  }

  return ''
}
