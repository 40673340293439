import React from 'react'
import { Result, Button, Typography } from 'antd'
import { ResultStatusType } from 'antd/lib/result'
import { Link } from 'react-router-dom'

import { Routes } from '../../constants/RoutesConstants'
import { GLOBAL_ERROR_MESSAGE } from '../../constants/ErrorConstants'

const { Text } = Typography
const GeneralError: React.FC<{ message: string; status?: ResultStatusType }> = ({ message, status = 500 }) => (
  <Result
    status={status}
    title={GLOBAL_ERROR_MESSAGE}
    subTitle={<Text copyable>{message}</Text>}
    extra={
      <Link to={Routes.SEARCH}>
        <Button type="primary">Back to search screen</Button>
      </Link>
    }
  />
)

export default GeneralError
