import React from 'react'
import { Table, Button, Alert, Result, Popconfirm, Typography, Tooltip, Divider } from 'antd'
import { Link, generatePath } from 'react-router-dom'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import {
  CLEAR_DEBT_SUCCESS,
  CLEAR_DEBT_FAILURE,
  CREATE_REFUND_FAILURE,
  CREATE_REFUND_SUCCESS,
} from '../../../constants/MessagesConstants'
import { formatCurrency } from '../../../utils'
import { Routes } from '../../../constants/RoutesConstants'
import {
  BrandEnum,
  CountryEnum,
  GetOrderPaymentsDocument,
  Maybe,
  OrderPaymentState,
  Payment,
  PaymentLogEntry,
  useClearDebtMutation,
  useCreateRefundMutation,
  useGetOrderPaymentsQuery,
} from '../../../apollo/generated/api'
import { handleMutationResult } from '../../../apollo'
import PaymentState from '../PaymentState'
import { queryNames } from '../../../apollo/client'
import { excludedBillingZipcodes } from '../../../constants/PaymentConstants'
import { filterMaybe } from '../../../utils/typeUtils'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

const { Column } = Table
const { Text } = Typography
const MissingPaymentMessage = (): JSX.Element => <Result status="info" title="This order doesn't have any payments" />

const ManageOrderPayments: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const queryVars = { variables: { number: orderNumber } }
  const { loading, error, data } = useGetOrderPaymentsQuery({ ...queryVars, fetchPolicy: 'no-cache' })
  const [createRefund, { loading: createRefundLoading }] = useCreateRefundMutation({
    refetchQueries: queryNames(GetOrderPaymentsDocument),
    awaitRefetchQueries: true,
  })
  const [clearDebt, { loading: clearDebtLoading }] = useClearDebtMutation({
    refetchQueries: queryNames(GetOrderPaymentsDocument),
    awaitRefetchQueries: true,
  })
  const { order } = data || {}
  const timezone = order?.customer?.timezone

  const handleRefund = (id: number): void => {
    const amount = order?.refundOptions?.refundableAmount

    if (!amount) {
      throw new Error(`Invalid refund amount: ${amount}`)
    }

    void handleMutationResult(createRefund({ variables: { id, amount } }), 'createRefund', {
      notifications: {
        success: {
          title: CREATE_REFUND_SUCCESS,
        },
        error: {
          title: CREATE_REFUND_FAILURE,
        },
      },
    })
  }

  const handleClearDebt = (uuid: Maybe<string> | undefined): void => {
    void handleMutationResult(clearDebt({ variables: { uuid: uuid || '' } }), 'clearDebt', {
      notifications: {
        success: {
          title: CLEAR_DEBT_SUCCESS,
        },
        error: {
          title: CLEAR_DEBT_FAILURE,
        },
      },
    })
  }

  const orderPaid = (): boolean => {
    return order?.paymentState === OrderPaymentState.Paid
  }

  /**
   *
   * We have a couple of zipcodes that are excluded from payment retries in AU,
   * This list will be updated as we expand the billing run to AU entirely.
   */
  const excludedFromBillingRun = (): boolean => {
    return (
      order?.brand === BrandEnum.Ms &&
      order.country === CountryEnum.Au &&
      excludedBillingZipcodes.includes(order.shippingAddress?.zipcode || '')
    )
  }

  const owingCustomer = (): boolean => {
    const amountOwed =
      order?.payments
        ?.filter(filterMaybe)
        .filter(p => p.state === 'pending' && p.owedAt !== null)
        .reduce((acc, payment) => acc + (payment?.amountPaid || 0), 0) || 0

    return amountOwed > 0
  }

  if (error) return <LoadComponentError errorMessage={error.message} />
  if (loading) return <Loading />
  if (order?.payments.length === 0) return <MissingPaymentMessage />

  return (
    <>
      {owingCustomer() && (
        <>
          <Alert
            type="error"
            description="This customer is owing money, to cancel the order the debt must be cleared first"
            message="Customer in debt"
            style={{ marginTop: 20 }}
            showIcon
          />
          <Divider />
        </>
      )}

      {data?.order?.payments && (
        <Table
          data-testid="order-management-payments-table"
          dataSource={order?.payments}
          rowKey="id"
          bordered
          pagination={false}
          expandable={{
            expandedRowRender: (payment): JSX.Element => (
              <Table dataSource={payment?.logEntries} pagination={false} rowKey="id">
                <Column
                  title="ID"
                  render={(log: PaymentLogEntry): JSX.Element => (
                    <Text code copyable>
                      {log?.id}
                    </Text>
                  )}
                />
                <Column title="Message" dataIndex="message" />
              </Table>
            ),
            rowExpandable: (payment): boolean => payment?.logEntries?.length > 0,
          }}
        >
          <Column
            title="Date and Time"
            render={(payment: Payment) => (
              <UserAwareDateWithTz datetime={payment?.createdAt as string} customerTimezone={timezone} />
            )}
          />

          <Column
            title="Amount"
            render={(payment: Payment): string => formatCurrency(payment?.amountPaid || 0, order?.pricing?.currency)}
          />

          <Column
            title="Payment Method"
            render={(payment: Payment): string => (payment?.method && payment?.method?.name) || ''}
          />

          <Column
            title="Payment State"
            render={(payment: Payment): JSX.Element => {
              if (payment?.state) {
                return <PaymentState state={payment.state} />
              }

              return <div />
            }}
          />

          <Column
            title="Trusted"
            align="center"
            render={(payment: Payment) => {
              if (payment.owedAt) {
                return (
                  <Tooltip
                    title="This payment will be reattempted.
                    Meanwhile, the associated order will be processed normally."
                  >
                    <Popconfirm
                      data-testid="clear-debt-popconfirm"
                      title="Are you sure you want to clear debt on this payment?"
                      onConfirm={(): void => handleClearDebt(payment?.uuid)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" data-testid="clear-debt-button" disabled={clearDebtLoading || orderPaid()}>
                        Clear Debt
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                )
              }

              return <div />
            }}
          />

          <Column
            title="Refund"
            render={(payment: Payment): JSX.Element => {
              if (order?.refundOptions?.refundableAmount) {
                const formattedAmount = formatCurrency(order?.refundOptions.refundableAmount, order?.pricing?.currency)

                return (
                  <Popconfirm
                    data-testid="refund-popconfirm"
                    title={`Are you sure you want to refund ${formattedAmount}?`}
                    onConfirm={(): void => handleRefund(payment?.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" data-testid="refund-button" disabled={createRefundLoading}>
                      {createRefundLoading ? 'Applying ...' : formattedAmount}
                    </Button>
                  </Popconfirm>
                )
              }

              return <div />
            }}
          />

          <Column
            render={(payment: Payment): JSX.Element => {
              const paymentPath = generatePath(Routes.ORDER_PAYMENT_DETAIL, {
                number: orderNumber,
                id: payment?.id,
              })

              return (
                <Link data-testid="order-sidebar-customer" to={paymentPath}>
                  View
                </Link>
              )
            }}
          />
        </Table>
      )}

      {excludedFromBillingRun() && (
        <Alert
          type="warning"
          description="This order is currently excluded from the 2nd billing run in AU"
          message="Second Billing Run"
          style={{ marginTop: 20 }}
          showIcon
        />
      )}

      {order?.refundOptions?.error?.message && (
        <Alert
          message="Refund Warning"
          description={data?.order?.refundOptions?.error?.message}
          type="warning"
          style={{ marginTop: 20 }}
          showIcon
        />
      )}
    </>
  )
}

export default ManageOrderPayments
