import React, { useContext, useEffect, useState } from 'react'
import { Layout, Typography, Row, Col, Dropdown, Switch, MenuProps } from 'antd'
import { UserOutlined, BulbFilled, BulbOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons'

import { getDayName, getUserSession } from '../../../utils'
import * as Authentication from '../../../components/Authentication'
import { ThemeContext } from '../../../contexts/ThemeContextProvider'
import UserPreferences from '../../../components/UserPreferences/UserPreferences'
import { UserPreferencesContext } from '../../../contexts/UserPreferencesContextProvider/UserPreferencesContextProvider'

import * as styles from './styles'

const { Text } = Typography

const MainContainerHeader: React.FC = () => {
  const { session } = getUserSession()
  const avatarProps = session?.user ? { src: session.user.picture } : { icon: <UserOutlined /> }
  const { theme, switchTheme } = useContext(ThemeContext)
  const [userPreferencesVisible, setUserPreferencesVisible] = useState(false)
  const { hasPreferences } = useContext(UserPreferencesContext)
  const accountMenu: MenuProps & { 'data-testid': string } = {
    'data-testid': 'account-menu',
    items: [
      {
        label: 'Preferences',
        key: 'menu-user-preferences-item',
        icon: <SettingOutlined />,
        onClick: () => setUserPreferencesVisible(true),
      },
      {
        key: 'divider',
        type: 'divider',
      },
      {
        label: <Authentication.Logout />,
        key: 'menu-logout-item',
        icon: <LogoutOutlined />,
      },
    ],
  }

  useEffect(() => {
    // show user preferences modal if user has not set preferences yet
    if (!hasPreferences) {
      setUserPreferencesVisible(true)
    }
  }, [])

  return (
    <Layout.Header style={styles.HEADER}>
      <Row justify="end">
        <Col span={18}>
          <Switch
            checkedChildren={<BulbFilled />}
            unCheckedChildren={<BulbOutlined />}
            checked={theme === 'dark'}
            onChange={switchTheme}
            data-testid="theme-switch"
          />
        </Col>
        <Col span={4}>
          <styles.User>
            <Text strong>Hi {session?.user.givenName}</Text>
            <Text>Happy {getDayName()}</Text>
          </styles.User>
        </Col>
        <Col span={2}>
          <Dropdown menu={accountMenu} placement="bottom" arrow trigger={['click']}>
            <span data-testid="user-avatar">
              <styles.Avatar {...avatarProps} alt="Avatar" />
            </span>
          </Dropdown>
        </Col>
      </Row>
      <UserPreferences visible={userPreferencesVisible} setVisible={setUserPreferencesVisible} />
    </Layout.Header>
  )
}

export default MainContainerHeader
