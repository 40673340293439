import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'

import config from '../config'

const REQUEST_TIMEOUT = 10000

export enum IncidentStatuses {
  LOGGED = 'logged',
  CLOSED = 'closed',
}

export type Incident = {
  uuid: string
  incidentId: number
  raisedAt: string
  loggedAt: string
  status: IncidentStatuses
  incidentType: string
  issueDescription: string
  rootCauseCategory: string
  mitigativeAction: string
  link: string
  deliveryCalendarWeek: number | null
  orderNumber: string
}

type IncidentResponse = {
  data: {
    incidents: Incident[]
  }
}

export const incidentAffectedOrderRequest = (number: string): Promise<Incident[]> =>
  axios
    .get<string, IncidentResponse>(`${config.onionApiBaseUrl}/cookbook/affected_orders/${number}`, {
      timeout: REQUEST_TIMEOUT,
      headers: { authorization: config.onionApiToken },
    })
    .then(response => response.data.incidents)
    .then(incidents => camelcaseKeys(incidents, { deep: true }))

export const incidentCustomerAffectedOrders = ({
  email,
  brand,
  status,
  rootCauseCategory,
  calendarWeekFrom,
  calendarWeekTo,
}: {
  email: string
  brand: string
  status?: IncidentStatuses | null
  rootCauseCategory?: string | null
  calendarWeekFrom?: number | null
  calendarWeekTo?: number | null
}): Promise<Incident[]> => {
  const query = new URLSearchParams()

  query.append('brand', brand)

  if (status) {
    query.append('status', status)
  }

  if (rootCauseCategory) {
    query.append('root_cause_category', rootCauseCategory)
  }

  if (calendarWeekFrom) {
    query.append('from_week', calendarWeekFrom.toString())
  }

  if (calendarWeekTo) {
    query.append('to_week', calendarWeekTo.toString())
  }

  return axios
    .get<string, IncidentResponse>(
      `${config.onionApiBaseUrl}/cookbook/affected_customers/${email}?${query.toString()}`,
      {
        timeout: REQUEST_TIMEOUT,
        headers: { authorization: config.onionApiToken },
      },
    )
    .then(response => response.data.incidents)
    .then(incidents => camelcaseKeys(incidents, { deep: true }))
}
