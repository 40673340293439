export const ORDER_ID_REQUIRED_ERROR = 'Order ID is required'
export const EMAIL_REQUIRED_ERROR = 'Email is required'
export const CONNECTION_DOWN_MESSAGE = 'Looks like your internet connection is playing up.'
export const CONNECTION_RESTORED_MESSAGE = `You're good to go, refresh the page.`
export const GLOBAL_ERROR_MESSAGE = 'Uh oh!'
export const FIELD_REQUIRED_ERROR = 'Field is required'
export const FIELD_NEGATIVE_VALUE_REQUIRED = 'Field must have a negative value.'
export const CREATE_ADJUSTMENT_ERROR = 'There was an error creating the adjustment.'
export const UPDATE_DELIVERY_ERROR = 'There was an error updating the order delivery'
export const PASSWORD_REQUIRED_ERROR = 'Password is required'
