import { Form, Select } from 'antd'
import React from 'react'

import { Option as OptionType } from '../../../../apollo/generated/beef'
import { required } from '../formConfig'

const { Option } = Select

const QualityIssue: React.FC<{ options: OptionType[] }> = ({ options }) => {
  return (
    <Form.Item name="qualityIssue" label="Pick a quality issue" rules={[required]}>
      <Select
        data-testid="quality-issue-select"
        mode="multiple"
        placeholder="Pick a quality issue please"
        optionFilterProp="children"
        showSearch
      >
        {options?.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default QualityIssue
