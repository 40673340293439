import { Table } from 'antd'
import React from 'react'

const { Column } = Table

const PreviewMassCredit: React.FC<{ massCredit: MassCreditInput[] }> = ({ massCredit }) => {
  return (
    <Table dataSource={massCredit} rowKey="orderNumber" pagination={false}>
      <Column title="Number" dataIndex="orderNumber" />
      <Column title="Amount" dataIndex="amount" />
    </Table>
  )
}

export type MassCreditInput = {
  orderNumber: string
  amount: number
}

export default PreviewMassCredit
