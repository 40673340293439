import { OrderStateEnum } from '../apollo/generated/api'

export const mappedStatus: { [state in OrderStateEnum]: string | null } = {
  [OrderStateEnum.Pending]: 'orange',
  [OrderStateEnum.Placed]: 'processing',
  [OrderStateEnum.Payment]: 'blue',
  [OrderStateEnum.PaymentAuthenticationRequired]: 'yellow',
  [OrderStateEnum.PaymentAuthorizationRequired]: 'orange',
  [OrderStateEnum.Complete]: 'green',
  [OrderStateEnum.Cancelled]: 'volcano',
  [OrderStateEnum.Skipped]: 'gold',
}
