import { Form, FormInstance, Input } from 'antd'
import React, { useEffect } from 'react'

import { useGetOrderDeliveryDetailsQuery } from '../../../../apollo/generated/api'

const ShippingPartner: React.FC<{ form: FormInstance; orderNumber: string }> = ({ form, orderNumber }) => {
  const { data } = useGetOrderDeliveryDetailsQuery({ variables: { number: orderNumber } })

  useEffect(() => {
    const shipperName = data?.order?.deliveries?.[0]?.timeSlot?.shipper?.name

    form.setFieldsValue({ shippingPartner: shipperName })
  }, [data])

  return (
    <Form.Item name="shippingPartner" label="Shipping Partner">
      <Input disabled />
    </Form.Item>
  )
}

export default ShippingPartner
