import { notification } from 'antd'

import { client } from '../../../../../apollo'
import { UNLOCK_ACCOUNT_SUCCESS, UNLOCK_ACCOUNT_FAILURE, ERROR_TITLE } from '../../../../../constants/MessagesConstants'
import {
  UnlockAccountDocument,
  UnlockAccountMutation,
  UnlockAccountMutationVariables,
} from '../../../../../apollo/generated/api'

const unlockAccount = ({ id }: { id: number }): Promise<void> => {
  return client
    .mutate<UnlockAccountMutation, UnlockAccountMutationVariables>({
      mutation: UnlockAccountDocument,
      variables: { id },
    })
    .then(data => {
      if (data.errors && data.errors.length > 0) {
        notification.error({
          message: ERROR_TITLE,
          description: data.errors.map(error => error.message),
        })
      } else {
        notification.success({
          message: UNLOCK_ACCOUNT_SUCCESS,
        })
      }
    })
    .catch(() => {
      notification.error({
        message: ERROR_TITLE,
        description: UNLOCK_ACCOUNT_FAILURE,
      })
    })
}

export default unlockAccount
