import React from 'react'
import { Form, FormInstance, Radio } from 'antd'

import { useFoodSafetyContext } from '../../../../../contexts/FoodSafetyContextProvider/FoodSafetyContextProvider'

const BoxOrIcePackDamaged: React.FC<{ form: FormInstance }> = () => {
  const { detailsDisabled } = useFoodSafetyContext()

  return (
    <Form.Item
      name={['fsqaDetails', 'boxIcePackDamaged']}
      label="Box/Ice Pack Damaged?"
      rules={[{ required: !detailsDisabled }]}
    >
      <Radio.Group disabled={detailsDisabled}>
        <Radio data-testid="box-ice-pack-damaged-yes" value style={{ marginRight: 25 }}>
          Yes
        </Radio>

        <Radio data-testid="box-ice-pack-damaged-no" value={false} style={{ marginRight: 25 }}>
          No
        </Radio>
      </Radio.Group>
    </Form.Item>
  )
}

export default BoxOrIcePackDamaged
