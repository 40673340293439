import { Form, FormInstance, Input } from 'antd'
import React, { useState, useEffect } from 'react'

import { required } from '../formConfig'
import { useGetOrderShipmentsQuery } from '../../../../apollo/generated/api'
import { ScopeNames } from '../../scopes'

const TrackingNumber: React.FC<{ form: FormInstance; orderNumber: string; scope?: ScopeNames | null }> = ({
  form,
  orderNumber,
  scope,
}) => {
  const [disabled, setDisabled] = useState(false)
  const { data } = useGetOrderShipmentsQuery({ variables: { number: orderNumber } })
  const shipments = data?.order?.deliveries?.[0]?.shipments

  useEffect(() => {
    // default - first shipment
    let trackingNumber = shipments?.[0].tracking.number

    if (shipments) {
      switch (scope) {
        // rule for fruitbox and addons shipment
        case ScopeNames.Fruitbox:
        case ScopeNames.AddOn:
          trackingNumber = shipments.find(shipment => (shipment.addOns || []).length > 0)?.tracking.number
          break
        default:
          break
      }
    }

    form.setFieldsValue({ trackingNumber })
    setDisabled(!!trackingNumber)
  }, [data, scope])

  return (
    <Form.Item name="trackingNumber" label="Tracking Number" rules={[required]}>
      <Input placeholder="Ex.: 19284782394" disabled={disabled} />
    </Form.Item>
  )
}

export default TrackingNumber
