
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AddressResponse": [
      "Address",
      "MutationErrorWrapper"
    ],
    "AdjustmentResponse": [
      "Adjustment",
      "MutationErrorWrapper"
    ],
    "AdjustmentSource": [
      "IncentiveClaim",
      "Promotion",
      "TaxRate"
    ],
    "AdminUserResponse": [
      "AdminUser",
      "MutationErrorWrapper"
    ],
    "CustomerAddressResponse": [
      "CustomerAddress",
      "MutationErrorWrapper"
    ],
    "CustomerCreditResponse": [
      "MutationErrorWrapper",
      "UserCredit"
    ],
    "CustomerPlanResponse": [
      "MutationErrorWrapper",
      "UserPlan"
    ],
    "CustomerResponse": [
      "Customer",
      "MutationErrorWrapper"
    ],
    "DeleteCustomerAddressResponse": [
      "DeletedCustomerAddress",
      "MutationErrorWrapper"
    ],
    "DeliveryOptionFilterResponse": [
      "DeliveryOptionFilter",
      "MutationErrorWrapper"
    ],
    "DeliveryOptionInterface": [
      "DeliveryOption",
      "VisitFirstDeliveryDateOption"
    ],
    "IncentiveItem": [
      "AddOn",
      "AddOnReward",
      "OrderDiscountReward"
    ],
    "Ingredient": [
      "AssumedIngredient",
      "ShippedIngredient"
    ],
    "MassCreditResponse": [
      "CreatedUserCredits",
      "MutationErrorWrapper"
    ],
    "MutateAddOnRecipeMatchingRuleResponse": [
      "AddOnRecipeMatchingRule",
      "MutationErrorWrapper"
    ],
    "OptionInterface": [
      "Card",
      "RecipeLike",
      "Slider"
    ],
    "OrderPriceInterface": [
      "OrderPricing",
      "OrderVirtualPrice"
    ],
    "OrderResponse": [
      "MutationErrorWrapper",
      "Order"
    ],
    "PaymentCredentials": [
      "AdyenKey",
      "BankAccount",
      "CreditCard",
      "DirectDebit",
      "InvoiceRecipient",
      "PaypalCustomer",
      "PreapprovalKey"
    ],
    "PaymentLinkResponse": [
      "MutationErrorWrapper",
      "PaymentLink"
    ],
    "PaymentResponse": [
      "MutationErrorWrapper",
      "Payment"
    ],
    "PlanInterface": [
      "Plan"
    ],
    "RecipeInterface": [
      "OrderRecipe",
      "Recipe"
    ],
    "RecipeMatchCondition": [
      "RecipeMatchFieldCondition",
      "RecipeMatchGroupCondition"
    ],
    "RecipeRatingInterface": [
      "UserRecipeRating"
    ],
    "ReferralInterface": [
      "ReferralEmail",
      "ReferralLink"
    ],
    "SkuInterface": [
      "Sku"
    ],
    "StopMembershipResponse": [
      "Customer",
      "MutationErrorWrapper"
    ],
    "TasteProfileResponse": [
      "MutationErrorWrapper",
      "TasteProfile"
    ],
    "TimeSlotInterface": [
      "SelectedTimeSlotOption",
      "TimeSlot"
    ],
    "UserChallengeResponse": [
      "FrequencyBasedUserChallenge",
      "ProgressBasedUserChallenge"
    ],
    "UserChallengeSharedInterface": [
      "EligibleChallenge",
      "FrequencyBasedUserChallenge",
      "ProgressBasedUserChallenge"
    ],
    "WeekdayDeliveryOptionInterface": [
      "UserPlanDeliveryOption",
      "VisitDeliveryOption"
    ],
    "_Entity": [
      "Adjustment",
      "AffectedOrder",
      "IncentiveClaim",
      "Order",
      "Promotion"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddressResponse": [
      "Address",
      "MutationErrorWrapper"
    ],
    "AdjustmentResponse": [
      "Adjustment",
      "MutationErrorWrapper"
    ],
    "AdjustmentSource": [
      "IncentiveClaim",
      "Promotion",
      "TaxRate"
    ],
    "AdminUserResponse": [
      "AdminUser",
      "MutationErrorWrapper"
    ],
    "CustomerAddressResponse": [
      "CustomerAddress",
      "MutationErrorWrapper"
    ],
    "CustomerCreditResponse": [
      "MutationErrorWrapper",
      "UserCredit"
    ],
    "CustomerPlanResponse": [
      "MutationErrorWrapper",
      "UserPlan"
    ],
    "CustomerResponse": [
      "Customer",
      "MutationErrorWrapper"
    ],
    "DeleteCustomerAddressResponse": [
      "DeletedCustomerAddress",
      "MutationErrorWrapper"
    ],
    "DeliveryOptionFilterResponse": [
      "DeliveryOptionFilter",
      "MutationErrorWrapper"
    ],
    "DeliveryOptionInterface": [
      "DeliveryOption",
      "VisitFirstDeliveryDateOption"
    ],
    "IncentiveItem": [
      "AddOn",
      "AddOnReward",
      "OrderDiscountReward"
    ],
    "Ingredient": [
      "AssumedIngredient",
      "ShippedIngredient"
    ],
    "MassCreditResponse": [
      "CreatedUserCredits",
      "MutationErrorWrapper"
    ],
    "MutateAddOnRecipeMatchingRuleResponse": [
      "AddOnRecipeMatchingRule",
      "MutationErrorWrapper"
    ],
    "OptionInterface": [
      "Card",
      "RecipeLike",
      "Slider"
    ],
    "OrderPriceInterface": [
      "OrderPricing",
      "OrderVirtualPrice"
    ],
    "OrderResponse": [
      "MutationErrorWrapper",
      "Order"
    ],
    "PaymentCredentials": [
      "AdyenKey",
      "BankAccount",
      "CreditCard",
      "DirectDebit",
      "InvoiceRecipient",
      "PaypalCustomer",
      "PreapprovalKey"
    ],
    "PaymentLinkResponse": [
      "MutationErrorWrapper",
      "PaymentLink"
    ],
    "PaymentResponse": [
      "MutationErrorWrapper",
      "Payment"
    ],
    "PlanInterface": [
      "Plan"
    ],
    "RecipeInterface": [
      "OrderRecipe",
      "Recipe"
    ],
    "RecipeMatchCondition": [
      "RecipeMatchFieldCondition",
      "RecipeMatchGroupCondition"
    ],
    "RecipeRatingInterface": [
      "UserRecipeRating"
    ],
    "ReferralInterface": [
      "ReferralEmail",
      "ReferralLink"
    ],
    "SkuInterface": [
      "Sku"
    ],
    "StopMembershipResponse": [
      "Customer",
      "MutationErrorWrapper"
    ],
    "TasteProfileResponse": [
      "MutationErrorWrapper",
      "TasteProfile"
    ],
    "TimeSlotInterface": [
      "SelectedTimeSlotOption",
      "TimeSlot"
    ],
    "UserChallengeResponse": [
      "FrequencyBasedUserChallenge",
      "ProgressBasedUserChallenge"
    ],
    "UserChallengeSharedInterface": [
      "EligibleChallenge",
      "FrequencyBasedUserChallenge",
      "ProgressBasedUserChallenge"
    ],
    "WeekdayDeliveryOptionInterface": [
      "UserPlanDeliveryOption",
      "VisitDeliveryOption"
    ],
    "_Entity": [
      "Adjustment",
      "AffectedOrder",
      "IncentiveClaim",
      "Order",
      "Promotion"
    ]
  }
};
      export default result;
    