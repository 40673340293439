export const ERROR_TITLE = 'Error'

export const CUSTOMER_EMAIL_SAVED_SUCCESS = 'Email address successfully saved'
export const CUSTOMER_EMAIL_SAVED_ERROR = 'Failed saving email address'

export const CUSTOMER_PASSWORD_CHANGED_SUCCESS = 'Password changed successfully'
export const CUSTOMER_PASSWORD_CHANGED_ERROR = 'Failed changing customer password'

export const RESET_CUSTOMER_PASSWORD_SUCCESS = 'Reset password email sent'
export const RESET_CUSTOMER_PASSWORD_ERROR = 'Failed to send reset password email'

export const CREATE_CREDIT_SUCCESS = 'Account credit successfully applied'
export const CREATE_CREDIT_ERROR = `Couldn't apply the credit`

export const CREDIT_REASONS_ERROR = 'Failed fetching credit reasons'
export const CUSTOMER_ORDERS_ERROR = 'Failed fetching customer orders'

export const RECIPES_UPDATED_SUCCESS = 'Recipes successfully updated'
export const ADDONS_UPDATED_SUCCESS = 'Add-ons successfully updated'

export const NOTIFICATION_TITLE_FAILURE = 'Oh damn it!'

export const COUPON_APPLIED_SUCCESS = 'Coupon successfully applied'
export const COUPON_APPLIED_FAILURE = `We couldn't apply this coupon to this order`

export const CUSTOMER_ADDRESS_UPDATED_SUCCESS = 'The address was successfully updated'
export const CUSTOMER_ADDRESS_UPDATED_FAILURE = 'There was an error updating the address'
export const CUSTOMER_ADDRESS_CREATED_SUCCESS = 'The address was successfully created'
export const CUSTOMER_ADDRESS_CREATED_FAILURE = 'There was an error creating the address'
export const CUSTOMER_ADDRESS_DELETED_SUCCESS = 'The address was successfully deleted'
export const CUSTOMER_ADDRESS_DELETED_FAILURE = 'There was an error deleting the address'

export const CUSTOMER_ADDRESS_CONFIRM_DELETION_TITLE = 'Are you sure you want to delete this address?'
export const CUSTOMER_ADDRESS_CONFIRM_DELETION_CONTENT = 'Once deleted, the address cannot be recovered.'

export const SKIP_ORDER_CONFIRM_TITLE = 'Skip Order'
export const SKIP_ORDER_CONFIRM_CONTENT = `Skipping the order will prevent it to be delivered.
Are you sure you want to continue?`
export const SKIP_ORDER_SUCCESS = 'The order was successfully skipped'
export const SKIP_ORDER_FAILURE = `The order couldn't be skipped`

export const PAY_ORDER_CONFIRM_TITLE = 'Pay Order'
export const PAY_ORDER_CONFIRM_CONTENT = `Paying the order will charge the customer on its default payment method.
Are you sure you want to continue?`
export const PAY_ORDER_SUCCESS = 'The order was successfully paid'
export const PAY_ORDER_FAILURE = `The order couldn't be paid`

export const GENERATE_INVOICE_CONFIRM_TITLE = 'Generate Invoice for Order'
export const GENERATE_INVOICE_SUCCESS = 'The invoice was successfully generated.'
export const GENERATE_INVOICE_FAILURE = `The invoice couldn't be generated`

export const REGENERATE_INVOICE_CONFIRM_TITLE = 'Regenerate Invoice for Order'
export const REGENERATE_INVOICE_SUCCESS = 'The invoice was successfully regenerated.'
export const REGENERATE_INVOICE_FAILURE = `The invoice couldn't be regenerated`

export const QUALITY_CONTROL_ORDER_CONFIRM_TITLE = 'Flag Order for Quality Control'
export const QUALITY_CONTROL_ORDER_CONFIRM_CONTENT = `Flag this order for quality control on the FC?`
export const QUALITY_CONTROL_ORDER_SUCCESS = 'The order was successfully flagged for quality control'
export const QUALITY_CONTROL_ORDER_FAILURE = `The order couldn't be flagged for quality control`

export const COMPLETE_ORDER_CONFIRM_TITLE = 'Complete Order'
export const COMPLETE_ORDER_CONFIRM_CONTENT = 'Mark the order as complete? (Ready for processing)'
export const COMPLETE_ORDER_SUCCESS = 'The order was successfully marked as completed'
export const COMPLETE_ORDER_FAILURE = `The order couldn't be marked as complete`

export const CANCEL_ORDER_SUCCESS = 'The order was successfully cancelled'
export const CANCEL_ORDER_FAILURE = `The order couldn't be cancelled`
export const CANCEL_REASONS_LOADING_FAILURE = `Couldn't load cancellation reasons`

export const UNLOCK_ACCOUNT_SUCCESS = 'Customer account was unlocked'
export const UNLOCK_ACCOUNT_FAILURE = 'Failed to unlock customer account'

export const UNSKIP_ORDER_CONFIRM_TITLE = 'Unskip Order'
export const UNSKIP_ORDER_CONFIRM_CONTENT = `Unskipping the order will reschedule it for delivery.
Are you sure you want to continue?`
export const UNSKIP_ORDER_CONFIRM_BUTTON = 'Yes, unskip the order'
export const UNSKIP_ORDER_SUCCESS = 'The order was successfully unskipped'
export const UNSKIP_ORDER_FAILURE = `The order couldn't be unskipped`

export const GENERATE_PAYMENT_LINK_CONFIRM_TITLE = 'Generate Payment Link'
export const GENERATE_PAYMENT_LINK_FAILURE = `Unable to generate Payment Link`

export const VOID_PAYMENT_SOURCES_SUCCESS = `Customer payment info was removed successfully.`
export const VOID_PAYMENT_SOURCES_FAILURE = `Failed to remove customer payment info.`
export const PAYMENTS_LOADING_FAILURE = `Couldn't load payments information`

export const NOT_FOUND = `The page you're looking for doesn't exist.`

export const SLOTS_NOT_AVAILABLE = 'There are no slots available at the moment.'

export const GOOGLE_LOGIN_BUTTON_ERROR = 'There was an error interacting with Google Login: '
export const GOOGLE_LOGOUT_BUTTON_ERROR = 'There was an error logging you out. Please try again.'
export const GOOGLE_AUTHENTICATION_FAILED = 'Could not login as there was an issue processing the response from Google.'
export const API_AUTHENTICATION_FAILED = `There was an error authenticating against Marley Spoon's API.
Please try again.`

export const CREATE_REFUND_SUCCESS = 'Refund successfully applied'
export const CREATE_REFUND_FAILURE = `We couldn't apply a refund to this order`

export const CLEAR_DEBT_SUCCESS = 'Debt cleared on payment!'
export const CLEAR_DEBT_FAILURE = `We couldn't apply a clear debt on this order`

export const CREATE_ADJUSTMENT_SUCCESS = 'The adjustment was successfully created'
export const DELETE_ADJUSTMENT_SUCCESS = 'Adjustment successfully deleted'
export const DELETE_ADJUSTMENT_ERROR = 'Could not delete adjustment'

export const ORDER_DELIVERY_UPDATED = 'The order delivery information was successfully updated'
export const ORDER_DELIVERY_UPDATE_FAILED = 'There was an error updating the order delivery information'

export const CREATE_ORDER_SUCCESS = 'The order was created successfully'
export const KEEP_GOING = `You're good to go. Keep going.`

export const NETWORK_ERROR = 'There was an error communicating with the server. Please try again.'
export const UNKNOWN_ERROR = 'There was an unknown error.'
export const MUTATION_SUCCESS = 'The operation was executed successfully'

export const SAVE_RECIPE_PREFERENCES_SUCCESS = 'Recipe preferences successfully saved'
export const SAVE_RECIPE_PREFERENCES_FAILURE = 'There was an error saving recipe preferences'

export const SAVE_NOTIFICATION_SETTINGS_SUCCESS = 'Customer notification settings successfully updated'
export const SAVE_NOTIFICATION_SETTINGS_FAILURE = 'There was an error updating the customer notification settings'

export const UPDATE_ADMIN_USER_SUCCESS = 'Admin user successfully update'
export const UPDATE_ADMIN_USER_FAILURE = 'There was an error updating an admin user'

export const CREATE_ADMIN_USER_SUCCESS = 'Admin user successfully created'
export const CREATE_ADMIN_USER_FAILURE = 'There was an error creating an admin user'

export const ANONYMIZE_USER_TITLE = 'Are you sure you want to delete the customer information?'
// eslint-disable-next-line max-len
export const ANONYMIZE_USER_CONTENT = `All the customer data, except for postal code, will be updated with a random combination of letters and numbers. The Iterable info will also be deleted.`
export const ANONYMIZE_USER_SUCCESS = 'User info was anonymized successfully.'
export const ANONYMIZE_USER_ERROR = 'Failed to anonymize user info.'

export const COMPLAINT_CONFIRM_TITLE = 'Log complaint'
export const COMPLAINT_CONFIRM_CONTENT = `This order is not yet completed.
Are you sure you want to log a complaint?`
export const COMPLAINT_CONFIRM_BUTTON = 'Yes, log complaint'

export const DELETE_COMPLAINT_SUCCESS = 'Complaint successfully deleted.'
export const DELETE_COMPLAINT_ERROR = 'Failed to delete the complaint.'

export const CREATE_COMPLAINT_SUCCESS = 'Complaint successfully created.'
export const CREATE_COMPLAINT_FAILURE = 'Could not create the complaint.'

export const UPDATE_COMPLAINT_SUCCESS = 'Complaint successfully updated.'
export const UPDATE_COMPLAINT_FAILURE = 'Could not update the complaint.'

export const IN_PRODUCTION_WARNING = `This order is being produced.
Please be aware that any changes made now might not take effect and
won't be considered in the fulfillment of this order. Are you sure you want to proceed?`

export const INVALID_ADDRESS_WARNING = `The address submitted did not pass our third
  party checks, make sure everything is correct.`
