import React from 'react'
import { Table, PageHeader, Typography, Tag } from 'antd'
import { Link, generatePath, RouteComponentProps } from 'react-router-dom'
import { FlagIconCode } from 'react-flag-kit'

import { headerIcons } from '../../constants/IconsConstants'
import { Routes } from '../../constants/RoutesConstants'
import Loading from '../../packages/Loading'
import GeneralError from '../../packages/GeneralError'
import { filterMaybe } from '../../utils/typeUtils'
import CountryFlag from '../../packages/CountryFlag'
import BrandIcon from '../../packages/BrandIcon'
import {
  MembershipStatusEnum,
  SearchCustomerAddressQuery,
  useSearchCustomerAddressQuery,
} from '../../apollo/generated/api'
import MembershipLabel from '../MembershipLabel'
import UserAwareDateWithTz from '../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

import SearchCustomerAddressResultsEmpty from './SearchCustomerAddressResultsEmpty'

type SearchResult = NonNullable<NonNullable<NonNullable<SearchCustomerAddressQuery['customerAddressSearch']>>[number]>

const { Text } = Typography
const anonymisedEmailPattern = '.com.invalid'
const columns = [
  {
    title: 'Brand',
    render: (result: SearchResult) => <BrandIcon brand={result.customer.brand} />,
    width: 70,
  },
  {
    title: 'Country',
    render: (result: SearchResult) => <CountryFlag country={result.customer.country?.iso as FlagIconCode} />,
  },
  {
    title: 'Email address',
    render: (result: SearchResult) => (
      <div>
        {(result.customer.email || '').search(anonymisedEmailPattern) > 0 ? (
          <Tag color="red" data-testid="anonymised-icon">
            Anonymised Account
          </Tag>
        ) : (
          <Text copyable>{result.customer.email}</Text>
        )}
      </div>
    ),
  },
  {
    title: 'Membership Status',
    key: 'status',
    render: (result: SearchResult) => (
      <MembershipLabel
        status={result.customer.membership?.status || MembershipStatusEnum.None}
        pausedUntil={result.customer.membership?.pausedUntil}
        customerTimezone={result.customer.timezone}
      />
    ),
  },
  {
    title: 'Member Since',
    render: (result: SearchResult) =>
      result.customer.membership?.startedAt ? (
        <UserAwareDateWithTz
          datetime={result.customer.membership.startedAt}
          customerTimezone={result.customer.timezone}
        />
      ) : (
        <span>-</span>
      ),
  },
  {
    title: 'Address',
    render: (result: SearchResult) => (
      <>
        {[
          result.address.line1,
          result.address.line2,
          result.address.city,
          result.address.zipcode,
          result.address.state?.name,
        ]
          .filter(a => a !== null && a !== undefined && a.length > 0)
          .join(', ')}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (result: SearchResult) => (
      <span>
        <Link to={generatePath(Routes.CUSTOMER_DETAIL, { id: result.customer.id as number })}>Manage customer</Link>
      </span>
    ),
  },
]

const SearchCustomerAddressResults: React.FC<RouteComponentProps<{ query: string }>> = ({
  match: {
    params: { query },
  },
  history: { goBack },
}) => {
  const queryVars = {
    variables: {
      query,
    },
  }
  const { loading, error, data } = useSearchCustomerAddressQuery({ ...queryVars })
  const results = (data?.customerAddressSearch || []).filter(filterMaybe)

  if (loading) return <Loading />
  if (error) return <GeneralError message={error.message} />
  if (!results.length) return <SearchCustomerAddressResultsEmpty searchParam={query} />

  return (
    <>
      <PageHeader
        title={`Search results for: ${query}`}
        avatar={{
          src: headerIcons.CUSTOMERS_LIST,
          shape: 'square',
        }}
        onBack={(): void => goBack()}
      />

      <Table data-testid="search-customers-address-results-table" dataSource={results} columns={columns} rowKey="id" />
    </>
  )
}

export default SearchCustomerAddressResults
