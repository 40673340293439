import React from 'react'
import { Row, Col, PageHeader } from 'antd'

import { getUserSession } from '../../utils'
import SearchOrderForm from '../Forms/SearchOrderForm'
import SearchCustomerForm from '../Forms/SearchCustomerEmailForm'
import SearchComplaintForm from '../Forms/SearchComplaintForm'
import { headerIcons } from '../../constants/IconsConstants'
import SearchCustomerAddressForm from '../Forms/SearchCustomerAddressForm'

const Search: React.FC = () => {
  const name = getUserSession().session?.user.givenName
  const SPAN = 24
  const LG_SPAN = 12
  const XXL_SPAN = 6

  return (
    <>
      <PageHeader
        title={`Howdy ${name}, what's cooking?`}
        avatar={{
          src: headerIcons.SEARCH,
          shape: 'square',
        }}
      />
      <Row justify="center" gutter={[32, 32]}>
        <Col span={SPAN} xxl={XXL_SPAN} xl={LG_SPAN}>
          <SearchOrderForm />
        </Col>
        <Col span={SPAN} xxl={XXL_SPAN} xl={LG_SPAN}>
          <SearchCustomerForm />
        </Col>
        <Col span={SPAN} xxl={XXL_SPAN} xl={LG_SPAN}>
          <SearchCustomerAddressForm />
        </Col>
        <Col span={SPAN} xxl={XXL_SPAN} xl={LG_SPAN}>
          <SearchComplaintForm />
        </Col>
      </Row>
    </>
  )
}

export default Search
