import React from 'react'
import { Row, Col } from 'antd'
import { Route, Switch, useParams, useLocation } from 'react-router-dom'
import { ApolloError } from '@apollo/client'

import Loading from '../../packages/Loading'
import GeneralError from '../../packages/GeneralError'
import * as customProps from '../../constants/OverridesConstants'
import { Routes } from '../../constants/RoutesConstants'
import ManageCustomer from '../../components/ManageCustomer'
import { DeliveryOption, ManageCustomerProps } from '../../components/ManageCustomer/props'
import {
  BrandEnum,
  CountryEnum,
  CurrencyEnum,
  CustomerAvailableAction,
  GetCustomerQuery,
  IngredientPreference,
  MembershipStatusEnum,
  RecipePreference,
  useGetCustomerQuery,
} from '../../apollo/generated/api'
import { useGetSteakUserInfoQuery } from '../../apollo/generated/steak'

import ManageCustomerHeader from './ManageCustomerHeader'
import ManageCustomerEmpty from './ManageCustomerEmpty'
import ManageCustomerSidebar from './ManageCustomerSidebar'

const mapCustomer = (data: GetCustomerQuery): ManageCustomerProps['customer'] => ({
  id: data.customer?.id || 0,
  email: data.customer?.email || '',
  country: data.customer?.country?.iso || CountryEnum.Us,
  brand: data.customer?.brand || BrandEnum.Ms,
  accountCredit: data.customer?.accountCredit || 0,
  trusted: data.customer?.trusted || false,
  timezone: data.customer?.timezone || '',
  membership: {
    startedAt: data.customer?.membership?.startedAt || '',
    startDeliveryDates: data.customer?.membership?.startDeliveryDates || [],
    status: data.customer?.membership?.status || MembershipStatusEnum.None,
    pausedUntil: data.customer?.membership?.pausedUntil,
  },
  deliveryStats: {
    numberOfOrders: data.customer?.deliveryStats?.numberOfOrders || 0,
    totalRevenue: data.customer?.deliveryStats?.totalRevenue || 0,
    currency: data.customer?.deliveryStats?.currency || CurrencyEnum.Usd,
    averageNetPricePerBox: data.customer?.deliveryStats?.averageNetPricePerBox || 0,
  },
  userPlan: {
    weekday: data.customer?.userPlan?.weekday || 0,
    fruitBox: {
      available: data.customer?.userPlan?.fruitBox.available || false,
      enabled: data.customer?.userPlan?.fruitBox.enabled || false,
      pricePerUnit: data.customer?.userPlan?.fruitBox.pricePerUnit || null,
      servings: data.customer?.userPlan?.fruitBox.servings || null,
    },
    timeSlot: {
      id: data.customer?.userPlan?.timeSlot?.id || -1,
    },
    deliveryOptions: (data.customer?.userPlan?.deliveryOptions as DeliveryOption[]) || [],
    plan: {
      planIdentifier: data.customer?.userPlan?.plan?.planIdentifier || '',
      displayTitle: data.customer?.userPlan?.plan?.displayTitle || '',
      segment: data.customer?.userPlan?.plan?.segment || '',
    },
  },
  recipePreferences: (data.customer?.recipePreferences as RecipePreference[]) || [],
  ingredientPreferences: (data.customer?.ingredientPreferences as IngredientPreference[]) || [],
  addressMeta: {
    isStateRequired: data?.customer?.addressMeta.isStateIdRequired === true,
    isTypeRequired: data?.customer?.addressMeta.isTypeRequired === true,
  },
  availableActions: data.customer?.availableActions as CustomerAvailableAction[],
  flags: data.customer?.flags || [],
  appUrl: data.customer?.appUrl || '',
})

interface UseCustomerData {
  customer?: ManageCustomerProps['customer']
  error?: ApolloError
  loading?: boolean
}

const useGetCustomer = (customerId: number): UseCustomerData => {
  const getCustomerQuery = useGetCustomerQuery({ variables: { id: customerId } })
  const getSteakUser = useGetSteakUserInfoQuery({ variables: { id: customerId } })
  const isLoading = getCustomerQuery.loading || getSteakUser.loading
  const error = getCustomerQuery.error || getSteakUser.error

  if (isLoading || error) {
    return {
      loading: isLoading,
      error,
    }
  }

  return {
    customer: {
      ...mapCustomer(getCustomerQuery.data as GetCustomerQuery),
      creditRevenueTier: getSteakUser.data?.userInfo?.creditRevenueTier ?? -1,
    },
  }
}

const ManageCustomerContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const customerId = parseInt(id, 10)
  const { pathname } = useLocation()
  const { loading, error, customer } = useGetCustomer(customerId)

  if (loading) return <Loading />
  if (error) return <GeneralError message={error.message} />
  if (!customer) return <ManageCustomerEmpty customerId={customerId} />

  const withCustomerProps = (WrappedComponent: React.ElementType) =>
    // eslint-disable-next-line react/display-name, func-names
    function (): JSX.Element {
      return <WrappedComponent customer={customer} />
    }

  return (
    <>
      <ManageCustomerHeader customer={customer} currentPath={pathname} />
      <Row {...customProps.ROW}>
        <Col span={16}>
          <Switch>
            <Route exact path={Routes.CUSTOMER_ORDERS} render={withCustomerProps(ManageCustomer.Orders)} />
            <Route exact path={Routes.CUSTOMER_PLAN} render={withCustomerProps(ManageCustomer.Plan)} />
            <Route
              exact
              path={Routes.CUSTOMER_RECIPE_PREFERENCES}
              render={withCustomerProps(ManageCustomer.RecipePreferences)}
            />
            <Route exact path={Routes.CUSTOMER_ADDRESSES} render={withCustomerProps(ManageCustomer.ListAddresses)} />
            <Route exact path={Routes.CUSTOMER_DETAIL} render={withCustomerProps(ManageCustomer.Account)} />
            <Route
              exact
              path={Routes.CUSTOMER_ADDRESS_CREATION}
              render={withCustomerProps(ManageCustomer.CreateAddress)}
            />
            <Route exact path={Routes.CUSTOMER_ADDRESS_DETAIL} render={withCustomerProps(ManageCustomer.EditAddress)} />
            <Route
              exact
              path={Routes.CUSTOMER_PAYMENT_SOURCES}
              render={withCustomerProps(ManageCustomer.PaymentSources)}
            />
            <Route exact path={Routes.CUSTOMER_PAYMENT_SOURCE_DETAIL} component={ManageCustomer.PaymentSource} />
            <Route
              exact
              path={Routes.CUSTOMER_ACCOUNT_HISTORY}
              render={withCustomerProps(ManageCustomer.AccountHistory)}
            />
            <Route
              exact
              path={Routes.CUSTOMER_CREDIT_HISTORY}
              render={withCustomerProps(ManageCustomer.CreditHistory)}
            />
            <Route exact path={Routes.CUSTOMER_ORDER_CREATION} render={withCustomerProps(ManageCustomer.CreateOrder)} />
            <Route exact path={Routes.CUSTOMER_MESSAGE} render={withCustomerProps(ManageCustomer.Message)} />
            <Route exact path={Routes.CUSTOMER_MESSAGES} render={withCustomerProps(ManageCustomer.Messages)} />
            <Route exact path={Routes.CUSTOMER_REWARDS} render={withCustomerProps(ManageCustomer.Rewards)} />
            <Route exact path={Routes.CUSTOMER_REFERRALS} render={withCustomerProps(ManageCustomer.Referrals)} />
            <Route exact path={Routes.CUSTOMER_COMPLAINTS} render={withCustomerProps(ManageCustomer.Complaints)} />
            <Route exact path={Routes.CUSTOMER_INCIDENTS} render={withCustomerProps(ManageCustomer.Incidents)} />
          </Switch>
        </Col>

        <Col span={8}>
          <ManageCustomerSidebar customer={customer} />
        </Col>
      </Row>
    </>
  )
}

export default ManageCustomerContainer
