import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import { Typography } from 'antd'

export enum DateFormats {
  DateText = 'dddd, MMM D, yyyy',
  DateTimeText = 'dddd, MMM D, yyyy, HH:mm:ss',
}

export type TimeWithTimezoneProps = {
  datetime: string
  timezone?: string | null
  format?: DateFormats
}

/**
 * America/Los_Angeles => Los Angeles
 */
export const formatTimezone = (timezone: string) => {
  const timezoneParts = timezone.split('/')
  const short = timezoneParts.length > 1 ? timezoneParts[1] : timezoneParts[0]

  return short.replaceAll('_', ' ')
}

const TimeWithTimeZone: React.FC<TimeWithTimezoneProps> = ({ datetime, timezone, format }) => {
  const tz = timezone || 'UTC'
  const formatted = moment.tz(datetime, tz).format(format || DateFormats.DateTimeText)
  const tzShort = formatTimezone(tz)

  return (
    <>
      <span>{formatted}</span> <Typography.Text type="secondary">{tzShort}</Typography.Text>
    </>
  )
}

export default TimeWithTimeZone
