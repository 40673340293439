import React, { FunctionComponent } from 'react'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'

type CountryFlagProps = {
  country: FlagIconCode
  size?: number
}

const CountryFlag: FunctionComponent<CountryFlagProps> = ({ country, size = 38 }) => (
  <FlagIcon code={country} style={{ width: size }} />
)

export default CountryFlag
