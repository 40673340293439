/* eslint-disable max-len */
import React from 'react'

const EmailClickIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" style={style} fill="none">
    <mask id="path-1-inside-1_3249_3484">
      <rect x="0.5" y="2.5" width="18" height="14" rx="1" fill="white" />
    </mask>
    <rect
      x="0.5"
      y="2.5"
      width="18"
      height="14"
      rx="1"
      strokeWidth="2.8"
      mask="url(#path-1-inside-1_3249_3484)"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M1.5 3.5L6.76772 7.45079M17.5 3.5L12.3798 7.34016"
      strokeWidth="1.4"
      strokeLinecap="round"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M12.85 9.5C12.85 11.3502 11.3502 12.85 9.5 12.85C7.64985 12.85 6.15 11.3502 6.15 9.5C6.15 7.64985 7.64985 6.15 9.5 6.15C11.3502 6.15 12.85 7.64985 12.85 9.5Z"
      strokeWidth="1.3"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M10.5 9.5C10.5 10.0523 10.0523 10.5 9.5 10.5C8.94772 10.5 8.5 10.0523 8.5 9.5C8.5 8.94772 8.94772 8.5 9.5 8.5C10.0523 8.5 10.5 8.94772 10.5 9.5Z"
      fill="currentColor"
    />
  </svg>
)

export default EmailClickIcon
