import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
};

export type ApiQueriesRoot = {
  __typename?: 'ApiQueriesRoot';
  deliveryTracking?: Maybe<Array<Maybe<DeliveryTracking>>>;
  shippingDates?: Maybe<Array<Maybe<ShippingDate>>>;
};


export type ApiQueriesRootDeliveryTrackingArgs = {
  deliveryDate?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  shipmentNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueriesRootShippingDatesArgs = {
  brand?: InputMaybe<BrandEnum>;
  country: CountryEnum;
  shipmentDateFrom: Scalars['Date']['input'];
  shipmentDateTo: Scalars['Date']['input'];
};

export enum BrandEnum {
  Dn = 'DN',
  Ms = 'MS'
}

export enum CountryEnum {
  At = 'AT',
  Au = 'AU',
  Be = 'BE',
  De = 'DE',
  Dk = 'DK',
  Ms = 'MS',
  Nl = 'NL',
  Us = 'US'
}

export type DeliveryTracking = {
  __typename?: 'DeliveryTracking';
  brand?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  shipmentCountry?: Maybe<Scalars['String']['output']>;
  shipmentLastUpdateAttempt?: Maybe<Scalars['String']['output']>;
  shipmentNumber?: Maybe<Scalars['String']['output']>;
  shipmentTrackingActivities?: Maybe<Array<Maybe<ShipmentTrackingActivity>>>;
  shipmentTrackingFirstScan?: Maybe<Scalars['String']['output']>;
  shipmentTrackingShipperStatuCode?: Maybe<Scalars['String']['output']>;
  shipmentTrackingShipperStatus?: Maybe<Scalars['String']['output']>;
  shipmentTrackingStatus?: Maybe<Scalars['String']['output']>;
  shipmentTrackingUpdateFrequency?: Maybe<Scalars['String']['output']>;
  shipperEmail?: Maybe<Scalars['String']['output']>;
  shipperInformation?: Maybe<Scalars['String']['output']>;
  shipperName?: Maybe<Scalars['String']['output']>;
  shipperPhone?: Maybe<Scalars['String']['output']>;
  shipperSlack?: Maybe<Scalars['String']['output']>;
  trackingLink?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
};

export type ShipmentTrackingActivity = {
  __typename?: 'ShipmentTrackingActivity';
  address?: Maybe<Scalars['String']['output']>;
  boxPlacedLocation?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  internalStatus?: Maybe<Scalars['String']['output']>;
  internalStatusDetails?: Maybe<Scalars['String']['output']>;
  otherDeliveryInfo?: Maybe<Scalars['String']['output']>;
  receivedBy?: Maybe<Scalars['String']['output']>;
  shipperStatus?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  timezoneOffset?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type ShippingDate = {
  __typename?: 'ShippingDate';
  deliveryDates?: Maybe<Array<Maybe<Scalars['Date']['output']>>>;
  shipmentDate?: Maybe<Scalars['Date']['output']>;
};

export type GetShipmentTrackingQueryVariables = Exact<{
  shipmentNumber: Scalars['String']['input'];
}>;


export type GetShipmentTrackingQuery = { __typename?: 'ApiQueriesRoot', deliveryTracking?: Array<{ __typename?: 'DeliveryTracking', shipmentNumber?: string | null, trackingNumber?: string | null, shipperName?: string | null, shipperPhone?: string | null, shipperEmail?: string | null, shipperSlack?: string | null, shipperInformation?: string | null, trackingLink?: string | null, shipmentTrackingActivities?: Array<{ __typename?: 'ShipmentTrackingActivity', internalStatus?: string | null, internalStatusDetails?: string | null, shipperStatus?: string | null, timestamp?: string | null, country?: string | null, state?: string | null, city?: string | null, zipcode?: string | null, address?: string | null, receivedBy?: string | null, boxPlacedLocation?: string | null, timezoneOffset?: string | null } | null> | null } | null> | null };


export const GetShipmentTrackingDocument = gql`
    query GetShipmentTracking_Logistics($shipmentNumber: String!) {
  deliveryTracking(shipmentNumber: $shipmentNumber) {
    shipmentNumber
    trackingNumber
    shipperName
    shipperPhone
    shipperEmail
    shipperSlack
    shipperInformation
    trackingLink
    shipmentTrackingActivities {
      internalStatus
      internalStatusDetails
      shipperStatus
      timestamp
      country
      state
      city
      zipcode
      address
      receivedBy
      boxPlacedLocation
      timezoneOffset
    }
  }
}
    `;
export function useGetShipmentTrackingQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentTrackingQuery, GetShipmentTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentTrackingQuery, GetShipmentTrackingQueryVariables>(GetShipmentTrackingDocument, options);
      }
export function useGetShipmentTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentTrackingQuery, GetShipmentTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentTrackingQuery, GetShipmentTrackingQueryVariables>(GetShipmentTrackingDocument, options);
        }
export type GetShipmentTrackingQueryHookResult = ReturnType<typeof useGetShipmentTrackingQuery>;
export type GetShipmentTrackingLazyQueryHookResult = ReturnType<typeof useGetShipmentTrackingLazyQuery>;
export type GetShipmentTrackingQueryResult = Apollo.QueryResult<GetShipmentTrackingQuery, GetShipmentTrackingQueryVariables>;