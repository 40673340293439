export const getCreditRevenueTierData = (
  tier: number | undefined,
): {
  visible: boolean
  text?: string
  color?: string
} => {
  const color = {
    0: '#0aff01',
    1: '#d9ead3',
    2: '#fce5cd',
    3: '#ff9900',
    4: '#f4cccc',
    5: '#ff0e00',
  }[`${tier}` as string]

  if (!color) {
    return { visible: false }
  }

  return {
    visible: true,
    text: `Tier ${tier}`,
    color,
  }
}
