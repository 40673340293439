import React from 'react'
import { Space, Tooltip, Typography } from 'antd'

import TimeWithTimeZone, { DateFormats } from '../TimeWithTimezone/TimeWithTimezone'

const { Text } = Typography

export type TimeWithDoubleTimezoneProps = {
  datetime: string
  tzPrimary: string
  tzSecondary: string
  formatPrimary?: DateFormats
  formatSecondary?: DateFormats
  useTooltip?: boolean
}

const TimeWithDoubleTimeZone = ({
  datetime,
  tzPrimary,
  tzSecondary,
  useTooltip,
  formatPrimary,
  formatSecondary,
}: TimeWithDoubleTimezoneProps) => {
  if (useTooltip === true) {
    return (
      <Tooltip title={<TimeWithTimeZone datetime={datetime} timezone={tzSecondary} format={formatSecondary} />}>
        <div>
          <TimeWithTimeZone datetime={datetime} timezone={tzPrimary} format={formatPrimary} />
        </div>
      </Tooltip>
    )
  }

  return (
    <Space direction="vertical">
      <TimeWithTimeZone datetime={datetime} timezone={tzPrimary} format={formatPrimary} />
      <div style={{ fontSize: 12 }}>
        <Text type="secondary">
          <TimeWithTimeZone datetime={datetime} timezone={tzSecondary} format={formatSecondary} />
        </Text>
      </div>
    </Space>
  )
}

export default TimeWithDoubleTimeZone
