import React from 'react'
import { Card, Row, Col, FormInstance } from 'antd'

import FoodSafetyIssue from '../FoodSafetyIssue'
import ShippingPartner from '../ShippingPartner'
import TrackingNumber from '../TrackingNumber'
import LogisticsDropdown from '../LogisticsDropdown'
import AffectedItem from '../AffectedItem'
import QualityIssue from '../QualityIssue'
import RecurringIssue from '../RecurringIssue'
import ComplaintDescription from '../ComplaintDescription'
import Compensation from '../Compensation'
import { Rule, ComplaintCategory, Attachment, ComplaintSubcategory } from '../../../../apollo/generated/beef'
import ComplaintAttachments from '../ComplaintAttachments'
import ExistingAttachments from '../ExistingAttachments'
import { ScopeNames } from '../../scopes'

// NOTE: this should be a Beef enum (Rule.name)
type ComponentName =
  | 'Food Safety Issue'
  | 'Shipping Partner'
  | 'Tracking Number'
  | 'Logistics Code'
  | 'Affected Item'
  | 'Quality Issue'

type ComponentType =
  | typeof FoodSafetyIssue
  | typeof ShippingPartner
  | typeof TrackingNumber
  | typeof LogisticsDropdown
  | typeof AffectedItem
  | typeof QualityIssue

type Component = Record<ComponentName, ComponentType>

const RuleComponent: Component = {
  'Food Safety Issue': FoodSafetyIssue,
  'Shipping Partner': ShippingPartner,
  'Tracking Number': TrackingNumber,
  'Logistics Code': LogisticsDropdown,
  'Affected Item': AffectedItem,
  'Quality Issue': QualityIssue,
}
const colProps = {
  style: { margin: '20px 0' },
  span: 12,
}

const Rules: React.FC<{
  rules: Rule[]
  orderNumber: string
  category: ComplaintCategory | undefined
  subcategory: ComplaintSubcategory | undefined
  form: FormInstance
  recurring: boolean
  scope?: ScopeNames | null
}> = ({ rules, orderNumber, category, subcategory, form, recurring, scope }) => {
  return (
    <Card
      title="Fill in the fields below"
      headStyle={{ textAlign: 'center' }}
      style={{ marginBottom: 35, boxShadow: '0 15px 15px rgba(0, 0, 0, .05)' }}
    >
      <Row gutter={32}>
        {category?.name === 'Logistics' && (
          <Col {...colProps}>
            <RecurringIssue recurring={recurring} />
          </Col>
        )}

        {rules.map(({ id, name, options }) => {
          const Component = RuleComponent[name as ComponentName]

          if (!Component) return <div key={id} />

          return (
            <Col key={id} {...colProps}>
              <Component orderNumber={orderNumber} form={form} scope={scope} options={options} />
            </Col>
          )
        })}
        <Col {...colProps}>
          <ComplaintDescription />
        </Col>
        <Col {...colProps}>
          <Compensation orderNumber={orderNumber} subcategory={subcategory} form={form} />
        </Col>
        <Col {...colProps}>
          <ComplaintAttachments form={form} />
        </Col>
        {((form.getFieldValue('existingAttachments') || []) as Attachment[]).length ? (
          <Col {...colProps}>
            <ExistingAttachments form={form} />
          </Col>
        ) : null}
      </Row>
    </Card>
  )
}

export default Rules
