import React from 'react'
import { Route, Switch } from 'react-router'
import { PageHeader } from 'antd'

import ManageMassCredit from '../../components/ManageMassCredit'
import { Routes } from '../../constants/RoutesConstants'
import { headerIcons } from '../../constants/IconsConstants'

const ManageMassCreditContainer: React.FC = () => {
  return (
    <>
      <PageHeader title="Mass Credit" avatar={{ src: headerIcons.MASS_CREDIT, shape: 'square' }} />
      <Switch>
        <Route exact path={Routes.MASS_CREDIT} component={ManageMassCredit} />
      </Switch>
    </>
  )
}

export default ManageMassCreditContainer
