import React from 'react'
import { Row, Col, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { WizardStep } from '../types'

const WizardNavigation: React.FC<WizardNavigationProps> = ({
  step,
  setStep,
  onSubmit,
  loading = false,
  nextDisabled = false,
  submitButtonText = 'Submit',
}) => {
  const lastStep = step === WizardStep.Confirmation
  const onNextClick = lastStep ? onSubmit : (): void => setStep(step + 1)
  const nextContent = lastStep ? (
    submitButtonText
  ) : (
    <>
      Next Step <RightOutlined />
    </>
  )

  return (
    <Row>
      <Col span={12}>
        {step > 0 && (
          <Button icon={<LeftOutlined />} onClick={(): void => setStep(step - 1)}>
            Previous Step
          </Button>
        )}
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Button
          data-testid="next-step-button"
          loading={loading}
          disabled={nextDisabled || loading}
          type="primary"
          onClick={onNextClick}
        >
          {nextContent}
        </Button>
      </Col>
    </Row>
  )
}

type WizardNavigationProps = {
  step: WizardStep
  setStep: (step: WizardStep) => void
  onSubmit: () => Promise<void>
  submitButtonText?: string
  loading?: boolean
  nextDisabled?: boolean
}

export default WizardNavigation
