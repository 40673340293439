import React from 'react'
import { Select, Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { ApolloError } from '@apollo/client'

import { Order } from '../../../../apollo/generated/api'

const SelectOrderInput: React.FC<SelectOrderInputProps> = ({ loading, error, orders }) => {
  let warning: Pick<FormItemProps, 'validateStatus' | 'help'> = {}

  if (error) {
    warning = {
      validateStatus: 'error',
      help: 'There was an error retrieving the customer orders.',
    }
  } else if (!loading && !orders.length) {
    warning = {
      validateStatus: 'warning',
      help: `The user has no orders, so you can't associate the credit with any order.`,
    }
  }

  return (
    <Form.Item {...warning} name="orderNumber" label="Related Order">
      <Select
        data-testid="customer-orders-select"
        placeholder="Select a related order"
        size="large"
        loading={loading}
        disabled={loading || error !== undefined || !orders.length}
        showSearch
      >
        {orders.map(({ number, deliveryDate }) => (
          <Select.Option data-testid="select-order-option" key={number} value={number}>
            [{deliveryDate}] {number}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export type SelectOrderInputProps = {
  orders: Pick<Order, 'id' | 'number' | 'deliveryDate'>[]
  loading: boolean
  error?: ApolloError
}

export default SelectOrderInput
