import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { History } from 'history'
import { generatePath } from 'react-router-dom'

import { Routes } from '../../../../../constants/RoutesConstants'
import {
  COMPLAINT_CONFIRM_TITLE,
  COMPLAINT_CONFIRM_CONTENT,
  COMPLAINT_CONFIRM_BUTTON,
} from '../../../../../constants/MessagesConstants'
import { OrderStateEnum, Maybe } from '../../../../../apollo/generated/api'

const openNewComplaintForm = (number: string, history: History): void => {
  history.push(generatePath(Routes.COMPLAINTS_NEW, { number }))
}

const confirmComplaint = (number: string, history: History): void => {
  Modal.confirm({
    title: COMPLAINT_CONFIRM_TITLE,
    content: COMPLAINT_CONFIRM_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okText: COMPLAINT_CONFIRM_BUTTON,
    onOk: () => openNewComplaintForm(number, history),
  })
}

const logComplaint = (
  { number, state }: { number: string; state?: Maybe<OrderStateEnum> | undefined },
  { history }: { history: History },
): void => {
  if (state !== OrderStateEnum.Complete) {
    return confirmComplaint(number, history)
  }

  return openNewComplaintForm(number, history)
}

export default logComplaint
