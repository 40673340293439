import { useEffect, useLayoutEffect, useRef, useState } from 'react'

const useSize = (target: React.MutableRefObject<HTMLDivElement | null>) => {
  const [domRect, setDomRect] = useState<DOMRect>()
  const frameIdRef = useRef<number>(NaN)

  const notifySizeChanged = (el: HTMLDivElement | null) => {
    if (!Number.isNaN(frameIdRef.current)) {
      window.cancelAnimationFrame(frameIdRef.current)
    }
    frameIdRef.current = window.requestAnimationFrame(() => {
      if (el && document.body.contains(el)) {
        const newRect = el.getBoundingClientRect()

        if (!areDOMRectsEqual(domRect, newRect)) {
          setDomRect(newRect)
        }
      }
    })
  }

  useLayoutEffect(() => {
    notifySizeChanged(target.current)
  }, [target])

  useEffect(() => {
    const element = target.current

    if (!element) return

    const observer = new ResizeObserver(() => {
      notifySizeChanged(element)
    })

    observer.observe(element)

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect()
    }
  }, [target])

  return domRect
}

const areDOMRectsEqual = (rect1: DOMRect | undefined, rect2: DOMRect | undefined): boolean => {
  if (!rect1 || !rect2) {
    return false
  }

  return (
    rect1.top === rect2.top && rect1.right === rect2.right && rect1.bottom === rect2.bottom && rect1.left === rect2.left
  )
}

export default useSize
