import React from 'react'
import { Card, Col, Row, Statistic, Tooltip } from 'antd'

export type StatsOverviewProps = {
  title: string
  description: string
  ratio: number
  loading?: boolean
  columns?: number
  items: {
    title: string
    value: number
  }[]
}

const StatsOverview: React.FC<StatsOverviewProps> = ({ items, description, ratio, title, loading, columns = 4 }) => {
  if (items.length === 0) {
    return null
  }

  return (
    <Card
      title={title}
      extra={
        <Tooltip title={description}>
          <div style={{ display: 'flex', border: '1px solid #f0f0f0' }}>
            <div
              className="stats-overview-ratio"
              style={{ borderRight: '1px solid #f0f0f0', padding: 5, background: '#fafafa', fontWeight: 500 }}
            >
              Rate:
            </div>
            <div style={{ padding: 5 }}>{ratio.toFixed(2)}</div>
          </div>
        </Tooltip>
      }
      style={{ marginBottom: 16 }}
      data-testid={loading ? null : 'stats-overview'}
    >
      <Row>
        {items.map(item => (
          <Col key={item.title} span={24 / columns} style={{ padding: 10 }}>
            <Statistic title={item.title} value={item.value} loading={loading} />
          </Col>
        ))}
      </Row>
    </Card>
  )
}

export default StatsOverview
