import React from 'react'

import { OrderWizardStepProps } from '../../types'
import SelectOrderAddresses from '../../../SelectOrderAddresses'

const OrderAddressesStep: React.FC<OrderWizardStepProps> = ({ state: { addresses, setAddresses, customerId } }) => {
  return (
    <div data-testid="order-addresses-step">
      <SelectOrderAddresses
        customerId={customerId}
        onChange={setAddresses}
        initialBillingId={addresses.billing?.id}
        initialShippingId={addresses.shipping?.id}
      />
    </div>
  )
}

export default OrderAddressesStep
