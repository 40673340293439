import { Button, Modal, Row, Col, Image } from 'antd'
import React, { useMemo, useState } from 'react'

import { handleMutationResult } from '../../../apollo'
import { useUpdateOrderContentsMutation } from '../../../apollo/generated/api'
import OrderRemoveFruitboxForm from '../../Forms/OrderRemoveFruitboxForm'
import { addOnsIcons } from '../../../constants/IconsConstants'
import { AddOnsComponentConfig } from '../types'

const fruitboxDisabledStyle = { filter: 'grayscale(100%)', opacity: '.15' }

const FruitBoxAddOn: React.FC<AddOnsComponentConfig> = ({
  orderNumber,
  orderAddOns,
  customerId,
  fruitBoxAvailable,
  editable,
  inProduction,
  onOrderUpdated,
}) => {
  const [updateOrderContents, { loading: addFruitboxLoading }] = useUpdateOrderContentsMutation()
  const [removeFruitboxFormVisible, setRemoveFruitboxFormVisible] = useState(false)
  const toggleRemoveFruitboxModal = (): void => setRemoveFruitboxFormVisible(!removeFruitboxFormVisible)

  const addFruitbox = (): void => {
    const mutation = updateOrderContents({ variables: { number: orderNumber, addFruitBox: true } })

    void handleMutationResult(mutation, 'updateOrderContents', {
      notifications: {
        success: {
          title: 'Added fruitbox to Order!',
        },
      },
    }).then(value => {
      if (value.data && onOrderUpdated) {
        onOrderUpdated('fruitbox', value.data)
      }
    })
  }
  const fruitBoxAddOns = useMemo(() => {
    return orderAddOns.filter(el => !!el.addOn?.isFruitBox)
  }, [orderAddOns])

  if (!fruitBoxAvailable) return <div />

  return (
    <>
      {customerId && editable && (
        <Modal
          title="Remove fruitbox from order"
          open={removeFruitboxFormVisible}
          onCancel={toggleRemoveFruitboxModal}
          footer={null}
        >
          <OrderRemoveFruitboxForm
            onOrderUpdated={onOrderUpdated}
            toggleModal={toggleRemoveFruitboxModal}
            number={orderNumber}
            customerId={customerId}
          />
        </Modal>
      )}

      <Row data-testid="addon-container" justify="center" style={{ margin: '45px 0' }}>
        {fruitBoxAddOns?.map(({ addOn }) => (
          <Col data-testid="enabled-fruitbox" span={3} key={addOn?.id} style={{ textAlign: 'center' }}>
            <Image src={addOnsIcons.FRUITBOX} />
          </Col>
        ))}

        {fruitBoxAddOns.length === 0 ? (
          <Col data-testid="disabled-fruitbox" span={3}>
            <Image src={addOnsIcons.FRUITBOX} style={fruitboxDisabledStyle} />
          </Col>
        ) : null}
      </Row>

      <div style={{ textAlign: 'center' }}>
        <Button
          data-testid="add-order-fruitbox"
          disabled={inProduction || addFruitboxLoading || fruitBoxAddOns.length !== 0}
          onClick={addFruitbox}
          loading={addFruitboxLoading}
        >
          Add Fruitbox
        </Button>

        <Button
          data-testid="remove-order-fruitbox"
          disabled={inProduction || fruitBoxAddOns.length === 0}
          onClick={toggleRemoveFruitboxModal}
          style={{ marginLeft: 10 }}
          danger
        >
          Remove fruitbox
        </Button>
      </div>
    </>
  )
}

export default FruitBoxAddOn
