import { Form, FormInstance, Select } from 'antd'
import React, { useEffect } from 'react'

import { LogisticsCode, Option as OptionType, useGetComplaintCategoriesQuery } from '../../../../apollo/generated/beef'
import { required } from '../formConfig'

const { Option } = Select
const codes = {
  DAMAGED_BOX: ['UD', 'DB', 'NIT-DB'],
  DELIVERY_MISTAKE: [
    'BD-MISS',
    'TD',
    'UD',
    'WP-D',
    'DBELL-EU',
    'HDEL-EU',
    'EMAIL',
    'INF',
    'INF-ST',
    'NO',
    'BEH',
    'SMS',
    'SMS-ST',
    'ST',
    'WA',
    'WP',
  ],
  NO_DELIVERY: ['BD-MISS', 'ND - UB', 'UD', 'GNR', 'ND', 'OA', 'DB'],
  NOT_IN_TIME_DELIVERY: [
    'NIT - UB',
    'NIT - W',
    'UD',
    'NIT 24',
    'NIT 48',
    'NIT 48+',
    'NIT',
    'NIT-ST',
    'NIT-DB',
    'NIT-E',
    'NIT-TT',
  ],
}

interface FilterableOption {
  key?: string | number
  value?: string | number
  children?: React.ReactNode
}

const LogisticsDropdown: React.FC<{ options: OptionType[]; form: FormInstance }> = ({ options, form }) => {
  const findOptions = (names: string[]) => options?.filter(option => names.includes(option.name))
  const { data, loading } = useGetComplaintCategoriesQuery()
  const subcategories = data?.complaintCategories?.find(({ name }) => name === 'Logistics')?.subcategories
  const selectedSubCategory = subcategories?.find(({ id }) => id === form.getFieldValue('subCategoryId'))
  const mappedOptions: { [index: string]: LogisticsCode[] } = {
    'DAMAGED BOX': findOptions(codes.DAMAGED_BOX) as LogisticsCode[],
    'DELIVERY MISTAKE': findOptions(codes.DELIVERY_MISTAKE) as LogisticsCode[],
    'NO DELIVERY': findOptions(codes.NO_DELIVERY) as LogisticsCode[],
    'NOT IN TIME DELIVERY': findOptions(codes.NOT_IN_TIME_DELIVERY) as LogisticsCode[],
  }

  useEffect(() => {
    if (!loading && selectedSubCategory) {
      // This is when the form is in edit mode, the logisticsCode is retrieved as a String (e.g UD) and not the id
      // hence we need to match it against existing ids. This is skipped when form is in create mode
      const selectedCode = mappedOptions[selectedSubCategory?.name.toUpperCase()]?.find(
        code => code.name === form.getFieldValue('logisticsCode'),
      )?.id

      form.setFieldsValue({ logisticsCode: selectedCode })
    }
  }, [loading])

  if (!selectedSubCategory) return <div />

  return (
    <Form.Item name="logisticsCode" label="Pick a logistics code" rules={[required]}>
      <Select
        data-testid="logistics-code-dropdown"
        placeholder="Pick a logistics code please"
        disabled={loading}
        loading={loading}
        filterOption={(input: string, option: FilterableOption | undefined) => {
          // Get first children, which contains
          // the name of the logistic code
          const [first] = option?.children as React.ReactElement<{ children: string }>[]

          return first?.props?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }}
        showSearch
      >
        {mappedOptions[selectedSubCategory?.name.toUpperCase()]?.map(({ id, name, description }: LogisticsCode) => (
          <Option key={id} value={id}>
            <div>{name}</div>
            <div style={{ fontSize: 11, color: '#999', whiteSpace: 'normal' }}>{description}</div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default LogisticsDropdown
