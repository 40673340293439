import React, { useEffect } from 'react'
import { FormInstance, FormItemProps } from 'antd/lib/form'
import { Form, Select } from 'antd'

import { CountryEnum, useGetCountryStatesQuery } from '../../../../apollo/generated/api'

const SelectState: React.FC<{
  country: CountryEnum
  stateId?: number | null
  autocompleteState: string
  form: FormInstance
}> = ({ country, stateId, autocompleteState, form }) => {
  const { loading, error, data } = useGetCountryStatesQuery({ variables: { iso: country }, fetchPolicy: 'no-cache' })
  const sortedStates = data?.country?.states.sort((a, b) => {
    return (a.name || '').localeCompare(b.name || '')
  })
  const testId = `customer-address-form[state][${loading ? 'loading' : 'loaded'}]`
  const formItemProps: Partial<FormItemProps> = {
    label: 'State',
    name: 'stateId',
    rules: [{ required: true }],
    validateStatus: error ? 'error' : undefined,
    help: error ? 'There was an error retrieving the list of available states.' : undefined,
  }

  useEffect(() => {
    if (data) {
      const state = data.country?.states.find(state => state.name === autocompleteState)?.id

      form.setFieldsValue({ stateId: state })
    }
  }, [autocompleteState])

  return (
    <Form.Item {...formItemProps}>
      <Select
        data-testid={testId}
        size="large"
        value={stateId || ''}
        placeholder="Please select a state..."
        showSearch
        optionFilterProp="children"
        loading={!error && loading}
        disabled={!!error}
      >
        {sortedStates &&
          sortedStates.map(({ id, name }) => (
            <Select.Option key={id} value={id} data-testid="customer-address-form[state][option]">
              {name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}

export default SelectState
