import React, { useEffect, useState } from 'react'
import { Select, Form, Button, Modal, notification, Row, Col, Space, Radio } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { useForm } from 'antd/lib/form/Form'

import { client, handleMutationResult } from '../../../apollo'
import { filterMaybe } from '../../../utils/typeUtils'
import * as messages from '../../../constants/MessagesConstants'
import {
  CancelOrderDocument,
  CancelOrderMutation,
  CancelOrderMutationVariables,
  EventReason,
  GetCancelOrderReasonsDocument,
  GetCancelOrderReasonsQuery,
  GetOrderPaymentsDocument,
  GetOrderPaymentsQuery,
} from '../../../apollo/generated/api'
import { filterOptionByText } from '../../../utils/inputUtils'

const { Option } = Select

enum CompensationType {
  CREDIT = 'CREDIT',
  REFUND = 'REFUND',
}

const reasonToCompensationType = (reasonKey: string): CompensationType => {
  switch (reasonKey) {
    case 'before_procurement':
    case 'emergency':
    case 'escalation':
    case 'flexible_cancellation':
    case 'marketing_mistake':
    case 'operations_mistake':
    case 'vip_cancellation':
      return CompensationType.CREDIT
    default:
      return CompensationType.REFUND
  }
}

const CancelOrderForm: React.FC<{ number: string; customerId: number }> = ({ number, customerId }) => {
  const [reasons, setReasons] = useState([] as Pick<EventReason, 'id' | 'displayName' | 'key'>[])
  const [loadingReasons, setLoadingReasons] = useState(false)
  const [loadingOrder, setLoadingOrder] = useState(false)
  const [hasPayments, setHasPayments] = useState(false)
  const [form] = useForm()
  const loading = loadingReasons || loadingOrder

  const chooseCompensationType = (value: number): void => {
    const reasonKey = reasons.find(reason => reason.id === value)?.key
    const compensationType = hasPayments ? reasonToCompensationType(reasonKey || '') : CompensationType.CREDIT

    form.setFieldsValue({ compensationType })
  }

  useEffect(() => {
    setLoadingReasons(true)
    setLoadingOrder(true)

    client
      .query<GetCancelOrderReasonsQuery>({
        query: GetCancelOrderReasonsDocument,
        variables: { id: customerId },
      })
      .then(({ data }) => {
        setLoadingReasons(false)
        setReasons(data?.eventReasons?.filter(filterMaybe) || [])
      })

      .catch(() => {
        setLoadingOrder(false)
        notification.error({
          message: messages.ERROR_TITLE,
          description: messages.CANCEL_REASONS_LOADING_FAILURE,
        })
      })

    client
      .query<GetOrderPaymentsQuery>({
        query: GetOrderPaymentsDocument,
        variables: { number },
      })
      .then(({ data }) => {
        setLoadingOrder(false)
        // we can only apply credit if there are no payments
        setHasPayments((data?.order?.payments || []).length > 0)
        form.setFieldsValue({ compensationType: CompensationType.CREDIT })
      })

      .catch(() => {
        setLoadingOrder(false)
        notification.error({
          message: messages.ERROR_TITLE,
          description: messages.PAYMENTS_LOADING_FAILURE,
        })
      })
  }, [customerId])

  const onFinish = async (values: Store): Promise<void> => {
    setLoadingReasons(true)

    const mutation = client.mutate<CancelOrderMutation, CancelOrderMutationVariables>({
      mutation: CancelOrderDocument,
      variables: {
        cancelOrder: {
          number,
          eventReasonId: values.eventReasonId,
          shouldGrantUserCredit: values.compensationType === CompensationType.CREDIT,
        },
      },
    })
    const { data } = await handleMutationResult(mutation, 'cancelOrder', {
      notifications: {
        success: {
          title: `${messages.CANCEL_ORDER_SUCCESS}. ${
            values.compensationType === CompensationType.CREDIT ? 'Credit was applied.' : 'Refund was given.'
          }`,
        },
      },
    })

    setLoadingReasons(false)

    if (data?.cancelOrder?.__typename === 'Order') {
      Modal.destroyAll()
    }
  }

  return (
    <Form name="cancelOrderForm" onFinish={onFinish} style={{ marginTop: 25 }} form={form}>
      <Form.Item
        name="eventReasonId"
        label="Reason"
        rules={[
          {
            required: true,
            message: 'You must select a reason.',
          },
        ]}
        hasFeedback
      >
        <Select
          loading={loadingReasons && reasons.length === 0}
          placeholder="Select a reason for cancelling"
          showSearch
          filterOption={filterOptionByText}
          data-testid="cancel-order-reasons"
          onChange={chooseCompensationType}
        >
          {reasons.map(({ id, displayName }) => (
            <Option key={id} value={id}>
              {displayName}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="compensationType" label="Compensation" required>
        <Radio.Group disabled={loadingOrder || !hasPayments}>
          <Space direction="vertical">
            <Radio value={CompensationType.REFUND}>Refund</Radio>
            <Radio value={CompensationType.CREDIT}>Credit</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Row gutter={16}>
        <Col span={8}>
          <Button data-testid="cancel-order-close-button" size="middle" onClick={(): void => Modal.destroyAll()} block>
            Exit
          </Button>
        </Col>

        <Col span={16}>
          <Button
            data-testid="cancel-order-submit-button"
            htmlType="submit"
            size="middle"
            block
            danger
            disabled={loading}
            loading={loading}
            style={{ marginBottom: 20 }}
          >
            Cancel Order
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default CancelOrderForm
