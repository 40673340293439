import React, { FunctionComponent } from 'react'
import { Table, Tag, Tooltip } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { CheckCircleTwoTone, CloseCircleTwoTone, WarningTwoTone } from '@ant-design/icons'

import Colors from '../../../constants/ColorsConstants'
import { Routes } from '../../../constants/RoutesConstants'
import { mappedStatus } from '../../../constants/OrderConstants'
import { formatCurrency, capitalize, buildOrderPrice } from '../../../utils'
import { useRelayPagination } from '../../../hooks'
import LoadComponentError from '../../../packages/LoadComponentError'
import {
  Delivery,
  GetCustomerOrdersQuery,
  OrderStateEnum,
  useGetCustomerOrdersQuery,
  TimeSlot,
  OrderEdge,
  OrderPricingFragment,
} from '../../../apollo/generated/api'
import PaymentState from '../../ManageOrder/PaymentState'
import SimplePagination from '../../SimplePagination'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import { ManageCustomerProps } from '../props'

const { Column } = Table
const parseTimeSlot = (timeSlot: TimeSlot): string => `${timeSlot.from} - ${timeSlot.to}`
const renderEditable = (days: number): JSX.Element =>
  days > 0 ? <CheckCircleTwoTone twoToneColor={Colors.SUCCESS} /> : <CloseCircleTwoTone twoToneColor={Colors.DANGER} />

export const PAGE_SIZE = 10

const ManageCustomerOrders: FunctionComponent<ManageCustomerProps> = ({ customer: { id: customerId, timezone } }) => {
  const { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage } = useRelayPagination()
  const { data, loading, error } = useGetCustomerOrdersQuery({
    variables: { id: customerId, first: PAGE_SIZE, after: currentCursor },
    fetchPolicy: 'no-cache',
  })
  const { edges: orders, pageInfo } = data?.customer?.allOrders || { edges: [] }

  const onNextPage = () => {
    if (pageInfo?.hasNextPage) {
      handleNextPage(pageInfo.endCursor)
    }
  }

  return (
    <>
      {error && <LoadComponentError errorMessage={error.message} />}
      {!error && (
        <>
          <Table<PartialOrder>
            data-testid="orders-table"
            dataSource={orders as PartialOrder[]}
            loading={loading}
            bordered
            pagination={false}
            scroll={{ x: true }}
            style={{ marginBottom: 15 }}
            rowKey={(order: PartialOrder) => `${order.node?.id}`}
          >
            <Column
              title="Delivery Date"
              dataIndex={['node', 'deliveryDate']}
              render={value => <UserAwareDateWithTz datetime={value} customerTimezone={timezone} dateOnly />}
            />

            <Column
              title="Slot"
              dataIndex={['node', 'deliveries']}
              render={(value: Delivery[]): string => {
                const { timeSlot } = value[0]

                if (timeSlot && timeSlot.from && timeSlot.to) return parseTimeSlot(timeSlot)

                return ''
              }}
            />

            <Column
              title="Number"
              dataIndex={['node', 'number']}
              render={(value): JSX.Element => (
                <Link to={generatePath(Routes.ORDER_DETAIL, { number: value })}>{value}</Link>
              )}
            />

            <Column
              title="Payment Status"
              dataIndex={['node', 'paymentState']}
              render={(paymentState: string, node: OrderEdge): JSX.Element => {
                const payments = node.node?.payments

                if (paymentState) return <PaymentState state={paymentState.toLowerCase()} />

                if (payments && payments.length > 0 && payments[0].state === 'failed') {
                  return (
                    <Tooltip placement="right" title="Payment Failed!">
                      <WarningTwoTone twoToneColor="red" style={{ fontSize: 'large' }} />
                    </Tooltip>
                  )
                }

                return <div />
              }}
            />

            <Column
              title="Status"
              dataIndex={['node', 'state']}
              render={(value: OrderStateEnum): JSX.Element => (
                <Tag color={mappedStatus[value] || 'red'}>{capitalize(value)}</Tag>
              )}
            />

            <Column title="Type" dataIndex={['node', 'segment']} render={(value): string => capitalize(value)} />

            <Column
              title="Editable?"
              dataIndex={['node', 'daysLeftToEdit']}
              align="center"
              render={(value): JSX.Element => renderEditable(value)}
            />

            <Column
              title="Total"
              dataIndex={['node', 'pricing']}
              render={(pricing: OrderPricingFragment['pricing']): string => {
                const { totalWithTax } = buildOrderPrice(pricing)

                return formatCurrency(totalWithTax, pricing?.currency)
              }}
            />
          </Table>

          <SimplePagination
            currentCount={data?.customer?.allOrders.edges?.length}
            totalCount={data?.customer?.allOrders.totalCount}
            hasNextPage={pageInfo?.hasNextPage}
            hasPreviousPage={hasPreviousPage}
            onNextPage={onNextPage}
            onPreviousPage={handlePreviousPage}
          />
        </>
      )}
    </>
  )
}

type PartialOrder = NonNullable<
  NonNullable<NonNullable<GetCustomerOrdersQuery['customer']>['allOrders']['edges']>[number]
>

export default ManageCustomerOrders
