import React from 'react'
import { Form, Row, Col, Input, Button, Divider, Switch } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { FIELD_REQUIRED_ERROR } from '../../../constants/ErrorConstants'
import { handleBeefMutationResult, handleMutationResult } from '../../../apollo'
import { CREATE_ADMIN_USER_SUCCESS, CREATE_ADMIN_USER_FAILURE } from '../../../constants/MessagesConstants'
import {
  CreateAdminUserMutationVariables,
  ListAdminsDocument,
  useCreateAdminUserMutation,
} from '../../../apollo/generated/api'
import {
  AdminUser,
  CreateAdminUserMutationVariables as CreateAdminUserBeefMutationVariables,
  useCreateAdminUserMutation as useCreateAdminUserBeefMutation,
} from '../../../apollo/generated/beef'
import { queryNames } from '../../../apollo/client'

const CreateAdminUserForm: React.FC<{ toggleModal: Function }> = ({ toggleModal }) => {
  const [form] = Form.useForm()
  const [createAdminUser, { loading: creatingAdminUser }] = useCreateAdminUserMutation()
  const [createAdminUserBeef] = useCreateAdminUserBeefMutation()

  const createAdminUserOnBeefApi = async (adminUser: AdminUser): Promise<void> => {
    const variables: CreateAdminUserBeefMutationVariables = {
      createAdminUser: {
        email: adminUser.email,
        admin: adminUser.admin,
        superAdmin: adminUser.superAdmin,
      },
    }
    const beefMutation = createAdminUserBeef({
      variables,
    })

    await handleBeefMutationResult(beefMutation, 'createAdminUser')
  }

  const onFinish = async (values: Store): Promise<void> => {
    const variables: CreateAdminUserMutationVariables = {
      adminUser: {
        email: values.email,
        admin: values.admin ?? false,
        superAdmin: values.superAdmin ?? false,
      },
    }
    const mutation = createAdminUser({
      variables,
      refetchQueries: queryNames(ListAdminsDocument),
    })
    const { data } = await handleMutationResult(mutation, 'createAdminUser', {
      notifications: {
        success: {
          title: CREATE_ADMIN_USER_SUCCESS,
        },
        error: {
          title: CREATE_ADMIN_USER_FAILURE,
        },
      },
    })

    if (data?.createAdminUser?.__typename !== 'MutationErrorWrapper') {
      const createdAdminUser = data?.createAdminUser as unknown as AdminUser

      if (createdAdminUser?.id) {
        void createAdminUserOnBeefApi(createdAdminUser)
      }
      form.resetFields()
      toggleModal()
    }
  }

  return (
    <Form
      layout="vertical"
      initialValues={{ admin: true }}
      data-testid="new-admin-user-form"
      name="adminUserForm"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={16}>
        <Col md={24}>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: FIELD_REQUIRED_ERROR,
              },
            ]}
            hasFeedback
          >
            <Input
              data-testid="admin-user-email"
              type="email"
              size="large"
              placeholder="Email address"
              style={{ width: '100%' }}
              autoFocus
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Item label="Admin?" name="admin" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item label="Super Admin?" name="superAdmin" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col md={24}>
          <Button
            data-testid="submit-admin-user-form"
            htmlType="submit"
            type="primary"
            size="large"
            disabled={creatingAdminUser}
            loading={creatingAdminUser}
            block
          >
            Create admin user
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default CreateAdminUserForm
