import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Routes } from '../../constants/RoutesConstants'
import ManageAdminUsers from '../../components/ManageAdminUsers'
import NotFoundError from '../../packages/UnauthorizedComponentError'
import { getUserSession } from '../../utils'

const ManageAdminUsersContainer: React.FC = () => {
  const { hasRole } = getUserSession()

  if (!hasRole('super_admin')) {
    return <NotFoundError />
  }

  return (
    <Switch>
      <Route exact path={Routes.ADMIN_USERS} component={ManageAdminUsers.ListAdminUsers} />
    </Switch>
  )
}

export default ManageAdminUsersContainer
