import React, { useState } from 'react'
import { Form, Checkbox, Select, Row, Col, Button, Card } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Store } from 'antd/lib/form/interface'

import {
  useDisableCustomerFruitboxMutation,
  useEnableCustomerFruitboxMutation,
  useGetPlanSkipFruitboxReasonsQuery,
} from '../../../apollo/generated/api'
import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { handleMutationResult } from '../../../apollo'

const { Option } = Select

type CustomerFruitboxPlanProps = {
  customer: {
    id: number
    userPlan: {
      fruitBox: {
        available: boolean
        enabled: boolean
        pricePerUnit: number | null
        servings: number | null
      }
    }
  }
}

const CustomerFruitBoxPlanForm: React.FC<CustomerFruitboxPlanProps> = ({ customer }) => {
  const { enabled } = customer.userPlan.fruitBox
  const [form] = Form.useForm()
  const [showReasons, setShowReasons] = useState(!enabled)
  const { loading, error, data } = useGetPlanSkipFruitboxReasonsQuery({ variables: { id: customer.id } })
  const [addFruitboxMutation, { loading: addFruitboxLoading }] = useEnableCustomerFruitboxMutation()
  const [removeFruitboxMutation, { loading: removeFruitboxLoading }] = useDisableCustomerFruitboxMutation()

  const removeFruitbox = (eventReasonId: number): void => {
    const mutation = removeFruitboxMutation({ variables: { id: customer.id, eventReasonId } })

    void handleMutationResult(mutation, 'disableCustomerFruitbox', {
      notifications: {
        success: {
          title: 'Removed fruitbox from User Plan!',
        },
      },
    })
  }

  const addFruitbox = (): void => {
    const mutation = addFruitboxMutation({ variables: { id: customer.id } })

    void handleMutationResult(mutation, 'enableCustomerFruitbox', {
      notifications: {
        success: {
          title: 'Added fruitbox to User Plan!',
        },
      },
    })
  }

  const submitForm = ({ eventReason }: Store) => {
    if (showReasons) {
      removeFruitbox(eventReason)
    } else {
      addFruitbox()
    }
  }

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />

  return (
    <Card title="Manage Customer Fruitbox">
      <Checkbox data-testid="fruitbox-checkbox" defaultChecked={enabled} onChange={() => setShowReasons(!showReasons)}>
        Add a fruitbox to the user plan?
      </Checkbox>

      <Form form={form} onFinish={submitForm}>
        <Form.Item name="eventReason" hidden={!showReasons}>
          <Select data-testid="customer-fruitbox-form-reasons" placeholder="Pick a reason for cancelling">
            {data?.eventReasons.map(({ id, displayName }) => (
              <Option key={id} value={id}>
                {displayName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row style={{ marginTop: 20 }}>
          <Col style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="customer-fruitbox-submit"
                icon={<CheckCircleOutlined />}
                size="large"
                disabled={loading || addFruitboxLoading || removeFruitboxLoading}
                loading={loading || addFruitboxLoading || removeFruitboxLoading}
              >
                Update FruitBox Preferences
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default CustomerFruitBoxPlanForm
