import { Button, Col, Form, Row, Select, Spin } from 'antd'
import React, { useState, useEffect } from 'react'

import { BrandEnum, CountryEnum, useSearchAddOnsLazyQuery } from '../../../apollo/generated/api'
import { useDebounce } from '../../../utils/hooks'
import { AddOn } from '../types'

const { Option } = Select

const AddOnSearchAutocomplete: React.FC<{
  onItemSelected: (item: AddOn | undefined) => void
  editable: boolean
  country: CountryEnum
  brand: BrandEnum
  orderNumber?: string | null
}> = ({ editable, country, onItemSelected, brand, orderNumber }) => {
  const [fetchAddOns, result] = useSearchAddOnsLazyQuery()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [form] = Form.useForm()
  const [selectedOption, setSelectedOption] = useState<AddOn>()

  const handleOnChange = (id: number) => {
    const item = result.data?.addOnSearch.find(x => x.id === id)

    setSelectedOption(item as AddOn)
  }

  const submitForm = () => {
    onItemSelected(selectedOption)
    clear()
  }

  const clear = () => {
    form.resetFields()
    setSelectedOption(undefined)
    setSearchTerm('')
  }

  useEffect(() => {
    void fetchAddOns({
      variables: {
        country,
        text: debouncedSearchTerm,
        brand,
        availableForOrder: orderNumber,
      },
    })
  }, [debouncedSearchTerm])

  return (
    <Form form={form} onFinish={() => submitForm()}>
      <Row gutter={[8, 8]}>
        <Col flex="auto">
          <Form.Item name="addon">
            <Select
              disabled={!editable}
              showSearch
              allowClear
              filterOption={false}
              onChange={handleOnChange}
              onClear={clear}
              onSearch={setSearchTerm}
              notFoundContent={result.loading ? <Spin size="small" /> : null}
              placeholder="Select or search for an add-on..."
              size="large"
              style={{ width: '100%' }}
            >
              {result.data?.addOnSearch?.map(addon => (
                <Option value={addon.id} key={addon.id}>
                  {addon.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col flex="190px">
          <Button type="primary" htmlType="submit" size="large" block disabled={!editable || !selectedOption}>
            Add add-on
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default AddOnSearchAutocomplete
