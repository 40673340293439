import React, { useState, useEffect, useCallback } from 'react'
import { Card, Row, Col, Checkbox, Button, Alert, Divider } from 'antd'

import { CARD as cardProps } from '../../../constants/OverridesConstants'
import { capitalize } from '../../../utils/textUtils'
import {
  BrandEnum,
  CountryEnum,
  IngredientPreference,
  RecipePreference,
  useUpdateRecipeAndIngredientPreferencesMutation,
} from '../../../apollo/generated/api'
import { ManageCustomerProps } from '../props'
import { handleMutationResult } from '../../../apollo'
import { SAVE_RECIPE_PREFERENCES_FAILURE, SAVE_RECIPE_PREFERENCES_SUCCESS } from '../../../constants/MessagesConstants'
import config from '../../../config'
import { AbortableRequest, makeGraphqlRequest } from '../../../services/GraphqlService'

type ManageCustomerRecipePreferencesProps = {
  customer: ManageCustomerProps['customer']
}

type RecipePreferencesOption = RecipePreference & { isSelected: boolean }
type IngredientPreferencesOption = IngredientPreference & { isSelected: boolean }

type Options = {
  recipePreferences: RecipePreferencesOption[]
  ingredientPreferences: IngredientPreferencesOption[]
}

type AvailableRecipeAndIngredientPreferences = {
  recipePreferences: RecipePreference[]
  ingredientPreferences: IngredientPreference[]
}

const GQL_PUBLIC_ENDPOINT = config.graphqlApiEndpoint?.replace('/graphql', '/public/graphql')
const RECIPE_PREFERENCES_NAMES = {
  EVERYTHING: 'everything',
  VEGETARIAN: 'vegetarian',
  VEGAN: 'vegan',
}
const rowStyle = { marginTop: 15 }

export function fetchAvailableRecipeAndIngredientPreferences(data: {
  brand: BrandEnum
  country: CountryEnum
}): AbortableRequest<AvailableRecipeAndIngredientPreferences> {
  const query = `
    query GetAvailableRecipeAndIngredientPreferences_Cookbook($country: CountryEnum!, $brand: BrandEnum!) {
      availableRecipeAndIngredientPreferences(country: $country, brand: $brand) {
        recipePreferences {
          id
          name
          title
          description
        }
        ingredientPreferences {
          id
          name
        }
      }
    }
  `

  return makeGraphqlRequest<{
    data: { availableRecipeAndIngredientPreferences: AvailableRecipeAndIngredientPreferences }
  }>(GQL_PUBLIC_ENDPOINT, query, data).then(value => value?.data?.availableRecipeAndIngredientPreferences)
}

const processRecipePreferences = (recipePreferences: RecipePreference[], userRecipePreferences: RecipePreference[]) => {
  const checkedRecipePreferences: Record<number, boolean> = userRecipePreferences.reduce(
    (acc, pref) => ({
      ...acc,
      [pref.id]: true,
    }),
    {},
  )

  return recipePreferences.reduce((acc, pref) => {
    const preference = {
      ...pref,
      isSelected: checkedRecipePreferences[pref.id],
    } as RecipePreferencesOption

    // Put 'everything' recipe preference first
    if (preference.name === RECIPE_PREFERENCES_NAMES.EVERYTHING) {
      return [preference, ...acc]
    }

    return [...acc, preference]
  }, [] as RecipePreferencesOption[])
}

const processIngredientPreferences = (
  ingredientPreferences: IngredientPreference[],
  userIngredientPreferences: IngredientPreference[],
) => {
  const checkedIngredientPreferences: Record<number, boolean> = userIngredientPreferences.reduce(
    (acc, pref) => ({
      ...acc,
      [pref.id]: true,
    }),
    {},
  )

  return ingredientPreferences.map(pref => ({
    ...pref,
    label: pref.name,
    isSelected: checkedIngredientPreferences[pref.id],
  })) as IngredientPreferencesOption[]
}

const areOnlyVegetarianOrVeganChecked = (checkedRecipePreferences: RecipePreference[]) => {
  return (
    !!checkedRecipePreferences.length &&
    checkedRecipePreferences.every(pref =>
      [RECIPE_PREFERENCES_NAMES.VEGETARIAN, RECIPE_PREFERENCES_NAMES.VEGAN].includes(pref.name),
    )
  )
}

const ManageCustomerRecipePreferences: React.FC<ManageCustomerRecipePreferencesProps> = ({ customer }) => {
  const { id: customerId, recipePreferences, ingredientPreferences, country, brand } = customer
  const [isLoadingQuery, setIsLoadingQuery] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [options, setOptions] = useState<Options>({ recipePreferences: [], ingredientPreferences: [] })
  const [onlyVegetarianOrVeganChecked, setOnlyVegetarianOrVeganChecked] = useState<boolean>(
    areOnlyVegetarianOrVeganChecked(recipePreferences),
  )
  const [updatePreferences, { loading: isLoadingMutation }] = useUpdateRecipeAndIngredientPreferencesMutation()

  useEffect(() => {
    const request = fetchAvailableRecipeAndIngredientPreferences({ country, brand })

    setIsLoadingQuery(true)

    request.promise
      .then(availablePreferences => {
        setOptions({
          recipePreferences: processRecipePreferences(availablePreferences.recipePreferences, recipePreferences),
          ingredientPreferences: processIngredientPreferences(
            availablePreferences.ingredientPreferences,
            ingredientPreferences,
          ),
        })
        setIsLoadingQuery(false)
      })
      .catch(reason => {
        console.error(reason)
        setError('Available recipe and ingredient preferences could not be fetched')
        setIsLoadingQuery(false)
      })

    // eslint-disable-next-line consistent-return
    return () => {
      request.abort()
    }
  }, [country, brand, recipePreferences, ingredientPreferences])

  const savePreferences = async (): Promise<void> => {
    const mutation = updatePreferences({
      variables: {
        customer_id: customerId,
        recipe_preference_ids: options.recipePreferences.filter(pref => pref.isSelected).map(pref => pref.id),
        ingredient_preference_ids: options.ingredientPreferences.filter(pref => pref.isSelected).map(pref => pref.id),
      },
    })

    await handleMutationResult(mutation, 'updateRecipeAndIngredientPreferences', {
      notifications: {
        success: {
          title: SAVE_RECIPE_PREFERENCES_SUCCESS,
        },
        error: {
          title: SAVE_RECIPE_PREFERENCES_FAILURE,
        },
      },
    })
  }
  const toggleRecipePreference = useCallback(
    (index: number): void => {
      let recipePreferencesOptions = options.recipePreferences

      recipePreferencesOptions[index].isSelected = !recipePreferencesOptions[index].isSelected

      // Uncheck all other preferences if 'everything' is checked
      if (index === 0) {
        recipePreferencesOptions = recipePreferencesOptions.map((pref, i) => ({ ...pref, isSelected: i === 0 }))
      } else {
        // Uncheck 'everything' if any other is checked
        recipePreferencesOptions[0].isSelected = false
      }

      const checkedRecipePreferences = recipePreferencesOptions.filter(pref => pref.isSelected)

      // Check 'everything' if none are selected
      if (!checkedRecipePreferences.length) {
        recipePreferencesOptions[0].isSelected = true
      }

      // Verify if only 'vegetarian' or 'vegan' preferences are checked, either individually or together
      setOnlyVegetarianOrVeganChecked(areOnlyVegetarianOrVeganChecked(checkedRecipePreferences))

      setOptions(prevState => ({
        ...prevState,
        recipePreferences: recipePreferencesOptions,
      }))
    },
    [options],
  )
  const toggleIngredientPreference = useCallback(
    (index: number): void => {
      const ingredientPreferencesOptions = options?.ingredientPreferences

      ingredientPreferencesOptions[index].isSelected = !ingredientPreferencesOptions[index].isSelected

      setOptions(prevState => ({
        ...prevState,
        ingredientPreferences: ingredientPreferencesOptions,
      }))
    },
    [options],
  )

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <>
      {customer.brand === BrandEnum.Bm && (
        <Alert type="info" message="Login as user to make changes to food preferences." />
      )}
      {customer.brand !== BrandEnum.Bm && (
        <Card
          {...cardProps}
          title="Recipe Preferences"
          data-testid="customer-recupe-preferences"
          loading={isLoadingQuery}
        >
          <h3>Categories</h3>
          <Row style={rowStyle}>
            {options?.recipePreferences?.map((option, index) => (
              <Col span={6} key={option.name} data-testid="recipe-preference-item">
                <Checkbox
                  data-testid={option.title}
                  checked={option.isSelected}
                  onChange={(): void => toggleRecipePreference(index)}
                  style={{ marginBottom: 20 }}
                >
                  {option.title}
                </Checkbox>
              </Col>
            ))}
          </Row>

          <Divider />

          <h3>Ingredients</h3>
          <Row style={rowStyle}>
            {options?.ingredientPreferences?.map((option, index) => (
              <Col span={6} key={option.name} data-testid="ingredient-preference-item">
                <Checkbox
                  data-testid={option.name}
                  checked={option.isSelected}
                  disabled={onlyVegetarianOrVeganChecked}
                  onChange={(): void => toggleIngredientPreference(index)}
                  style={{ marginBottom: 20 }}
                >
                  {capitalize(option.name)}
                </Checkbox>
              </Col>
            ))}
          </Row>

          <Row justify="end">
            <Col span={8}>
              <Button
                data-testid="protein-preferences-submit-button"
                onClick={savePreferences}
                type="primary"
                disabled={isLoadingMutation}
                loading={isLoadingMutation}
                block
              >
                Save recipe preferences
              </Button>
            </Col>
          </Row>
        </Card>
      )}
    </>
  )
}

export default ManageCustomerRecipePreferences
