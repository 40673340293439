import { Button, Form, Select } from 'antd'
import React from 'react'
import { Store } from 'antd/lib/form/interface'

import {
  UpdateOrderContentsMutation,
  useGetOrderRemoveFruitboxReasonsQuery,
  useUpdateOrderContentsMutation,
} from '../../../apollo/generated/api'
import { handleMutationResult } from '../../../apollo'
import LoadComponentError from '../../../packages/LoadComponentError'
import { OrderUpdateSource } from '../../AddOns/types'

const { Option } = Select

const OrderRemoveFruitboxForm: React.FC<{
  toggleModal: Function
  number: string
  customerId: number
  onOrderUpdated?: (source: OrderUpdateSource, data: UpdateOrderContentsMutation) => void
}> = ({ toggleModal, number, customerId, onOrderUpdated }) => {
  const [form] = Form.useForm()
  const [updateOrderContents, { loading: removeFruitboxLoading }] = useUpdateOrderContentsMutation()
  const { loading, error, data } = useGetOrderRemoveFruitboxReasonsQuery({ variables: { id: customerId } })

  const onFinish = ({ eventReasonId }: Store) => {
    const mutation = updateOrderContents({ variables: { number, removeFruitBox: eventReasonId } })

    void handleMutationResult(mutation, 'updateOrderContents', {
      notifications: {
        success: {
          title: 'Removed Fruitbox from Order',
        },
      },
    }).then(value => {
      if (value.data && onOrderUpdated) {
        onOrderUpdated('fruitbox', value.data)
      }
    })

    toggleModal()
  }

  const isLoading = (): boolean => {
    return removeFruitboxLoading || loading
  }

  if (error) return <LoadComponentError errorMessage={error.message} />

  return (
    <Form layout="vertical" name="removeOrderFruitboxForm" form={form} onFinish={onFinish}>
      <Form.Item name="eventReasonId">
        <Select
          loading={loading}
          placeholder="Select a reason for removing the fruitbox"
          data-testid="remove-fruitbox-reasons"
        >
          {data?.eventReasons.map(({ id, displayName }) => (
            <Option key={id} value={id}>
              {displayName}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Button
        data-testid="remove-order-fruitbox-submit"
        htmlType="submit"
        size="large"
        disabled={isLoading()}
        loading={isLoading()}
        danger
        block
      >
        Remove fruitbox from order
      </Button>
    </Form>
  )
}

export default OrderRemoveFruitboxForm
