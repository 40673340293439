import React, { useState, useCallback } from 'react'
import { Moment } from 'moment'
import { Steps, Row, Col, Card } from 'antd'

import { AddressSelection } from '../SelectOrderAddresses'
import { SlotSelection } from '../OrderDeliveryCalendar'

import { OrderWizardState, WizardStep, OrderWizardProps, OrderWizardStepProps } from './types'
import { OrderAddressesStep, OrderDeliveryStep, OrderConfirmationStep } from './steps'
import WizardNavigation from './WizardNavigation'

type StepEntries = {
  [key: number]: {
    title: string
    component: React.ComponentType<OrderWizardStepProps>
    isComplete: (state: OrderWizardState) => boolean
  }
}

const steps: StepEntries = {
  [WizardStep.Addresses]: {
    title: 'Addresses',
    component: OrderAddressesStep,
    isComplete: ({ addresses }): boolean => !!(addresses.billing && addresses.shipping),
  },
  [WizardStep.Delivery]: {
    title: 'Delivery',
    component: OrderDeliveryStep,
    isComplete: ({ delivery }): boolean => !!delivery.timeslot?.id,
  },
  [WizardStep.Confirmation]: {
    title: 'Confirmation',
    component: OrderConfirmationStep,
    isComplete: (): boolean => true,
  },
}
const { Step } = Steps
const StepRow: React.FC = ({ children }) => <Row gutter={32}>{children}</Row>

const OrderWizard: React.FC<OrderWizardProps> = ({
  customerId,
  onSubmit,
  submitButtonText,
  initialState,
  includeDatesPastCutoff,
}) => {
  const [step, setStep] = useState<WizardStep>(WizardStep.Addresses)
  const [addresses, setAddresses] = useState<{ billing?: AddressSelection; shipping?: AddressSelection }>({})
  const [delivery, setDelivery] = useState<{ date?: Moment; timeslot?: SlotSelection }>({})
  const [loading, setLoading] = useState(false)
  const wizardState = {
    step,
    customerId,
    includeDatesPastCutoff,
    addresses,
    delivery,
    setStep,
    setDelivery: useCallback(delivery => setDelivery(delivery), []),
    setAddresses: useCallback(addresses => setAddresses(addresses), []),
  }

  const onWizardSubmit = async (): Promise<void> => {
    setLoading(true)

    const resetWizard = await onSubmit(wizardState)

    if (resetWizard) {
      setStep(WizardStep.Addresses)
      setLoading(false)
    }
  }
  const { component: StepComponent, isComplete } = steps[step]

  return (
    <Card>
      <StepRow>
        <Col span={24}>
          <Steps size="small" current={step} style={{ marginBottom: 30 }}>
            {Object.entries(steps).map(([key, { title }]) => (
              <Step title={title} key={key} />
            ))}
          </Steps>
        </Col>
      </StepRow>

      <StepRow>
        <Col span={24}>
          <StepComponent state={wizardState} initial={initialState} />

          <div style={{ marginTop: 40 }}>
            <WizardNavigation
              step={step}
              loading={loading}
              setStep={setStep}
              onSubmit={onWizardSubmit}
              nextDisabled={loading || !isComplete(wizardState)}
              submitButtonText={submitButtonText}
            />
          </div>
        </Col>
      </StepRow>
    </Card>
  )
}

export default OrderWizard
