import React, { useState, useEffect } from 'react'
import { Select, Form, Input } from 'antd'

import { CountryEnum, Address } from '../../../../apollo/generated/api'
import { DeliveryInstruction } from '../../../../apollo/generated/public_api'

export type CustomerAddressFormValues = Address & {
  isDefault: boolean
  stateId?: number
}

export type CustomerAddressFormInitialValues = Partial<{
  [k in keyof CustomerAddressFormValues]: CustomerAddressFormValues[k] | null
}>

const SelectDeliveryInstructions: React.FC<SelectDeliveryInstructionsProps> = ({
  deliveryInstructions,
  country,
  loading,
  initialValues,
}) => {
  const [selectedInstructionKey, setInstructionKey] = useState<string>()

  useEffect(() => setInstructionKey(initialValues.instructionKey || undefined), [])

  const onChange = (value: string) => setInstructionKey(value)
  const selectedInstruction = deliveryInstructions.find(
    instruction => instruction.instructionKey === selectedInstructionKey,
  )
  const textAreaMaxLength = country === 'AU' ? 80 : 35

  return (
    <>
      <Form.Item name="instructionKey" label="Add delivery instructions" style={{ marginBottom: 10 }}>
        <Select
          data-testid="customer-address-form[instruction-key]"
          placeholder="Choose delivery instruction"
          size="large"
          showSearch
          loading={loading}
          disabled={loading}
          value={selectedInstructionKey}
          onChange={onChange}
        >
          {deliveryInstructions.map(({ instructionKey, localizedText }) => (
            <Select.Option
              data-testid="customer-address-form[instruction-key][option]"
              key={instructionKey}
              value={instructionKey}
            >
              {localizedText}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {selectedInstruction?.allowFreeText && (
        <Form.Item name="instructions">
          <Input.TextArea
            name="instructions"
            data-testid="customer-address-form[instructions]"
            maxLength={textAreaMaxLength}
            showCount
            rows={5}
            value={initialValues.instructions || ''}
            placeholder={selectedInstruction.freeTextPlaceholder || ''}
          />
        </Form.Item>
      )}
    </>
  )
}

export type SelectDeliveryInstructionsProps = {
  deliveryInstructions: Array<DeliveryInstruction>
  loading: boolean
  country: CountryEnum
  initialValues: CustomerAddressFormInitialValues
}

export default SelectDeliveryInstructions
