import React from 'react'
import { Card, InputNumber, Row, Col, Button, Select, Form } from 'antd'
import { Store } from 'antd/lib/form/interface'

import LoadComponentError from '../../../packages/LoadComponentError'
import {
  FIELD_REQUIRED_ERROR,
  CREATE_ADJUSTMENT_ERROR,
  FIELD_NEGATIVE_VALUE_REQUIRED,
} from '../../../constants/ErrorConstants'
import { CREATE_ADJUSTMENT_SUCCESS } from '../../../constants/MessagesConstants'
import { handleMutationResult } from '../../../apollo'
import {
  GetOrderAdjustmentsDocument,
  GetOrderManagementDetailsDocument,
  useCreateOrderAdjustmentMutation,
  useGetCustomerCreditReasonsQuery,
} from '../../../apollo/generated/api'
import { queryNames } from '../../../apollo/client'

const { Option } = Select

const OrderAdjustmentForm: React.FC<{ orderNumber: string; customerId: number }> = ({ orderNumber, customerId }) => {
  const [form] = Form.useForm()
  const { loading: loadingReasons, error, data } = useGetCustomerCreditReasonsQuery({ variables: { id: customerId } })
  const [createAdjustment, { loading: loadingSubmission }] = useCreateOrderAdjustmentMutation()
  const creditReasons = data?.customer?.creditReasons || []
  const loading = loadingReasons || loadingSubmission

  const onFinish = async (values: Store): Promise<void> => {
    const variables = {
      adjustment: {
        amount: parseFloat(values.amount),
        creditReasonId: parseInt(values.reason, 10),
        orderNumber,
      },
    }
    const mutation = createAdjustment({
      variables,
      refetchQueries: queryNames(GetOrderAdjustmentsDocument, GetOrderManagementDetailsDocument),
    })
    const { data } = await handleMutationResult(mutation, 'createAdjustment', {
      notifications: {
        success: {
          title: CREATE_ADJUSTMENT_SUCCESS,
        },
        error: {
          title: CREATE_ADJUSTMENT_ERROR,
        },
      },
    })

    if (data?.createAdjustment?.__typename === 'Adjustment') {
      form.resetFields()
    }
  }

  if (error) return <LoadComponentError canRefresh={false} />

  return (
    <Card title="Add Adjustment">
      <Form data-testid="new-order-adjustment-form" name="orderAdjustmentForm" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col md={10}>
            <Form.Item
              name="amount"
              data-testid="adjustment-amount-wrapper"
              rules={[
                {
                  required: true,
                  message: FIELD_REQUIRED_ERROR,
                },
                {
                  type: 'number',
                  max: -0.01,
                  message: FIELD_NEGATIVE_VALUE_REQUIRED,
                },
              ]}
              hasFeedback
            >
              <InputNumber
                data-testid="adjustment-amount-input"
                placeholder="Write a negative value"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item
              name="reason"
              data-testid="adjustment-reason-wrapper"
              rules={[
                {
                  required: true,
                  message: FIELD_REQUIRED_ERROR,
                },
              ]}
              hasFeedback
            >
              <Select
                data-testid="adjustment-reason-select"
                placeholder="Select a reason"
                loading={loadingReasons}
                style={{ width: '100%' }}
                optionFilterProp="children"
                disabled={loadingReasons}
                showSearch
              >
                {creditReasons.map(({ id, name }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ textAlign: 'right' }}>
          <Col span={24}>
            <Button disabled={loading} data-testid="submit-adjustment" type="primary" htmlType="submit">
              Create adjustment
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default OrderAdjustmentForm
