import { Table, Tag, Typography } from 'antd'
import { PresetColorType } from 'antd/lib/_util/colors'
import humanizeString from 'humanize-string'
import React from 'react'
import { useParams } from 'react-router'

import { ComplaintVersion, useGetComplaintHistoryQuery } from '../../../apollo/generated/beef'
import { useRelayPagination } from '../../../hooks'
import LoadComponentError from '../../../packages/LoadComponentError'
import Loading from '../../../packages/Loading'
import SimplePagination from '../../SimplePagination'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import type { ManageOrderProps } from '../../../containers/ManageOrderContainer/ManageOrderContainer'

import { HistoryAction } from './constants'

const { Title } = Typography
const { Column } = Table
const PAGE_SIZE = 10

const ManageComplaintHistory: React.FC<{ order: ManageOrderProps }> = ({ order }) => {
  const { complaintId } = useParams<{ complaintId: string }>()
  const { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage } = useRelayPagination()
  const { loading, error, data } = useGetComplaintHistoryQuery({
    variables: { id: complaintId, first: PAGE_SIZE, after: currentCursor },
    fetchPolicy: 'no-cache',
  })
  const { edges: complaintHistory, pageInfo } = data?.complaintHistory || { edges: [] }
  const historyEntries = complaintHistory ? complaintHistory.map(c => c?.node as ComplaintVersion) : []

  const onNextPage = () => {
    if (pageInfo?.hasNextPage) {
      handleNextPage(pageInfo.endCursor)
    }
  }

  if (loading) return <Loading />
  if (error) return <LoadComponentError />

  return (
    <>
      <Title level={4} data-testid="complaint-history">
        Complaint History
      </Title>
      <Table
        data-testid="complaint-history-table"
        dataSource={historyEntries}
        rowKey="id"
        pagination={false}
        bordered
        style={{ whiteSpace: 'pre-wrap', paddingBottom: 20 }}
      >
        <Column
          title="Date and Time"
          dataIndex="loggedAt"
          render={loggedAt => <UserAwareDateWithTz datetime={loggedAt} customerTimezone={order?.customer?.timezone} />}
        />
        <Column
          title="Event"
          dataIndex="action"
          render={(value: string): JSX.Element => (
            <Tag
              style={{ fontSize: 12 }}
              color={HistoryAction[value.toUpperCase() as keyof typeof HistoryAction] as PresetColorType}
            >
              {value.toUpperCase()}
            </Tag>
          )}
        />
        <Column
          title="Fields Changed"
          dataIndex="fieldsChanged"
          render={(value: Array<string>): string => {
            const fields = value.map((field: string) => {
              if (field === 'updated_at') {
                return ''
              }
              if (['complaintable_type', 'complaintable_id'].includes(field)) {
                return 'Category'
              }
              if (field === 'complaint_category_id') {
                return 'SubCategory'
              }

              return humanizeString(field)
            })
            const uniqueFields = [...new Set(fields)] // remove duplicates

            return uniqueFields.filter(field => field).join(', ')
          }}
        />
        <Column title="Logged By" dataIndex="loggedBy" />
      </Table>
      <SimplePagination
        currentCount={data?.complaintHistory?.edges?.length}
        totalCount={data?.complaintHistory?.edges?.length}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </>
  )
}

export default ManageComplaintHistory
