import React, { createContext, useMemo, useState } from 'react'

import useUserPreferences, { UserPreferencesType } from '../../hooks/useUserPreferences'

export type UserPreferencesContextState = {
  preferences: UserPreferencesType | null
  hasPreferences: boolean
  savePreferences: (preferences: UserPreferencesType) => void
}

export const UserPreferencesContext = createContext<UserPreferencesContextState>({
  preferences: null,
  hasPreferences: false,
  savePreferences: () => null,
})

const UserPreferencesContextProvider: React.FC = ({ children }) => {
  const { preferences, hasPreferences, savePreferences } = useUserPreferences()
  const [statePreferences, setStatePreferences] = useState<UserPreferencesType | null>(preferences)
  const value = useMemo(
    () => ({
      preferences: statePreferences,
      hasPreferences,
      savePreferences: (preferences: UserPreferencesType) => {
        savePreferences(preferences)
        setStatePreferences(preferences)
      },
    }),
    [statePreferences, hasPreferences],
  )

  return <UserPreferencesContext.Provider value={value}>{children}</UserPreferencesContext.Provider>
}

export default UserPreferencesContextProvider
