import React from 'react'
import { Form, FormInstance, Radio } from 'antd'

import { useFoodSafetyContext } from '../../../../../contexts/FoodSafetyContextProvider/FoodSafetyContextProvider'

const WarmProtein: React.FC<{ form: FormInstance }> = () => {
  const { detailsDisabled } = useFoodSafetyContext()

  return (
    <Form.Item
      name={['fsqaDetails', 'warmProtein']}
      label="Warm Protein?"
      rules={[{ required: !detailsDisabled, message: 'This field is required' }]}
    >
      <Radio.Group disabled={detailsDisabled}>
        <Radio data-testid="warm-protein-yes" value style={{ marginRight: 25 }}>
          Yes
        </Radio>

        <Radio data-testid="warm-protein-no" value={false} style={{ marginRight: 25 }}>
          No
        </Radio>
      </Radio.Group>
    </Form.Item>
  )
}

export default WarmProtein
