import React, { useState } from 'react'
import { Card, Row, Col, Form, Checkbox } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

import { useGetOrderRecipesIngredientsQuery } from '../../../../apollo/generated/api'

const AddOns: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const [affectedAddOns, setAffectedAddOns] = useState<string[]>([])
  const { data: orderData, loading } = useGetOrderRecipesIngredientsQuery({ variables: { number: orderNumber } })
  const addOns = (orderData?.order?.contents?.addOns || []).map(item => item.addOn)

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setAffectedAddOns(checkedValues as string[])
  }

  return (
    <Card
      title="Pick add-ons that were affected"
      headStyle={{ textAlign: 'center' }}
      style={{ marginBottom: 35, boxShadow: '0 15px 15px rgba(0, 0, 0, .05)' }}
      loading={loading}
    >
      {addOns.length ? (
        <Card style={{ marginBottom: 30 }}>
          <Form.Item name="affectedAddOns" rules={[{ required: true, message: 'Pick at least one add-on.' }]}>
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={affectedAddOns}>
              <Row gutter={[16, 16]}>
                {addOns.map(addOn => (
                  <Col span={8} key={addOn?.id}>
                    <Checkbox
                      data-testid={`add-on-${addOn?.name}`}
                      value={`${addOn?.id}|${addOn?.name}`}
                      className="complaint-ingredient-checkbox"
                    >
                      <img style={{ width: '100%' }} src={addOn?.image?.url || ''} alt={addOn?.name || ''} />
                      <div style={{ marginTop: 5 }}>{addOn?.name}</div>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      ) : (
        <div style={{ textAlign: 'center' }}>No add-ons found for this order.</div>
      )}
    </Card>
  )
}

export default AddOns
