import React from 'react'

import { PaymentSourceDetailsFragment } from '../../apollo/generated/api'
import LoadComponentError from '../../packages/LoadComponentError'

import AdyenKey from './AdyenKey'
import BankAccount from './BankAccount'
import CreditCard from './CreditCard'
import DirectDebit from './DirectDebit'
import InvoiceRecipient from './Invoice Recipient'
import PaypalCustomer from './PaypalCustomer'
import PreapprovalKey from './PreapprovalKey'

const PaymentSource: React.FC<{ source: PaymentSourceDetailsFragment; transactionToken?: string }> = ({
  source,
  transactionToken,
}) => {
  const { credentials, method } = source

  switch (credentials?.__typename) {
    case 'CreditCard': {
      return <CreditCard card={credentials} />
    }
    case 'DirectDebit': {
      return <DirectDebit directDebit={credentials} />
    }
    case 'BankAccount': {
      return (
        <BankAccount
          bankAccount={{
            bic: credentials.accountBic,
            iban: credentials.accountIban,
            name: credentials.bankAccountName,
          }}
        />
      )
    }
    case 'AdyenKey': {
      return <AdyenKey adyenKey={credentials} methodName={method?.name || ''} />
    }
    case 'InvoiceRecipient': {
      return <InvoiceRecipient invoiceRecipient={credentials} />
    }
    case 'PreapprovalKey': {
      return <PreapprovalKey preapprovalKey={credentials} />
    }
    case 'PaypalCustomer': {
      return <PaypalCustomer transactionToken={transactionToken || ''} email={credentials.email || ''} />
    }
    default:
      return <LoadComponentError errorMessage="Don't know how to present this payment source." />
  }
}

export default PaymentSource
