import { BrandEnum, CurrencyEnum, Promotion } from '../../apollo/generated/api'
import { formatCurrency } from '..'
import config from '../../config'

type UpcomingPromotion = {
  name: string
  code: string
  value: string
  valueLimit: string | null
  viewLink: string
  remoteOrigin: boolean
}

export const getPromotionViewLink = (type: string, id: string | number): string => {
  const baseUrl = type === 'PAPI::Promotion' ? config.breadcrumbsBaseUrl : config.chilliBaseUrl

  return `${baseUrl}/vouchers/${id}`
}

export const getVoucherUsageViewLink = (brand: BrandEnum, id: string | number) => {
  const baseUrl = config.breadcrumbsBaseUrl

  return `${baseUrl}/customers/${brand}/${id}`
}

export const defaultUpcomingPromotion = (): UpcomingPromotion => {
  return {
    name: '',
    code: '',
    value: '0',
    valueLimit: null,
    viewLink: '',
    remoteOrigin: false,
  }
}

export const formatUpcomingPromotion = (
  promotion: Pick<Promotion, 'id' | 'name' | 'code' | 'value' | 'valueType' | 'valueLimit' | 'remoteOrigin'>,
  currency: CurrencyEnum,
): UpcomingPromotion => {
  const promotionValueType = promotion?.valueType || 'default'
  const promotionValue = promotion?.value
  const valueType = {
    percentage: {
      value: `${promotionValue}%`,
    },
    fixed: {
      value: formatCurrency(promotionValue, currency),
    },
    default: {
      value: formatCurrency(0, currency),
    },
  }
  let promotionValueLimit = null

  if (promotion?.valueLimit) {
    promotionValueLimit = formatCurrency(promotion?.valueLimit, currency)
  }

  return {
    name: promotion?.name || '',
    code: promotion?.code || '',
    value: valueType[promotionValueType]?.value,
    valueLimit: promotionValueLimit,
    remoteOrigin: promotion.remoteOrigin,
    viewLink:
      (promotion.remoteOrigin
        ? `${config.breadcrumbsBaseUrl}/vouchers/${promotion.id}`
        : `${config.chilliBaseUrl}/vouchers/${promotion.id}`) || ``,
  }
}
