import React, { FunctionComponent } from 'react'
import { Row, Col } from 'antd'
import { Route, Switch, useParams, useLocation, Redirect, generatePath } from 'react-router-dom'

import * as customProps from '../../constants/OverridesConstants'
import { Routes } from '../../constants/RoutesConstants'
import ManageOrder from '../../components/ManageOrder'
import ManageComplaint from '../../components/ManageComplaint'
import Loading from '../../packages/Loading'
import GeneralError from '../../packages/GeneralError'
import { GetOrderManagementDetailsQuery, useGetOrderManagementDetailsQuery } from '../../apollo/generated/api'

import ManageOrderHeader from './ManageOrderHeader'
import ManageOrderSidebar from './ManageOrderSidebar'
import ManageOrderEmpty from './ManageOrderEmpty'

const Loader: FunctionComponent = () => (
  <div data-testid="order-container-loading">
    <Loading />
  </div>
)
const Error: FunctionComponent<{ message: string }> = ({ message }) => (
  <div data-testid="order-container-error">
    <GeneralError message={message} />
  </div>
)

export type ManageOrderProps = GetOrderManagementDetailsQuery['order']

const ManageOrderContainer: React.FC = () => {
  const { number } = useParams<{ number: string }>()
  const { pathname } = useLocation()
  const { loading, error, data } = useGetOrderManagementDetailsQuery({ variables: { number } })

  const withHeaderProps: Function = (
    WrappedComponent: FunctionComponent<{ orderNumber: string; customerId?: number; order: ManageOrderProps }>,
  ) =>
    // eslint-disable-next-line react/display-name, func-names
    function (): React.ReactElement {
      return <WrappedComponent orderNumber={number} order={data?.order} />
    }

  if (loading) return <Loader />
  if (error) return <Error message={error.message} />
  if (!data?.order) return <ManageOrderEmpty orderNumber={number} />

  const { order } = data

  if (!order.customer?.id) return <GeneralError message="This order is not associated to a customer" />

  return (
    <>
      <ManageOrderHeader order={{ ...order, customerId: order.customer.id }} currentPath={pathname} />

      <Row {...customProps.ROW}>
        <Col span={16}>
          <Switch>
            <Route
              path={Routes.ORDER_DETAIL}
              render={(): React.ReactElement => <Redirect to={generatePath(Routes.ORDER_DELIVERY, { number })} />}
              exact
            />
            <Route path={Routes.ORDER_DELIVERY} render={withHeaderProps(ManageOrder.Delivery)} exact />
            <Route path={Routes.ORDER_RECIPES} render={withHeaderProps(ManageOrder.Recipes)} exact />
            <Route path={Routes.ORDER_SHIPMENTS} render={withHeaderProps(ManageOrder.Shipments)} exact />
            <Route path={Routes.ORDER_PAYMENTS} render={withHeaderProps(ManageOrder.Payments)} exact />
            <Route path={Routes.ORDER_PAYMENT_DETAIL} render={withHeaderProps(ManageOrder.PaymentDetails)} exact />
            <Route path={Routes.ORDER_ADJUSTMENTS} render={withHeaderProps(ManageOrder.Adjustments)} exact />
            <Route path={Routes.ORDER_COMPLAINTS} render={withHeaderProps(ManageOrder.Complaints)} exact />
            <Route path={Routes.ORDER_COMPLAINT_DETAILS} render={withHeaderProps(ManageComplaint.Details)} exact />
            <Route path={Routes.ORDER_COMPLAINT_HISTORY} render={withHeaderProps(ManageComplaint.History)} exact />
            <Route path={Routes.ORDER_COMPLAINT_EDIT} render={withHeaderProps(ManageComplaint.Edit)} />
            <Route path={Routes.ORDER_HISTORY} render={withHeaderProps(ManageOrder.History)} exact />
            <Route path={Routes.ORDER_ADDRESS_DETAIL} render={withHeaderProps(ManageOrder.AdressDetails)} exact />
            <Route path={Routes.COMPLAINTS_NEW} render={withHeaderProps(ManageComplaint.Create)} exact />
          </Switch>
        </Col>

        <Col span={8}>{data.order && <ManageOrderSidebar data={data} />}</Col>
      </Row>
    </>
  )
}

export default ManageOrderContainer
