import React from 'react'
import { DeleteTwoTone, FileSearchOutlined } from '@ant-design/icons'
import { Table, Popconfirm, Typography, Row, Col, Card } from 'antd'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import OrderAdjustmentForm from '../../Forms/OrderAdjustmentForm'
import OrderCouponForm from '../../Forms/OrderCouponForm'
import { formatCurrency } from '../../../utils'
import { formatUpcomingPromotion, defaultUpcomingPromotion } from '../../../utils/promotionUtils'
import { DELETE_ADJUSTMENT_SUCCESS, DELETE_ADJUSTMENT_ERROR } from '../../../constants/MessagesConstants'
import Colors from '../../../constants/ColorsConstants'
import {
  Adjustment,
  GetOrderAdjustmentsDocument,
  GetOrderManagementDetailsDocument,
  OrderStateEnum,
  useDeleteAdjustmentMutation,
  useGetOrderAdjustmentsQuery,
} from '../../../apollo/generated/api'
import { handleMutationResult } from '../../../apollo'
import { queryNames } from '../../../apollo/client'
import { getPromotionViewLink } from '../../../utils/promotionUtils/promotionUtils'

const { Column } = Table
const { Text } = Typography
const adjustmentStates = {
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
}

const ManageOrderAdjustments: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const { loading, error, data } = useGetOrderAdjustmentsQuery({
    variables: { number: orderNumber },
    fetchPolicy: 'no-cache',
  })
  const [deleteAdjustment, { loading: loadingDeleteAdjustment }] = useDeleteAdjustmentMutation()

  const deleteAdjustmentAction = (id: number): void => {
    const mutation = deleteAdjustment({
      variables: { adjustment: { id } },
      refetchQueries: queryNames(GetOrderAdjustmentsDocument, GetOrderManagementDetailsDocument),
    })

    void handleMutationResult(mutation, 'deleteAdjustment', {
      notifications: {
        success: {
          title: DELETE_ADJUSTMENT_SUCCESS,
        },
        error: {
          title: DELETE_ADJUSTMENT_ERROR,
        },
      },
    })
  }
  const order = data?.order
  const customerId = order?.customer?.id
  const adjustments = order?.adjustments || []
  const currency = order?.pricing?.currency
  const isOrderComplete = order?.state === OrderStateEnum.Complete
  const upcomingOrderPromotion = order?.upcomingPromotion
  let promotion = defaultUpcomingPromotion()

  if (upcomingOrderPromotion && order?.pricing?.itemTotal && currency) {
    promotion = formatUpcomingPromotion(upcomingOrderPromotion, currency)
  }

  const hasAnUpcomingPromotion = upcomingOrderPromotion && !isOrderComplete
  const paragraphInlineStyle = {
    fontWeight: 600,
    cssFloat: 'left',
    marginRight: 6,
  }

  if (loading) return <Loading />
  if (error || !customerId) return <LoadComponentError errorMessage={error?.message} />

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 30 }}>
        <Col span={12}>
          <OrderAdjustmentForm orderNumber={orderNumber} customerId={customerId} />
        </Col>

        <Col span={12}>
          <OrderCouponForm orderNumber={orderNumber} />
        </Col>
      </Row>
      {hasAnUpcomingPromotion && (
        <Row gutter={16} style={{ marginBottom: 30 }}>
          <Col span={24}>
            <Card title="Upcoming Promotion" data-testid="order-upcoming-promotion">
              <div style={{ marginBottom: '1em' }}>
                <FileSearchOutlined style={{ marginRight: 6 }} />
                <a href={promotion.viewLink} target="_blank" rel="noopener noreferrer">
                  View on {promotion.remoteOrigin ? 'Breadcrumbs' : 'Chilli'}
                </a>
              </div>
              <div>
                <p style={paragraphInlineStyle}>Name:</p> <p>{promotion.name}</p>
              </div>
              <div>
                <p style={paragraphInlineStyle}>Code:</p> <p>{promotion.code}</p>
              </div>
              <div>
                <p style={paragraphInlineStyle}>Value:</p> <p>{promotion.value}</p>
              </div>
              {promotion.valueLimit && (
                <div>
                  <p style={paragraphInlineStyle}>Discount limit:</p> <p>{promotion.valueLimit}</p>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      )}
      <Table
        title={() => 'Adjustments'}
        data-testid="order-management-adjustments-table"
        dataSource={adjustments}
        rowKey="id"
        bordered
        pagination={false}
      >
        <Column title="Item" dataIndex="type" render={(value): JSX.Element => <Text code>{value}</Text>} />

        <Column
          title="Description"
          dataIndex="label"
          render={(_, { label, source, sourceType }: Adjustment): JSX.Element => {
            if (sourceType && source?.id) {
              const link = getPromotionViewLink(sourceType, source.id)

              return (
                <a href={link} style={{ hyphens: 'auto' }} target="_blank" rel="noopener noreferrer">
                  {label}
                </a>
              )
            }

            return <>{label}</>
          }}
        />

        <Column title="Amount" dataIndex="amount" render={(value): string => formatCurrency(value, currency)} />

        <Column
          title="Actions"
          align="center"
          render={(_, { id, amount, state }: Adjustment): JSX.Element => {
            const formattedAmount = formatCurrency(amount, currency)

            if (state === adjustmentStates.OPENED) {
              return (
                <Popconfirm
                  title={`Are you sure you want to delete this ${formattedAmount} adjustment?`}
                  onConfirm={(): void => deleteAdjustmentAction(id)}
                >
                  {loadingDeleteAdjustment ? (
                    <div />
                  ) : (
                    <DeleteTwoTone data-testid="delete-adjustment-icon" twoToneColor={Colors.DANGER} />
                  )}
                </Popconfirm>
              )
            }

            return <div />
          }}
        />
      </Table>
    </>
  )
}

export default ManageOrderAdjustments
