import { Alert } from 'antd'
import React from 'react'
import { generatePath, Redirect, useParams } from 'react-router'

import { handleMutationResult } from '../../../apollo'
import {
  GetOrderAddressesQuery,
  useGetOrderAddressesQuery,
  useUpdateAddressMutation,
} from '../../../apollo/generated/api'
import {
  CUSTOMER_ADDRESS_UPDATED_FAILURE,
  CUSTOMER_ADDRESS_UPDATED_SUCCESS,
} from '../../../constants/MessagesConstants'
import { Routes } from '../../../constants/RoutesConstants'
import { buildAddressInput, buildDynamicFormFields } from '../../../utils/addressUtils/addressUtils'
import CustomerAddressForm from '../../Forms/CustomerAddressForm'
import { CustomerAddressFormInitialValues, CustomerAddressFormValues } from '../../Forms/CustomerAddressForm/types'
import { AddressMeta } from '../../ManageCustomer/props'
import logger from '../../../logging'

const ManageOrderAddress: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const { id } = useParams<{ id: string }>()

  const findAddress = (
    addressId: number,
    response?: GetOrderAddressesQuery,
  ): CustomerAddressFormInitialValues | null => {
    let match = null

    if (response?.order?.shippingAddress?.id === addressId) {
      match = response?.order?.shippingAddress
    } else if (response?.order?.billingAddress?.id === addressId) {
      match = response?.order?.billingAddress
    }

    return {
      ...match,
      stateId: Number(match?.state?.id),
    }
  }
  const { data, loading } = useGetOrderAddressesQuery({ variables: { number: orderNumber } })
  const [updateAddress, { data: updateAddressData }] = useUpdateAddressMutation()
  const address = loading ? null : findAddress(Number(id), data)
  const addressMeta: AddressMeta = {
    isTypeRequired: data?.order?.customer?.addressMeta.isTypeRequired || false,
    isStateRequired: data?.order?.customer?.addressMeta.isStateIdRequired || false,
  }

  const onFinish = async (formValues: CustomerAddressFormValues): Promise<void> => {
    const variables = {
      id: Number(id),
      updateAddress: buildAddressInput(formValues),
    }
    const mutation = updateAddress({ variables })

    if (data?.order?.orderStatus?.inProduction) {
      logger().warning('Address updated with order in production')
    }

    await handleMutationResult(mutation, 'updateAddress', {
      notifications: {
        success: {
          title: CUSTOMER_ADDRESS_UPDATED_SUCCESS,
        },
        error: {
          title: CUSTOMER_ADDRESS_UPDATED_FAILURE,
        },
      },
    })
  }

  if (updateAddressData?.updateAddress?.__typename === 'Address') {
    return <Redirect to={generatePath(Routes.ORDER_DELIVERY, { number: orderNumber })} />
  }

  return (
    <>
      <Alert
        type="error"
        message="Please, only use this form if you need to perform late order changes to the address"
      />
      {!loading && address && data?.order?.country && (
        <CustomerAddressForm
          country={data.order.country}
          brand={data.order.brand}
          initialValues={address}
          onFinish={onFinish}
          loading={loading}
          dynamicFields={buildDynamicFormFields(addressMeta)}
        />
      )}
    </>
  )
}

export default ManageOrderAddress
