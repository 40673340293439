import React from 'react'
import { Space } from 'antd'

import { MembershipStatusEnum } from '../../apollo/generated/api'
import { formatMembershipStatus } from '../../utils'
import { ManageCustomerProps } from '../ManageCustomer/props'
import UserAwareDateWithTz from '../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

const MembershipLabel: React.FC<
  ManageCustomerProps['customer']['membership'] & { customerTimezone?: string | null }
> = ({ status, pausedUntil, customerTimezone }) => {
  const renderLabel = (): React.ReactElement => {
    if (status === 'PAUSED' && pausedUntil) {
      return (
        <Space direction="vertical">
          {formatMembershipStatus(status)}
          <small>
            Paused until: <UserAwareDateWithTz datetime={pausedUntil} customerTimezone={customerTimezone} dateOnly />
          </small>
        </Space>
      )
    }

    return formatMembershipStatus(status || MembershipStatusEnum.None)
  }

  return renderLabel()
}

export default MembershipLabel
