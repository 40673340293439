import { ColumnType } from 'antd/lib/table'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Table, TableProps } from 'antd'
import { CustomizeScrollBody } from 'rc-table/lib/interface'
import { FixedSizeList } from 'react-window'
import ResizeObserver from 'rc-resize-observer'

import { ValueCommitter, ValueOf } from './types'
import { PaginatedDataSource } from './PaginatedDataSource'

export type VirtualTableColumnProps<RecordType> = ColumnType<RecordType> & {
  editable?: boolean
  renderEditor?: <T>(
    record: RecordType,
    committer: ValueCommitter<T>,
    style: React.CSSProperties,
  ) => ReactElement | null | undefined
  renderValue?: (record: RecordType, style: React.CSSProperties) => ReactElement | null | undefined
}

export type VirtualTableProps<RecordType> = Omit<TableProps<RecordType>, 'dataSource'> & {
  onVisibleRangeChanged?: (firstVisibleIndex: number, lastVisibleIndex: number) => void
  columns: VirtualTableColumnProps<RecordType>[]
  dataSource: PaginatedDataSource<RecordType, unknown>
}

const ROW_HEIGHT_PX = 54

export const VirtualTable = <RecordType extends object>(props: VirtualTableProps<RecordType>) => {
  const columns = props.columns || []
  const [viewportSize, setViewportSize] = useState([0, 0])
  const [visibleRange, setVisibleRange] = useState([0, 0])
  const widthColumnCount = columns.filter(({ width }) => !width).length
  const scrollPosition = useRef(0)
  const [editedCellKey, setEditedCellKey] = useState('')
  const mergedColumns = columns.map(column => {
    if (column.width) {
      return column
    }

    return {
      ...column,
      width: Math.floor(viewportSize[0] / widthColumnCount),
    }
  }) as VirtualTableColumnProps<RecordType>[]

  useEffect(() => {
    checkVisibleRangeChanged()
  }, [...viewportSize, props.dataSource.itemCount])

  const checkVisibleRangeChanged = (newScroll = scrollPosition.current) => {
    scrollPosition.current = newScroll
    const dsItemCount = props.dataSource.itemCount
    const firstVisibleIndex = Math.min(dsItemCount, Math.floor(newScroll / ROW_HEIGHT_PX)),
      lastVisibleIndex = Math.min(dsItemCount, Math.ceil((newScroll + viewportSize[1]) / ROW_HEIGHT_PX))

    if (visibleRange[0] !== firstVisibleIndex || visibleRange[1] !== lastVisibleIndex) {
      if (lastVisibleIndex > dsItemCount - 10) {
        props.dataSource.triggerLoad()
      }

      setVisibleRange([firstVisibleIndex, lastVisibleIndex])
    }
  }

  useEffect(() => {
    props.onVisibleRangeChanged?.(visibleRange[0], visibleRange[1])
  }, visibleRange)

  const renderVirtualList: CustomizeScrollBody<RecordType> = (
    rawData: readonly RecordType[],
    { scrollbarSize }: { scrollbarSize: number },
  ) => {
    // eslint-disable-next-line no-param-reassign

    const getColumnWidth = (index: number) => {
      const { width } = mergedColumns[index]

      if (typeof width === 'number') {
        if (index === mergedColumns.length - 1) {
          return `${width - scrollbarSize}px`
        }

        return `${width}px`
      }

      return width
    }

    const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
      return GridRow(index, style)
    }

    const GridRowCell = (rowIndex: number, colIndex: number, style: React.CSSProperties) => {
      const cellKey = `cell-${rowIndex}-${colIndex}`
      const colSpec = mergedColumns[colIndex]

      // eslint-disable-next-line no-param-reassign
      style = {
        ...style,
        width: getColumnWidth(colIndex),
      }

      const record = rawData[rowIndex]
      const dataIndex = mergedColumns[colIndex].dataIndex as keyof RecordType
      const cellValue = (record as Record<typeof dataIndex, unknown>)[dataIndex]

      if (colSpec.editable && cellKey === editedCellKey) {
        const committer: ValueCommitter<unknown> = {
          key: cellKey,
          save(value: unknown) {
            console.warn('value', value)
            props.dataSource.setItemValue(record, dataIndex, value as ValueOf<RecordType>)
            setEditedCellKey('')
          },
          cancel() {
            setEditedCellKey('')
          },
        }

        return colSpec?.renderEditor?.(record, committer, style)
      }

      const child = colSpec.renderValue?.(record, {}) || <span>{cellValue}</span>

      return (
        <div
          onDoubleClick={() => {
            if (colSpec.editable && editedCellKey === '') {
              setEditedCellKey(cellKey)
            }
          }}
          key={cellKey}
          style={style}
        >
          {child}
        </div>
      )
    }

    const GridRow = (rowIndex: number, style: React.CSSProperties) => {
      // eslint-disable-next-line no-param-reassign
      style = {
        ...style,
        display: 'flex',
      }

      const children = Array(mergedColumns.length)
        .fill(null)
        .map((_a, colIndex) => {
          return GridRowCell(rowIndex, colIndex, {})
        })

      return <div style={style}>{children}</div>
    }
    const itemCount = props.dataSource?.itemCount || 0

    return (
      <FixedSizeList
        onScroll={props1 => {
          checkVisibleRangeChanged((scrollPosition.current = props1.scrollOffset))
        }}
        itemCount={itemCount}
        itemSize={ROW_HEIGHT_PX}
        width={viewportSize[0]}
        height={viewportSize[1]}
      >
        {Row}
      </FixedSizeList>
    )
  }

  return (
    <ResizeObserver
      onResize={({ width, height }) => {
        setViewportSize([width, height - 60]) // TODO find a proper way of getting the table header size
        checkVisibleRangeChanged()
      }}
    >
      <Table
        {...props}
        dataSource={props.dataSource.loadedItems}
        className="virtual-table"
        columns={mergedColumns as ColumnType<RecordType>[]}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>
  )
}
