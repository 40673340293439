import { Button, Card, Modal, PageHeader, Switch, Table, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import Search from 'antd/lib/input/Search'
import { ApolloError } from '@apollo/client'

import { useRelayPagination } from '../../../hooks'
import { getUserSession } from '../../../utils'
import { headerIcons } from '../../../constants/IconsConstants'
import { UPDATE_ADMIN_USER_FAILURE, UPDATE_ADMIN_USER_SUCCESS } from '../../../constants/MessagesConstants'
import LoadComponentError from '../../../packages/LoadComponentError'
import { handleBeefMutationResult, handleMutationResult } from '../../../apollo'
import CreateAdminUserForm from '../CreateAdminUserForm'
import {
  AdminUser,
  ListAdminsDocument,
  UpdateAdminUserMutationVariables,
  useListAdminsQuery,
  useUpdateAdminUserMutation,
} from '../../../apollo/generated/api'
import {
  AdminUser as BeefAdminUser,
  AdminUserRole,
  AdminUserRole as BeefAdminUserRole,
  UpdateAdminUserMutationVariables as UpdateAdminUserBeefMutationVariables,
  useGetAdminUsersQuery,
  useUpdateAdminUserMutation as useUpdateAdminUserBeefMutation,
} from '../../../apollo/generated/beef'
import { queryNames } from '../../../apollo/client'
import SimplePagination from '../../SimplePagination'

type AdminUserNode = { node: AdminUser }

const { Text } = Typography

export const PAGE_SIZE = 25

function mergeRoles(ms: AdminUser, beef?: BeefAdminUser): Set<BeefAdminUserRole> {
  const res = new Set<BeefAdminUserRole>()

  if (ms.admin) {
    res.add(BeefAdminUserRole.Admin)
  }
  if (ms.superAdmin) {
    res.add(BeefAdminUserRole.SuperAdmin)
  }
  // eslint-disable-next-line no-unsafe-optional-chaining,no-restricted-syntax
  for (const role of beef?.roles || []) {
    res.add(role)
  }

  return res
}

class UserInfo {
  roles: Set<BeefAdminUserRole>

  // eslint-disable-next-line no-useless-constructor
  constructor(public ms: AdminUser, public beef: BeefAdminUser | undefined) {
    this.roles = mergeRoles(ms, beef)
  }

  hasRole(role: BeefAdminUserRole) {
    return this.roles.has(role)
  }

  get email() {
    return this.ms.email
  }

  get id() {
    return this.ms.id
  }

  msRolesChanged(updatedRoles: Set<BeefAdminUserRole> | undefined) {
    if (!updatedRoles) {
      return false
    }
    if (this.ms.admin !== updatedRoles.has(BeefAdminUserRole.Admin)) {
      return true
    }
    if (this.ms.superAdmin !== updatedRoles.has(BeefAdminUserRole.SuperAdmin)) {
      return true
    }

    return false
  }

  rolesMatch(updatedRoles: Set<BeefAdminUserRole> | undefined): boolean {
    if (!updatedRoles) {
      return this.roles.size === 0
    }
    if (this.roles.size !== updatedRoles.size) {
      return false
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const role of this.roles) {
      if (!updatedRoles.has(role)) {
        return false
      }
    }

    return true
  }
}

const useUserData = (
  emailQuery: string,
  currentCursor: string,
): {
  loading: boolean
  error: ApolloError | undefined
  data: UserInfo[]
  totalCount: number | undefined
  nextPageCursor: string | undefined
} => {
  const {
    data: msAdminData,
    loading: msDataLoading,
    error: msError,
  } = useListAdminsQuery({
    variables: {
      email: emailQuery,
      first: PAGE_SIZE,
      after: currentCursor,
    },
  })
  const totalCount = msAdminData?.admins.totalCount
  const emails = useMemo(() => {
    return ((msAdminData?.admins?.edges || []) as AdminUserNode[]).map(value => value.node.email)
  }, [msAdminData?.admins?.edges])
  const {
    data: beefAdminData,
    loading: beefDataLoading,
    error: beefError,
  } = useGetAdminUsersQuery({
    variables: {
      params: {
        emails,
      },
    },
  })
  const loading = msDataLoading || beefDataLoading
  const error = msError || beefError
  const data = useMemo(() => {
    return ((msAdminData?.admins?.edges || []) as AdminUserNode[]).map(msEntry => {
      const beefEntry = ((beefAdminData?.adminUsers || []) as unknown as BeefAdminUser[])?.find(
        u => u.email === msEntry.node.email,
      )

      return new UserInfo(msEntry.node, beefEntry)
    })
  }, [loading, error, msAdminData, beefAdminData])
  const nextPageCursor = msAdminData?.admins?.pageInfo?.endCursor || undefined

  return {
    loading,
    error,
    data,
    totalCount,
    nextPageCursor,
  }
}

const ListAdminUsers: React.FC = () => {
  const { session } = getUserSession()
  const [adminUserFormVisible, setAdminUserFormVisible] = useState(false)
  const { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage } = useRelayPagination()
  const [emailQuery, setEmailQuery] = useState('')
  const { data: adminUsers, loading, error, nextPageCursor, totalCount } = useUserData(emailQuery, currentCursor)
  const [updateAdminUser, { loading: savingAdminUser }] = useUpdateAdminUserMutation()
  const [updateAdminUserBeef] = useUpdateAdminUserBeefMutation()
  const toggleAdminUserModal = (): void => setAdminUserFormVisible(!adminUserFormVisible)
  const isCurrentUser = (email: string): boolean => session?.user?.email === email
  const [changedUserRoles, setChangedUserRoles] = useState(new Map<number, Set<AdminUserRole>>())

  const canSave = (user: UserInfo): boolean => {
    return changedUserRoles.has(user.id)
  }

  const userHasRole = (user: UserInfo, role: BeefAdminUserRole): boolean => {
    const roles = changedUserRoles.get(user.id) || user.roles

    return roles.has(role)
  }

  const toggleUserRole = (user: UserInfo, role: BeefAdminUserRole) => {
    const roles = new Set(changedUserRoles.get(user.id) || user.roles)

    if (roles.has(role)) {
      roles.delete(role)
    } else {
      roles.add(role)
    }

    if (user.rolesMatch(roles)) {
      changedUserRoles.delete(user.id)
    } else {
      changedUserRoles.set(user.id, roles)
    }
    setChangedUserRoles(new Map(changedUserRoles))
  }

  const updateAdminUserOnBeefApi = async (user: UserInfo): Promise<void> => {
    const isAdmin = userHasRole(user, AdminUserRole.Admin),
      isSuperAdmin = userHasRole(user, AdminUserRole.SuperAdmin),
      isCCAdmin = userHasRole(user, AdminUserRole.CcAdmin),
      roles: AdminUserRole[] = []

    if (isAdmin) {
      roles.push(AdminUserRole.Admin)
    }
    if (isSuperAdmin) {
      roles.push(AdminUserRole.SuperAdmin)
    }
    if (isCCAdmin) {
      roles.push(AdminUserRole.CcAdmin)
    }

    const variables: UpdateAdminUserBeefMutationVariables = {
      updateAdminUser: {
        email: user.email,
        admin: isAdmin,
        superAdmin: isSuperAdmin,
        roles,
      },
    }
    const beefMutation = updateAdminUserBeef({
      variables,
    })

    await handleBeefMutationResult(beefMutation, 'updateAdminUser')
  }

  const saveUser = async (user: UserInfo): Promise<void> => {
    let msSaveSuccess = true
    const updatedRoles = changedUserRoles.get(user.id)

    if (user.msRolesChanged(updatedRoles)) {
      // ms api returns an error unless we change at least one of the original
      // values, so we need to make sure we only make the request if something has changed
      const variables: UpdateAdminUserMutationVariables = {
        adminUser: {
          email: user.email,
          admin: userHasRole(user, AdminUserRole.Admin),
          superAdmin: userHasRole(user, AdminUserRole.SuperAdmin),
        },
      }
      const mutation = updateAdminUser({
        variables,
        refetchQueries: queryNames(ListAdminsDocument),
      })
      const { data } = await handleMutationResult(mutation, 'updateAdminUser', {
        notifications: {
          success: {
            title: UPDATE_ADMIN_USER_SUCCESS,
          },
          error: {
            title: UPDATE_ADMIN_USER_FAILURE,
          },
        },
      })

      if (data?.updateAdminUser?.__typename !== 'MutationErrorWrapper') {
        const updatedAdminUser = data?.updateAdminUser as unknown as AdminUser

        // eslint-disable-next-line no-param-reassign
        user.ms = updatedAdminUser
      } else {
        msSaveSuccess = false
      }
    }

    if (msSaveSuccess) {
      void updateAdminUserOnBeefApi(user)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,no-param-reassign
      user.roles = new Set(updatedRoles)
      changedUserRoles.delete(user.id)
      setChangedUserRoles(new Map(changedUserRoles))
    }
  }

  const onSearch = (value: string): void => {
    setEmailQuery(value)
  }

  const onNextPage = () => {
    if (nextPageCursor) {
      handleNextPage(nextPageCursor)
    }
  }

  return (
    <>
      <Modal title="Create new admin user" open={adminUserFormVisible} onCancel={toggleAdminUserModal} footer={null}>
        <CreateAdminUserForm toggleModal={toggleAdminUserModal} />
      </Modal>

      <PageHeader
        title="Admin Users"
        avatar={{ src: headerIcons.ADMIN_USERS, shape: 'square' }}
        extra={[
          <Button onClick={toggleAdminUserModal} type="primary" key="createAdminUser">
            New admin user
          </Button>,
        ]}
      />
      {error && <LoadComponentError errorMessage={error.message} />}
      {!error && (
        <>
          <Card style={{ marginBottom: 20 }}>
            <Search
              data-testid="admin-user-search"
              placeholder="Search for admin users"
              allowClear
              size="middle"
              onSearch={onSearch}
            />
          </Card>

          <Table<UserInfo>
            data-testid="admin-users"
            bordered
            loading={loading}
            dataSource={adminUsers}
            rowKey={({ ms }) => ms.id}
            pagination={false}
            style={{ marginBottom: 20 }}
          >
            <Table.Column
              title="ID"
              dataIndex="id"
              render={(value: number): JSX.Element => <Text code>{value}</Text>}
            />
            <Table.Column title="Email" dataIndex="email" />
            <Table.Column
              title="Admin?"
              width="160px"
              render={(_value: boolean, u: UserInfo): React.ReactElement => {
                return (
                  <Switch
                    data-testid="admin-toggle"
                    checked={userHasRole(u, BeefAdminUserRole.Admin)}
                    onChange={(): void => toggleUserRole(u, BeefAdminUserRole.Admin)}
                    size="small"
                    disabled={isCurrentUser(u.email)}
                  />
                )
              }}
            />
            <Table.Column
              title="Super Admin?"
              width="160px"
              render={(_value: boolean, u: UserInfo): React.ReactElement => {
                return (
                  <Switch
                    data-testid="super-admin-toggle"
                    checked={userHasRole(u, BeefAdminUserRole.SuperAdmin)}
                    onChange={(): void => toggleUserRole(u, BeefAdminUserRole.SuperAdmin)}
                    size="small"
                    disabled={isCurrentUser(u.email)}
                  />
                )
              }}
            />
            <Table.Column
              width="160px"
              title="CC Admin?"
              render={(_value: boolean, u: UserInfo): React.ReactElement => {
                return (
                  <Switch
                    data-testid="cc-admin-toggle"
                    checked={userHasRole(u, BeefAdminUserRole.CcAdmin)}
                    onChange={(): void => toggleUserRole(u, BeefAdminUserRole.CcAdmin)}
                    size="small"
                    disabled={isCurrentUser(u.email)}
                  />
                )
              }}
            />
            <Table.Column
              title="Actions"
              width="160px"
              dataIndex="actions"
              render={(_, u: UserInfo): JSX.Element => (
                <Button
                  data-testid="update-admin-button"
                  onClick={(): Promise<void> => saveUser(u)}
                  type="primary"
                  size="small"
                  disabled={!canSave(u) || savingAdminUser}
                  loading={savingAdminUser}
                >
                  Save
                </Button>
              )}
            />
          </Table>

          <SimplePagination
            totalCount={totalCount}
            hasNextPage={!!nextPageCursor}
            hasPreviousPage={hasPreviousPage}
            onNextPage={onNextPage}
            onPreviousPage={handlePreviousPage}
          />
        </>
      )}
    </>
  )
}

export default ListAdminUsers
