import React, { useContext } from 'react'

import TimeWithDoubleTimeZone from '../TimeWithDoubleTimezone/TimeWithDoubleTimezone'
import { CustomTimezones } from '../../UserPreferences/UserPreferences'
import { DateFormats } from '../TimeWithTimezone/TimeWithTimezone'
import { UserPreferencesContext } from '../../../contexts/UserPreferencesContextProvider/UserPreferencesContextProvider'

export type UserAwareDateWithTzProps = {
  datetime: string
  customerTimezone?: string | null
  dateOnly?: boolean
  useTooltip?: boolean
}

const UserAwareDateWithTz = ({ datetime, customerTimezone, dateOnly, useTooltip }: UserAwareDateWithTzProps) => {
  const format = dateOnly ? DateFormats.DateText : DateFormats.DateTimeText
  const tzCustomer = customerTimezone || 'UTC'
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { preferences } = useContext(UserPreferencesContext)
  let tzPrimary = preferences?.primaryTz || tzCustomer
  const tzSecondary = preferences?.secondaryTz || browserTimezone

  switch (tzPrimary) {
    case CustomTimezones.CUSTOMER:
      tzPrimary = tzCustomer
      break
    default:
      break
  }

  return (
    <TimeWithDoubleTimeZone
      datetime={datetime}
      tzPrimary={tzPrimary}
      tzSecondary={tzSecondary}
      formatPrimary={format}
      formatSecondary={format}
      useTooltip={useTooltip ?? true}
    />
  )
}

export default UserAwareDateWithTz
