import { Tag } from 'antd'
import React from 'react'

import { capitalize } from '../../../utils'

const mappedPaymentStatus: { [status: string]: string } = {
  failed: 'volcano',
  'credit owned': 'blue',
  owned: 'orange',
  void: 'gold',
  completed: 'green',
  paid: 'green',
  'balance due': 'purple',
}
const PaymentState: React.FC<{ state: string }> = ({ state }) => (
  <Tag data-testid="payment-state" color={mappedPaymentStatus[state]}>
    {capitalize(state)}
  </Tag>
)

export default PaymentState
