import React from 'react'
import { Form, Input } from 'antd'

const ComplaintDescription: React.FC = () => {
  return (
    <Form.Item name="description" label="Complaint description">
      <Input.TextArea data-testid="complaint-description" placeholder="Briefly explain what this complaint is about." />
    </Form.Item>
  )
}

export default ComplaintDescription
