export const copyToClipboard = async (url: string) => {
  // available only in secure contexts (HTTPS)
  if (!window.navigator.clipboard) {
    console.error('Clipboard interface not available')

    return
  }

  await window.navigator.clipboard.writeText(url)
}
