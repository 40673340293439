import ManageComplaintDetails from './ManageComplaintDetails'
import ManageComplaintCreate from './ManageComplaintCreate'
import ManageComplaintEdit from './ManageComplaintEdit'
import ManageComplaintHistory from './ManageComplaintHistory'

const ManageComplaint = {
  Create: ManageComplaintCreate,
  Edit: ManageComplaintEdit,
  Details: ManageComplaintDetails,
  History: ManageComplaintHistory,
}

export default ManageComplaint
