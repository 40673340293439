import React, { useState, useEffect } from 'react'
import { Card, Form, Select, Row, Col, Button, Alert, Checkbox, Tooltip } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Store } from 'antd/lib/form/interface'

import { CARD as cardProps, ROW as rowProps } from '../../../constants/OverridesConstants'
import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { weekdays as weekdayNames } from '../../../utils'
import { handleMutationResult } from '../../../apollo'
import {
  BrandEnum,
  CountryEnum,
  GetAllPlansDocument,
  useGetAllPlansQuery,
  useUpdatePlanMutation,
} from '../../../apollo/generated/api'
import { queryNames } from '../../../apollo/client'
import { showExtraShippingPrice } from '../OrderDeliveryCalendar/helper'

const { Option } = Select

const CustomerPlanForm: React.FC<CustomerPlanFormProps> = ({ customer }) => {
  const { id: customerId, brand, country } = customer
  const canPrintRecipeCards =
    brand === BrandEnum.Ms || (brand === BrandEnum.Dn && (country === CountryEnum.Us || country === CountryEnum.Au))
  const { loading, error, data } = useGetAllPlansQuery({ variables: { customerId } })
  const [form] = Form.useForm()
  const [weekday, setWeekday] = useState<number>()
  const [timeslotId, setTimeslotId] = useState<number>()
  const [hasPrintedRecipeCards, setHasPrintedRecipeCards] = useState<boolean>()
  const [updatePlan, { loading: updatePlanLoading }] = useUpdatePlanMutation()
  const options = data?.customer?.userPlan?.deliveryOptions || []
  const timeslots = options.find(opt => opt.weekday === weekday)?.slots || []
  const weekdays = options.map(opt => opt.weekday).filter((wday): wday is number => !!wday)
  const plans = (data?.customer?.userPlan?.plans || []).filter(
    (plan): plan is { planIdentifier: string; displayTitle: string } => !!plan?.planIdentifier && !!plan?.displayTitle,
  )
  const initialValues = {
    plan: data?.customer?.userPlan?.plan?.planIdentifier,
    weekday: data?.customer?.userPlan?.weekday,
    timeSlot: data?.customer?.userPlan?.timeSlot?.id,
    hasPrintedRecipeCards: data?.customer?.userPlan?.hasPrintedRecipeCards,
  }

  useEffect(() => {
    if (timeslotId && !timeslots.find(slot => slot.id === timeslotId)) {
      setTimeslotId(undefined)
      form.setFieldsValue({ timeSlot: undefined })
    }
  }, [weekday, timeslots, timeslotId, form])

  useEffect(() => {
    if (initialValues.weekday) {
      setWeekday(initialValues.weekday)
      setTimeslotId(initialValues.timeSlot)
    }
  }, [initialValues.weekday, initialValues.timeSlot])

  useEffect(() => {
    if (initialValues.hasPrintedRecipeCards !== undefined) {
      setHasPrintedRecipeCards(initialValues.hasPrintedRecipeCards)
    }
  }, [initialValues.hasPrintedRecipeCards])

  const handleSubmit = (values: Store): void => {
    const mutation = updatePlan({
      variables: {
        id: customerId,
        userPlan: {
          planIdentifier: values.plan,
          weekday: Number(values.weekday),
          timeSlotId: values.timeSlot,
          hasPrintedRecipeCards,
        },
      },
      refetchQueries: queryNames(GetAllPlansDocument),
    })

    void handleMutationResult(mutation, 'updateCustomerPlan', {
      notifications: {
        success: {
          title: 'Plan Updated!',
          description: `You're good to go. Keep going`,
        },
      },
    })
  }

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />

  return (
    <Card {...cardProps} title="Plan Settings">
      <Form
        layout="vertical"
        data-testid="customer-plan-form"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialValues}
      >
        <Row {...rowProps} style={{ marginBottom: 0 }}>
          <Col span={12}>
            <Form.Item name="plan" label="Plan" rules={[{ required: true, message: 'Plan type is required' }]}>
              <Select
                data-testid="customer-plan-form[planIdentifier]"
                style={{ width: '100%' }}
                showSearch
                placeholder="Select a plan type"
              >
                {plans.map(({ planIdentifier, displayTitle }) => (
                  <Option key={planIdentifier} value={planIdentifier}>
                    {displayTitle}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row {...rowProps} style={{ marginBottom: 0 }}>
          <Col span={12}>
            <Form.Item
              name="weekday"
              label="Delivery Day"
              rules={[{ required: true, message: 'Delivery day is required' }]}
            >
              <Select
                data-testid="customer-plan-form[weekday]"
                style={{ width: '100%' }}
                showSearch
                onSelect={(value: number): void => setWeekday(value)}
                placeholder="Select a day for delivery"
              >
                {weekdays.map(wday => (
                  <Option key={wday} value={wday}>
                    {weekdayNames[wday - 1]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="timeSlot"
              label="Time Slot"
              rules={[{ required: true, message: 'Delivery time is required' }]}
            >
              <Select
                data-testid="customer-plan-form[timeslot]"
                style={{ width: '100%' }}
                showSearch
                onSelect={(value: number): void => setTimeslotId(value)}
                placeholder="Select a time for delivery"
              >
                {timeslots.map(slot => (
                  <Option key={slot.id} value={slot.id}>
                    {`${slot.from} — ${slot.to}`} ({slot.shipper?.name}
                    {showExtraShippingPrice(slot?.additionalShippingPrice, slot?.priceCurrency)})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row {...rowProps} style={{ marginBottom: 0 }}>
          <Col span={12}>
            <Form.Item name="hasPrintedRecipeCards">
              <Checkbox
                name="hasPrintedRecipeCards"
                value="hasPrintedRecipeCards"
                data-testid="printed-recipe-cards-checkbox"
                checked={hasPrintedRecipeCards}
                onClick={(): void => {
                  setHasPrintedRecipeCards(!hasPrintedRecipeCards)
                }}
                disabled={!canPrintRecipeCards}
              >
                {canPrintRecipeCards ? (
                  <>Receive recipe printed cards?</>
                ) : (
                  <Tooltip title={`Printed recipe cards are not offered to ${brand} (${country}) customers.`}>
                    Receive recipe printed cards?
                  </Tooltip>
                )}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col span={24}>
            <Alert
              message="Updating the plan type may update all future orders (if possible)."
              type="warning"
              showIcon
            />
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="customer-plan-form[submit]"
                icon={<CheckCircleOutlined />}
                size="large"
                disabled={loading || updatePlanLoading}
                loading={loading || updatePlanLoading}
              >
                Update Plan
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

type CustomerPlanFormProps = {
  customer: {
    id: number
    brand: BrandEnum
    country: CountryEnum
  }
}

export default CustomerPlanForm
