import React from 'react'
import { Space, Table, Tag } from 'antd'
import { PresetColorType } from 'antd/lib/_util/colors'
import { useQuery } from 'react-query'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { OrderEvent, useGetOrderHistoryQuery } from '../../../apollo/generated/api'
import { HistorySource } from '../../../constants/AppConstants'
import { useGetOrderComplaintsQuery } from '../../../apollo/generated/beef'
import { incidentAffectedOrderRequest } from '../../../services/IncidentService'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'

const { Column } = Table

/**
 * ManagerOrderHistory component that retrieves information from the OrderEvents table in the backend
 * OrderEvents are created when changes are performed at the order level (i.e state changes, recipe updates, deliveries)
 * Please refer to MS::EventType model in the backend to get more information on all the possible event types.
 */
const ManageOrderHistory: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const queryVars = { variables: { number: orderNumber } }
  const { loading, error, data } = useGetOrderHistoryQuery({ ...queryVars, fetchPolicy: 'no-cache' })
  const {
    loading: complaintLoading,
    error: complaintsError,
    data: complaintData,
  } = useGetOrderComplaintsQuery({
    variables: { orderNumber },
    fetchPolicy: 'no-cache',
  })
  const { error: incidentError, data: incidentData } = useQuery('incident-affected-orders', () =>
    incidentAffectedOrderRequest(orderNumber),
  )
  const history = data?.order?.history
  const complaintsHistory: OrderEvent[] = []
  const incidentHistory: OrderEvent[] = []

  complaintData?.orderComplaints.edges?.forEach(c => {
    if (c?.node?.createdAt) {
      complaintsHistory.push({
        details: `Logged at: ${c.node.createdAt}`,
        name: 'Complaint logged',
        source: 'admin',
        createdAt: c.node.createdAt,
      })
    }
    if (c?.node?.discardedAt) {
      complaintsHistory.push({
        details: `Deleted at: ${c.node.discardedAt}`,
        name: 'Complaint deleted',
        source: 'admin',
        createdAt: c.node.discardedAt,
      })
    }
  })

  incidentData?.forEach(incident => {
    incidentHistory.push({
      name: 'Incident logged',
      source: 'admin',
      createdAt: incident.loggedAt,
      details: (
        <Space direction="vertical">
          <div>Incident Type: {incident.incidentType}</div>
          <div>Issue Description: {incident.issueDescription}</div>
          <div>Root Cause: {incident.rootCauseCategory}</div>
          <div>Mitigative Action: {incident.mitigativeAction}</div>
          <div>
            <a href={incident.link} target="_blank" rel="noreferrer">
              {incident.link}
            </a>
          </div>
        </Space>
      ) as unknown as string,
    })
  })

  if (loading || complaintLoading) return <Loading />
  if (error || complaintsError || incidentError) return <LoadComponentError />

  const timezone = data?.order?.customer?.timezone
  const dataSource = history
    ?.concat(complaintsHistory)
    ?.concat(incidentHistory)
    .sort((event1, event2) => Date.parse(event2.createdAt) - Date.parse(event1.createdAt))

  return (
    <Table
      data-testid="order-management-history-table"
      dataSource={dataSource}
      rowKey={({ createdAt, source }): string => `${createdAt}-${source}`}
      bordered
      pagination={false}
      style={{ whiteSpace: 'pre-wrap' }}
    >
      <Column
        title="Date and Time"
        dataIndex="createdAt"
        render={createdAt => <UserAwareDateWithTz datetime={createdAt} customerTimezone={timezone} />}
      />
      <Column title="Event" dataIndex="name" />
      <Column
        title="Additional Information"
        dataIndex="details"
        onCell={() => ({ style: { wordBreak: 'break-all' } })}
      />
      <Column
        title="Source"
        dataIndex="source"
        render={(value: string): JSX.Element => (
          <Tag
            style={{ fontSize: 12 }}
            color={HistorySource[value.toUpperCase() as keyof typeof HistorySource] as PresetColorType}
          >
            {value.toUpperCase()}
          </Tag>
        )}
      />
    </Table>
  )
}

export default ManageOrderHistory
