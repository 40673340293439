/* eslint-disable react/no-danger */
import React from 'react'
import { Card, PageHeader, Typography } from 'antd'
import { useHistory, useParams } from 'react-router'

import { UserMarketingMessageType, useGetCustomerMessagesQuery } from '../../../apollo/generated/api'
import LoadComponentError from '../../../packages/LoadComponentError'
import Loading from '../../../packages/Loading'
import NotFoundError from '../../../packages/UnauthorizedComponentError'

const ManageCustomerMessage: React.FC<{ customer: { id: number } }> = ({ customer: { id } }) => {
  const { messageId: messageIdRaw } = useParams<{ messageId: string; type: UserMarketingMessageType }>()
  const history = useHistory()
  const [_messageId, eventType] = messageIdRaw.split('_') as [string, UserMarketingMessageType]
  const hasHtml = [
    UserMarketingMessageType.EmailSend,
    UserMarketingMessageType.EmailClick,
    UserMarketingMessageType.EmailOpen,
    UserMarketingMessageType.EmailBounce,
  ].includes(eventType)
  const { error, loading, data } = useGetCustomerMessagesQuery({
    variables: {
      id,
      types: [eventType],
    },
  })

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />

  const messages = data?.customer?.marketingMessages || []
  const message = messages.find(({ id: msgId }) => msgId === messageIdRaw)

  if (!message) return <NotFoundError />

  let content = ''

  // html content is returned as a stringified json
  if (hasHtml) {
    content = JSON.parse(message.content)
  } else {
    content = message.content
  }

  return (
    <div data-testid="customer-message-details">
      <PageHeader onBack={() => history.goBack()} title={message.name} ghost={false}>
        <Typography.Title level={5}>{message.subject}</Typography.Title>
        <Typography.Text type="secondary">
          *Note* the contents for a data feed send are not stored. Thus, the subject and body shown may look different
          from the actual email.
        </Typography.Text>
      </PageHeader>
      <Card
        style={{
          background: 'none',
          marginTop: -31,
        }}
      >
        <div style={{ height: 700 }}>
          <iframe srcDoc={content} title="HTML content" style={{ border: 0, width: '100%', height: '100%' }} />
        </div>
      </Card>
    </div>
  )
}

export default ManageCustomerMessage
