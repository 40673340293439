import React from 'react'
import { Card, Descriptions, Space, Tag } from 'antd'

import { ManageCustomerProps } from '../../../components/ManageCustomer/props'
import * as customProps from '../../../constants/OverridesConstants'
import { formatCurrency } from '../../../utils'
import BrandIcon from '../../../packages/BrandIcon'
import CountryFlag from '../../../packages/CountryFlag'
import ValidPlan from '../../../components/Validation/ValidPlan'
import MembershipLabel from '../../../components/MembershipLabel'
import { CustomerFlagEnum } from '../../../apollo/generated/api'
import { getVoucherUsageViewLink } from '../../../utils/promotionUtils/promotionUtils'
import UserAwareDateWithTz from '../../../components/Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import { getCreditRevenueTierData } from '../../../utils/utils'

const ManageCustomerSidebar: React.FC<ManageCustomerProps> = ({ customer }) => {
  const {
    accountCredit,
    deliveryStats: { totalRevenue, numberOfOrders, currency, averageNetPricePerBox },
    membership: { startedAt, status, pausedUntil },
    flags,
    timezone,
  } = customer
  const creditRevenueTierData = getCreditRevenueTierData(customer.creditRevenueTier)

  return (
    <Card
      {...customProps.CARD}
      title="Customer Overview"
      data-testid="manage-customer-sidebar-details"
      extra={<Tag color="success">{formatCurrency(accountCredit, currency)}</Tag>}
    >
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Brand">
          <BrandIcon brand={customer.brand} />
        </Descriptions.Item>

        <Descriptions.Item label="Country">
          <CountryFlag country={customer.country} />
        </Descriptions.Item>

        <Descriptions.Item label="Email" style={{ overflowWrap: 'anywhere' }}>
          {customer.email}
        </Descriptions.Item>

        <Descriptions.Item label="Vouchers" style={{ overflowWrap: 'anywhere' }}>
          <a
            href={getVoucherUsageViewLink(customer.brand, customer.id)}
            style={{ hyphens: 'auto' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            View voucher history
          </a>
        </Descriptions.Item>

        <Descriptions.Item label="Member Status">
          <Space direction="vertical">
            <MembershipLabel status={status} pausedUntil={pausedUntil} customerTimezone={customer.timezone} />
            <Space>
              {flags.includes(CustomerFlagEnum.Trusted) && <Tag color="blue">TRUSTED</Tag>}
              {flags.includes(CustomerFlagEnum.Vip) && <Tag color="red">VIP</Tag>}
              {flags.includes(CustomerFlagEnum.Locked) && <Tag color="yellow">LOCKED</Tag>}
            </Space>
          </Space>
        </Descriptions.Item>

        {creditRevenueTierData.visible && (
          <Descriptions.Item label="Credit Revenue Tier">
            <span style={{ padding: '8px', color: 'rgb(0,0,0)', background: creditRevenueTierData.color }}>
              {creditRevenueTierData.text}
            </span>
          </Descriptions.Item>
        )}

        <Descriptions.Item label="Member Since">
          {startedAt ? <UserAwareDateWithTz customerTimezone={timezone} datetime={startedAt} /> : '—'}
        </Descriptions.Item>

        <Descriptions.Item label="Plan">
          <ValidPlan userPlan={customer.userPlan} />
        </Descriptions.Item>

        <Descriptions.Item label="Total Sales">{formatCurrency(totalRevenue, currency)}</Descriptions.Item>

        <Descriptions.Item label="# Orders">{numberOfOrders}</Descriptions.Item>

        <Descriptions.Item label="Avg. Order Value">
          {formatCurrency(averageNetPricePerBox, currency)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default ManageCustomerSidebar
