import { Form, Select } from 'antd'
import React from 'react'

import { BrandEnum } from '../../apollo/generated/api'
import { MappedBrands } from '../../constants/AppConstants'

const { Option } = Select

const BrandDropdown: React.FC = () => {
  return (
    <Form.Item name="brand" label="Brand" rules={[{ required: true, message: 'Please select a brand!' }]}>
      <Select
        data-testid="brand-dropdown"
        style={{ width: '100%' }}
        placeholder="Brand"
        optionFilterProp="children"
        showSearch
      >
        {Object.values(BrandEnum).map(brand => (
          <Option value={brand} key={brand}>
            {MappedBrands[brand]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default BrandDropdown
