import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Popconfirm } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { CARD as cardProps } from '../../../constants/OverridesConstants'
import { CREATE_CREDIT_SUCCESS, CREATE_CREDIT_ERROR } from '../../../constants/MessagesConstants'
import { handleMutationResult } from '../../../apollo'
import {
  CurrencyEnum,
  GetCustomerDocument,
  useGetCustomerOrdersForCreditQuery,
  useUpdateCustomerCreditMutation,
} from '../../../apollo/generated/api'
import { queryNames } from '../../../apollo/client'
import { currencySymbol } from '../../../utils'
import { OrderCreditWarning } from '../../Shared/OrderCreditWarning/OrderCreditWarning'

import AmountInput from './AmountInput'
import SelectOrderInput from './SelectOrderInput'
import SelectCreditReasonInput from './SelectCreditReasonInput'

const FORM_LAYOUT = 'vertical'

const ManageCustomerCredit: React.FC<{ customerId: number }> = ({ customerId }) => {
  const [form] = Form.useForm<FormValues>()
  const [amount, setAmount] = useState(0)
  const [updateCustomerCredit, { loading: updating }] = useUpdateCustomerCreditMutation()
  const { loading, data, error } = useGetCustomerOrdersForCreditQuery({ variables: { id: customerId } })
  const currency = data?.customer?.deliveryStats?.currency || undefined
  const accountCredit = data?.customer?.accountCredit || undefined
  const orders = data?.customer?.orders || []
  const initialOrderNumber = orders.length ? orders[0].number : undefined
  const creditReasons = data?.customer?.creditReasons || []
  const [selectedOrderNum, setSelectedOrderNum] = useState<string | undefined>(initialOrderNumber)

  // This approach comes from Antd's documentation:
  // https://ant.design/components/form/#API
  //
  // "You cannot set value for each form control via value or defaultValue prop, you should set default value with
  // initialValues of Form. Note that initialValues cannot be updated by setState dynamically, you should use
  // setFieldsValue in that situation."
  useEffect(() => {
    if (initialOrderNumber) {
      form.setFieldsValue({ orderNumber: initialOrderNumber })
      setSelectedOrderNum(initialOrderNumber)
    }
  }, [initialOrderNumber, form])

  const onValuesChange = (changedValues: { amount?: string; orderNumber?: string }): void => {
    const parsedAmount = changedValues.amount ? parseFloat(changedValues.amount) : undefined
    const orderNum = changedValues.orderNumber

    if (parsedAmount && !Number.isNaN(parsedAmount)) {
      setAmount(parsedAmount)
    }
    if (orderNum !== selectedOrderNum) {
      setSelectedOrderNum(orderNum)
    }
  }
  let buttonProps = {}
  let text = ''

  if (amount === 0) {
    text = 'Pick a value'
  } else if (amount > 0) {
    buttonProps = { type: 'primary' as const, icon: <PlusCircleOutlined /> }
    text = 'Add credit'
  } else {
    buttonProps = { danger: true, icon: <MinusCircleOutlined /> }
    text = 'Remove credit'
  }

  const onFinish = async (values: FormValues): Promise<void> => {
    const variables = { ...values, amount, customerId }
    const mutation = updateCustomerCredit({ variables, refetchQueries: queryNames(GetCustomerDocument) })
    const { data } = await handleMutationResult(mutation, 'updateCustomerCredit', {
      notifications: {
        success: {
          title: CREATE_CREDIT_SUCCESS,
        },
        error: {
          title: CREATE_CREDIT_ERROR,
        },
      },
    })

    if (data?.updateCustomerCredit?.__typename === 'UserCredit') {
      form.resetFields()
    }
  }

  return (
    <Card {...cardProps} title="Manage Account Credit" style={{ height: '100%' }}>
      <Form
        onValuesChange={onValuesChange}
        form={form}
        name="customerAccountCredit"
        data-testid="customer-account-credit-form"
        layout={FORM_LAYOUT}
        onFinish={onFinish}
      >
        <OrderCreditWarning creditAmount={amount} orderNumber={selectedOrderNum} />
        <AmountInput loading={loading} currency={currency} accountCredit={accountCredit} />
        <SelectOrderInput loading={loading} error={error} orders={orders} />
        <SelectCreditReasonInput loading={loading} error={error} creditReasons={creditReasons} />
        <Form.Item>
          <Popconfirm
            title={`You are about to ${text} (${amount}${currencySymbol(currency || CurrencyEnum.Eur)})`}
            placement="bottom"
            okButtonProps={{ htmlType: 'submit', autoFocus: true }}
            cancelText="Cancel"
            data-testid="popover-credit-submit-button"
            onConfirm={() => {
              form.submit()
            }}
            okText="Ok"
          >
            <Button {...buttonProps} data-testid="credit-submit-button" size="large" block disabled={updating}>
              {text}
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Card>
  )
}

type FormValues = {
  amount: number
  orderNumber: string
  reasonId: number
}

export default ManageCustomerCredit
