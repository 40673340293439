// eslint-disable-next-line max-classes-per-file
import { useEffect, useMemo, useState } from 'react'

import { PageData } from '../../../services/SteakService'
import { AbortableRequest } from '../../../services/GraphqlService'

import { ValueOf } from './types'

type dsChangedFunc<RecordType, FilterType> = (
  eventType: string,
  ds: PaginatedDataSource<RecordType, FilterType>,
) => void

const EVENT_DATA_CHANGED = 'data_changed'
const EVENT_LOADING_CHANGED = 'loading_changed'
const EVENT_DIRTY_RECORDS_CHANGED = 'dirty_records_changed'

type RecordChangelog = {
  id: unknown
  fieldName: string
  oldValue: unknown
  newValue: unknown
}

class ChangelogReg {
  private logMap = new Map<unknown, Map<unknown, RecordChangelog>>()

  add(id: unknown, fieldName: string, oldValue: unknown, newValue: unknown) {
    if (!this.logMap.has(id)) {
      this.logMap.set(id, new Map<string, RecordChangelog>())
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const m = this.logMap.get(id)!

    if (!m.has(fieldName)) {
      m.set(fieldName, {
        id,
        fieldName,
        oldValue,
        newValue,
      })
    }

    if (m.get(fieldName)?.oldValue === newValue) {
      m.delete(fieldName)
    } else {
      const f = m.get(fieldName)

      if (f) {
        f.newValue = newValue
      }
    }
  }

  clear(): boolean {
    if (!this.isEmpty) {
      this.logMap.clear()

      return true
    }

    return false
  }

  get isEmpty() {
    return this.logMap.size === 0
  }

  get logs(): Map<unknown, RecordChangelog[]> {
    const logs = new Map<unknown, RecordChangelog[]>()

    // eslint-disable-next-line no-restricted-syntax
    for (const [recId, fieldMap] of this.logMap.entries()) {
      logs.set(recId, Array.from(fieldMap.values()))
    }

    return logs
  }
}

export abstract class PaginatedDataSource<RecordType, FilterType> {
  filters: Partial<FilterType>

  currentRequest: AbortableRequest<unknown> | null = null

  loadedItems: RecordType[] = []

  private nextPageCursor: string | undefined = undefined

  private hasMoreToLoad = true

  private listeners: dsChangedFunc<RecordType, FilterType>[] = []

  private changeLog = new ChangelogReg()

  addListener(listener: dsChangedFunc<RecordType, FilterType>): () => void {
    this.listeners.push(listener)

    return () => {
      const idx = this.listeners.indexOf(listener)

      if (idx >= 0) {
        this.listeners.splice(idx, 1)
      }
    }
  }

  setFilters(newFilters: Partial<FilterType>) {
    this.abortPendingRequest()
    this.filters = newFilters
    this.nextPageCursor = undefined
    this.hasMoreToLoad = true
    this.clearLoadedData()
    this.triggerLoad()
  }

  setItemValue(record: RecordType, fieldName: keyof RecordType, newValue: ValueOf<RecordType>): boolean {
    if (record[fieldName] !== newValue) {
      const oldValue = record[fieldName]

      // eslint-disable-next-line no-param-reassign
      record[fieldName] = newValue
      this.changeLog.add(this.getItemId(record), fieldName as string, oldValue, newValue)
      this.notifyUpdated(EVENT_DIRTY_RECORDS_CHANGED)
    }

    return true
  }

  get itemCount() {
    return this.loadedItems.length
  }

  triggerLoad() {
    if (!this.hasMoreToLoad) {
      return
    }
    if (this.isLoading) {
      return
    }
    this.loadNext()
  }

  private loadNext() {
    if (!this.filtersValid(this.filters)) {
      return
    }
    this.notifyUpdated(EVENT_LOADING_CHANGED)
    this.currentRequest = this.fetchData(this.filters as FilterType, this.nextPageCursor).then(value => {
      this.abortPendingRequest()
      this.loadedItems = [...this.loadedItems, ...value.data]
      this.currentRequest = null
      this.nextPageCursor = value.nextPageCursor || undefined
      this.hasMoreToLoad = !!this.nextPageCursor
      this.notifyUpdated(EVENT_DATA_CHANGED)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  filtersValid(_filters: Partial<FilterType>): boolean {
    return true
  }

  abstract fetchData(filters: FilterType, cursor?: string): AbortableRequest<PageData<RecordType>>

  abstract getItemId(item: RecordType): unknown

  private notifyUpdated(evType: string) {
    // eslint-disable-next-line no-restricted-syntax
    for (const listener of this.listeners) {
      listener(evType, this)
    }
  }

  get isLoading() {
    return !!this.currentRequest
  }

  get hasDirtyRecords() {
    return !this.changeLog.isEmpty
  }

  clearDirtyRecords() {
    if (this.changeLog.clear()) {
      this.notifyUpdated(EVENT_DIRTY_RECORDS_CHANGED)
    }
  }

  revertDirtyRecords() {
    let somethingChanged = false

    // eslint-disable-next-line no-restricted-syntax
    for (const [recId, recLogs] of this.changeLog.logs) {
      const record = this.loadedItems.find(value => this.getItemId(value) === recId)

      if (!record) {
        // eslint-disable-next-line no-continue
        continue
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const log of recLogs) {
        record[log.fieldName as keyof RecordType] = log.oldValue as never
        somethingChanged = true
      }
    }
    this.changeLog.clear()
    if (somethingChanged) {
      this.notifyUpdated(EVENT_DIRTY_RECORDS_CHANGED)
      this.notifyUpdated(EVENT_DATA_CHANGED)
    }
  }

  getChangelogs() {
    return this.changeLog.logs
  }

  private abortPendingRequest() {
    if (this.isLoading) {
      this.currentRequest?.abort?.()
      this.notifyUpdated(EVENT_LOADING_CHANGED)
    }
    this.currentRequest = null
  }

  private clearLoadedData() {
    if (this.loadedItems.length) {
      this.loadedItems.length = 0
      this.clearDirtyRecords()
      this.notifyUpdated(EVENT_DATA_CHANGED)
    }
  }
}

export function useDataSource<RecordType, FilterType>(
  factoryFunc: () => PaginatedDataSource<RecordType, FilterType>,
): {
  dataSource: PaginatedDataSource<RecordType, FilterType>
  data: RecordType[]
  isLoading: boolean
  hasDirtyRecords: boolean
} {
  const ds = useMemo(() => {
    return factoryFunc()
  }, [])
  const [data, setData] = useState<RecordType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasDirtyRecords, setHasDirtyRecords] = useState<boolean>(false)

  useEffect(() => {
    const removeListener = ds.addListener(eventType => {
      // eslint-disable-next-line default-case
      switch (eventType) {
        case EVENT_DATA_CHANGED:
          setData([...ds.loadedItems])
          break
        case EVENT_LOADING_CHANGED:
          setIsLoading(ds.isLoading)
          break
        case EVENT_DIRTY_RECORDS_CHANGED:
          setHasDirtyRecords(ds.hasDirtyRecords)
          break
      }
    })

    ds.triggerLoad()

    return () => {
      removeListener()
    }
  }, [])

  return {
    dataSource: ds,
    data,
    isLoading,
    hasDirtyRecords,
  }
}
