import React from 'react'

import Loading from '../../../packages/Loading'
import LoadComponentError from '../../../packages/LoadComponentError'
import { useGetOrderComplaintsQuery } from '../../../apollo/generated/beef'
import { CurrencyEnum, useGetOrderCurrencyQuery } from '../../../apollo/generated/api'
import ManageComplaintTable from '../../ManageComplaint/ManageComplaintTable'
import { useRelayPagination } from '../../../hooks'
import { ComplaintType } from '../../ManageComplaint/ManageComplaintTable/ManageComplaintTable'
import SimplePagination from '../../SimplePagination'

export const PAGE_SIZE = 10

const ManageOrderComplaints: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const orderDetails = useGetOrderCurrencyQuery({ variables: { number: orderNumber } })
  const { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage, currentPage } = useRelayPagination()
  const currency = orderDetails?.data?.order?.pricing?.currency as CurrencyEnum
  const { loading, error, data } = useGetOrderComplaintsQuery({
    variables: { orderNumber, first: PAGE_SIZE, after: currentCursor },
    fetchPolicy: 'no-cache',
  })
  const { edges: complaints, pageInfo, totalCount } = data?.orderComplaints || { edges: [] }

  const onNextPage = () => {
    if (pageInfo?.hasNextPage) {
      handleNextPage(pageInfo.endCursor)
    }
  }

  if (loading) return <Loading />
  if (error) return <LoadComponentError />

  const totalPages = Math.ceil((totalCount || 0) / PAGE_SIZE)

  return (
    <>
      <ManageComplaintTable
        complaints={complaints?.map(c => c?.node as ComplaintType)}
        orderNumber={orderNumber}
        currency={currency}
      />
      <SimplePagination
        currentCount={currentPage + 1}
        totalCount={totalPages}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </>
  )
}

export default ManageOrderComplaints
