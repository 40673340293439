import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { handleMutationResult } from '../../../../../apollo'
import {
  QUALITY_CONTROL_ORDER_CONFIRM_TITLE,
  QUALITY_CONTROL_ORDER_CONFIRM_CONTENT,
  QUALITY_CONTROL_ORDER_SUCCESS,
  QUALITY_CONTROL_ORDER_FAILURE,
} from '../../../../../constants/MessagesConstants'
import {
  QualityControlOrderDocument,
  QualityControlOrderMutation,
  QualityControlOrderMutationVariables,
} from '../../../../../apollo/generated/api'

type MutationClient = Pick<ApolloClient<NormalizedCacheObject>, 'mutate'>

const onQualityControlConfirmation = (number: string, client: MutationClient): void => {
  void handleMutationResult(
    client.mutate<QualityControlOrderMutation, QualityControlOrderMutationVariables>({
      mutation: QualityControlOrderDocument,
      variables: { number },
    }),
    'orderQualityControl',
    {
      notifications: {
        success: {
          title: QUALITY_CONTROL_ORDER_CONFIRM_TITLE,
          description: QUALITY_CONTROL_ORDER_SUCCESS,
        },
        error: {
          title: QUALITY_CONTROL_ORDER_FAILURE,
        },
      },
    },
  )
}

const qualityControlOrder = ({ number }: { number: string }, { client }: { client: MutationClient }): void => {
  const modal = Modal.confirm({
    title: QUALITY_CONTROL_ORDER_CONFIRM_TITLE,
    content: QUALITY_CONTROL_ORDER_CONFIRM_CONTENT,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    okText: 'Ok',
    onOk: () => {
      modal.update({ okButtonProps: { loading: true } })

      return onQualityControlConfirmation(number, client)
    },
  })
}

export default qualityControlOrder
