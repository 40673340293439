import React, { FunctionComponent } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { PageHeader } from 'antd'

import { Routes } from '../../constants/RoutesConstants'
import { headerIcons } from '../../constants/IconsConstants'
import ManageShipmentTracking from '../../components/ManageShipmentTracking'

export type TrackingParams = {
  number: string
  shipmentNumber: string
}

const ManageShipmentTrackingContainer: React.FC = () => {
  const { number, shipmentNumber } = useParams<TrackingParams>()

  const withHeaderProps: Function = (WrappedComponent: FunctionComponent<TrackingParams>) =>
    // eslint-disable-next-line react/display-name, func-names
    function (): React.ReactElement {
      return <WrappedComponent number={number} shipmentNumber={shipmentNumber} />
    }

  return (
    <>
      <PageHeader title="RADAR Tracking" avatar={{ src: headerIcons.SHIPMENT_TRACKING, shape: 'square' }} />
      <Switch>
        <Route exact path={Routes.SHIPMENT_TRACKING} render={withHeaderProps(ManageShipmentTracking)} />
      </Switch>
    </>
  )
}

export default ManageShipmentTrackingContainer
