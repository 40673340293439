import React from 'react'
import { Button, notification } from 'antd'
import { generatePath, Redirect, useHistory } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

import logger from '../../../../logging'
import * as messages from '../../../../constants/MessagesConstants'
import { Routes } from '../../../../constants/RoutesConstants'
import CustomerAddressForm, { CustomerAddressFormValues } from '../../../Forms/CustomerAddressForm'
import { buildCustomerAddressInput } from '../../../../utils/addressUtils'
import { handleMutationResult } from '../../../../apollo'
import { buildDynamicFormFields } from '../../../../utils/addressUtils/addressUtils'
import {
  CountryEnum,
  BrandEnum,
  GetCustomerAddressesDocument,
  useCreateCustomerAddressMutation,
} from '../../../../apollo/generated/api'
import { queryNames } from '../../../../apollo/client'
import { validateAddress } from '../../../../services/LoqateService'

export type CreacteCustomerAddressProps = {
  customer: {
    id: number
    country: CountryEnum
    brand: BrandEnum
    addressMeta: {
      isStateRequired: boolean
      isTypeRequired: boolean
    }
  }
}
const CreateCustomerAddress: React.FC<CreacteCustomerAddressProps> = ({ customer }) => {
  const { id: customerId, brand, country } = customer
  const [createAddress, { data, loading }] = useCreateCustomerAddressMutation()
  const { goBack } = useHistory()

  const onFinish = async (formValues: CustomerAddressFormValues): Promise<void> => {
    const variables = { customerAddress: { customerId, ...buildCustomerAddressInput(formValues) } }

    await validateAddress(variables.customerAddress.address, country).then(async result => {
      if (result && result.valid()) {
        logger().warning('Address created with failed loqate validation')
        notification.warning({
          message: messages.ERROR_TITLE,
          description: messages.INVALID_ADDRESS_WARNING,
        })
      }

      const mutation = createAddress({ variables, refetchQueries: queryNames(GetCustomerAddressesDocument) })

      await handleMutationResult(mutation, 'createCustomerAddress', {
        notifications: {
          success: {
            title: messages.CUSTOMER_ADDRESS_CREATED_SUCCESS,
          },
          error: {
            title: messages.CUSTOMER_ADDRESS_CREATED_FAILURE,
          },
        },
      })
    })
  }

  if (data?.createCustomerAddress?.__typename === 'CustomerAddress') {
    return <Redirect to={generatePath(Routes.CUSTOMER_ADDRESSES, { id: customerId })} />
  }

  return (
    <>
      <Button onClick={goBack} icon={<LeftOutlined />} style={{ marginBottom: 20 }}>
        Back
      </Button>

      <CustomerAddressForm
        country={country}
        brand={brand}
        loading={loading}
        onFinish={onFinish}
        dynamicFields={buildDynamicFormFields(customer.addressMeta)}
      />
    </>
  )
}

export default CreateCustomerAddress
