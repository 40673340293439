import ManageCustomerAccount from './ManageCustomerAccount'
import ManageCustomerOrders from './ManageCustomerOrders'
import ManageCustomerPaymentSources from './ManageCustomerPaymentSources'
import ManageCustomerAccountHistory from './ManageCustomerAccountHistory'
import ManageCustomerPlan from './ManageCustomerPlan'
import ManageCustomerCreditHistory from './ManageCustomerCreditHistory'
import ManageCustomerAddresses from './ManageCustomerAddresses'
import CreateOrder from './CreateOrder'
import ManageCustomerMessages from './ManageCustomerMessages'
import ManageCustomerPaymentSource from './ManageCustomerPaymentSource'
import ManageCustomerRewards from './ManageCustomerRewards'
import ManageCustomerReferrals from './ManageCustomerReferrals'
import ManageCustomerComplaints from './ManageCustomerComplaints'
import ManageCustomerMessage from './ManageCustomerMessages/ManageCustomerMessage'
import ManageCustomerIncidents from './ManageCustomerIncidents'
import ManageCustomerRecipePreferences from './ManageCustomerRecipePreferences'

const ManageCustomer = {
  Account: ManageCustomerAccount,
  Orders: ManageCustomerOrders,
  PaymentSources: ManageCustomerPaymentSources,
  PaymentSource: ManageCustomerPaymentSource,
  AccountHistory: ManageCustomerAccountHistory,
  CreditHistory: ManageCustomerCreditHistory,
  Plan: ManageCustomerPlan,
  RecipePreferences: ManageCustomerRecipePreferences,
  Rewards: ManageCustomerRewards,
  Referrals: ManageCustomerReferrals,
  Complaints: ManageCustomerComplaints,
  Incidents: ManageCustomerIncidents,
  Messages: ManageCustomerMessages,
  Message: ManageCustomerMessage,
  CreateOrder,
  ...ManageCustomerAddresses,
}

export default ManageCustomer
