import React from 'react'
import { Row, Col, PageHeader, Button } from 'antd'
import { Link } from 'react-router-dom'

import SearchComplaintForm from '../Forms/SearchComplaintForm'
import { headerIcons } from '../../constants/IconsConstants'
import { Routes } from '../../constants/RoutesConstants'

const COL_SIZE = 8
const SearchComplaint: React.FC = () => (
  <>
    <PageHeader
      title="Complaints"
      avatar={{
        src: headerIcons.COMPLAIN_ICON,
        shape: 'square',
      }}
      extra={[
        <Link key="mass-upload-complaints" to={Routes.COMPLAINTS_MASS_UPLOAD}>
          <Button>Mass upload complaints</Button>
        </Link>,
      ]}
    />

    <Row justify="center" gutter={32}>
      <Col span={COL_SIZE}>
        <SearchComplaintForm />
      </Col>
    </Row>
  </>
)

export default SearchComplaint
