import React, { useState, useEffect } from 'react'
import { Form, FormInstance, Tooltip, Tag, Row, Col } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'

import { Attachment } from '../../../../apollo/generated/beef'

const ExistingAttachments: React.FC<{ form: FormInstance }> = ({ form }) => {
  const [existingAttachments, setExistingAttachments] = useState([] as Attachment[])
  const [removedAttachments, setRemovedAttachments] = useState([] as string[])

  const removeAttachment = (id: string): void => {
    const remainingAttachments = existingAttachments.filter(att => att.id !== id)
    const removeList = removedAttachments

    removeList.push(id)
    setExistingAttachments(remainingAttachments)
    setRemovedAttachments(removeList)
    form.setFieldsValue({ existingAttachments: remainingAttachments, removedAttachments: removeList })
  }

  useEffect(() => setExistingAttachments(form.getFieldValue('existingAttachments') || []), [form])

  return (
    <Form.Item name="existingAttachments" label="Previous Attachments">
      <Row gutter={16}>
        {existingAttachments.map(item => (
          <Col key={item.id} className="gutter-row" span={6}>
            <div style={{ padding: '8px' }}>
              <img
                style={{ width: '100%', backgroundColor: 'gray', borderRadius: 8 }}
                src={item.link || ''}
                alt="Not available"
              />
              <Tag
                id={item.id}
                style={{ textAlign: 'center', width: '100%' }}
                role="presentation"
                tabIndex={-1}
                onClick={() => removeAttachment(item.id)}
              >
                <Tooltip title="Delete this attachment">
                  <DeleteTwoTone twoToneColor="red" />
                </Tooltip>
              </Tag>
            </div>
          </Col>
        ))}
      </Row>
    </Form.Item>
  )
}

export default ExistingAttachments
