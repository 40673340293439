import React from 'react'
import { Moment } from 'moment'
import { Divider } from 'antd'

import { SlotSelection } from '../types'

const OrderDeliverySlots: React.FC<{ slots?: SlotSelection[]; showExtraShippingPrice: Function }> = ({
  slots,
  showExtraShippingPrice,
}) => {
  if (!slots || slots.length === 0) return <div />
  const showDivider = (currentId: number, items: SlotSelection[]) => {
    return currentId !== items.slice(-1)[0].id
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {slots.map(({ from, to, id, shipper, additionalShippingPrice, priceCurrency }) => (
        <li key={id}>
          <div>
            {from} - {to}
          </div>
          <small>
            ({shipper}
            {showExtraShippingPrice(additionalShippingPrice, priceCurrency)})
          </small>

          {showDivider(id, slots) && <Divider className="delivery-calendar-divider" />}
        </li>
      ))}
    </ul>
  )
}

const OrderDeliveryDay: React.FC<{ date: Moment; slots?: SlotSelection[]; showExtraShippingPrice: Function }> = ({
  date,
  slots,
  showExtraShippingPrice,
}) => {
  return (
    <div
      data-testid={`delivery-date-${date.format('Y-MM-DD')}`}
      className="ant-picker-cell-inner ant-picker-calendar-date"
      style={{ height: '100%', padding: 5 }}
    >
      <div className="ant-picker-calendar-date-value">{date.format('DD')}</div>
      <div className="ant-picker-calendar-date-content" style={{ fontSize: '0.8em' }}>
        <OrderDeliverySlots slots={slots} showExtraShippingPrice={showExtraShippingPrice} />
      </div>
    </div>
  )
}

export default OrderDeliveryDay
