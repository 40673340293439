import React from 'react'
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { handleMutationResult } from '../../../../../apollo'
import {
  REGENERATE_INVOICE_CONFIRM_TITLE,
  REGENERATE_INVOICE_FAILURE,
  REGENERATE_INVOICE_SUCCESS,
} from '../../../../../constants/MessagesConstants'
import {
  RegenerateInvoiceDocument,
  RegenerateInvoiceMutation,
  RegenerateInvoiceMutationVariables,
  GetOrderInvoiceDocument,
  GetOrderInvoiceQuery,
  GetOrderInvoiceQueryVariables,
} from '../../../../../apollo/generated/api'

type MutationClient = ApolloClient<NormalizedCacheObject>

const onGenerateConfirmation = async (number: string, client: MutationClient) => {
  const result = await handleMutationResult(
    client.mutate<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>({
      mutation: RegenerateInvoiceDocument,
      variables: { number },
    }),
    'regenerateInvoice',
    {
      notifications: {
        success: {
          title: REGENERATE_INVOICE_CONFIRM_TITLE,
          description: REGENERATE_INVOICE_SUCCESS,
        },
        error: {
          title: REGENERATE_INVOICE_FAILURE,
        },
      },
    },
  )

  // error
  if (result.data?.regenerateInvoice?.__typename !== 'Order') {
    return false
  }

  // success
  const modal = Modal.confirm({
    title: 'Invoice generated',
    icon: <InfoCircleOutlined />,
    cancelText: 'Cancel',
    closable: true,
    okText: 'Download invoice',
    maskClosable: true,
    onOk: async () => {
      modal.update({ okButtonProps: { loading: true } })

      const result = await client.query<GetOrderInvoiceQuery, GetOrderInvoiceQueryVariables>({
        query: GetOrderInvoiceDocument,
        variables: { number },
        fetchPolicy: 'no-cache',
      })

      // open in new tab
      if (result.data?.order?.invoice?.link) {
        window.open(result.data.order.invoice.link, '_blank', 'noopener,noreferrer')
      }

      // reload page to update invoice button state
      return window.location.reload()
    },
  })

  return null
}

const regenerateInvoice = ({ number }: { number: string }, { client }: { client: MutationClient }): void => {
  const modal = Modal.confirm({
    title: REGENERATE_INVOICE_CONFIRM_TITLE,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    okText: 'Yes, regenerate invoice',
    maskClosable: true,
    closable: true,
    onOk: () => {
      modal.update({ okButtonProps: { loading: true } })

      return onGenerateConfirmation(number, client)
    },
  })
}

export default regenerateInvoice
