import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { BrandEnum } from '../../apollo/generated/api'
import { brandsIcons } from '../../constants/BrandsConstants'

type BrandIconProps = {
  brand: BrandEnum
  size?: number
}

const StyledBrand = styled.div<BrandIconProps>`
  width: ${(props): string => `${props.size}px`};
  height: ${(props): string => `${props.size}px`};
  background: url(${(props): string => brandsIcons[props.brand]});
  background-size: 100%;
  border-radius: 6px;
`
const BrandIcon: FunctionComponent<BrandIconProps> = ({ brand, size = 38, ...rest }) => (
  <StyledBrand brand={brand} size={size} {...rest} />
)

export default BrandIcon
