import React from 'react'
import { RouteComponentProps } from 'react-router'

import { useGetCustomerPaymentSourceLazyQuery } from '../../../apollo/generated/api'
import LoadComponentError from '../../../packages/LoadComponentError'
import Loading from '../../../packages/Loading'
import PaymentSource from '../../PaymentSources'

const InvalidPaymentSource: React.FC = () => (
  <LoadComponentError errorMessage="Couldn't find the customer payment source" />
)

const ManageCustomerPaymentSource: React.FC<RouteComponentProps<{ id: string; index: string }>> = ({ match }) => {
  const id = parseInt(match.params.id, 10)
  const index = parseInt(match.params.index, 10)
  const [getPaymentSource, { called, loading, data, error }] = useGetCustomerPaymentSourceLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  })
  const source = (data?.customer?.paymentSources || [])[index]

  if (Number.isNaN(id) || Number.isNaN(index)) {
    return <InvalidPaymentSource />
  }

  if (!called) {
    void getPaymentSource()
  }

  if (!called || loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />
  if (!source) return <InvalidPaymentSource />

  return <PaymentSource source={source} />
}

export default ManageCustomerPaymentSource
