import CustomerAddressForm from './CustomerAddressForm'
import {
  CustomerAddressFormValues as FormValues,
  CustomerAddressFormInitialValues as InitialValues,
  AddressDynamicFields as DynamicFields,
} from './types'

export type CustomerAddressFormValues = FormValues
export type CustomerAddressFormInitialValues = InitialValues
export type AddressDynamicFields = DynamicFields

export default CustomerAddressForm
