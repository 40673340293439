import React from 'react'
import { StopOutlined } from '@ant-design/icons'
import { Button, Form, Alert, TreeSelect } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { DataNode } from 'antd/lib/tree'

import { handleMutationResult } from '../../../../apollo'
import { useGetCancellationReasonsQuery, useStopMembershipMutation } from '../../../../apollo/generated/api'

type Reason = { id: number; displayName: string; children?: Readonly<Reason[]> }
const mapReason = (node: Reason): DataNode & { value: number } => ({
  title: node.displayName,
  key: node.id,
  children: node.children?.map(mapReason),
  value: node.id,
  selectable: node.children === undefined,
})

const ManageMembershipStop: React.FC<{ customerId: number }> = ({ customerId }) => {
  const [form] = Form.useForm()
  const { loading, error, data } = useGetCancellationReasonsQuery({
    variables: { id: customerId },
  })
  const [stopMembership, { loading: loadingMutation }] = useStopMembershipMutation()

  const handleSubmit = (values: Store): void => {
    const mutation = stopMembership({
      variables: {
        stopMembership: {
          id: customerId,
          eventReasonId: values?.eventReasonId,
        },
      },
    })

    void handleMutationResult(mutation, 'stopMembership', {
      notifications: {
        success: {
          title: 'Membership successfully stopped',
        },
      },
    })
  }

  if (error) {
    return <Alert type="error" message="Error retrieving cancellation reasons." />
  }

  return (
    <Form data-testid="stop-membership-form" layout="vertical" onFinish={handleSubmit} form={form}>
      <Form.Item
        name="eventReasonId"
        label="Cancellation reason"
        rules={[
          {
            required: true,
            message: 'You must select a reason.',
          },
        ]}
        hasFeedback
      >
        <TreeSelect
          placeholder="Pick a reason"
          size="large"
          style={{ width: '100%' }}
          data-testid="membership-reasons-dropdown"
          loading={loading}
          disabled={loading}
          treeData={(data?.eventReasons || []).map(mapReason)}
          treeNodeFilterProp="title"
          treeDefaultExpandAll
          showSearch
        />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          data-testid="stop-membership-submit"
          type="primary"
          danger
          htmlType="submit"
          icon={<StopOutlined />}
          size="large"
          disabled={loadingMutation}
          loading={loadingMutation}
        >
          Stop membership
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ManageMembershipStop
