import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useGoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from '@leecheuk/react-google-login'
import { GoogleOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'

import { GOOGLE_COOKIE_POLICY } from '../../../constants/AuthConstants'
import { REDIRECT_PATH } from '../../../constants/AppConstants'
import config from '../../../config'
import { Routes } from '../../../constants/RoutesConstants'
import { authenticate, SessionType } from '../../../utils'
import {
  GOOGLE_AUTHENTICATION_FAILED,
  API_AUTHENTICATION_FAILED,
  GOOGLE_LOGIN_BUTTON_ERROR,
} from '../../../constants/MessagesConstants'

type GoogleLoginErrorResponse = {
  details: string
  error: string
}

const GoogleLogin: React.FC<{ autoLoad?: boolean }> = ({ autoLoad = false }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSuccess = async (response: GoogleLoginResponse | GoogleLoginResponseOffline): Promise<void> => {
    if (!(response as GoogleLoginResponse)) {
      void message.error(GOOGLE_AUTHENTICATION_FAILED)
      console.error(response)

      return
    }

    try {
      setLoading(true)

      const token = (response as GoogleLoginResponse).tokenObj.id_token

      await authenticate(token)

      await authenticate(token, SessionType.BEEF)

      setAuthenticated(true)
    } catch (e) {
      setLoading(false)
      void message.error(API_AUTHENTICATION_FAILED)
      console.log(e)
    }
  }
  const { signIn, loaded } = useGoogleLogin({
    clientId: config.googleClientId,
    cookiePolicy: GOOGLE_COOKIE_POLICY,
    responseType: 'id_token',
    autoLoad,
    onSuccess,
    onRequest: (): void => undefined,
    onFailure: (failure: GoogleLoginErrorResponse): void => {
      void message.error(`${GOOGLE_LOGIN_BUTTON_ERROR} ${failure.error}`)
      console.error(failure.details)
    },
  })

  if (authenticated) {
    const redirectPath = localStorage.getItem(REDIRECT_PATH) || Routes.SEARCH

    localStorage.removeItem(REDIRECT_PATH)

    return <Redirect to={redirectPath} />
  }

  const disableButton: boolean = !loaded || loading

  return (
    <Button
      data-qa="google-login-button"
      data-testid="google-login-button"
      type="primary"
      size="large"
      icon={<GoogleOutlined />}
      onClick={(): void => signIn()}
      disabled={disableButton}
      loading={disableButton}
    >
      {loading ? 'Wait please...' : 'Login'}
    </Button>
  )
}

export default GoogleLogin
