import React from 'react'
import { Select, Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { ApolloError } from '@apollo/client'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

import { filterOptionByText } from '../../../../utils/inputUtils'

const rules = [
  {
    required: true,
    message: 'You must select a reason.',
  },
]

const SelectCreditReasonInput: React.FC<SelectCreditReasonInputProps> = ({
  loading,
  error,
  creditReasons,
  ...props
}) => {
  let warning: Pick<FormItemProps, 'validateStatus' | 'help'> = {}

  if (error) {
    warning = {
      validateStatus: 'error',
      help: 'There was an error retrieving the credit reasons',
    }
  }

  return (
    <Form.Item {...warning} name="reasonId" label="Reason" rules={props.required && !props.disabled ? rules : []}>
      <Select
        data-testid="credit-reasons-select"
        placeholder="Select a reason"
        size="large"
        loading={loading}
        disabled={loading || !!error || !creditReasons.length}
        showSearch
        filterOption={filterOptionByText}
        {...props}
      >
        {creditReasons.map(({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export type SelectCreditReasonInputProps = {
  creditReasons: { id: number; name: string }[]
  defaultValue?: string | undefined
  loading: boolean
  error?: ApolloError
  size?: SizeType
  disabled?: boolean
  required?: boolean
}

export default SelectCreditReasonInput
