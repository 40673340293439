import { Alert, Card, Tag, Table, Typography, Row, Col, Statistic } from 'antd'
import React from 'react'

import {
  BrandEnum,
  CustomerReferralFragment,
  ReferralStatusEnum,
  useGetCustomerReferralsQuery,
} from '../../../apollo/generated/api'
import LoadComponentError from '../../../packages/LoadComponentError'
import Loading from '../../../packages/Loading'
import UserAwareDateWithTz from '../../Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import { ManageCustomerProps } from '../props'

type NormalizedReferral = {
  id?: number
  name?: string
  content?: string
  date?: string | null
  status?: ReferralStatusEnum
}

type HeaderProps = {
  availableToSend?: number | null
  isEnabled?: boolean | null
  minimumOrdersForReferralAccess?: number | null
  numberOfOrdersUntilEnabled?: number | null
}
const { Text } = Typography
const referralStatusColors: { [state in ReferralStatusEnum]: string } = {
  CANCELLED: 'default',
  NOT_REDEEMED: 'gold',
  EXPIRED: 'volcano',
  REDEEMED: 'green',
}
const ReferralHeader: React.FC<HeaderProps> = ({
  isEnabled,
  availableToSend,
  minimumOrdersForReferralAccess,
  numberOfOrdersUntilEnabled,
}) => (
  <div data-testid="customer-referral-header" style={{ marginBottom: 30 }}>
    <Card
      title="Customer Referral Status"
      extra={[<>Enabled: {isEnabled ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}</>]}
      style={{ width: '100%' }}
    >
      <Row>
        <Col span={8}>
          <Statistic title="Available Referrals" value={availableToSend || 0} />
        </Col>

        {!isEnabled && (
          <>
            <Col span={8}>
              <Statistic title="Required Orders" value={minimumOrdersForReferralAccess || 0} />
            </Col>

            <Col span={8}>
              <Statistic title="Missing Orders" value={numberOfOrdersUntilEnabled || 0} />
            </Col>
          </>
        )}
      </Row>
    </Card>
  </div>
)

const normalizeReferrals = (referral: CustomerReferralFragment): NormalizedReferral => {
  const baseReferral = {
    id: referral.id || undefined,
    status: referral.status || undefined,
  }

  // eslint-disable-next-line no-underscore-dangle
  if (referral.__typename === 'ReferralEmail') {
    return {
      ...baseReferral,
      name: referral.recipientName || undefined,
      content: referral.recipientEmail || undefined,
      date: referral.sentAt,
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  if (referral.__typename === 'ReferralLink') {
    return {
      ...baseReferral,
      name: 'N/A',
      content: referral.link || undefined,
      date: referral.createdAt,
    }
  }

  return baseReferral
}

const renderStatus = ({ status }: NormalizedReferral): React.ReactElement => {
  let color = 'gray'
  let text = 'N/A'

  if (status) {
    color = referralStatusColors[status]
    text = status
      .toString()
      .toLowerCase()
      .split('_')
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join(' ')
  }

  return <Tag color={color}>{text}</Tag>
}
const ReferralsTable: React.FC<{ referrals: CustomerReferralFragment[]; customerTimezone?: string | null }> = ({
  referrals,
  customerTimezone,
}) => (
  <div data-testid="customer-referral-table">
    <Table<NormalizedReferral> dataSource={referrals.map(normalizeReferrals)} rowKey="id" pagination={false}>
      <Table.Column
        title="ID"
        dataIndex="id"
        render={value => (
          <Text code copyable>
            {value}
          </Text>
        )}
      />
      <Table.Column title="Status" render={renderStatus} />
      <Table.Column title="Name" dataIndex="name" />
      <Table.Column
        title="E-mail / Link"
        dataIndex="content"
        render={(value: string) => {
          if (value.startsWith('http')) {
            return (
              <a href={value} target="_blank" rel="noreferrer">
                {value}
              </a>
            )
          }

          return value
        }}
      />
      <Table.Column
        title="Generated At"
        dataIndex="date"
        render={date => (date ? <UserAwareDateWithTz datetime={date} customerTimezone={customerTimezone} /> : 'n/a')}
      />
    </Table>
  </div>
)

const ManageCustomerReferrals: React.FC<ManageCustomerProps> = ({ customer }) => {
  const { loading, data, error } = useGetCustomerReferralsQuery({
    variables: { id: customer.id },
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Loading />
  if (error) return <LoadComponentError errorMessage={error.message} />

  const headerProps = data?.customer?.referrals || {}
  const referrals = data?.customer?.referrals?.sent

  return (
    <>
      {customer.brand === BrandEnum.Bm && (
        <Alert type="info" message="Referrals not currently available for this customer." />
      )}
      <ReferralHeader {...headerProps} />
      <ReferralsTable referrals={referrals || []} customerTimezone={customer.timezone} />
    </>
  )
}

export default ManageCustomerReferrals
