import React, { FunctionComponent } from 'react'
import { Descriptions, Card, Tag, Divider, Typography } from 'antd'
import { Link, generatePath } from 'react-router-dom'

import BrandIcon from '../../../packages/BrandIcon'
import CountryFlag from '../../../packages/CountryFlag'
import { mappedStatus } from '../../../constants/OrderConstants'
import * as customProps from '../../../constants/OverridesConstants'
import { Routes } from '../../../constants/RoutesConstants'
import { formatCurrency, capitalize } from '../../../utils'
import { GetOrderManagementDetailsQuery } from '../../../apollo/generated/api'
import PaymentState from '../../../components/ManageOrder/PaymentState'
import ValidOrderDelivery from '../../../components/Validation/ValidOrderDelivery'
import PriceBreakDown from '../../../components/Shared/PriceBreakDown/PriceBreakDown'
import { ProductionSites } from '../../../constants/AppConstants'
import useLogger from '../../../logging'
import UserAwareDateWithTz from '../../../components/Shared/UserAwareDateWithTz/UserAwareDateWithTz'
import TimeWithTimeZone, {
  DateFormats,
  formatTimezone,
} from '../../../components/Shared/TimeWithTimezone/TimeWithTimezone'
import { useGetSteakUserInfoQuery } from '../../../apollo/generated/steak'
import { getCreditRevenueTierData } from '../../../utils/utils'

const { Text } = Typography

export const ProductionDate = ({
  datetime,
  productionSite,
}: {
  datetime: string
  productionSite: string | null | undefined
}) => {
  const logger = useLogger()
  const errorMsg = `Unknown production site: ${productionSite}`
  const productionSiteTimezone = (() => {
    let tz = 'America/Los_Angeles'

    switch (productionSite) {
      case ProductionSites.Ca1:
      case ProductionSites.Nyc1:
      case ProductionSites.Tex1:
        tz = 'America/Los_Angeles'
        break
      case ProductionSites.Mel1:
      case ProductionSites.Per1:
      case ProductionSites.Syd1:
        tz = 'Australia/Melbourne'
        break
      case ProductionSites.Nwg:
        tz = 'Europe/Amsterdam'
        break
      default:
        logger.error(errorMsg)
        console.error(errorMsg)
        break
    }

    return tz
  })()

  return <UserAwareDateWithTz datetime={datetime} customerTimezone={productionSiteTimezone} useTooltip={false} />
}

const ManageOrderSidebar: FunctionComponent<ManageOrderSidebarProps> = ({ data: { order } }) => {
  const currency = order?.pricing?.currency
  const customerId = order?.customer?.id?.toString() || ' -1'
  const customerPath = generatePath(Routes.CUSTOMER_DETAIL, { id: customerId })
  const delivery = order?.deliveries?.length ? order.deliveries[0] : null
  const { data: steakUser } = useGetSteakUserInfoQuery({ variables: { id: parseInt(customerId, 10) } })
  const creditRevenueTierData = getCreditRevenueTierData(steakUser?.userInfo.creditRevenueTier)

  return (
    <Card
      {...customProps.CARD}
      title="Details"
      data-testid="order-sidebar"
      extra={<ValidOrderDelivery order={order} />}
    >
      <Descriptions column={1} bordered>
        {order?.brand && (
          <Descriptions.Item label="Brand">
            <BrandIcon brand={order.brand} />
          </Descriptions.Item>
        )}

        {order?.country && (
          <Descriptions.Item label="Country">
            <CountryFlag country={order.country} />
          </Descriptions.Item>
        )}

        {order?.customer && (
          <>
            <Descriptions.Item label="Customer">
              <Link data-testid="order-sidebar-customer" to={customerPath}>
                See profile (#{order.customer.id})
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Customer Orders">
              <Link data-testid="order-sidebar-orders" to={generatePath(Routes.CUSTOMER_ORDERS, { id: customerId })}>
                See orders
              </Link>
            </Descriptions.Item>

            <Descriptions.Item label="Customer Complaints">
              <Link
                data-testid="order-sidebar-complaints"
                to={generatePath(Routes.CUSTOMER_COMPLAINTS, { id: customerId })}
              >
                See complaints
              </Link>
            </Descriptions.Item>

            {creditRevenueTierData.visible && (
              <Descriptions.Item label="Credit Revenue Tier">
                <span style={{ padding: '8px', color: 'rgb(0,0,0)', background: creditRevenueTierData.color }}>
                  {creditRevenueTierData.text}
                </span>
              </Descriptions.Item>
            )}
          </>
        )}

        {order?.state && (
          <Descriptions.Item label="Status">
            <Tag color={mappedStatus[order.state]?.toString()}>{capitalize(order.state)}</Tag>
          </Descriptions.Item>
        )}

        {order?.paymentState && (
          <Descriptions.Item label="Payment Status">
            <PaymentState state={order.paymentState.toLowerCase()} />
          </Descriptions.Item>
        )}

        {order?.customer?.timezone && (
          <Descriptions.Item label="Timezone">{formatTimezone(order.customer.timezone)}</Descriptions.Item>
        )}

        {delivery && (
          <Descriptions.Item label="Delivery Date">
            {delivery.date && (
              <TimeWithTimeZone
                datetime={delivery.date}
                timezone={order?.customer?.timezone}
                format={DateFormats.DateText}
              />
            )}
            <br />
            <Text type="secondary">
              <small>
                {delivery.timeSlot?.from} — {delivery.timeSlot?.to} • {delivery.timeSlot?.shipper?.name}
              </small>
            </Text>
          </Descriptions.Item>
        )}

        {order?.billDate && (
          <Descriptions.Item label="Billing Date">
            <TimeWithTimeZone
              datetime={order.billDate}
              timezone={order.customer?.timezone}
              format={DateFormats.DateText}
            />
          </Descriptions.Item>
        )}

        {order?.completedAt && (
          <Descriptions.Item label="Completed At">
            <TimeWithTimeZone
              datetime={order.completedAt}
              timezone={order.customer?.timezone}
              format={DateFormats.DateText}
            />
          </Descriptions.Item>
        )}

        {order?.productionInfo && (
          <>
            <Descriptions.Item label="Production Site">
              <Text>
                <small>
                  {order.productionInfo.productionSite}, Transit Time(h): {order.productionInfo.transitTime}
                </small>
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Production Date">
              <Text>
                {order.productionInfo.productionDatetime ? (
                  <ProductionDate
                    datetime={order.productionInfo.productionDatetime}
                    productionSite={order.productionInfo.productionSite}
                  />
                ) : (
                  'Not Available'
                )}
              </Text>
            </Descriptions.Item>
          </>
        )}

        {order?.segment && <Descriptions.Item label="Plan Type">{capitalize(order.segment)}</Descriptions.Item>}

        {order?.plan?.pricePerPortion && (
          <Descriptions.Item label="Price per Portion">
            {formatCurrency(order.plan.pricePerPortion, currency)}
          </Descriptions.Item>
        )}
      </Descriptions>

      <Divider />

      <PriceBreakDown pricing={order?.pricing} currency={currency} brand={order?.brand} />
    </Card>
  )
}

export type ManageOrderSidebarProps = {
  data: GetOrderManagementDetailsQuery
}

export default ManageOrderSidebar
