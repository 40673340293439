import styled from 'styled-components'

export const Logout = styled.button`
  background: none;
  border: none;
  cursor: hand;
  cursor: pointer;
  padding: 0;
  width: 100%;
  text-align: left;
`
