import { InboxOutlined } from '@ant-design/icons'
import { Upload, Row, Col, Card, Divider, Typography, Alert, Result } from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import React, { useState, useEffect } from 'react'
import * as CSV from 'csv-string'

import { useGetCreditReasonsFromOrderLazyQuery } from '../../apollo/generated/api'

import { MassCreditInput } from './PreviewMassCredit/PreviewMassCredit'
import PreviewMassCredit from './PreviewMassCredit'
import MassCreditForm from './MassCreditForm'

const { Text } = Typography

const ManageMassCredit: React.FC<{}> = () => {
  const [creditData, setCreditData] = useState<MassCreditInput[]>([])
  const [mutationSucceeded, setMutationSucceeded] = useState(false)
  const [getCreditReasons, { loading, data }] = useGetCreditReasonsFromOrderLazyQuery()

  useEffect(() => {
    // We need one customer id to figure out the available credit reasons.
    // This is a hack that gets the first order number and requests the reasons from that order's customer.
    if (creditData.length !== 0) {
      void getCreditReasons({ variables: { number: creditData[0].orderNumber } })
    }
  }, [creditData])

  const parseCsv = (text: string): MassCreditInput[] => {
    return CSV.parse(text).map((row: string[]): MassCreditInput => {
      return {
        orderNumber: row[0],
        amount: Number(row[1]),
      }
    })
  }

  const parseFile = async (file: RcFile) => {
    await file
      .text()
      .then(text => {
        setCreditData(parseCsv(text))
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Overriding antd file upload, as the Upload component requires you to handle the code that uploads the file
  // to a server. Since the csv is never uploaded to any server this mockRequest is needed.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      void parseFile(file)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      onSuccess({}, file)
    }, 10)
  }

  if (mutationSucceeded) {
    return <Result status="success" title="Successfully imported all mass credit data." />
  }

  return (
    <Row gutter={32} align="top">
      <Col span={12}>
        <Upload.Dragger
          disabled={creditData.length !== 0 || loading}
          accept=".csv"
          customRequest={customRequest}
          onRemove={() => setCreditData([])}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            CSV format must follow the pattern <Text code>order_number,amount</Text>
          </p>
        </Upload.Dragger>

        <Alert message="NOTE: No headers are required." type="info" style={{ marginTop: 20 }} showIcon />
      </Col>

      {creditData.length ? (
        <Col span={12}>
          <Card title="Preview and submit data">
            <PreviewMassCredit massCredit={creditData} />

            <Divider style={{ marginTop: 25 }}>Double check the data before submitting</Divider>
            <MassCreditForm
              creditData={creditData}
              creditReasons={data?.order?.customer?.creditReasons || []}
              mutationSucceeded={setMutationSucceeded}
            />
          </Card>
        </Col>
      ) : null}
    </Row>
  )
}

export default ManageMassCredit
