const defaultDateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

export const getDayName = (date: Date = new Date()): string => date.toLocaleString('en-US', { weekday: 'long' })

export const getFullYear = (date: Date = new Date()): number => date.getFullYear()

export const formatDate = ({
  date,
  options = defaultDateFormat,
}: {
  date: Date
  options?: Intl.DateTimeFormatOptions
}): string => new Date(date).toLocaleDateString('en-US', options)

export const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
