import { AddressInputType, CreateCustomerAddressInputType, Address } from '../../apollo/generated/api'
import { CustomerAddressFormValues } from '../../components/Forms/CustomerAddressForm'
import { AddressDynamicFields } from '../../components/Forms/CustomerAddressForm/types'
import { AddressMeta } from '../../components/ManageCustomer/props'

export const buildAddressInput = (formValues: CustomerAddressFormValues): AddressInputType => ({
  firstName: formValues.firstName || '',
  lastName: formValues.lastName || '',
  line1: formValues.line1 || '',
  line2: formValues.line2,
  company: formValues.company,
  houseNumber: formValues.houseNumber,
  zipcode: formValues.zipcode || '',
  city: formValues.city || '',
  type: formValues.type,
  phone: formValues.phone || '',
  instructions: formValues.instructions,
  instructionKey: formValues.instructionKey,
  stateId: formValues.stateId,
})

export const buildCustomerAddressInput = (
  formValues: CustomerAddressFormValues,
): Omit<CreateCustomerAddressInputType, 'customerId'> => ({
  isDefault: formValues.isDefault,
  description: null,
  address: buildAddressInput(formValues),
})

export const buildDynamicFormFields = (meta: AddressMeta): AddressDynamicFields[] => {
  const dynamicFields: AddressDynamicFields[] = []

  if (meta.isStateRequired) {
    dynamicFields.push('state')
  }

  if (meta.isTypeRequired) {
    dynamicFields.push('addressType')
  }

  return dynamicFields
}

export const compareAddresses = (address: Address, defaultAddress?: Address): boolean => {
  if (!defaultAddress) return false

  const addressEntries = Object.entries(address).filter(([key]) => key !== 'id')
  const defaultAddressEntries = Object.entries(defaultAddress).filter(([key]) => key !== 'id')

  return addressEntries.toString() === defaultAddressEntries.toString()
}

/**
 * Formats an address for clipboard
 * This standard should be used across the app to ensure consistency
 * @param address
 * @param instructionsText
 * @returns formatted address (string)
 */
export const buildCopyableAddress = (
  address: Omit<Address, 'id' | 'deliveryCode' | 'type' | 'fullStreetAddress'>,
  instructionsText: string,
): string => {
  const formattedFullAddress = `${address.line1 || ''} ${address.line2 || ''} ${address.houseNumber || ''}`.trim()
  const instructions = instructionsText || address.instructions || ''

  return `
    ${address.firstName} ${address.lastName}
    ${formattedFullAddress}
    ${address.zipcode} — ${address.city}
    ${address.state?.name || ''}
    ${address.company || ''}
    ${address.phone}
    ${instructions}
  `
    .replace(/^\s*[\r\n]/gm, '')
    .trim()
}
