import React from 'react'
import { CodeSandboxOutlined, DeleteOutlined, UnlockOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { generatePath } from 'react-router-dom'
import { History } from 'history'
import { Alert } from 'antd'

import { Routes } from '../../../constants/RoutesConstants'
import ContainerHeader, { ContainerHeaderMenu, ContainerHeaderAction } from '../../ContainerHeader'
import { CustomerAvailableAction } from '../../../apollo/generated/api'

import unlockAccount from './actions/unlockAccount'
import anonymizeAccount from './actions/AnonymizeAccount'

const actionsEnums = {
  CREATE_ORDER: 'CREATE_ORDER',
}
const headerConfiguration: {
  menu: ContainerHeaderMenu[]
  actions: ContainerHeaderAction<{
    id: number
    appUrl: string
    availableActions: ReadonlyArray<CustomerAvailableAction>
  }>[]
} = {
  menu: [
    {
      route: {
        pattern: Routes.CUSTOMER_DETAIL,
        prefix: false,
      },
      icon: 'CUSTOMER_DETAILS',
      title: 'Account',
    },
    {
      route: {
        pattern: Routes.CUSTOMER_ORDERS,
        prefix: true,
      },
      icon: 'CUSTOMER_ORDERS',
      title: 'Orders',
    },
    {
      route: {
        pattern: Routes.CUSTOMER_ACCOUNT_HISTORY,
        prefix: true,
      },
      icon: 'CUSTOMER_ACCOUNT_HISTORY',
      title: 'History',
    },
    {
      route: {
        pattern: Routes.CUSTOMER_CREDIT_HISTORY,
        prefix: true,
      },
      icon: 'CUSTOMER_CREDIT_HISTORY',
      title: 'Credits',
    },
    {
      route: {
        pattern: Routes.CUSTOMER_ADDRESSES,
        prefix: true,
      },
      icon: 'CUSTOMER_ADDRESSES',
      title: 'Addresses',
    },
    {
      route: {
        pattern: Routes.CUSTOMER_PAYMENT_SOURCES,
        prefix: true,
      },
      icon: 'CUSTOMER_PAYMENTS',
      title: 'Payments',
      subMenu: true,
    },
    {
      route: {
        pattern: Routes.CUSTOMER_RECIPE_PREFERENCES,
        prefix: true,
      },
      icon: 'CUSTOMER_RECIPE_PREFERENCES',
      title: 'Recipe Preferences',
      subMenu: true,
    },
    {
      route: {
        pattern: Routes.CUSTOMER_PLAN,
        prefix: true,
      },
      icon: 'CUSTOMER_PLAN',
      title: 'Plan',
      subMenu: true,
    },
    {
      route: {
        pattern: Routes.CUSTOMER_REWARDS,
        prefix: true,
      },
      icon: 'CUSTOMER_REWARDS',
      title: 'Rewards',
      subMenu: true,
    },
    {
      route: {
        pattern: Routes.CUSTOMER_REFERRALS,
        prefix: true,
      },
      icon: 'CUSTOMERS',
      title: 'Referrals',
      subMenu: true,
    },
    {
      route: {
        pattern: Routes.CUSTOMER_COMPLAINTS,
        prefix: true,
      },
      icon: 'COMPLAIN_ICON',
      title: 'Complaints',
    },
    {
      route: {
        pattern: Routes.CUSTOMER_INCIDENTS,
        prefix: true,
      },
      icon: 'COMPLAIN_ICON',
      title: 'Incidents',
    },
    {
      route: {
        pattern: Routes.CUSTOMER_MESSAGES,
        prefix: true,
      },
      icon: 'CUSTOMER_MESSAGES',
      title: 'Messages',
      subMenu: true,
    },
  ],
  actions: [
    {
      title: 'Create order',
      key: 'action-create-order',
      icon: <CodeSandboxOutlined />,
      onClick: ({ id }: { id: number }, { history }: { history: History }): void =>
        history.push(generatePath(Routes.CUSTOMER_ORDER_CREATION, { id })),
      isVisible: ({ availableActions }): boolean =>
        availableActions.some(({ action }): boolean => action === actionsEnums.CREATE_ORDER),
    },
    {
      title: 'Unlock account',
      key: 'action-unlock-account',
      icon: <UnlockOutlined />,
      onClick: unlockAccount,
    },
    {
      title: 'Anonymize account',
      key: 'action-anonymize-account',
      icon: <DeleteOutlined />,
      onClick: anonymizeAccount,
    },
    {
      title: 'Login as user',
      key: 'action-login-as-user',
      icon: <UserSwitchOutlined />,
      onClick: ({ appUrl, id }) => window.open(`${appUrl}/admin/users/${id}/become`, '_blank', 'noopener,noreferrer'),
    },
  ],
}

const ManageCustomerHeader: React.FC<ManageCustomerHeaderProps> = ({ customer, currentPath }) => {
  const creditRevenueAlertText = getCreditRevenueTierAlertText(customer.creditRevenueTier)

  return (
    <>
      <ContainerHeader
        {...headerConfiguration}
        title={customer.email}
        path={currentPath}
        object={customer}
        defaultIcon="CUSTOMER_DETAILS"
      />
      {creditRevenueAlertText && <Alert message={creditRevenueAlertText} type="error" style={{ marginBottom: 20 }} />}
    </>
  )
}

/* eslint-disable max-len */
function getCreditRevenueTierAlertText(tierNum?: number): string | undefined {
  switch (tierNum) {
    case 3:
      return 'Potentially Fraudulent - CCR at 50%+, 30-49% credits as % of revenue. Please check with supervisor before proceeding.'
    case 4:
      return 'Potentially Fraudulent - CCR at 50%+, 50-74% credits as % of revenue. Please check with supervisor before proceeding.'
    case 5:
      return 'Potentially Fraudulentt - CCR at 50%+, 75-99% credits as % of revenue. Please check with supervisor before proceeding.'
    case 6:
      return 'Potentially Fraudulent - CCR at 50%+, 100%+ credits as % of revenue. Please check with supervisor before proceeding.'
    default:
      break
  }

  return undefined
}
/* eslint-enable max-len */

type ManageCustomerHeaderProps = {
  customer: {
    id: number
    email: string
    appUrl: string
    availableActions: ReadonlyArray<CustomerAvailableAction>
    creditRevenueTier?: number
  }
  currentPath: string
}

export default ManageCustomerHeader
