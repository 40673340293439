import React, { useContext } from 'react'

type FoodSafety = {
  detailsDisabled: boolean
  setDetailsDisabled: Function
}
/**
 * This context is used to disable all the food safety details form fields when the option N/A is ticket.
 */
export const FoodSafetyContext = React.createContext<FoodSafety>({
  detailsDisabled: false,
  setDetailsDisabled: Function,
})

export const useFoodSafetyContext = (): FoodSafety => useContext(FoodSafetyContext)
